import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";

const chartData = {
  labels: ["September", "October", "November", "December", "January"],
  datasets: [
    {
      label: "Score",
      data: [0, 1, 3, 6, 8],
      borderColor: "green",
      backgroundColor: "transparent",
      pointRadius: 5,
      pointBorderWidth: 2,
      pointBorderColor: "green",
      fill: false
    },
  ]
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  }
};

const EnpsChart = () => {
  const [data, setData] = useState(chartData);

  useEffect(() => {
    setData({
      ...data,
      datasets: [
        {
          ...data.datasets[0],
        //   data: data.labels.map(() => Math.floor(Math.random() * 100))
        }
      ]
    });
  }, []);

  return (
    <Line
      data={data}
      options={options}
      height={50}
      width={100}
    />
  );
};

export default EnpsChart;
