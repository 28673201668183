import React from "react";
import { useTranslation, withTranslation } from "react-i18next";

const ResponseRequired = () => {
    const { t } = useTranslation();

    return (
        <span style={{fontSize: "11px"}}>* {t("REQUIRED")}</span>
    )
}

export default ResponseRequired