import React from "react";
import Chart from "react-apexcharts";

const DonutChartWithTotal = () => {
  // sample data
  const series = [5000, 10000, 100];
  const total = series.reduce((a, b) => a + b, 0);

  const options = {
    chart: {
      type: 'donut',
    },
    labels: ['Site 1', 'Site 2', 'Site 3'],
    colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return  ''
      },
      total: {
        show: true,
        label: 'Total',
        formatter: function () {
          return total;
        }
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: false,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: undefined,
              offsetY: -10
            },
            value: {
              show: true,
              fontSize: '72px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              color: undefined,
              offsetY: 16,
              formatter: function (val) {
                if (val >= 1000) {
                  return (val / 1000).toFixed(0) + 'k';
                } else {
                  return val;
                }
              }
            },
            total: {
              show: true,
              showAlways: false,
              label: '',
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: '#373d3f',
              formatter: function (w) {
                const totalValue = w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
                if (totalValue >= 1000) {
                  return (totalValue / 1000).toFixed(0) + 'k';
                } else {
                  return totalValue;
                }
              }
            },
          }
        }
      }
    }
  };

  return (
    <Chart options={options} series={series} type="donut" />
  );
};

export default DonutChartWithTotal;
