import moment from 'moment';
import Parse from 'parse';
import React from "react";
import {
  Card,
  CardBody,
  Col,
  Form,
  Row,
} from "shards-react";
import { ReactComponent as ArrowDownIcon } from "../../images/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../images/arrow_up.svg";

import { withTranslation } from "react-i18next";


class ActivityHistory extends React.Component {
    constructor(props) {
        super(props);
        const {t} = this.props
        this.state = {
          data:[],
          ideaQuestions: [],
          answers:[],
          category:null,
          department:null,
          date: '',
          categoryQuestions: [],
          filterQuestions: [],
          selectedFilterQ: [],
          filterQAnswers: [],
          categoryQAnswers: [],
          ideaDescription: String,
          file:null, 
          buttonNext:true,
          descriptionValid:'',
          remainingCharacters: 250,
          visible: true,
          filterVisible: false,
          filterQuestionsVisible: false, 
          ideaQuestionsVisible: false,
          hideNextButton: false,
          userName: 'User Name',
          sectionTitle:'',
          formButtonTitle: 'Continuar',
          ideaNumber: '#00008',
          firstActive: false,
          secondActive: false,
          thirdActive: false,
          fourthActive: false,
          progress: [25,75],
          commentTitle: t('COMMENTS_IDEA'),
          submitBtnTitle: "Actualizar IDEA", 
          showCompleted: false,
          isOpen: false,
        }

        this.change = this.change.bind(this);
        this.setCategory = this.setCategory.bind(this);
        this.setDate = this.setDate.bind(this);
        this.setIdeaDescription = this.setIdeaDescription.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.showNext = this.showNext.bind(this);
        this.addAnswer = this.addAnswer.bind(this);
        this.saveIdea = this.saveIdea.bind(this);
        this.showNext = this.showNext.bind(this);
        this.progressBtnPress = this.progressBtnPress.bind(this);
        this.submitEvaluation = this.submitEvaluation.bind(this);
        this.addToQueue = this.addToQueue.bind(this);
        this.showCompletedIdeaForm = this.showCompletedIdeaForm.bind(this);
    }

    componentDidMount() {
      // let currUser = Parse.User.current();
      // this.getActivityHistory()
    //   this.showCorrectScreen();
    //   this.getUserName(currUser);
    //   this.getDate();
    //   this.setPercentage();
      this.setState({data: this.props.history})
    }

    componentDidUpdate(prevProps) {
      const currentStatus = prevProps.reloadData
      const nextStatus = this.props.reloadData
      
      if (currentStatus !== nextStatus) {
      //  this.setState({open: this.props.isOpen})
     
        this.getActivityHistory()
      }
    }


    getActivityHistory() {
      const { currIdea } = this.props;
      this.setState({
        data: currIdea
      });
    }

    showCorrectScreen() {
      const idea = this.props.idea;
      const percentage = idea.get("progress")[0];
      if (percentage == 100) {
        this.setState({showCompleted: true});
      } else {
        this.setState({showCompleted: false});
      }
    }

    setPercentage() {
      const idea = this.props.idea;
      const percentage = idea.get("progress")[0];

      switch(percentage) {
        case 25:
          // code block
      
          this.setState({firstActive: true,
            secondActive: false,
            thirdActive: false,
            fourthActive: false,});
          break;
        case 50:
          // code block
         
          this.setState({firstActive: false,
            secondActive: true,
            thirdActive: false,
            fourthActive: false,});
          break;
        case 75:
          // code block
       
          this.setState({firstActive: false,
            secondActive: false,
            thirdActive: true,
            fourthActive: false,});
          break;
        case 100:
          // code block
         
          this.setState({firstActive: false,
            secondActive: false,
            thirdActive: false,
            fourthActive: true,});
          break;
        default:
          // code block
          this.setState({firstActive: false,
            secondActive: false,
            thirdActive: false,
            fourthActive: false,});
          break;
      }
      
    }

    getDate() {
      this.setState({
        date: new Date()
      });
    }

    async getUserName(user) {
      var query = new Parse.Query(Parse.User);
      query.equalTo("objectId", user.id);
      const results = await query.find();
      const firstName = results[0].get('firstName');
      const lastName = results[0].get('lastName');
      const fullName = firstName + ' ' + lastName;
      this.setState({
        userName: fullName
      });
    }

    handleSubmit() {
      const { formButtonTitle } = this.state;
      
      if (formButtonTitle == "Continuar") {
        this.showNext();
      } else {
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
          
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas requeridas.");
        } else {
        alert("Su IDEA ha sido sometida.");
      }
    }
  }

    showNext() {
      const isShowingForm = this.state.visible;
      const isShowingFilter = this.state.filterVisible;
      const isShowingFilterQuestions = this.state.filterQuestionsVisible;
      const isShowingQuestions = this.state.ideaQuestionsVisible;

      if (isShowingForm && !isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {

        const { category, department, descriptionValid } = this.state;

        if (!category || !department || !descriptionValid) {
          alert("Por favor seleccione una categoria, un departamento y asegurese de que la descripcion de la idea sea valida.");
        } else {
          this.setState({
            visible: !isShowingForm,
            filterVisible: !isShowingFilter,
            buttonState: false,
            hideNextButton: true,
          });
        }
      } else if (!isShowingForm && isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {
        this.setState({
          hideNextButton: false,
          filterVisible: !isShowingFilter,
          filterQuestionsVisible: !isShowingFilterQuestions,
          buttonState: false,
        });
      } else if (!isShowingForm && !isShowingFilter && isShowingFilterQuestions && !isShowingQuestions) {
        var allAnswered = false;
        
        var isInvalid = false
        this.state.filterQAnswers.forEach((item,idx) => {
          
          if (!item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas.");
        } else {
          this.setState({
            filterQuestionsVisible: !isShowingFilterQuestions,
            ideaQuestionsVisible: !isShowingQuestions,
            buttonState: false,
            formButtonTitle: 'Someter',
          });
        }
      } else if (!isShowingForm && !isShowingFilter && !isShowingFilterQuestions && isShowingQuestions) {
        
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
        
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Please answer all required questions");
        } else {
          alert('¡Congrats! Thanks for submitting your idea.', 
          this.resetIdeaForm());
        }
      }
     
    }

    resetIdeaForm() {
      window.location.reload();
    }

    fetchNewData() {
      const className = "IdeaDepartment";

      var ItemClass = Parse.Object.extend(className);
      var query = new Parse.Query(ItemClass);

      query.find()
      .then((results) => {
          this.setState({
              data: results
          });
         
      }, (error) => {
          this.setState({
              data: []
          });
        // The object was not retrieved successfully.
        // error is a Parse.Error with an error code and message.
      });
    }

    fetchQuestions() {
      const className = "IdeaQuestion";

      var ItemClass = Parse.Object.extend(className);
      var query = new Parse.Query(ItemClass);

      query.find()
      .then((results) => {
       
          this.setState({
              ideaQuestions: results
          });
      }, (error) => {
          this.setState({
              data: []
          });
        // The object was not retrieved successfully.
        // error is a Parse.Error with an error code and message.
      });
    }

    fetchFilterQuestions() {
      const className = "FilterQuestion";

      var ItemClass = Parse.Object.extend(className);
      var query = new Parse.Query(ItemClass);
      query.find()
      .then((results) => {
        
          this.setState({
              filterQuestions: results
          });
      }, (error) => {
          this.setState({
              filterQuestions: []
          });
        // The object was not retrieved successfully.
        // error is a Parse.Error with an error code and message.
      });
    }

    handleFilterChange(newFilter) {
      const newCategory = newFilter;
      const { filterQuestions, selectedFilterQ } = this.state;
     
      var filteredData;
      if (newCategory !== 'Todas') {
        filteredData = filterQuestions.filter(item => item.get("filter") === newCategory);
      }  else {
        filteredData = filterQuestions;
      }
      
      this.setState({
        selectedFilterQ: filteredData
      }, this.addFilterAnswer(filteredData));
      
      this.showNext();
    }

    handleCategoryChange(selectedCategory) {
      const newCategory = selectedCategory;
      const { ideaQuestions } = this.state;

      var filteredData = ideaQuestions.filter(item => item.get("category") === newCategory );
            
      this.setState({
        categoryQuestions: filteredData
      }, this.addAnswer(filteredData));
    }

    onSubmit = e => {
      e.preventDefault();
      alert('Form submitted');
    };

    saveIdea() {
      // Simple syntax to create a new subclass of Parse.Object.
      var Idea = Parse.Object.extend("Idea");
      // Create a new instance of that class.
      var ideaInfo = new Idea();

      const currentUser = Parse.User.current();
      const userId = currentUser.get("username");
    
      // IDEA Properties
      ideaInfo.set("proponent", userId);
      ideaInfo.set("department",this.state.department);
      ideaInfo.set("category", this.state.category);
      ideaInfo.set("date", this.state.date);
      ideaInfo.set("description", this.state.ideaDescription);
      ideaInfo.set("file", this.state.file);
      ideaInfo.set("status", "saved");
      ideaInfo.set("progress", [0,100]);
      ideaInfo.set("customUpdatedAt", new Date());
      ideaInfo.save()
      .then((ideaInfo) => {
        if(ideaInfo && ideaInfo.id) {
          fetch(`https://services.murmuratto.com/update-file/${process.env.REACT_APP_CLIENT_NAME || 'murmuratto-demo'}/Idea/file/${ideaInfo.id}`).then(() => {})
        }
        
        // Execute any logic that should take place after the object is saved.
        this.resetForm();
        alert('Su IDEA fue guardada exitosamente.');
      }, (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        alert('Failed to create new object, with error code: ' + error.message);
      });
    }

    resetForm() {
      this.setState({proponent: '', department: '', category: '', ideaDescription:'', date: new Date(),file: '', remainingCharacters: 250, descriptionValid:''});
    }

    change(event) {
  
      this.setState({department: event.target.value});
   
    }

    setCategory(categoryName) {
      this.setState({
        category: categoryName
      })

      this.handleCategoryChange(categoryName)
    
    }

    setFilter(filterName) {
      
      this.handleFilterChange(filterName);
    }

    clickedPrint() {
      
    }

    setDate(ideaDate) {
      this.setState({
        date: ideaDate
      })
     
    }

    filterQuestionAnswerChange(event, idx) {
      
      this.state.filterQAnswers[idx].answer = event.target.value;
     
    }

    questionAnswerChange(event, idx) {
     
      this.state.answers[idx].answer = event.target.value;
     
    }

    setIdeaDescription(event) {
      const description = event.target.value;
      const maxCharacters = 250;
      const charCount = maxCharacters - description.length
      var isValid = null;

      if (charCount < 0 && description.length > 0) {
        isValid = false
      } else if (charCount > 0 && description.length > 0) {
        isValid = true
      } else {
        isValid = null
      }

    
      this.setState({
        descriptionValid: isValid,
        ideaDescription: description,
        remainingCharacters: charCount
      })
      
    }

    selectFile(file) {
   
    }

    addAnswer(filteredQuestions) {
      filteredQuestions.map((item, idx) => (
        
        this.setState((prevState) => ({
          answers: [...prevState.answers, {question:item.get("question"), answer:"", required: item.get("required")}],
        }))
      ))
     
    }

    addFilterAnswer(filteredData) {
     
    
      var newItems = [];
      filteredData.forEach((item, idx) => {
        newItems.push({question:item.get("question"), answer:''});
      })

      this.setState({ filterQAnswers: newItems}, () => {
          
        });
    }

    submitEvaluation() {
      const { progress, ideaDescription, userName } = this.state;
      const { idea, closeIdea } = this.props;
    
      // IDEA Properties
      if (progress.length > 0) {
        idea.set("progress", progress);
      }

      if (idea.get("status") == 'Idea Devuelta - Mas Informacion') {
        idea.set("needsEvaluation", true);
      }

      if (ideaDescription.length > 0) {
          var comments = idea.get("comments");
          var myProgress = progress;
          comments.push({"comment": ideaDescription, "date": new Date(), "user": userName, "progress": myProgress});
          idea.set("comments", comments);
          idea.set("completed", false);
      }
      idea.set("edited", true);
      idea.set("customUpdatedAt", new Date());

      // Idea History
      
      const ideaObjectJson = idea.toJSON();
      ideaObjectJson.ideaReference = ideaObjectJson.objectId
      delete ideaObjectJson.objectId;
      delete ideaObjectJson.comments;
      delete ideaObjectJson.date;
      delete ideaObjectJson.file;
      delete ideaObjectJson.proponentObj;
      delete ideaObjectJson.responsible;
      delete ideaObjectJson.createdAt;
      delete ideaObjectJson.customUpdatedAt;
      delete ideaObjectJson.updatedAt;
      const IdeaHistoryItem = Parse.Object.extend("IdeaHistory");
      const newItem = new IdeaHistoryItem();
      
     
      newItem.set(ideaObjectJson)
      newItem.set("customUpdatedAt", new Date());
    
      Parse.Object.saveAll([idea, newItem], {useMasterKey: true})
      .then((ideaInfo) => {
        // Execute any logic that should take place after the object is saved.
        // 
        if (progress[0] == 100) {
          alert('Su IDEA ha sido actualizada. ¡Gracias!', this.showCompletedIdeaForm());
        } else {
          alert('Su IDEA ha sido actualizada. ¡Gracias!', closeIdea());
        }
      }, (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        alert('Failed to create new object, with error code: ' + error.message);
      });
    }

    addToQueue() {
      // const { progress, ideaDescription, userName } = this.state;
      const { idea } = this.props;
    
      // IDEA Properties
      idea.set("status", "En Espera");
      
      idea.set("customUpdatedAt", new Date());
      idea.save()
      .then((ideaInfo) => {
        // Execute any logic that should take place after the object is saved.
        // 
        // if (progress[0] == 100) {
        //   alert('Su IDEA ha sido actualizada. ¡Gracias!', this.showCompletedIdeaForm());
        // } else {
        //   alert('Su IDEA ha sido actualizada. ¡Gracias!', window.location.reload());
        // }
        alert('Su IDEA ha sido actualizada. ¡Gracias!', window.location.reload());
      }, (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        alert('Failed to create new object, with error code: ' + error.message);
      });
    }

    progressBtnPress(e) {
      const { idea, changeStatus } = this.props;

      e.preventDefault();
      const value = e.target.value;
      if (value==25) {
        this.setState({
          progress:[25,75],
          firstActive: true,
          secondActive: false,
          thirdActive: false,
          fourthActive: false,
        });
      } else if (value==50) {
        this.setState({
          progress:[50,50],
          firstActive: false,
          secondActive: true,
          thirdActive: false,
          fourthActive: false,
        });
      } else if (value==75) {
        this.setState({
          progress:[75,25],
          firstActive: false,
          secondActive: false,
          thirdActive: true,
          fourthActive: false,
        });
      } else if (value==100) {
        this.setState({
          progress:[100,0],
          firstActive: false,
          secondActive: false,
          thirdActive: false,
          fourthActive: true,
        });
      }

      if (value > idea.get("progress")[0]) {
        changeStatus(true)
      }

    }

    getDate(date) {
     
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    }

    showCompletedIdeaForm() {
      this.setState({showCompleted: true});
    }

    getDate(date) {
  
        return moment(date).format('DD/MM/YYYY');
      }

    openHistoryItem(index) {
     // alert(this.state.data[index].get("type"))
      // 1. Make a shallow copy of the items
      let items = [...this.props.history];
      // 2. Make a shallow copy of the item you want to mutate
      let item = items[index];
      // 3. Replace the property you're intested in
      item.isOpen = true;
      // 4. Put it back into our array. N.B. we *are* mutating the array here, 
      //    but that's why we made a copy first
      items[index] = item;
      // 5. Set the state to our new copy
      this.setState({data: items});
    }
    
    closeHistoryItem(index) {
      // alert(this.state.data[index].get("type"))
      // 1. Make a shallow copy of the items
      let items = [...this.state.data];
      // 2. Make a shallow copy of the item you want to mutate
      let item = items[index];
      // 3. Replace the property you're intested in
      item.isOpen = false;
      // 4. Put it back into our array. N.B. we *are* mutating the array here, 
      //    but that's why we made a copy first
      items[index] = item;
      // 5. Set the state to our new copy
      this.setState({data: items});
    }

    render() {
        const { data } = this.state

        return(
            <Row>
            {/* Latest Orders */}
            <Col lg="12" className="m-auto">
                    <h6 style={{fontWeight: 500, color: '#303030'}}>Stage History: </h6>
                  <Card small className="edit-user-details pt-4">
                    <CardBody className="p-0">
                      <Form className="my-auto"
                      onSubmit={this.onSubmit}
                      noValidate
                      >
                            {
                             data.map((historyItem, index) => {
                                const showSeparator = index != data.length - 1
                                return(
                                  <div>
                                  <Row form className="pl-4">
                                      <Col md="11" className="form-group my-auto">
                                          
                                          <h6 style={{fontWeight: 500, color: '#303030', marginBottom: 5}}>{'Activity: ' + historyItem.type}</h6>
                                          { historyItem.isOpen && 
                                          <div>
                                            <p style={{marginBottom: 5}}><strong>Activity Details:</strong> {historyItem.type}</p>
                                            {historyItem.description && <p style={{marginBottom: 5}}><strong>Description:</strong> {historyItem.description}</p>}
                                            <p style={{marginBottom: 5}}><strong>Update by:</strong> {historyItem.updatedBy}</p>
                                            <p style={{marginBottom: 0}}><strong>Updated at:</strong> {moment(historyItem.createdAt).format("DD MMMM YYYY")}</p>
                                          </div> 
                                          }
                      
                                      </Col>

                                    
                                      <Col md="1" className="form-group my-auto">
                                          <Row>
                                              <Col>
                                                  <div className="mx-auto" style={{height: '100%', width: 1, backgroundColor: '#BABABA'}}></div>
                                              </Col>
                                              <Col className="my-auto mr-1">
                                                  {historyItem.isOpen && <ArrowUpIcon onClick={() => this.closeHistoryItem(index)} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowUpIcon>}
                                                  {!historyItem.isOpen && <ArrowDownIcon onClick={() => this.openHistoryItem(index)} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}} ></ArrowDownIcon>}
                                              </Col>
                                          </Row>
                                      </Col>
                                  </Row>
                                  <Row className="mx-0">
                                    <Col md="12" className="form-group">
                                        {showSeparator  && <div style={{height: 1, width: '100%', backgroundColor: 'black', opacity: 0.4, marginTop: 10}}></div>}
                                    </Col>
                                  </Row>
                                  </div>
                                )
                              })
                            }
                        </Form>
                    </CardBody>
                  </Card>
                  </Col></Row>);
    }
}




export default withTranslation()(ActivityHistory);