import Parse from 'parse';
import React from 'react';
import { Col, FormTextarea, Modal, ModalBody, ModalHeader, Row, Tooltip } from "shards-react";
import { ReactComponent as AcceptIcon } from "../../images/accept_button.svg";
import { ReactComponent as CancelIcon } from "../../images/cancel_button.svg";
import { ReactComponent as InfoIcon } from "../../images/info_icon.svg";

class MoreInformationModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rfiMessage: this.props.text,
      idea: this.props.idea,
      responseInfo: false,
      open: false,
    }
  }

  componentDidUpdate(prevProps) {
    const currentStatus =
      prevProps.isOpen 
    const nextStatus =
      this.props.isOpen

    if (currentStatus !== nextStatus) {
      this.setState({open: this.props.isOpen})
    }
  }

  toggle = () => {
    this.props.closeModal()
  }

  toggleInfo = () => {
    this.setState({
      responseInfo: !this.state.responseInfo
    });
  }

  onTextChange = (event) => {
      const text = event.target.value
      this.setState({rfiMessage: text})
  }

  updateRfi = () => {
    if (this.state.rfiMessage.length > 0) {
      this.props.changeText(this.state.rfiMessage)
    } else {
      alert('Your request cannot be empty.')
    }
    
  }

  updateIdea = async () => {
    const { rfiMessage } = this.state;
    const { idea } = this.props;
    if (rfiMessage.length > 0) {
      const EvaluationHistory = Parse.Object.extend('EvaluationHistory');
      const query = new Parse.Query(EvaluationHistory);
      query.equalTo('ideaReference', idea.id);
      query.descending('createdAt');
      query.limit(1);

      try {
        const results = await query.find();
        if (results.length > 0) {
          const evaluationHistory = results[0];
          evaluationHistory.set("rfiAnswer", rfiMessage);
          await evaluationHistory.save(null, { useMasterKey: true });
        } else {
          console.error('No EvaluationHistory record found with the given ideaReference.');
        }

        idea.set("rfiAnswer", rfiMessage);
        idea.set("status", 'SOMETIDA');
        idea.set("needsEvaluation", true);
        idea.set("customUpdatedAt", new Date());

        await idea.save(null, { useMasterKey: true });
        this.toggle();
      } catch (error) {
        console.error('Error while updating EvaluationHistory and Idea:', error);
      }
    } else {
      alert('Your request cannot be empty.');
    }
  }
  closeModal = () => {
    this.setState({rfiMessage: ''})
    this.props.closeModal()
  }
    

  render() {
      const { open, rfiMessage } = this.state;
      const { idea, type } = this.props;

      const hasIdea = idea != ''
      const shouldEditIdea = type == 'submitInfo'
      const tooltipMsg = shouldEditIdea?'Help the Idea Evaluation Committee review your idea by providing additional details.':'Use the comment box below to ask additional information from the user.'

      return (
      <Modal open={open} centered backdrop toggle={() => this.toggle()}>
        <ModalHeader>
          <div style={{display: 'flex', flexDirection: 'row'}}>
              <h5 className="m-auto" style={{fontWeight: 500, color: '#303030'}}>Request more Information</h5>
            
                  {/* <a id={"ModalTooltipResponseInfo"} className="text-right" style={{ color: 'inherit'}} onClick={() => {
                      var myCopy = this.state.responseInfo
                      myCopy = !myCopy
                      this.setState({responseInfo: myCopy})
                  }}>
                      
                      <InfoIcon className="ml-2" style={{height: 12, width: 12}}></InfoIcon>
                      
                  </a>
              
                  
                  <Tooltip
                      open={this.state.responseInfo}
                      target={"#ModalTooltipResponseInfo"}
                      id={"ModalTooltipResponseInfo1"}
                      toggle={() => {this.toggleInfo()}}
                      className={"shards-tooltip"}
                  >
                      {tooltipMsg}
                  </Tooltip> */}
          </div>
        </ModalHeader>
        <ModalBody>
          { hasIdea && 
            <Row>
            <Col md={12}>
              <p><strong>{idea.get("rfiQuestion")}</strong></p>
            </Col>
          </Row>
          }
          <Row>
              <Col md={12}>
              <FormTextarea
                  style={{ minHeight: "120px" }}
                  id="userBio"
                  placeholder={'Type response here'}
                  value={rfiMessage}
                  onChange={(event) => this.onTextChange(event)}
              />
              </Col>
          </Row>
          <Row className="mt-4">
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
                  <div className="ml-auto" style={{ display: 'flex', flexDirection: 'row'}}>
                      <div className='mr-2'>
                          <AcceptIcon  className="functionalButton ml-auto" style={{height: 34, width: 34}} onClick={() => shouldEditIdea?this.updateIdea():this.updateRfi()}></AcceptIcon>
                      </div>
                      <div>
                          <CancelIcon className="functionalButton ml-auto" style={{height: 34, width: 34}} onClick={() => this.closeModal()}></CancelIcon>
                      </div>
                  </div>
              </div>
          </Row>
        </ModalBody>
      </Modal>
      );
    }
  }

export default MoreInformationModal