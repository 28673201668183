export const users = [
  {
    "Name": "Aguirre - Alvarado, Eric",
    "lastName": "Aguirre - Alvarado",
    "firstName": "Eric",
    "username": "_aguire3",
    "department": "TBA",
    "email": "eric_aguirre___alvarado@baxter.com",
    "User": "X",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Alicea-Diaz, Josue",
    "lastName": "Alicea-Diaz",
    "firstName": "Josue",
    "username": "aliceaj5",
    "department": "TBA",
    "email": "josue_alicea_diaz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Alicea-Miranda, Eugenio",
    "lastName": "Alicea-Miranda",
    "firstName": "Eugenio",
    "username": "aliceae",
    "department": "TBA",
    "email": "eugenio_alicea_miranda@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Alméstica Bernier, Samuel",
    "lastName": "Alméstica Bernier",
    "firstName": "Samuel",
    "username": "almests",
    "department": "TBA",
    "email": "samuel_almestica_bernier@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Alvarado-Figueroa, Melquicedes",
    "lastName": "Alvarado-Figueroa",
    "firstName": "Melquicedes",
    "username": "alvaram15",
    "department": "TBA",
    "email": "melquicedes_alvarado_figueroa@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Alvarez Rodriguez, Marietta",
    "lastName": "Alvarez Rodriguez",
    "firstName": "Marietta",
    "username": "alvarem18",
    "department": "TBA",
    "email": "marietta_alvarez_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Alvarez-Rosario, Abimael",
    "lastName": "Alvarez-Rosario",
    "firstName": "Abimael",
    "username": "alvarea21",
    "department": "TBA",
    "email": "abimael_alvarez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Aponte-Alabarces, Alejandro",
    "lastName": "Aponte-Alabarces",
    "firstName": "Alejandro",
    "username": "alabara",
    "department": "TBA",
    "email": "alejandro_aponte_alabarces@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Aponte-Mateo, Sheila I",
    "lastName": "Aponte-Mateo",
    "firstName": "Sheila I",
    "username": "apontes",
    "department": "TBA",
    "email": "sheila_aponte_mateo@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Arizmendi-Rivera, Felix A",
    "lastName": "Arizmendi-Rivera",
    "firstName": "Felix A",
    "username": "arizmef",
    "department": "TBA",
    "email": "felix_arizmendi_rivera@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Arroyo Melendez, Melvin J",
    "lastName": "Arroyo Melendez",
    "firstName": "Melvin J",
    "username": "arroyom5",
    "department": "TBA",
    "email": "melvin_arroyo_melendez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Aviles Negron, Yomarie",
    "lastName": "Aviles Negron",
    "firstName": "Yomarie",
    "username": "avilesy1",
    "department": "TBA",
    "email": "yomarie_aviles@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Baerga Rodriguez, Zulmarie",
    "lastName": "Baerga Rodriguez",
    "firstName": "Zulmarie",
    "username": "baergaz",
    "department": "TBA",
    "email": "zulmary_baergas@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Baerga-Colon, Neysa Y",
    "lastName": "Baerga-Colon",
    "firstName": "Neysa Y",
    "username": "baergan",
    "department": "TBA",
    "email": "neysa_baerga_colon@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Baerga-Colon, Yislene",
    "lastName": "Baerga-Colon",
    "firstName": "Yislene",
    "username": "baergay",
    "department": "TBA",
    "email": "yislene_baerga_colon@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Bajandas David, Zulmarie",
    "lastName": "Bajandas David",
    "firstName": "Zulmarie",
    "username": "bajandz",
    "department": "TBA",
    "email": "zulmarie_bajandas@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Banks-Beaubrauth, Haydee",
    "lastName": "Banks-Beaubrauth",
    "firstName": "Haydee",
    "username": "banksh",
    "department": "TBA",
    "email": "haydee_banks@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Barbosa-Medina, Hector",
    "lastName": "Barbosa-Medina",
    "firstName": "Hector",
    "username": "barbosh1",
    "department": "TBA",
    "email": "hector_barbosa_medina@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Bermudez-Santiago, Daniel O",
    "lastName": "Bermudez-Santiago",
    "firstName": "Daniel O",
    "username": "bermudd",
    "department": "TBA",
    "email": "daniel_bermudez_santiago@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Bernardi Santiago, Edith",
    "lastName": "Bernardi Santiago",
    "firstName": "Edith",
    "username": "bernare2",
    "department": "TBA",
    "email": "edith_bernardi_santiago@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Berrios Amaro, Yodalis Marie",
    "lastName": "Berrios Amaro",
    "firstName": "Yodalis Marie",
    "username": "berrioy4",
    "department": "TBA",
    "email": "yodalis_berrios_amaro@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Bilbraut-Pirela, Liz Nellie",
    "lastName": "Bilbraut-Pirela",
    "firstName": "Liz Nellie",
    "username": "_bilbrl",
    "department": "TBA",
    "email": "liz_bilbraut_pirela@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Borges Reyes, José Felipe",
    "lastName": "Borges Reyes",
    "firstName": "José Felipe",
    "username": "_borgej",
    "department": "TBA",
    "email": "jose_borges@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Bosques, Julio",
    "lastName": "Bosques",
    "firstName": "Julio",
    "username": "bosquej1",
    "department": "TBA",
    "email": "julio_bosques@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Caban-Rivera, Carlos A",
    "lastName": "Caban-Rivera",
    "firstName": "Carlos A",
    "username": "cabanrc",
    "department": "TBA",
    "email": "carlos_caban_rivera@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Cancel, Guillermo J",
    "lastName": "Cancel",
    "firstName": "Guillermo J",
    "username": "cancelg",
    "department": "TBA",
    "email": "guillermo_cancel@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Candelario Gonzalez, Santos Javier",
    "lastName": "Candelario Gonzalez",
    "firstName": "Santos Javier",
    "username": "candels",
    "department": "TBA",
    "email": "santos_candelario@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Carattini-Santiago, Nadly T",
    "lastName": "Carattini-Santiago",
    "firstName": "Nadly T",
    "username": "carattn1",
    "department": "TBA",
    "email": "nadly_carattini_santiago@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Carrasquillo-Velez, Collette M",
    "lastName": "Carrasquillo-Velez",
    "firstName": "Collette M",
    "username": "carrasc",
    "department": "TBA",
    "email": "collette_carrasquillo_velez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Cartagena-Aponte, Edwin",
    "lastName": "Cartagena-Aponte",
    "firstName": "Edwin",
    "username": "cartage2",
    "department": "TBA",
    "email": "edwin_cartagena_aponte@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Castillo Perez, Felipe D",
    "lastName": "Castillo Perez",
    "firstName": "Felipe D",
    "username": "castilf3",
    "department": "TBA",
    "email": "felipe_castillo_perez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Castro-Rodriguez, Ivonne",
    "lastName": "Castro-Rodriguez",
    "firstName": "Ivonne",
    "username": "castroi",
    "department": "TBA",
    "email": "ivonne_castro_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Centeno-Lopez, Elizabeth",
    "lastName": "Centeno-Lopez",
    "firstName": "Elizabeth",
    "username": "centene1",
    "department": "TBA",
    "email": "elizabeth_centeno_lopez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Cintron-Lebron, Yamil",
    "lastName": "Cintron-Lebron",
    "firstName": "Yamil",
    "username": "cintroy1",
    "department": "TBA",
    "email": "yamil_cintron_lebron1@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Cintron-Romero, Orlando",
    "lastName": "Cintron-Romero",
    "firstName": "Orlando",
    "username": "cintroo2",
    "department": "TBA",
    "email": "orlando_cintron_romero@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Cintron-Santiago, Omar",
    "lastName": "Cintron-Santiago",
    "firstName": "Omar",
    "username": "cintroo1",
    "department": "TBA",
    "email": "omar_cintron_santiago@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Cintron, Faustino",
    "lastName": "Cintron",
    "firstName": "Faustino",
    "username": "cintrof2",
    "department": "TBA",
    "email": "faustino_cintron@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Claudio Medina, Alejandro",
    "lastName": "Claudio Medina",
    "firstName": "Alejandro",
    "username": "claudia",
    "department": "TBA",
    "email": "alejandro_claudio_medina@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Claudio Rodriguez, Noely",
    "lastName": "Claudio Rodriguez",
    "firstName": "Noely",
    "username": "claudin1",
    "department": "TBA",
    "email": "noely_claudio_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Clavell-Gierbolini, Giancarlo",
    "lastName": "Clavell-Gierbolini",
    "firstName": "Giancarlo",
    "username": "clavelg",
    "department": "TBA",
    "email": "giancarlo_clavell_gierbolini@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Collazo, Milexy",
    "lastName": "Collazo",
    "firstName": "Milexy",
    "username": "collazm1",
    "department": "TBA",
    "email": "milexy_collazo@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Colon Soto, Linsay",
    "lastName": "Colon Soto",
    "firstName": "Linsay",
    "username": "colonl3",
    "department": "TBA",
    "email": "linsay_colon@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Colon-Dominguez, Elizabeth",
    "lastName": "Colon-Dominguez",
    "firstName": "Elizabeth",
    "username": "_colone18",
    "department": "TBA",
    "email": "elizabeth_colon_domnguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Colon-Figueroa, Olga D",
    "lastName": "Colon-Figueroa",
    "firstName": "Olga D",
    "username": "colonfo1",
    "department": "TBA",
    "email": "olga_colon_figueroa@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Colon-Lopez, Gerardo",
    "lastName": "Colon-Lopez",
    "firstName": "Gerardo",
    "username": "colonlg",
    "department": "TBA",
    "email": "gerardo_colon_lopez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Colon-Maldonado, Carlos J",
    "lastName": "Colon-Maldonado",
    "firstName": "Carlos J",
    "username": "colonmc1",
    "department": "TBA",
    "email": "carlos_colon_maldonado@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Colon-Martell, Rolando J",
    "lastName": "Colon-Martell",
    "firstName": "Rolando J",
    "username": "colonmr",
    "department": "TBA",
    "email": "rolando_colon_martell@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Colon-Rivera, Jonathan",
    "lastName": "Colon-Rivera",
    "firstName": "Jonathan",
    "username": "colonrj",
    "department": "TBA",
    "email": "jonathan_colon_rivera@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Correa-Rivera, Pedro R",
    "lastName": "Correa-Rivera",
    "firstName": "Pedro R",
    "username": "correap",
    "department": "TBA",
    "email": "pedro_correa_rivera@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Cortes Padilla, Joan S",
    "lastName": "Cortes Padilla",
    "firstName": "Joan S",
    "username": "cortesj7",
    "department": "TBA",
    "email": "joan_cortes_padilla@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Crispin Rodríguez, Daisy",
    "lastName": "Crispin Rodríguez",
    "firstName": "Daisy",
    "username": "_crispd",
    "department": "TBA",
    "email": "daisy_crispin@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Cruz Garcia, Ismael",
    "lastName": "Cruz Garcia",
    "firstName": "Ismael",
    "username": "cruzgai",
    "department": "TBA",
    "email": "ismael_cruz_garcia@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Cruz-Diaz, Marvin I",
    "lastName": "Cruz-Diaz",
    "firstName": "Marvin I",
    "username": "cruzdim",
    "department": "TBA",
    "email": "marvin_cruz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Cuevas Rodríguez, Mary Sue",
    "lastName": "Cuevas Rodríguez",
    "firstName": "Mary Sue",
    "username": "cuevasm13",
    "department": "TBA",
    "email": "mary_cuevas_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Curet, Francis J",
    "lastName": "Curet",
    "firstName": "Francis J",
    "username": "curetf",
    "department": "TBA",
    "email": "francis_curet@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Davila, Nelson Omar",
    "lastName": "Davila",
    "firstName": "Nelson Omar",
    "username": "davilan4",
    "department": "TBA",
    "email": "nelson_davila_castro@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "De Hoyos Rodríguez, Ariel A",
    "lastName": "De Hoyos Rodríguez",
    "firstName": "Ariel A",
    "username": "dehoyoa",
    "department": "TBA",
    "email": "ariel_a_de_hoyos_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "De Jesus-De Jesus, Denis M",
    "lastName": "De Jesus-De Jesus",
    "firstName": "Denis M",
    "username": "dejesud2",
    "department": "TBA",
    "email": "denis_de_jesus_de_jesus@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "De la Cruz-Ramos, Julio L",
    "lastName": "De la Cruz-Ramos",
    "firstName": "Julio L",
    "username": "delacrj",
    "department": "TBA",
    "email": "julio_de_la_cruz_ramos@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "De Leon Rivera, Juan J.",
    "lastName": "De Leon Rivera",
    "firstName": "Juan J.",
    "username": "deleonj14",
    "department": "TBA",
    "email": "juan_j_de_leon_rivera@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "DeJesus-Correa, Alfredo",
    "lastName": "DeJesus-Correa",
    "firstName": "Alfredo",
    "username": "dejesua4",
    "department": "TBA",
    "email": "alfredo_dejesus@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Del Valle Saez, Ruben",
    "lastName": "Del Valle Saez",
    "firstName": "Ruben",
    "username": "delvalr4",
    "department": "TBA",
    "email": "ruben_del_valle@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Delgado, Alice N",
    "lastName": "Delgado",
    "firstName": "Alice N",
    "username": "delgada1",
    "department": "TBA",
    "email": "alice_delgado@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Diaz Olmeda, Edgardo",
    "lastName": "Diaz Olmeda",
    "firstName": "Edgardo",
    "username": "diazole",
    "department": "TBA",
    "email": "edgardo_diaz_olmeda@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Diaz-Laureano, Alexis",
    "lastName": "Diaz-Laureano",
    "firstName": "Alexis",
    "username": "diazlaa",
    "department": "TBA",
    "email": "alexis_diaz_laureano@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Diaz-Mendez, Sasha M",
    "lastName": "Diaz-Mendez",
    "firstName": "Sasha M",
    "username": "diazmes",
    "department": "TBA",
    "email": "sasha_diaz_mendez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Diaz, Felix R",
    "lastName": "Diaz",
    "firstName": "Felix R",
    "username": "diazmof",
    "department": "TBA",
    "email": "felix_diaz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Echandy Gierbolini, Claribel",
    "lastName": "Echandy Gierbolini",
    "firstName": "Claribel",
    "username": "echandc",
    "department": "TBA",
    "email": "claribel_echandy_gierbolini@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Escalante-Ramirez, Luis Geraldo",
    "lastName": "Escalante-Ramirez",
    "firstName": "Luis Geraldo",
    "username": "escalal",
    "department": "TBA",
    "email": "luis_escalante_ramirez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Estrada, Edgard",
    "lastName": "Estrada",
    "firstName": "Edgard",
    "username": "estrade5",
    "department": "TBA",
    "email": "edgard_estrada@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Feliciano-Amaro, Laisa D",
    "lastName": "Feliciano-Amaro",
    "firstName": "Laisa D",
    "username": "felicil2",
    "department": "TBA",
    "email": "laisa_feliciano_amaro@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Feliciano-Torres, Marjorie",
    "lastName": "Feliciano-Torres",
    "firstName": "Marjorie",
    "username": "felicim4",
    "department": "TBA",
    "email": "marjorie_feliciano_torres@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Felix-Gomez, Angela",
    "lastName": "Felix-Gomez",
    "firstName": "Angela",
    "username": "felixga",
    "department": "TBA",
    "email": "angela_felix_gomez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Felix-Vazquez, Alberto",
    "lastName": "Felix-Vazquez",
    "firstName": "Alberto",
    "username": "felixva",
    "department": "TBA",
    "email": "alberto_felix_vazquez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Felix, Pedro",
    "lastName": "Felix",
    "firstName": "Pedro",
    "username": "felixp",
    "department": "TBA",
    "email": "pedro_felix@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Fernandez Lebron, Daniel",
    "lastName": "Fernandez Lebron",
    "firstName": "Daniel",
    "username": "fernand12",
    "department": "TBA",
    "email": "daniel_fernandez_lebron@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ferrer Cruz, Cecilia",
    "lastName": "Ferrer Cruz",
    "firstName": "Cecilia",
    "username": "ferrerc",
    "department": "TBA",
    "email": "cecilia_ferrer_cruz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ferrer Rivera, Jose",
    "lastName": "Ferrer Rivera",
    "firstName": "Jose",
    "username": "ferrerj3",
    "department": "TBA",
    "email": "jose_ferrer_rivera@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Figueroa Negron, Marilyn",
    "lastName": "Figueroa Negron",
    "firstName": "Marilyn",
    "username": "_figuem6",
    "department": "TBA",
    "email": "marilyn_figueroa@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Flores-Vazquez, Peter C",
    "lastName": "Flores-Vazquez",
    "firstName": "Peter C",
    "username": "floresp2",
    "department": "TBA",
    "email": "peter_flores_vazquez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Fonseca-Crespo, Arnaldo J",
    "lastName": "Fonseca-Crespo",
    "firstName": "Arnaldo J",
    "username": "fonseca4",
    "department": "TBA",
    "email": "arnaldo_fonseca_crespo@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Franceschi, Rafael",
    "lastName": "Franceschi",
    "firstName": "Rafael",
    "username": "francer",
    "department": "TBA",
    "email": "rafael_franceschi@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "García Cora, Miguel",
    "lastName": "García Cora",
    "firstName": "Miguel",
    "username": "garciam152",
    "department": "TBA",
    "email": "miguel_garcia1@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Garcia-Colon, Juan Carlos",
    "lastName": "Garcia-Colon",
    "firstName": "Juan Carlos",
    "username": "garciaj13",
    "department": "TBA",
    "email": "juan_garcia_colon@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Garcia-Ruiz, Victor M",
    "lastName": "Garcia-Ruiz",
    "firstName": "Victor M",
    "username": "garciav15",
    "department": "TBA",
    "email": "victor_garcia_ruiz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Gomez-Rivera, Juan Fco",
    "lastName": "Gomez-Rivera",
    "firstName": "Juan Fco",
    "username": "gomezrj1",
    "department": "TBA",
    "email": "juan_gomez_rivera@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Gonzalez Delgado, Manuel",
    "lastName": "Gonzalez Delgado",
    "firstName": "Manuel",
    "username": "gonzalm176",
    "department": "TBA",
    "email": "manuel_gonzalez1@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Gonzalez-Delgado, Enrique A",
    "lastName": "Gonzalez-Delgado",
    "firstName": "Enrique A",
    "username": "gonzale25",
    "department": "TBA",
    "email": "enrique_gonzalez_delgado@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Gonzalez-Olmo, Alexander O",
    "lastName": "Gonzalez-Olmo",
    "firstName": "Alexander O",
    "username": "gonzala19",
    "department": "TBA",
    "email": "alexander_gonzalez_olmo@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Gonzalez-Rodriguez, Xiomara L",
    "lastName": "Gonzalez-Rodriguez",
    "firstName": "Xiomara L",
    "username": "gonzalx",
    "department": "TBA",
    "email": "xiomara_gonzalez_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Gonzalez-Rosa, Antonio",
    "lastName": "Gonzalez-Rosa",
    "firstName": "Antonio",
    "username": "gonzala86",
    "department": "TBA",
    "email": "antonio_gonzalez_rosa@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Gonzalez-Suarez, Mario",
    "lastName": "Gonzalez-Suarez",
    "firstName": "Mario",
    "username": "gonzalm8",
    "department": "TBA",
    "email": "mario_gonzalez_suarez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Gonzalez-Zayas, Angel E",
    "lastName": "Gonzalez-Zayas",
    "firstName": "Angel E",
    "username": "gonzala69",
    "department": "TBA",
    "email": "angel_gonzalez_zayas@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Gonzalez, Jessimar",
    "lastName": "Gonzalez",
    "firstName": "Jessimar",
    "username": "gonzalj173",
    "department": "TBA",
    "email": "jessimar_gonzalez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Goycochea, Alejandro",
    "lastName": "Goycochea",
    "firstName": "Alejandro",
    "username": "goycoca",
    "department": "TBA",
    "email": "alejandro_goycochea@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Gratacós Dessus, Zilkia B.",
    "lastName": "Gratacós Dessus",
    "firstName": "Zilkia B.",
    "username": "gratacz",
    "department": "TBA",
    "email": "zilkia_gratacos@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Guilbe-Zayas, Johanna",
    "lastName": "Guilbe-Zayas",
    "firstName": "Johanna",
    "username": "guilbej",
    "department": "TBA",
    "email": "johanna_guilbe@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Guzman Rivera, Victor",
    "lastName": "Guzman Rivera",
    "firstName": "Victor",
    "username": "guzmanv9",
    "department": "TBA",
    "email": "victor_guzman1@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Guzman Rosado, Sandra",
    "lastName": "Guzman Rosado",
    "firstName": "Sandra",
    "username": "guzmans11",
    "department": "TBA",
    "email": "sandra_guzman_rosado@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Guzman-Ortiz, Marcos A",
    "lastName": "Guzman-Ortiz",
    "firstName": "Marcos A",
    "username": "guzmanm12",
    "department": "TBA",
    "email": "marcos_guzman_ortiz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Guzman-Sostre, Linda M",
    "lastName": "Guzman-Sostre",
    "firstName": "Linda M",
    "username": "guzmanl6",
    "department": "TBA",
    "email": "linda_guzman_sostre@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Guzman, Vilma",
    "lastName": "Guzman",
    "firstName": "Vilma",
    "username": "guzmanv1",
    "department": "TBA",
    "email": "vilma_guzman@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Hernández Alamo, Gerardo",
    "lastName": "Hernández Alamo",
    "firstName": "Gerardo",
    "username": "hernang39",
    "department": "TBA",
    "email": "gerardo_hernandez_alamo@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Hernandez Figueroa, Efrain",
    "lastName": "Hernandez Figueroa",
    "firstName": "Efrain",
    "username": "hernane57",
    "department": "TBA",
    "email": "efrain_hernandez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Hernandez González, Rafael",
    "lastName": "Hernandez González",
    "firstName": "Rafael",
    "username": "hernanr12",
    "department": "TBA",
    "email": "rafael_hernandez_gonzalez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Hernandez-Ramos, Irmary",
    "lastName": "Hernandez-Ramos",
    "firstName": "Irmary",
    "username": "hernani21",
    "department": "TBA",
    "email": "irmary_hernandez_ramos@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Hernández-Santana, Eliezer",
    "lastName": "Hernández-Santana",
    "firstName": "Eliezer",
    "username": "hernane48",
    "department": "TBA",
    "email": "eliezer_hernandez_santana@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Hernández, Alberto",
    "lastName": "Hernández",
    "firstName": "Alberto",
    "username": "hernana104",
    "department": "TBA",
    "email": "alberto_hernandez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Hernández, José",
    "lastName": "Hernández",
    "firstName": "José",
    "username": "hernanj147",
    "department": "TBA",
    "email": "jose_hernandez3@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Hernandez, Jose Oscar",
    "lastName": "Hernandez",
    "firstName": "Jose Oscar",
    "username": "hernanj25",
    "department": "TBA",
    "email": "jose_hernandez_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Hidalgo-Mojica, Juan",
    "lastName": "Hidalgo-Mojica",
    "firstName": "Juan",
    "username": "hidalgj",
    "department": "TBA",
    "email": "juan_hidalgo_mojica@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Hourston, Clifford",
    "lastName": "Hourston",
    "firstName": "Clifford",
    "username": "hourstc",
    "department": "TBA",
    "email": "clifford_hourston@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Huertas Mendez, Israel",
    "lastName": "Huertas Mendez",
    "firstName": "Israel",
    "username": "huertai",
    "department": "TBA",
    "email": "israel_huertas_mendez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ildefonso-Rivera, Fernando A",
    "lastName": "Ildefonso-Rivera",
    "firstName": "Fernando A",
    "username": "ildefof",
    "department": "TBA",
    "email": "fernando_ildefonso_rivera@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Irizarry-Gonzalez, Rene",
    "lastName": "Irizarry-Gonzalez",
    "firstName": "Rene",
    "username": "irizarr3",
    "department": "TBA",
    "email": "rene_irizarry_gonzalez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Irizarry-Gracia, Jose L",
    "lastName": "Irizarry-Gracia",
    "firstName": "Jose L",
    "username": "irizarj4",
    "department": "TBA",
    "email": "jose_irizarry_gracia@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "La Santa, Ruth N.",
    "lastName": "La Santa",
    "firstName": "Ruth N.",
    "username": "_lasanr",
    "department": "TBA",
    "email": "ruth_n_la_santa@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Laboy-Avenaut, Felix A",
    "lastName": "Laboy-Avenaut",
    "firstName": "Felix A",
    "username": "laboyf",
    "department": "TBA",
    "email": "felix_laboy@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Laboy-Carrasquillo, Rafael",
    "lastName": "Laboy-Carrasquillo",
    "firstName": "Rafael",
    "username": "laboyr",
    "department": "TBA",
    "email": "rafael_laboy@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Lebron Miranda, Maria",
    "lastName": "Lebron Miranda",
    "firstName": "Maria",
    "username": "lebronm1",
    "department": "TBA",
    "email": "maria_lebron_miranda@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Lebron-Merced, Luis C",
    "lastName": "Lebron-Merced",
    "firstName": "Luis C",
    "username": "lebronl",
    "department": "TBA",
    "email": "luis_lebron_merced@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Lebrón-Santell, Ilia Yazmin",
    "lastName": "Lebrón-Santell",
    "firstName": "Ilia Yazmin",
    "username": "lebroni",
    "department": "TBA",
    "email": "ilia_lebron_santell@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Lopez Leon, Alexis",
    "lastName": "Lopez Leon",
    "firstName": "Alexis",
    "username": "lopezla",
    "department": "TBA",
    "email": "alexis_lopez_leon@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "López Sampayo, Roberto",
    "lastName": "López Sampayo",
    "firstName": "Roberto",
    "username": "lopezsr1",
    "department": "TBA",
    "email": "roberto_lopez_sampayo@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Lopez, Ida R",
    "lastName": "Lopez",
    "firstName": "Ida R",
    "username": "lopezi2",
    "department": "TBA",
    "email": "ida_lopez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Lugo-Nieves, Danishka",
    "lastName": "Lugo-Nieves",
    "firstName": "Danishka",
    "username": "lugonid",
    "department": "TBA",
    "email": "danishka_lugo_nieves@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Madera Zaccheus, Jovanny",
    "lastName": "Madera Zaccheus",
    "firstName": "Jovanny",
    "username": "maderaj",
    "department": "TBA",
    "email": "jovanny_madera_zaccheus@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Madera-Santiago, Ada I",
    "lastName": "Madera-Santiago",
    "firstName": "Ada I",
    "username": "maderaa1",
    "department": "TBA",
    "email": "ada_madera_santiago@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Manautou Hernandez, Luis",
    "lastName": "Manautou Hernandez",
    "firstName": "Luis",
    "username": "manautl",
    "department": "TBA",
    "email": "luis_manautou_hernandez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Marquez-Lopez, Sadrac",
    "lastName": "Marquez-Lopez",
    "firstName": "Sadrac",
    "username": "marques3",
    "department": "TBA",
    "email": "sadrac_marquez_lopez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Martinez Laboy, Bryan",
    "lastName": "Martinez Laboy",
    "firstName": "Bryan",
    "username": "martinb34",
    "department": "TBA",
    "email": "bryan_martinez_laboy@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Martinez Morales, Jorge",
    "lastName": "Martinez Morales",
    "firstName": "Jorge",
    "username": "_martij83",
    "department": "TBA",
    "email": "jorge_luis_martinez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Martinez-Calez, Nixa L",
    "lastName": "Martinez-Calez",
    "firstName": "Nixa L",
    "username": "martinn6",
    "department": "TBA",
    "email": "nixa_martinez_calez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Martinez-Rivera, Marlene",
    "lastName": "Martinez-Rivera",
    "firstName": "Marlene",
    "username": "martinm113",
    "department": "TBA",
    "email": "marlene_martinez_rivera@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Martínez, Thais I.",
    "lastName": "Martínez",
    "firstName": "Thais I.",
    "username": "martint27",
    "department": "TBA",
    "email": "thais_martinez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Meléndez González, Wilfredo X.",
    "lastName": "Meléndez González",
    "firstName": "Wilfredo X.",
    "username": "melendw",
    "department": "TBA",
    "email": "wilfredo_melendez_gonzalez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Mercado Aguayo, Juan Carlos",
    "lastName": "Mercado Aguayo",
    "firstName": "Juan Carlos",
    "username": "mercadj19",
    "department": "TBA",
    "email": "juan_carlos_mercado_aguayo@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Montaner-Oliver, Jaime R",
    "lastName": "Montaner-Oliver",
    "firstName": "Jaime R",
    "username": "montanj1",
    "department": "TBA",
    "email": "jaime_montaner@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Montanez-Diaz, Jose A",
    "lastName": "Montanez-Diaz",
    "firstName": "Jose A",
    "username": "montanj2",
    "department": "TBA",
    "email": "jose_montanez_diaz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Montero Montero, Bryan",
    "lastName": "Montero Montero",
    "firstName": "Bryan",
    "username": "monterb2",
    "department": "TBA",
    "email": "bryan_montero@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Montes-Nieves, Jose A",
    "lastName": "Montes-Nieves",
    "firstName": "Jose A",
    "username": "montesj4",
    "department": "TBA",
    "email": "jose_montes_nieves@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Morales Antuna, Migdoel",
    "lastName": "Morales Antuna",
    "firstName": "Migdoel",
    "username": "moralem27",
    "department": "TBA",
    "email": "migdoel_morales_antuna@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Morales Melendez, Celiangely",
    "lastName": "Morales Melendez",
    "firstName": "Celiangely",
    "username": "moralec7",
    "department": "TBA",
    "email": "celiangely_morales_melendez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Morales Nieves, Jaime",
    "lastName": "Morales Nieves",
    "firstName": "Jaime",
    "username": "moralej46",
    "department": "TBA",
    "email": "jaime_morales_nieves@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Morales-Cotto, Norienne B",
    "lastName": "Morales-Cotto",
    "firstName": "Norienne B",
    "username": "moralen5",
    "department": "TBA",
    "email": "norienne_morales_cotto@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Morales-Cruz, Maridsa",
    "lastName": "Morales-Cruz",
    "firstName": "Maridsa",
    "username": "moralem25",
    "department": "TBA",
    "email": "maridsa_morales@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Morales-Torres, Michael",
    "lastName": "Morales-Torres",
    "firstName": "Michael",
    "username": "moralem",
    "department": "TBA",
    "email": "michael_morales_torres@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Morales, Florencio",
    "lastName": "Morales",
    "firstName": "Florencio",
    "username": "moralef2",
    "department": "TBA",
    "email": "florencio_morales@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Muniz-Mercado, Rene",
    "lastName": "Muniz-Mercado",
    "firstName": "Rene",
    "username": "munizmr",
    "department": "TBA",
    "email": "rene_muniz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Nazario-Colon, Geovanna O",
    "lastName": "Nazario-Colon",
    "firstName": "Geovanna O",
    "username": "nazarig1",
    "department": "TBA",
    "email": "geovanna_nazario@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Neris-Pagan, William",
    "lastName": "Neris-Pagan",
    "firstName": "William",
    "username": "nerispw",
    "department": "TBA",
    "email": "william_neris_pagan@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Nieves Bernier, Luis Elías",
    "lastName": "Nieves Bernier",
    "firstName": "Luis Elías",
    "username": "nievesl3",
    "department": "TBA",
    "email": "luis_elias_nieves@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Nieves-Zayas, Ricardo",
    "lastName": "Nieves-Zayas",
    "firstName": "Ricardo",
    "username": "_niever3",
    "department": "TBA",
    "email": "ricardo_nieves_zayas@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Orama Irizarry, Miriam M",
    "lastName": "Orama Irizarry",
    "firstName": "Miriam M",
    "username": "oramaim",
    "department": "TBA",
    "email": "miriam_orama_irizarry@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Orengo-Ortiz, Esther",
    "lastName": "Orengo-Ortiz",
    "firstName": "Esther",
    "username": "orengoe",
    "department": "TBA",
    "email": "esther_orengo@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ortiz De Jesus, Noel",
    "lastName": "Ortiz De Jesus",
    "firstName": "Noel",
    "username": "ortizdn",
    "department": "TBA",
    "email": "noel_ortiz_de_jesus@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ortiz Vega, Luis A",
    "lastName": "Ortiz Vega",
    "firstName": "Luis A",
    "username": "ortizvl1",
    "department": "TBA",
    "email": "luis_ortiz_vega@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ortiz-Leon, Raquel",
    "lastName": "Ortiz-Leon",
    "firstName": "Raquel",
    "username": "ortizlr1",
    "department": "TBA",
    "email": "raquel_ortiz_leon@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ortiz-Pueyo, Leez M",
    "lastName": "Ortiz-Pueyo",
    "firstName": "Leez M",
    "username": "ortizpl",
    "department": "TBA",
    "email": "leez_ortizpueyo@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ortiz-Reyes, Dennisse",
    "lastName": "Ortiz-Reyes",
    "firstName": "Dennisse",
    "username": "ortizrd1",
    "department": "TBA",
    "email": "dennisse_ortiz_reyes@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ortiz-Sanchez, Rolando",
    "lastName": "Ortiz-Sanchez",
    "firstName": "Rolando",
    "username": "ortizsr1",
    "department": "TBA",
    "email": "rolando_ortiz_sanchez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ortiz-Santiago, Carmen I",
    "lastName": "Ortiz-Santiago",
    "firstName": "Carmen I",
    "username": "ortizsc",
    "department": "TBA",
    "email": "carmen_i_ortiz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ortiz-Santiago, Emmanuel",
    "lastName": "Ortiz-Santiago",
    "firstName": "Emmanuel",
    "username": "ortizse1",
    "department": "TBA",
    "email": "emmanuel_ortiz_santiago@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ortiz-Torres, Miguel E",
    "lastName": "Ortiz-Torres",
    "firstName": "Miguel E",
    "username": "ortiztm",
    "department": "TBA",
    "email": "miguel_ortiz_torres@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Pabon Garcia, Roberto",
    "lastName": "Pabon Garcia",
    "firstName": "Roberto",
    "username": "pabongr",
    "department": "TBA",
    "email": "roberto_pabon_garcia@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Padilla Lebron, Francis Y",
    "lastName": "Padilla Lebron",
    "firstName": "Francis Y",
    "username": "padillf",
    "department": "TBA",
    "email": "francis_padilla_lebron@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Padilla-Santiago, Lymari",
    "lastName": "Padilla-Santiago",
    "firstName": "Lymari",
    "username": "padilll1",
    "department": "TBA",
    "email": "lymari_padilla_santiago@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Pagan-Rodriguez, Yolanda",
    "lastName": "Pagan-Rodriguez",
    "firstName": "Yolanda",
    "username": "paganry",
    "department": "TBA",
    "email": "yolanda_pagan_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Peña Hernandez, Javier",
    "lastName": "Peña Hernandez",
    "firstName": "Javier",
    "username": "_penaj1",
    "department": "TBA",
    "email": "javier_pena@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Peña Torres, Joel Alexis",
    "lastName": "Peña Torres",
    "firstName": "Joel Alexis",
    "username": "penatoj",
    "department": "TBA",
    "email": "joel_pena_torres@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Perez-Falcon, Edgardo J",
    "lastName": "Perez-Falcon",
    "firstName": "Edgardo J",
    "username": "perezfe1",
    "department": "TBA",
    "email": "edgardo_perez_falcon@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Perez-Nieves, Glidden E",
    "lastName": "Perez-Nieves",
    "firstName": "Glidden E",
    "username": "perezng",
    "department": "TBA",
    "email": "glidden_perez_nieves@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Perez-Ruiz, Ilianie",
    "lastName": "Perez-Ruiz",
    "firstName": "Ilianie",
    "username": "perezri",
    "department": "TBA",
    "email": "ilianie_perez_ruiz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Perez-Valentin, Elsa M",
    "lastName": "Perez-Valentin",
    "firstName": "Elsa M",
    "username": "pereze1",
    "department": "TBA",
    "email": "elsa_perez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Piazza, Omar",
    "lastName": "Piazza",
    "firstName": "Omar",
    "username": "piazzao",
    "department": "TBA",
    "email": "omar_piazza@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Pinero-Garcia, Luis A",
    "lastName": "Pinero-Garcia",
    "firstName": "Luis A",
    "username": "pinerol",
    "department": "TBA",
    "email": "luis_pinero_garcia@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Polanco, Jose",
    "lastName": "Polanco",
    "firstName": "Jose",
    "username": "polancj3",
    "department": "TBA",
    "email": "jose_polanco1@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ramírez, Héctor O.",
    "lastName": "Ramírez",
    "firstName": "Héctor O.",
    "username": "ramireh7",
    "department": "TBA",
    "email": "hector_ramirez_mateo@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ramos Miranda, Ana Y",
    "lastName": "Ramos Miranda",
    "firstName": "Ana Y",
    "username": "ramosma",
    "department": "TBA",
    "email": "ana_ramos_miranda@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ramos-Aponte, Joevanny",
    "lastName": "Ramos-Aponte",
    "firstName": "Joevanny",
    "username": "ramosaj1",
    "department": "TBA",
    "email": "jeovanny_ramos@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ramos, William",
    "lastName": "Ramos",
    "firstName": "William",
    "username": "ramosw2",
    "department": "TBA",
    "email": "william_ramos@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rentas-Diaz, Edward",
    "lastName": "Rentas-Diaz",
    "firstName": "Edward",
    "username": "rentase",
    "department": "TBA",
    "email": "edward_rentas_diaz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Reyes-Serrano, Ramon L",
    "lastName": "Reyes-Serrano",
    "firstName": "Ramon L",
    "username": "reyessr",
    "department": "TBA",
    "email": "ramon_reyes_serrano@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Riascos Arboleda, Andres F",
    "lastName": "Riascos Arboleda",
    "firstName": "Andres F",
    "username": "riascoa1",
    "department": "TBA",
    "email": "andres_fernando_riascos@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera De jesus, Luis",
    "lastName": "Rivera De jesus",
    "firstName": "Luis",
    "username": "riveral70",
    "department": "TBA",
    "email": "luis_rivera@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera Enchautegui, Pedro Manuel",
    "lastName": "Rivera Enchautegui",
    "firstName": "Pedro Manuel",
    "username": "_riverp5",
    "department": "TBA",
    "email": "pedro_rivera_enchautegui@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera Gonzalez, Desiree",
    "lastName": "Rivera Gonzalez",
    "firstName": "Desiree",
    "username": "riverad16",
    "department": "TBA",
    "email": "desiree_rivera_gonzalez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera Rodriguez, Ismael",
    "lastName": "Rivera Rodriguez",
    "firstName": "Ismael",
    "username": "riverai2",
    "department": "TBA",
    "email": "ismael_rivera_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera Rosado, Omar",
    "lastName": "Rivera Rosado",
    "firstName": "Omar",
    "username": "riverao16",
    "department": "TBA",
    "email": "omar_rivera_rosado@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera-Amaro, Edna M",
    "lastName": "Rivera-Amaro",
    "firstName": "Edna M",
    "username": "riverae32",
    "department": "TBA",
    "email": "edna_rivera_amaro@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera-Berrios, Lydia Jeanine",
    "lastName": "Rivera-Berrios",
    "firstName": "Lydia Jeanine",
    "username": "riveral13",
    "department": "TBA",
    "email": "lydia_rivera_berrios@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera-Garcia, Angel",
    "lastName": "Rivera-Garcia",
    "firstName": "Angel",
    "username": "riveraa40",
    "department": "TBA",
    "email": "angel_rivera_garcia@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera-Gonzalez, Juan C.",
    "lastName": "Rivera-Gonzalez",
    "firstName": "Juan C.",
    "username": "riveraj85",
    "department": "TBA",
    "email": "juan_rivera_gonzalez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera-Guzman, Jesus M",
    "lastName": "Rivera-Guzman",
    "firstName": "Jesus M",
    "username": "riveraj73",
    "department": "TBA",
    "email": "jesus_rivera_guzman@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera-Lopez, Yolanda",
    "lastName": "Rivera-Lopez",
    "firstName": "Yolanda",
    "username": "riveray11",
    "department": "TBA",
    "email": "yolanda_rivera_lopez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera-Medina, Miguel",
    "lastName": "Rivera-Medina",
    "firstName": "Miguel",
    "username": "_riverm37",
    "department": "TBA",
    "email": "miguel_rivera_medina@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera-Pagan, Roberto",
    "lastName": "Rivera-Pagan",
    "firstName": "Roberto",
    "username": "riverar15",
    "department": "TBA",
    "email": "roberto_rivera_pagan@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera-Ramos, Jorge H",
    "lastName": "Rivera-Ramos",
    "firstName": "Jorge H",
    "username": "riveraj74",
    "department": "TBA",
    "email": "jorge_rivera_ramos@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera-Santini, Jose E",
    "lastName": "Rivera-Santini",
    "firstName": "Jose E",
    "username": "riveraj67",
    "department": "TBA",
    "email": "jose_rivera_santini@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera-Vazquez, Brian",
    "lastName": "Rivera-Vazquez",
    "firstName": "Brian",
    "username": "riverab5",
    "department": "TBA",
    "email": "brian_rivera_vazquez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera, Luz E",
    "lastName": "Rivera",
    "firstName": "Luz E",
    "username": "riveral4",
    "department": "TBA",
    "email": "luz_e_rivera@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rivera, Margarita",
    "lastName": "Rivera",
    "firstName": "Margarita",
    "username": "riveram18",
    "department": "TBA",
    "email": "margie_rivera_ruiz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Roche-Figueroa, Gloribel",
    "lastName": "Roche-Figueroa",
    "firstName": "Gloribel",
    "username": "rocheg",
    "department": "TBA",
    "email": "gloribel_roche@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rocher Vazquez, Erick Antonio",
    "lastName": "Rocher Vazquez",
    "firstName": "Erick Antonio",
    "username": "rochere",
    "department": "TBA",
    "email": "erick_rocher@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rodriguez Maldonado, Janluis",
    "lastName": "Rodriguez Maldonado",
    "firstName": "Janluis",
    "username": "_rodrij63",
    "department": "TBA",
    "email": "janluis_rodriguez_maldonado@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rodriguez Negron, Juan Saul",
    "lastName": "Rodriguez Negron",
    "firstName": "Juan Saul",
    "username": "rodrigs1",
    "department": "TBA",
    "email": "saul_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rodriguez Ortiz, Jose",
    "lastName": "Rodriguez Ortiz",
    "firstName": "Jose",
    "username": "rodrigj155",
    "department": "TBA",
    "email": "jose_rodriguez_ortiz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rodriguez-Caraballo, Irving",
    "lastName": "Rodriguez-Caraballo",
    "firstName": "Irving",
    "username": "rodrigi18",
    "department": "TBA",
    "email": "irving_rodriguez_caraballo@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rodriguez-Cartagena, Cesar A",
    "lastName": "Rodriguez-Cartagena",
    "firstName": "Cesar A",
    "username": "rodrigc6",
    "department": "TBA",
    "email": "cesar_rodriguez_cartagena@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rodriguez-Malave, Marilolie",
    "lastName": "Rodriguez-Malave",
    "firstName": "Marilolie",
    "username": "rodrigm80",
    "department": "TBA",
    "email": "marilolie_rodriguez_malave@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rodriguez-Sanguinetti, Naylin",
    "lastName": "Rodriguez-Sanguinetti",
    "firstName": "Naylin",
    "username": "rodrign12",
    "department": "TBA",
    "email": "naylin_rodriguez_sanguinetti@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rodriguez-Suliveras, Juan E",
    "lastName": "Rodriguez-Suliveras",
    "firstName": "Juan E",
    "username": "rodrigj83",
    "department": "TBA",
    "email": "juan_rodriguez_suliveras@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rodriguez-Vazquez, Carmen M",
    "lastName": "Rodriguez-Vazquez",
    "firstName": "Carmen M",
    "username": "rodrigc2",
    "department": "TBA",
    "email": "carmen_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rodriguez-Velez, Hector I",
    "lastName": "Rodriguez-Velez",
    "firstName": "Hector I",
    "username": "rodrigh12",
    "department": "TBA",
    "email": "hector_rodriguez_velez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rodriguez, Evat",
    "lastName": "Rodriguez",
    "firstName": "Evat",
    "username": "rodrige18",
    "department": "TBA",
    "email": "evat_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rojas-Rodriguez, German",
    "lastName": "Rojas-Rodriguez",
    "firstName": "German",
    "username": "rojasrg",
    "department": "TBA",
    "email": "german_rojas_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Roldan, Edgar Anibal",
    "lastName": "Roldan",
    "firstName": "Edgar Anibal",
    "username": "roldane2",
    "department": "TBA",
    "email": "edgar_roldan@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rolon-Rodriguez, Nicolas",
    "lastName": "Rolon-Rodriguez",
    "firstName": "Nicolas",
    "username": "rolonrn1",
    "department": "TBA",
    "email": "nicolas_rolon_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Roman-Blasini, Sheila",
    "lastName": "Roman-Blasini",
    "firstName": "Sheila",
    "username": "romanbs1",
    "department": "TBA",
    "email": "sheila_roman_blasini@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Román, Roberto",
    "lastName": "Román",
    "firstName": "Roberto",
    "username": "romanr5",
    "department": "TBA",
    "email": "roberto_roman@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Roque-Vicente, Marylin",
    "lastName": "Roque-Vicente",
    "firstName": "Marylin",
    "username": "roquem",
    "department": "TBA",
    "email": "marylin_roque@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rosado Alicea, Yazmin",
    "lastName": "Rosado Alicea",
    "firstName": "Yazmin",
    "username": "rosadoy4",
    "department": "TBA",
    "email": "yazmin_rosado_alicea@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rosario Amaro, Peter",
    "lastName": "Rosario Amaro",
    "firstName": "Peter",
    "username": "rosarip2",
    "department": "TBA",
    "email": "peter_rosario@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rosario-Quinones, Shakira B",
    "lastName": "Rosario-Quinones",
    "firstName": "Shakira B",
    "username": "rosaris4",
    "department": "TBA",
    "email": "shakira_rosario_quinones@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Rovira-Defendini, Wanda I",
    "lastName": "Rovira-Defendini",
    "firstName": "Wanda I",
    "username": "roviraw",
    "department": "TBA",
    "email": "wanda_rovira_defendini@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Ruiz-Rivera, Ismael",
    "lastName": "Ruiz-Rivera",
    "firstName": "Ismael",
    "username": "_ruizri",
    "department": "TBA",
    "email": "ismael_ruiz_rivera@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Sanchez Rivera, Giana M",
    "lastName": "Sanchez Rivera",
    "firstName": "Giana M",
    "username": "gianas",
    "department": "TBA",
    "email": "sanchez_rivera_giana@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Sanchez Vazquez, Kermit",
    "lastName": "Sanchez Vazquez",
    "firstName": "Kermit",
    "username": "sanchek3",
    "department": "TBA",
    "email": "kermit_sanchez_vazquez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Sanchez-Torres, Jenniffer A",
    "lastName": "Sanchez-Torres",
    "firstName": "Jenniffer A",
    "username": "sanchej34",
    "department": "TBA",
    "email": "jenniffer_sanchez_torres@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Sanchez-Vega, Idelis",
    "lastName": "Sanchez-Vega",
    "firstName": "Idelis",
    "username": "sanchei12",
    "department": "TBA",
    "email": "idelis_sanchez_vega@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Sanchez, Aimee",
    "lastName": "Sanchez",
    "firstName": "Aimee",
    "username": "sanchea61",
    "department": "TBA",
    "email": "aimee_sanchez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Sanchez, Luis Enrique",
    "lastName": "Sanchez",
    "firstName": "Luis Enrique",
    "username": "sanchel",
    "department": "TBA",
    "email": "luis_sanchez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Sandell-Caraballo, Gloria M",
    "lastName": "Sandell-Caraballo",
    "firstName": "Gloria M",
    "username": "sandelg",
    "department": "TBA",
    "email": "gloria_sandell_caraballo@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Santiago Constantino, Alyssa L",
    "lastName": "Santiago Constantino",
    "firstName": "Alyssa L",
    "username": "santiaa21",
    "department": "TBA",
    "email": "alyssa_santiago_constantino@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Santiago Rivera, Kermit E.",
    "lastName": "Santiago Rivera",
    "firstName": "Kermit E.",
    "username": "santiak2",
    "department": "TBA",
    "email": "kermit_santiago_rivera@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Santiago-Felix, Angel R",
    "lastName": "Santiago-Felix",
    "firstName": "Angel R",
    "username": "santiaa19",
    "department": "TBA",
    "email": "angel_santiago_felix@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Santiago-Perez, Adaline",
    "lastName": "Santiago-Perez",
    "firstName": "Adaline",
    "username": "santiaa22",
    "department": "TBA",
    "email": "adaline_santiago_perez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Santiago-Quesada, Erick O",
    "lastName": "Santiago-Quesada",
    "firstName": "Erick O",
    "username": "santiae20",
    "department": "TBA",
    "email": "erick_santiago_quesada@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Santiago-Rodriguez, Antonio",
    "lastName": "Santiago-Rodriguez",
    "firstName": "Antonio",
    "username": "santiaa18",
    "department": "TBA",
    "email": "antonio_santiago_rodriguez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Santiago-Ruiz, Olvin D",
    "lastName": "Santiago-Ruiz",
    "firstName": "Olvin D",
    "username": "santiao5",
    "department": "TBA",
    "email": "olvin_santiago_ruiz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Santiago-Vazquez, Luis Fernando",
    "lastName": "Santiago-Vazquez",
    "firstName": "Luis Fernando",
    "username": "santial12",
    "department": "TBA",
    "email": "luis_santiago_vazquez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Santiago, Carlos R",
    "lastName": "Santiago",
    "firstName": "Carlos R",
    "username": "santiac",
    "department": "TBA",
    "email": "carlos_r_santiago@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Santiago, Carlos T",
    "lastName": "Santiago",
    "firstName": "Carlos T",
    "username": "santiac1",
    "department": "TBA",
    "email": "carlos_t_santiago@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Santiago, Diana M",
    "lastName": "Santiago",
    "firstName": "Diana M",
    "username": "santiad",
    "department": "TBA",
    "email": "diana_santiago@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Santiago, Luz Maritza",
    "lastName": "Santiago",
    "firstName": "Luz Maritza",
    "username": "santial18",
    "department": "TBA",
    "email": "luz_maritza_santiago@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Santos-Colon, Karla M",
    "lastName": "Santos-Colon",
    "firstName": "Karla M",
    "username": "santosk",
    "department": "TBA",
    "email": "karla_santos_colon@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Santos-De Leon, Wendy",
    "lastName": "Santos-De Leon",
    "firstName": "Wendy",
    "username": "santosw5",
    "department": "TBA",
    "email": "wendy_santos_de_leon@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Serrano, Irene",
    "lastName": "Serrano",
    "firstName": "Irene",
    "username": "serrani",
    "department": "TBA",
    "email": "irene_serrano@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Solis Amaro, Stephanie",
    "lastName": "Solis Amaro",
    "firstName": "Stephanie",
    "username": "solisas",
    "department": "TBA",
    "email": "stephanie_solis_amaro@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Solivan Colon, Miguel A",
    "lastName": "Solivan Colon",
    "firstName": "Miguel A",
    "username": "solivam",
    "department": "TBA",
    "email": "miguel_solivan_colon@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Suarez-Martinez, Ivan",
    "lastName": "Suarez-Martinez",
    "firstName": "Ivan",
    "username": "suarezi",
    "department": "TBA",
    "email": "ivan_suarez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Torres Colomba, David",
    "lastName": "Torres Colomba",
    "firstName": "David",
    "username": "torresd19",
    "department": "TBA",
    "email": "david_torres@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Torres Sanchez, Maria",
    "lastName": "Torres Sanchez",
    "firstName": "Maria",
    "username": "torresm55",
    "department": "TBA",
    "email": "maria_torres_sanchez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Torres-Cordero, Damaris I",
    "lastName": "Torres-Cordero",
    "firstName": "Damaris I",
    "username": "torresd7",
    "department": "TBA",
    "email": "damaris_torres_cordero@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Torres-Cruz, Rafael I",
    "lastName": "Torres-Cruz",
    "firstName": "Rafael I",
    "username": "torresr26",
    "department": "TBA",
    "email": "rafael_torres_cruz@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Torres-Gomez, Maria I",
    "lastName": "Torres-Gomez",
    "firstName": "Maria I",
    "username": "torresm37",
    "department": "TBA",
    "email": "maria_torres_gomez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Torres-Iraola, Jose A",
    "lastName": "Torres-Iraola",
    "firstName": "Jose A",
    "username": "torresj49",
    "department": "TBA",
    "email": "jose_torres_iraola@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Torres-Laporte, Carlos J",
    "lastName": "Torres-Laporte",
    "firstName": "Carlos J",
    "username": "torresc1",
    "department": "TBA",
    "email": "carlos_j_torres@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Torres-Maldonado, Zue H",
    "lastName": "Torres-Maldonado",
    "firstName": "Zue H",
    "username": "torresz",
    "department": "TBA",
    "email": "zue_torres_maldonado@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Torres-Martinez, Onaida",
    "lastName": "Torres-Martinez",
    "firstName": "Onaida",
    "username": "torreso7",
    "department": "TBA",
    "email": "onaida_torres@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Torres-Mercado, Yanixa M",
    "lastName": "Torres-Mercado",
    "firstName": "Yanixa M",
    "username": "torresy2",
    "department": "TBA",
    "email": "yanixa_torres_mercado@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Torres-Vazquez, Jose A",
    "lastName": "Torres-Vazquez",
    "firstName": "Jose A",
    "username": "torresj2",
    "department": "TBA",
    "email": "jose_torres-vazquez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Torres, Carlos R",
    "lastName": "Torres",
    "firstName": "Carlos R",
    "username": "torresc5",
    "department": "TBA",
    "email": "carlos_r_torres@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Torres, Darlenne",
    "lastName": "Torres",
    "firstName": "Darlenne",
    "username": "torresd24",
    "department": "TBA",
    "email": "darlenne_torres@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Torres, Ricardo",
    "lastName": "Torres",
    "firstName": "Ricardo",
    "username": "torresr5",
    "department": "TBA",
    "email": "ricardo_i_torres@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Valentin Morales, Jose M",
    "lastName": "Valentin Morales",
    "firstName": "Jose M",
    "username": "valentj7",
    "department": "TBA",
    "email": "jose_valentin_morales@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Valentin-Gutierrez, Edgardo L",
    "lastName": "Valentin-Gutierrez",
    "firstName": "Edgardo L",
    "username": "valente3",
    "department": "TBA",
    "email": "edgardo_valentin_gutierrez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Valentin-Martinez, Lussan E",
    "lastName": "Valentin-Martinez",
    "firstName": "Lussan E",
    "username": "valentl1",
    "department": "TBA",
    "email": "lussan_valentin_martinez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Valentin, Brendaliz",
    "lastName": "Valentin",
    "firstName": "Brendaliz",
    "username": "valentb",
    "department": "TBA",
    "email": "brendaliz_valentin@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Valles-Vazquez, Heidee Y",
    "lastName": "Valles-Vazquez",
    "firstName": "Heidee Y",
    "username": "vallesh",
    "department": "TBA",
    "email": "heidee_valles_vazquez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Vazquez-Alvarado, Hector A",
    "lastName": "Vazquez-Alvarado",
    "firstName": "Hector A",
    "username": "vazqueh",
    "department": "TBA",
    "email": "hector_vazquez_alvarado@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Vazquez-Dinguis, Marisol",
    "lastName": "Vazquez-Dinguis",
    "firstName": "Marisol",
    "username": "vazquem21",
    "department": "TBA",
    "email": "marisol_vazquez_dinguis@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Vazquez-Lebron, Leslie X",
    "lastName": "Vazquez-Lebron",
    "firstName": "Leslie X",
    "username": "vazquel10",
    "department": "TBA",
    "email": "leslie_vazquez_lebron@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Vazquez-Rodriguez, Nancy I",
    "lastName": "Vazquez-Rodriguez",
    "firstName": "Nancy I",
    "username": "vazquen",
    "department": "TBA",
    "email": "nancy_vazquez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Vazquez-Velez, Denise",
    "lastName": "Vazquez-Velez",
    "firstName": "Denise",
    "username": "vazqued4",
    "department": "TBA",
    "email": "denise_vazquez_velez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Vazquez, Johnny",
    "lastName": "Vazquez",
    "firstName": "Johnny",
    "username": "vazquej5",
    "department": "TBA",
    "email": "johnny_vazquez_morales@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Vazquez, Julia",
    "lastName": "Vazquez",
    "firstName": "Julia",
    "username": "vazquej1",
    "department": "TBA",
    "email": "julia_vazquez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Vega Sierra, Gabriel",
    "lastName": "Vega Sierra",
    "firstName": "Gabriel",
    "username": "vegasig",
    "department": "TBA",
    "email": "gabriel_vega1@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Vega-Morales, Alexander",
    "lastName": "Vega-Morales",
    "firstName": "Alexander",
    "username": "vegamoa",
    "department": "TBA",
    "email": "alexander_vega_morales@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Vega-Rivera, Hector M",
    "lastName": "Vega-Rivera",
    "firstName": "Hector M",
    "username": "vegarih",
    "department": "TBA",
    "email": "hector_vega_rivera@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Vigo-Gonzalez, Guayte",
    "lastName": "Vigo-Gonzalez",
    "firstName": "Guayte",
    "username": "vigogog",
    "department": "TBA",
    "email": "guayte_vigo_gonzalez@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Wloczuk-Davila, Ricardo A",
    "lastName": "Wloczuk-Davila",
    "firstName": "Ricardo A",
    "username": "wloczur",
    "department": "TBA",
    "email": "ricardo_wloczuk_davila@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Yordan-Frau, Luis A.",
    "lastName": "Yordan-Frau",
    "firstName": "Luis A.",
    "username": "yordanl",
    "department": "TBA",
    "email": "luis_yordan_frau@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  },
  {
    "Name": "Zayas-Reyes, Yamilka E",
    "lastName": "Zayas-Reyes",
    "firstName": "Yamilka E",
    "username": "zayasry",
    "department": "TBA",
    "email": "yamilka_zayas@baxter.com",
    "User": "",
    "Coach": "",
    "Super User": ""
  }
]