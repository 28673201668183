import { subDays } from "date-fns";
import moment from 'moment';
import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  FormInput
} from "shards-react";



const DateRangePickerWithCustomPresets = ({ onDateRangeChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null); // Usamos una referencia para el picker

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);

    if(start && end)
    {
      setShowPicker(false)
    }
    
    if (onDateRangeChange) {
      const startISO = start ? moment(start).toISOString() : null;
      const endISO = end ? moment(end).toISOString() : null;
      if((start && end) || (!start && !end))
        onDateRangeChange(startISO, endISO);
    }
  };

  const handlePresetSelect = (preset) => {
    let start, end;
    switch (preset) {
      case "Last Month":
        start = subDays(new Date(), 30);
        end = new Date();
        break;
      case "30 Days":
        start = subDays(new Date(), 30);
        end = new Date();
        break;
      case "60 Days":
        start = subDays(new Date(), 60);
        end = new Date();
        break;
      case "90 Days":
        start = subDays(new Date(), 90);
        end = new Date();
        break;
      case "1 Year":
        start = subDays(new Date(), 365);
        end = new Date();
        break;
      case "All Time":
        start = null;
        end = null;
        break;
      default:
        break;
    }
    handleDateChange(start, end);
    setShowPicker(false); // Cerrar el picker después de seleccionar un preset
  };

  const handleClickOutside = (e) => {
    if (pickerRef.current && !pickerRef.current.contains(e.target)) {
      setShowPicker(false);
    }
  };

  // Para detectar click fuera de la ventana del picker
  React.useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  return (
    <div style={{ position: 'relative' }} ref={pickerRef}>
      <FormInput
        onFocus={() => setShowPicker(true)}
        value={
          startDate && endDate
            ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
            : "All time"
        }
        readOnly
        style={{ padding: '8px', width: '100%', cursor: 'pointer' }}
      />

      {/* Picker personalizado */}
      {showPicker && (
        <div
          style={{
            position: 'absolute',
            top: '40px',
            display: 'flex',
            border: '1px solid #ddd',
            borderRadius: '8px',
            backgroundColor: '#fff',
            zIndex: 1000,
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          }}
          ref={pickerRef}
        >
          <div style={{ padding: '10px', minWidth:'150px', borderRight: '1px solid #ddd' }}>
            <div style={{cursor: 'pointer', marginBottom:5}} onClick={() => handlePresetSelect("All Time")}>All Time</div>
            <div style={{cursor: 'pointer', marginBottom:5}} onClick={() => handlePresetSelect("Last Month")}>Last Month</div>
            <div style={{cursor: 'pointer', marginBottom:5}} onClick={() => handlePresetSelect("30 Days")}>30 Days</div>
            <div style={{cursor: 'pointer', marginBottom:5}} onClick={() => handlePresetSelect("60 Days")}>60 Days</div>
            <div style={{cursor: 'pointer', marginBottom:5}} onClick={() => handlePresetSelect("90 Days")}>90 Days</div>
            <div style={{cursor: 'pointer', marginBottom:5}} onClick={() => handlePresetSelect("1 Year")}>1 Year</div>
          </div>

          <div style={{ padding: '10px' }}>
            <DatePicker
              selected={startDate}
              onChange={(dates) => {
                if (Array.isArray(dates)) {
                  const [start, end] = dates;

                  if (start && end && start.getTime() === end.getTime()) {
                    handleDateChange(start, null); // Enviar una sola fecha
                  } else {
                    handleDateChange(start, end); // Enviar el rango
                  }
                } else {
                  if(startDate && endDate)
                  {
                    handleDateChange(dates, null)
                  }else{
                    if(startDate)
                    {
                      if(dates < startDate){
                        handleDateChange(dates, startDate)
                      }else{
                        handleDateChange(startDate, dates)
                      }
                    }else{
                        handleDateChange(dates)
                    }
                  }
                }
              }}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePickerWithCustomPresets;
