import { saveAs } from 'file-saver';
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import Select, { components } from 'react-select';
import {
  Button,
  Card,
  CardBody, Col, Container, Row
} from "shards-react";
import BarChart from "../components/charts/BarChart";
import PieChart from "../components/charts/PieChart";
import DateRangePickerWithPresets from "../components/common/DateRangePickerWithCustomPresets";
import PageTitle from "../components/common/PageTitle";
import { fetchReports, fetchSites } from "../services/reportsEnterpriseService";
import { authUser } from "../services/userService";
import colors from "../utils/colors";


class EnterpriseR1 extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      report: null,
      date: { startDate: null, endDate: null },
      dataIsLoading: true,
      ideasByCategoryForPie: [],
      ideasByTypeForPie: [],
      selectedSites: [],
      sites: [],
    }

    this.getResults = this.getResults.bind(this);
    this.handleSiteChange = this.handleSiteChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }
  
  async handleDownload() {
    let companies = null;
    const { selectedSites, date } = this.state;

    if (selectedSites) {
      companies = selectedSites.map((site) => site.value);
    }

    try {
      const response = await fetchReports(companies, date, 'report');
      const { fileData } = response;

      if (!fileData) return;

      const byteCharacters = atob(fileData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });

      const currentDate = new Date().toISOString().slice(0, 10);
      const downloadFileName =  `report_${currentDate}.xlsx`;

      saveAs(blob, downloadFileName);
    } catch (error) {
      console.error('Error al descargar los datos:', error);
    }
  }


  componentWillMount() {
    this.getSites();
    this.getResults(null);
  }

  handleSiteChange(selectedSites) {
    const { date } = this.state;
    this.setState({ selectedSites });
    this.getResults(selectedSites, date);
  }
  
  handleDateChange(start, end) {
    const date = { startDate: start, endDate: end };
    const { selectedSites } = this.state;
    this.setState({ date });
    this.getResults(selectedSites, date);
  }
  
  async getResults(selectedSites, date) {
    this.setState({dataIsLoading: true})

    let companies = null
    if(selectedSites){
      companies = selectedSites.map((site) => site.value)
    }

    try {
      const reports = await fetchReports(companies, date);

      const totalByCategories = reports ? reports.opportunities.byCategories.reduce((acc, category) => acc + (category.count || 0), 0) : 0;

      const ideasByCategoryForPie = reports ? reports.opportunities.byCategories.map((category, index) => {
        const percentage = totalByCategories > 0 ? ((category.count / totalByCategories) * 100).toFixed(2) : 0;
        return {
          label: category.label,
          color: this.getCategoryColor(index),
          value: parseFloat(percentage), 
        };
      }) : [];

      const totalByTypes = reports ? reports.opportunities.byTypes.reduce((acc, type) => acc + (type.count || 0), 0) : 0;
      const ideasByTypeForPie = reports ? reports.opportunities.byTypes.map((type, index) => {
        const percentage = totalByTypes > 0 ? ((type.count / totalByTypes) * 100).toFixed(2) : 0;

        let color = "#5e40d2";
        if (type.label === 'Innovation') 
          color = "#2961a7";
        if (type.label === 'Problem Solving')
          color = "#3f8cf4";
        if (type.label === 'Continuous Improvement')
          color = "#ed7333";

        return {
          label: type.label,
          color,
          value: parseFloat(percentage),
        };
      }) : [];


      this.setState({ report: reports, ideasByCategoryForPie, ideasByTypeForPie});

    } catch (error) {
      console.error('Error while fetching sites:', error);
    }finally{
      this.setState({dataIsLoading: false})
    }
  }

  getSites = async () => {
    try {
      const sites = await fetchSites();
      this.setState({ sites: sites.map((s)=>{
        return {label: s.title, value: s.id}
      }) });
    } catch (error) {
      console.error('Error while fetching sites:', error);
    }
  }

  setupNumberOfIdeasByCategoryDataNew() {
    const { report } = this.state;
    const { t } = this.props;

    const data = report ? report.opportunities.byCategories.map((c) => ({
      x: c.label,
      y: c.count
    })) : [];

    const series = [
      {
        name: 'Opportunities',
        data,
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c']
      }
    ];

    const demoData = {
      series: series,
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        yaxis: {
          title: {
            text: t('CHART_CATEGORIES'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          }
        },
        xaxis: {
          title: {
            text: t('QUANTITY_OF_OPPORTUNITIES'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function(val) {
              return val;
            }
          }
        },
        fill: {
          opacity: 1
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
        tooltip: {
          y: {
            formatter: function (val) {
              return Math.round(val); 
            }
          }
        }
      }
    };

    return demoData;
  }

  setupNumberOfIdeasByTypeDataNew() {
    const { report } = this.state;
    const { t } = this.props;

    const data = report ? report.opportunities.byTypes.map((c) => ({
      x: c.label,
      y: c.count
    })) : [];

    const series = [
      {
        name: 'Opportunities',
        data,
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c']
      }
    ];

    const demoData = {
      series: series,
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        yaxis: {
          title: {
            text: t('OPPORTUNITY_TYPE'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          }
        },
        xaxis: {
          title: {
            text: t('QUANTITY_OF_OPPORTUNITIES'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function(val) {
              return val;
            }
          }
        },
        fill: {
          opacity: 1
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
        tooltip: {
          y: {
            formatter: function (val) {
              return Math.round(val);
            }
          }
        }
      }
    };

    return demoData;
  }

  getCategoryColor(i) {
    const colors = ['#3e8df4', '#2961a8', '#5e41d2', '#ed7333', '#7ec3fa', '#9cd64c', '#5fb2f9', '#8577db', '#6e952f'];
    const colorLength = colors.length;
    const index = i % colorLength;
    return colors[index];
  }

  getPercentageIdeasEmployeesBySiteNew() {
    const { report } = this.state;
    const {t} = this.props;

    const demoData = {
      series: [
        {
          name: 'Employee Participation',
          data: [
           
          ]
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        yaxis: {
          title: {
            text: t('CHART_EMPLOYEE_PARTICIPATION'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          max: 100,
          labels: {
            formatter: function (value) {
              return value + '%';
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return Math.trunc(val) + '%'
            }
          }
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
      }
    };

    const participation = report ? report.opportunities.participationByCompany.map((site) => {
      return {
        x: site.label,
        y: site.count
      }
    }) : [];

    demoData.series[0].data = participation

    return demoData

  }

  getIdeasEmployeesBySiteNew() {
    const { report } = this.state;
    const { t } = this.props;

    const contribution = report ? report.opportunities.contributionByCompany.map((site) => {
      return {
        x: site.label,
        y: parseFloat(site.count.toFixed(2))
      };
    }) : [];

    const maxContribution = Math.max(...contribution.map(site => site.y));

    const maxYAxis = Math.ceil(maxContribution * 2) / 2;

    const demoData = {
      series: [
        {
          name: 'Employee Participation',
          data: contribution
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          labels: {
            formatter: function (value) {
              return value;
            }
          }
        },
        yaxis: {
          title: {
            text: t('AVG_CONTRIBUTION_EMPLOYEE'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          min: 0,
          max: maxYAxis, // Establecer el valor máximo dinámico
          tickAmount: maxYAxis * 2, // Esto genera ticks cada 0.5 (multiplicado por 2 para que coincida con 0.5)
          labels: {
            formatter: function (value) {
              return value.toFixed(2); // Mostrar los valores con 2 decimales
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val.toFixed(2); // Redondear los valores de la tooltip a 2 decimales
            }
          }
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c'],
      }
    };

    return demoData;
  }


  getIdeasByStatusDataNew() {
    const { report } = this.state;
  
    const { t } = this.props;
    var demoData = {
      series: [
        {
          name: 'Ideas by Status',
          data: [],
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
         
        xaxis: {
          categories: ['Submitted', 'Evaluated', 'In Progress', 'Completed', 'Verified', 'R&R'],
          
        },
        yaxis: {
          title: {
            text: t('QUANTITY_OF_OPPORTUNITIES'),
            style: {
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontWeight: 'normal',
            },
          },
          labels: {
            formatter: function (value) {
              return value;
            },
          },
        },
        fill: {
          opacity: 1,
          colors: [
            '#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c' ,'#9dd64c' 
          ]
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        colors: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c', '#9dd64c'],
      },
    };
      demoData.series[0].data = [
        {
          x: 'Submitted',
          y: report ? report.opportunities.submitted : 0,
        
        },
        {
          x: 'Evaluated',
          y: report ? report.opportunities.pendingEvaluation : 0,
          
        },
        {
          x: 'In Progress',
          y: report ? report.opportunities.inProgress : 0,
          
        },
        {
          x: 'Completed',
          y: report ? report.opportunities.closed : 0,
        },
        {
          x: 'Verified',
          y: report ? report.opportunities.verified : 0,
        },
        {
          x: 'R&R',
          y: report ? report.opportunities.rewardRecognition : 0,
        },
      ]

    return demoData;
  }
  
  render() {
    const currentUser = authUser();
    if (!currentUser.company.isEnterprise)  return <></>;

    const { t } = this.props;
    const { sites, selectedSites,  report } = this.state;

    const ideasByType = this.state.ideasByTypeForPie;
    const ideasByCategoryPie = this.state.ideasByCategoryForPie;

    const officialNumSubmitted = report ? report.opportunities.submitted : 0
    const officialNumCompleted = report ? report.opportunities.closed : 0
    const officialNumInProgress = report ? report.opportunities.inProgress : 0
    const officialNumPendingEval = report ? report.opportunities.pendingEvaluation : 0

    const ideasPerCategory = this.setupNumberOfIdeasByCategoryDataNew()
    const ideasByTypeBar = this.setupNumberOfIdeasByTypeDataNew()
    const ideasByStatusData = this.getIdeasByStatusDataNew()
    const ideasPerEmployeeData = this.getPercentageIdeasEmployeesBySiteNew()
    const ideasPerEmployeeLast = this.getIdeasEmployeesBySiteNew()
  

    var smallStats = [
      {
        label: t('SUBMITTED_IDEAS'),
        value: officialNumSubmitted,
        percentage: "12.4%",
        increase: true,
        chartLabels: [null, null, null, null, null],
        decrease: false,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.primary.toRGBA(0.1),
            borderColor: colors.primary.toRGBA(),
            data: [9, 3, 3, 9, 9]
          }
        ]
      },
      {
        label: t('COMPLETED_IDEAS'),
        value: officialNumCompleted,
        percentage: "7.21%",
        increase: false,
        chartLabels: [null, null, null, null, null],
        decrease: true,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.success.toRGBA(0.1),
            borderColor: colors.success.toRGBA(),
            data: [3.9, 4, 4, 9, 4]
          }
        ]
      },
      {
        label: t('PENDING_FOR_EVALUATION'),
        value: officialNumPendingEval,
        percentage: "3.71%",
        increase: true,
        chartLabels: [null, null, null, null, null],
        decrease: false,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.warning.toRGBA(0.1),
            borderColor: colors.warning.toRGBA(),
            data: [6, 6, 9, 3, 3]
          }
        ]
      },
      {
        label: t('IDEAS_ON_PROGRESS'),
        value: officialNumInProgress,
        percentage: "3.71%",
        increase: true,
        chartLabels: [null, null, null, null, null],
        decrease: false,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.warning.toRGBA(0.1),
            borderColor: colors.warning.toRGBA(),
            data: [6, 6, 9, 3, 3]
          }
        ]
      },
    ];

    const Option = (props) => {
      return (
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label className="ml-2">{props.label}</label>
        </components.Option>
      );
    };
    const downloadReport = (currentUser.roles.includes('murmuratto') || currentUser.allowDownloadReportEnterprise);

    return (
      <Container fluid className="main-content-container px-4">
        <Row  className="page-header py-4">
          <Col style={{margin:0,  padding:0}} noGutters lg={downloadReport ? 4 : 5} sm={downloadReport ? 4 : 5}>
            <PageTitle title={t('Enterprise')} subtitle={t('REPORTS')} className="text-sm-left mb-3" />
          </Col>
          <Col xs="3" sm="3">
            <DateRangePickerWithPresets onDateRangeChange={this.handleDateChange} />
          </Col>
          <Col xs="4" sm="4" >
            <Select
              options={sites}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option }}
              onChange={this.handleSiteChange}
              value={selectedSites}
            />
          </Col>
          {downloadReport && 
          <>
            <Col lg="1" sm="1">
              <Button onClick={this.handleDownload} >Download</Button>
            </Col>
          </>}
        </Row>

        <Row>
          {smallStats.map((stats, idx) => (
            <Col key={idx} md="6" lg="3" className="mb-4">
              <div style={{ display: 'flex', height: '100%' }}>
                <Card style={{ flex: 1 }}>
                  <CardBody style={{ padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <p style={{ textAlign: 'center', marginTop: 32, marginBottom: 0, fontSize: 20, fontWeight: 500, paddingLeft: 30, paddingRight: 30 }}>
                      {stats.label}
                    </p>
                    <div style={{ textAlign: 'center', marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
                      {this.state.dataIsLoading ? (
                        <ReactLoading type={'spokes'} color={'#633FDA'} width={40} height={40} />
                      ) : (
                        <p style={{ fontSize: 50, fontWeight: 700, color: '#3f8cf4' }}>
                          {stats.value === 0 ? '0' : stats.value}
                        </p>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          ))}
          
          <Col lg="12" sm="12" className="mb-4">
            <Card>
              <CardBody>
                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
                  <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
                    {t('OPPORTUNITY_STATUS')}
                  </p>
                </div>
                  <BarChart data={ideasByStatusData}></BarChart>
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" sm="12"  className="mb-4">
            <Card style={{minHeight: '550px'}}>
              <CardBody>
              <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
                    <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
                        {t('OPPORTUNITIES_BY_CATEGORY_PERCENTAGE')}
                    </p>
                    <p style={{marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500}}>
                        {t('PERCENTAGE')}
                    </p>
                </div>
                  <PieChart data={ideasByCategoryPie} id="enterprisePie1"></PieChart>
              </CardBody>
            </Card>
          </Col>

        <Col lg="6" sm="12" className="mb-4">
          <Card style={{minHeight: '550px'}}>
            <CardBody>
            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
                    <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
                    {t('OPPORTUNITIES_BY_CATEGORY_PERCENTAGE')}
                    </p>
                    <p style={{marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500}}>
                        {t('QUANTITY')}
                    </p>
                </div>
              <BarChart data={ideasPerCategory}></BarChart>
            </CardBody>
          </Card>
        </Col>

        <Col lg="6" sm="12" className="mb-4">
          <Card style={{minHeight: '550px'}}>
            <CardBody>
            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
                  <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
                    {t('OPPORTUNITIES_BY_TYPE_PERCENTAGE')}
                  </p>
                  <p style={{marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500}}>
                      { t('PERCENTAGE')}
                  </p>
              </div>
                <PieChart data={ideasByType} id="enterprisePie2"></PieChart>
              

            </CardBody>
          </Card>
        </Col>

        <Col lg="6" sm="12" className="mb-4">
          <Card style={{minHeight: '550px'}}>
              <CardBody>
              <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
                  <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
                    {t('OPPORTUNITIES_BY_TYPE_PERCENTAGE')}
                  </p>
                  <p style={{marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500}}>
                  { t('QUANTITY')}
                  </p>
              </div>
              <BarChart data={ideasByTypeBar}></BarChart>
              </CardBody>
          </Card>
        </Col>
       
        
        <Col lg="6" sm="12" className="mb-4">
          <Card style={{minHeight: '500px'}}>
            <CardBody>
              <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
                <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
                  {t('PARTICIPATION_BY_SITE')}
                </p>
                <p style={{marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500}}>
                  { t('PERCENTAGE')}
                </p>
              </div>
              
              <BarChart data={ideasPerEmployeeData}></BarChart>
            </CardBody>
          </Card>
        </Col>

        <Col lg="6" sm="12" className="mb-4">
        <Card style={{minHeight: '500px'}}>
            <CardBody>
              <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 30, paddingRight: 30}}>
                <p style={{textAlign: 'left', marginTop: 0, marginBottom: 20, fontSize: 20, fontWeight: 500}}>
                  {t('CONTRIBUTIONS_PER_EMPLOYEE')}
                </p>
                <p style={{marginTop: 0, marginBottom: 20, fontSize: 16, color: '#888', marginLeft: 8, fontWeight: 500}}>
                  { t('QUANTITY')}
                </p>
              </div>
              {<BarChart data={ideasPerEmployeeLast}></BarChart>}
            </CardBody>
          </Card>
        </Col> 

      </Row>

    </Container>
    )
  }
}

EnterpriseR1.propTypes = {
  smallStats: PropTypes.array
};

EnterpriseR1.defaultProps = {
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default withTranslation()(EnterpriseR1);