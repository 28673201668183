import React from "react";
import ReactTable from "react-table";

const ResponsiveTable = ({ columns, data, pageSizeOptions, pageSize, showPageSizeOptions, resizable }) => {    
    
    return (
        <div className={"responsive-table"}>
            <div className={"cards"} >
                {
                    data.map((field, fieldIndex) => {
                        return (
                            <div key={fieldIndex} className="card">
                                {columns.map((column, colIndex) => {
                                    if (typeof field[column.accessor] !== "string") {
                                        return
                                    }
                                    return (
                                        <div className="card--row" key={colIndex}>
                                            <h5>{column.Header}</h5>
                                            <span>{field[column.accessor] === "SOMETIDA" ?  "Submited" : field[column.accessor]}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })
                }
            </div>
            <div className="full-table">
            <ReactTable
                columns={columns}
                data={data}
                defaultPageSize={pageSize}
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                showPageSizeOptions={showPageSizeOptions}
                resizable={resizable}
            />
            </div>
        </div>
    )
}

export default ResponsiveTable;