import React from "react";
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { fetchUserPrivileges, fetchUserRoles } from "../../services/tableService";
import "./LocalStyles/CategorySelectStyle.css";

const selectedColor = '#157ffb';//'#ff9012';

class SelectPrivileges extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            options: [],
            selectionValue: '',
            sq1Color: '',
            sq2Color: '',
            sq3Color: '',
            sq4Color: '',
            titleColor1: 'black',
            titleColor2: 'black',
            titleColor3: 'black',
            titleColor4: 'black',
            handIcon: 'black',
            shieldIcon: 'black',
            chartIcon: 'black',
            clockIcon: 'black',
            puzzleIcon: 'black',
            gearIcon: 'black',
            data:[],
            showSecurity:'',
            showQuality:'',
            showProductivity:'',
            showAction:'',
            selectedPerson:this.props.selectedVal,
          }
          this.fetchCategoryData = this.fetchCategoryData.bind(this);
          this.handleSelectedPersonChange = this.handleSelectedPersonChange.bind(this);
    }

    componentDidMount() {
        this.fetchCategoryData();
    }

    fetchCategoryData = async () => {
        let options = [];
        const { type } = this.props;
    
        try {
            if (type === 'privileges') {
                const privileges = await fetchUserPrivileges(); // Fetch data from the API
                options = privileges.map((privilege) => ({
                    value: privilege.id, 
                    label: privilege.title
                }));
            } else {
                const roles = await fetchUserRoles(); 
           
                options = roles.map((role) => ({
                    value: role.id, 
                    label: role.title
                }));
            }
            this.setState({ options });
        } catch (error) {
            console.error('Error fetching category data:', error);
            // Handle the error accordingly, such as showing a message to the user
        }
    };
    handleSelectedPersonChange(event) {
        this.props.setResponsible(event, 0);
    }

    getFullName(result) {
        if (result) {
            return result.get("firstName") + ' ' + result.get("lastName");
        }
    }

    render() {
        const { options, coaches } = this.state;
        const { selectedVal, type} = this.props;

        const value = selectedVal ? options.filter((val) => selectedVal.includes(val.value)) : null;
        
        return(
            this.props.evalType === "execution" ?
                <div  style={{width: '100%'}}>
                    <Select
                        value={value}
                        onChange={this.handleSelectedPersonChange}
                        options={options}
                        isMulti
                        placeholder={type === "privileges" ? 'Privileges' : 'Activity'}
                        clearable={false}
                        className="insideFont"
                    />
                </div>
            :
                <div  style={{minWidth: 250}}>
                    <Select
                        value={selectedVal}
                        onChange={this.handleSelectedPersonChange}
                        options={coaches}
                        placeholder={'No Coach'}
                        className="insideFont"
                    />
                </div>
        )
    }
}

export default withTranslation()(SelectPrivileges);