import axios from 'axios';
import React, { Component } from "react";
import { withRouter } from 'react-router';
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormTextarea,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "shards-react";
import Swal from 'sweetalert2';
import { authUser, getSubdomain, purgeCache } from '../../services/userService';

import { sendTroubleEmail } from '../../services/emailService';

const ErrorCard = (message) => {
  return (
    <div style={styles.card}>
      <div style={styles.textContainer}>
        <span style={styles.message}>{message}</span>
      </div>
    </div>
  );
};

class LoginFormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password:'',
      redirectToReffer: false,
      modalMailToVisible: false,
      emailBody: '',
      companyName: '',
      showLast90Days: false,
      showErrorLogin: false,
      loginErrorMsg: ''
    }

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.toggleMailToModal = this.toggleMailToModal.bind(this);
    this.sendMailTo = this.sendMailTo.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }


  toggleMailToModal() {
    this.setState(prevState => ({
      modalMailToVisible: !prevState.modalMailToVisible,
      emailBody: '',
    }));
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  async sendMailTo() {
    const { companyName, email, emailBody } = this.state;
    const subdomain = getSubdomain() || 'demo';

    try {
      const troubleEmailData = {
        body: emailBody,
        company: subdomain,
        location: companyName,
        email: email
      };

      const response = await sendTroubleEmail(troubleEmailData);
  
      if (response.status === 200) {
        Swal.fire("We are working on this!", response.message, "success");
      } else {
        Swal.fire("Error", response.message, "error");
      }
    } catch (error) {
      Swal.fire("Error", "There was an issue sending your email. Please try again later.", "error");
      console.error('Error sending trouble email:', error);
    } finally {
      this.toggleMailToModal();
    }
  }

  componentDidMount() {}

  emailChangeHandler = event => {
    this.setState({
      email: event.target.value
    });
  }

  passwordChangeHandler = event => {
    this.setState({
      password: event.target.value
    });
  }

  needsPasswordChange() {
    const { password } = this.state;
    const validation = password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8}/)
    
    return validation ? false:true
  }

  async handleFormSubmit() {
    this.setState({ showErrorLogin: true, showLast90Days: false });
    purgeCache()
    const subdomain = getSubdomain() || 'demo';

    try {
      const {email, password} = this.state;
      const userData = await this.apiLogin(email, password, subdomain);

      localStorage.setItem('user', JSON.stringify(userData));
      await authUser()
    
      this.redToReffer = true;
      this.setState(() => ({
        redirectToReffer: this.redToReffer
      }))

    } catch (error) {
      this.setState({ loginErrorMsg: error, showErrorLogin: true})
    }

  }


  async apiLogin(email, password, company) {
    if (!email || !password || !company) throw new Error('Email and password are required.');

    const formData = new URLSearchParams({
      email,
      password,
      company,
    });

    try {
      const response = await axios.post(
        process.env.REACT_APP_EP_API + '/auth/login',
        formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      
      return response.data;
    } catch (error) {
      if(error.response.data.error)
        throw error.response.data.error.message;
      else if(error.response.data.message)
        throw error.response.data.message;
      else
        throw error;
    }
  }

  onKeyDown(e) {
    if (e.key === 'Enter') {
      this.handleFormSubmit();
    }
  }
  
  render() {
    const { redirectToReffer, modalMailToVisible, emailBody, companyName, showLast90Days, showErrorLogin, loginErrorMsg } = this.state
    var handleAuth = this.props.handleAuth;
    if (redirectToReffer === true) {  
      handleAuth()
      return (
        <Redirect to="/"/>
      )
    }

    return (
      <Form>
        {showLast90Days && ErrorCard(<>Por favor actualizar tu contraseña cada 90 días. <Link to="/forgot-password" style={{  textDecoration: 'underline' }}>Oprima aquí</Link>.</>)}
        {showErrorLogin && loginErrorMsg && ErrorCard(<>{loginErrorMsg}</>)}
        <FormGroup>
          <label htmlFor="loginInputEmail">User ID</label>
          <FormInput
            type="email"
            name="email"
            id="loginInputEmail"
            value={this.state.email}
            onChange={this.emailChangeHandler}
            placeholder="Enter email"
            autoComplete="email"
            onKeyDown={this.onKeyDown}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="loginInputPassword">Password</label>
          <FormInput
            type="password"
            name="password"
            id="loginInputPassword"
            value={this.state.password}
            onChange={this.passwordChangeHandler}
            placeholder="Password"
            autoComplete="current-password"
            onKeyDown={this.onKeyDown}
          />
        </FormGroup>
        <Button onClick={this.handleFormSubmit}
          pill
          theme="accent"
          className="d-table mx-auto"
          type="button">
          Access Account
        </Button>
        <div className="mx-auto auth-form__meta mt-4" style={{textAlign: 'center'}}>
          <Link to="/forgot-password">Forgot your password?</Link>
        </div>

        <div className="mx-auto auth-form__meta" style={{textAlign: 'center'}}>
          <Button theme="link" onClick={this.toggleMailToModal}>Trouble logging in?</Button>
        </div>

        <Modal className="modal700" open={modalMailToVisible} toggle={this.toggleMailToModal}>
          <ModalHeader>Let's get you back into your account!</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <p style={{margin: 0}}>Please describe the issue you face when logging into your account.</p>
                  <FormTextarea
                    type="textarea"
                    name="emailBody"
                    value={emailBody}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <p style={{margin: 0}}>Please provide your e-mail address.</p>
                  <FormInput
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                 <p style={{margin: 0}}>What is your company name and location?</p>
                  <FormInput
                    type="text"
                    name="companyName"
                    value={companyName}
                    onChange={this.handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button theme="secondary" onClick={this.toggleMailToModal}>
              Cancel
            </Button>
            <Button theme="primary" onClick={this.sendMailTo}>
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      </Form>
    );
  }

}

export default withRouter(LoginFormContainer);

const styles = {
  card: {
    display: 'flex',
    alignItems: 'start',
    backgroundColor: '#f1f1f1',
    color: '#ff6912',
    padding: '15px 20px',
    borderRadius: '7px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    borderBottom: '6px solid #ff6912',
    marginBottom: '20px',
  },
  icon: {
    fontSize: '24px',
    marginRight: '10px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  message: {
    fontSize: '14px',
    color: '#444',
  }
};