import { any } from "prop-types";
import React from "react";
import { Col, Container, Row } from "shards-react";

import VerificationIdeaTable from "../components/administration/VerificationIdeaTable";
import PageTitle from "../components/common/PageTitle";

import IdeaVerificationForm from "../components/common/IdeaVerificationForm";


import { withTranslation } from 'react-i18next';
import { ReactComponent as PreviousIcon } from "../images/PreviousIcon.svg";
import CompletedIdeaFormNew from "./CompletedIdeaFormNew";

class IdeaVerificationInbox extends React.Component { 

  constructor(props) {
    super(props)

    this.state = {
      categoryName: '',
      statusName: '',
      open: false,
      ideaItem: any,
      viewIdea: false,
      showPreviousIcon: true,
    }
  }

  onCategoryChange(categoryName) {
     
    this.setState({
      categoryName: categoryName
    })
  }

  onStatusChange(statusName) {
     
    this.setState({
      statusName: statusName
    })
  }

  onTypeChange(ideaType) {
     
    // var selectedIdeaType = ""
    // if (ideaType == "innovation" || ideaType == 'innovacion') {
    //   selectedIdeaType = "innovacion"
    // } else if (ideaType == "problem solving" || ideaType == 'problema') {
    //   selectedIdeaType = "problema"
    // } else if (ideaType == "continuous improvement" || ideaType == 'mejoras') {
    //   selectedIdeaType = "improvement"
    // }

    this.setState({
      ideaType: ideaType
    })
  }

  onDepartmentChange(categoryName) {
    
    var selectedCategoryName = categoryName
    if (categoryName == "All" || categoryName == "Todas") {
      selectedCategoryName = ""
    }

  

    this.setState({
      departmentName: selectedCategoryName
    })

    
  }

  toggle(item) {
     
    this.setState({
      open: true,
      ideaItem: item,
      viewIdea: false,
    });
  }

  dismissModal() {
    this.setState({
      open: !this.state.open
    });
  }

  showIdea(item) {
    this.setState({
    open: true,
    ideaItem: item,
    viewIdea: true,
  });
}

handlePreviousClick = () => {
  // Implement logic for previous button click
  // Example: navigate to the previous step or close modal
  // You can set state or call functions based on your requirements
  // For example:
  this.setState({
    open: false, // Close the modal
    viewIdea: false, // Update view state
  });
};

  render() {
    const {categoryName, statusName, open, viewIdea} = this.state;
    const {t} = this.props
    return(
      !open?(
      <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header pt-4 pb-2">
      <PageTitle title={t("IDEA_VERIFICATION")} subtitle="Workflow" className="text-sm-left mb-3" />
    </Row>
    <Row noGutters className="page-header pt-2 mb-4">
      {t("Verify Inbox Workflow Instruction")}
    </Row>

    <Row className="pt-4">
      <Col >
        <VerificationIdeaTable category={categoryName} status={statusName} onEditActionPress={this.toggle.bind(this)} onViewIdeaPress={this.showIdea.bind(this)}/>
      </Col>
    </Row>

  </Container>)
  :
  ( !viewIdea?<Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title={this.state.ideaItem.status == 'Idea Proyecto'?t('EVAL_IDEA_TITLE_PROJECT'):t('EVAL_IDEA_TITLE')} subtitle={t("Verify Workflow")} className="text-sm-left mb-3" />
    </Row>
    
    <Row>
        <Col className="mb-4">
          <IdeaVerificationForm dismissModal={this.dismissModal.bind(this)} ideaItem={this.state.ideaItem}/>
        </Col>
    </Row>
    <Row>
        <Col lg="12" className="mb-4 mr-auto">
          {this.state.showPreviousIcon && (
            <PreviousIcon
              className="functionalButton mr-auto d-block"
              style={{ minWidth: 140, maxWidth: 140 }}
              onClick={this.handlePreviousClick}
            ></PreviousIcon>
          )}
        </Col>
      </Row>
    </Container>
    
    :
       <CompletedIdeaFormNew currIdea={this.state.ideaItem} hiddenFormA3={true} closeModal={() => {this.setState({open: false})}} closeA3={() => this.setState({open: false})} stage="completed" customTitle="Verify"></CompletedIdeaFormNew>
    )
  
    )
  }
}

export default withTranslation()(IdeaVerificationInbox);
