import PropTypes from "prop-types";
import React from "react";

class SingleProgressChart extends React.Component {
  render() {
    const { idea } = this.props;
  
    let progress = 0;
    if(idea){
      if(idea.progress){
          progress = idea.progress; 
      }
    }
    return (
      <div>
        <div className="go-stats__chart justify-content-center">
          <h6 style={{fontSize:'.8rem', lineHeight: '0.9rem', margin:'0px'}} className="go-stats__label justify-content-start">{progress}%</h6>
         <div className="progress-container" style={{ position: "relative", width: "100px", height: "15px", backgroundColor: "#e0e0e0", borderRadius: "10px" }}>
            <div
              className="progress-bar"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: `${progress}%`,
                height: "100%",
                backgroundColor: "#643fda",
                borderRadius: "10px",
                transition: "width 0.5s ease-in-out"
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

SingleProgressChart.propTypes = {
  idea: PropTypes.object.isRequired,
};

export default SingleProgressChart;
