import { any } from "prop-types";
import React from "react";
import { Button, ButtonGroup, Col, Container, Row } from "shards-react";

import IdeaFilterManager from "../components/administration/IdeaFilterManager";
import ManagementIdeaTable from "../components/administration/ManagementIdeaTable";
import PageTitle from "../components/common/PageTitle";

import IdeaViewCard from "../components/common/IdeaViewCard";

import dateFormat from "dateformat";

import { withTranslation } from "react-i18next";

class OnlineStore extends React.Component { 

  constructor(props) {
    super(props)

    this.state = {
      categoryName: '',
      departmentName: '',
      statusName: '',
      ideaType: '',
      open: false,
      viewIdea: false,
      ideaItem: any,
      showPreviousIcon: true,
      showNextIcon: false,
      showSubmitIcon: false,
    }
  }

  onCategoryChange(categoryName) {
     
    var selectedCategoryName = categoryName
    if (categoryName == "All" || categoryName == "Todas") {
      selectedCategoryName = ""
    }

    this.setState({
      categoryName: selectedCategoryName
    })
  }

  onTypeChange(ideaType) {
     
    // var selectedIdeaType = ""
    // if (ideaType == "innovation" || ideaType == 'innovacion') {
    //   selectedIdeaType = "innovacion"
    // } else if (ideaType == "problem solving" || ideaType == 'problema') {
    //   selectedIdeaType = "problema"
    // } else if (ideaType == "continuous improvement" || ideaType == 'mejoras') {
    //   selectedIdeaType = "improvement"
    // }

    this.setState({
      ideaType: ideaType
    })
  }

  onDepartmentChange(categoryName) {
    
    var selectedCategoryName = categoryName
    if (categoryName == "All" || categoryName == "Todas") {
      selectedCategoryName = ""
    }

  

    this.setState({
      departmentName: selectedCategoryName
    })

    
  }

  onStatusChange(statusName) {
     
    var selectedStatusName = ""
    if (statusName == "Submitted" || statusName == 'Sometidas') {
      selectedStatusName = "SOMETIDA"
    } else if (statusName == "Done" || statusName == 'Completadas') {
      selectedStatusName = "problema"
    }
    this.setState({
      statusName: selectedStatusName
    })
  }

  onTypeChange(ideaType) {
     
    var selectedIdeaType = ""
    if (ideaType == "innovation" || ideaType == 'innovacion') {
      selectedIdeaType = "innovacion"
    } else if (ideaType == "problem" || ideaType == 'problema') {
      selectedIdeaType = "problema"
    } else if (ideaType == "improvement" || ideaType == 'mejoras') {
      selectedIdeaType = "improvement"
    }

    this.setState({
      ideaType: selectedIdeaType
    })
  }

  onQuestionChange(question) {
     
    // var selectedIdeaType = ""
    // if (ideaType == "innovation" || ideaType == 'innovacion') {
    //   selectedIdeaType = "innovacion"
    // } else if (ideaType == "problem" || ideaType == 'problema') {
    //   selectedIdeaType = "problema"
    // }

    this.setState({
      ideaQuestion: question
    })
  }

  toggle(item) {
     
    this.setState({
      open: !this.state.open,
      ideaItem: item,
      viewIdea: false
    });
  }

  showIdea(item) {
      this.setState({
      open: true,
      ideaItem: item,
      viewIdea: true,
    });
  }

  dismissModal() {
    this.setState({
      open: !this.state.open
    });
  }

  handlePreviousClick = () => {
    // Implement logic for previous button click
    // Example: navigate to the previous step or close modal
    // You can set state or call functions based on your requirements
    // For example:
    this.setState({
      open: false, // Close the modal
      viewIdea: false, // Update view state
    });
  };

  handleNextClick = () => {
    // Implement logic for next button click
    // Example: navigate to the next step or perform an action
    // You can set state or call functions based on your requirements
    // For example:
    this.setState({
      open: true, // Open a modal or proceed to the next step
      viewIdea: true, // Update view state
    });
  };

  handleSubmitClick = () => {
    // Implement logic for submit button click
    // Example: submit form data or perform an action
    // You can set state or call functions based on your requirements
    // For example:
    this.setState({
      open: true, // Open a modal or proceed to the submission process
      viewIdea: false, // Update view state
    });
  };



  render() {
    const {categoryName, statusName, ideaType, open, viewIdea, ideaQuestion} = this.state;
    const { t } = this.props;
    const tableColumns = [
      {
        Header: "#",
        accessor: "id",
        maxWidth: 60,
        className: "text-center"
      },
      {
        Header: "Titulo",
        accessor: "date",
        className: "text-center",
        minWidth: 200,
        Cell: row =>
          dateFormat(new Date(row.original.date), "dddd, mmmm dS, yyyy")
      },
      {
        Header: "Categoria",
        accessor: "customer",
        className: "text-center"
      },
      {
        Header: "Proponente",
        accessor: "products",
        maxWidth: 100,
        className: "text-center"
      },
      {
        Header: "Estatus",
        accessor: "status",
        maxWidth: 100,
        Cell: row => (
          <span className={this.getStatusClass(row.original.status)}>
            {row.original.status}
          </span>
        ),
        className: "text-center"
      },
      {
        Header: "Acciones",
        accessor: "actions",
        maxWidth: 300,
        minWidth: 180,
        sortable: false,
        Cell: row => (
          <ButtonGroup size="sm" className="d-table mx-auto">
            <Button theme="white" onClick={() => this.handleItemConfirm(row)}>
              <i className="material-icons">visibility</i>
            </Button>
            <Button theme="white" onClick={() => this.handleItemConfirm(row)}>
            <i class="fas fa-edit"></i>
            </Button>
          </ButtonGroup>
        )
      }
    ];
    return(
      !open?(
      <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title={t("TRANSACTION_MANAGE")} subtitle="IDEA" className="text-sm-left mb-3" />
    </Row>

    {/* Small Stats Blocks */}
    <Row>
        <Col className="mb-4">
          <IdeaFilterManager onTypeChange={this.onTypeChange.bind(this)} onCategoryChange={this.onCategoryChange.bind(this)} onStatusChange={this.onStatusChange.bind(this)} onTypeChange={this.onTypeChange.bind(this)}  onQuestionChange={this.onQuestionChange.bind(this)}  onDepartmentChange={this.onDepartmentChange.bind(this)}/>
        </Col>
    </Row>

    <Row>
      {/* Latest Orders */}
      <Col className="mb-4">
        <ManagementIdeaTable category={categoryName} status={statusName} ideaType={ideaType} filterQuestion={ideaQuestion} onEditActionPress={this.toggle.bind(this)} onViewIdeaPress={this.showIdea.bind(this)}/>
      </Col>
    </Row>

  </Container>)
  :
  (<Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title={t("EVAL_IDEA_TITLE")} subtitle="IDEA" className="text-sm-left mb-3" />
    </Row>
    {!viewIdea?
    <Row>
        {/* <Col className="mb-4">
          {this.state.ideaItem.get("completed") == true? 
          <IdeaVerificationForm dismissModal={this.dismissModal.bind(this)} ideaItem={this.state.ideaItem}/>
        :
        <EvaluateIdeaForm dismissModal={this.dismissModal.bind(this)} ideaItem={this.state.ideaItem}/>
        }
        </Col> */}
    </Row>:
    <Row>
      <Col md="12" className="col d-flex align-items-center">
        <IdeaViewCard dismissModal={this.dismissModal.bind(this)} ideaItem={this.state.ideaItem} onViewIdeaPress={this.toggle.bind(this)}/>
      </Col>
    </Row>
    
    }
  </Container>)
    )
  }
}

export default withTranslation()(OnlineStore);
