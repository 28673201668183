import React from 'react';
import Parse from 'parse';
import { useEffect, useState } from 'react';

function IdeasWithProgress25() {
  const [ideas, setIdeas] = useState([]);

  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const query = new Parse.Query('EvaluationHistory');
    // query.equalTo('progress.0', 25);
    query.greaterThanOrEqualTo('createdAt', firstDayOfMonth);
    query.lessThanOrEqualTo('createdAt', lastDayOfMonth);
    query.include('evaluatedBy');
    query.find().then((results) => {
      const ideasList = results.map(idea => {
        let ideaType = idea.get('ideaType')
        switch (ideaType) {
          case 'problema':
            ideaType = 'Problem Solving';
            break;
          case 'innovacion':
            ideaType = 'Innovation';
            break;
          case 'mejoras':
            ideaType = 'Continuous Improvements';
            break;
          default:
            break;
        }
        
        return ({
        title: idea.get('title'),
        evaluatedBy: idea.get('evaluatedBy'),
        status: idea.get('status'),
        responsibleName: idea.get('responsibleName'),
        evaluatedAt: idea.get('evaluatedAt'),
        ideaType: ideaType,
        category: idea.get('category'),
        department: idea.get('department'),
      })});
      setIdeas(ideasList);
    });
  }, []);

  const downloadCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Idea Title,Evaluated By, Decision Made, Implementation Leader,Evaluation Date, Idea Type, Category, Department\n";

    ideas.forEach(({ title, evaluatedBy, status, responsibleName,  evaluatedAt, ideaType, category, department}) => {
      csvContent += `${title},${evaluatedBy},${status},${responsibleName},${evaluatedAt},${ideaType},${category},${department}\n`;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "evaluation_history.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div style={{flex: 1, marginTop: 100, marginBottom: 100, alignContent: 'center', alignItems: 'center'}}>
      <button onClick={downloadCSV}>Download CSV</button>
      {/* <ul>
        {ideas.map(({ title, evaluatedBy }) => (
          <li key={title}>
            {title} - Evaluated by: {evaluatedBy}
          </li>
        ))}
      </ul> */}
    </div>
  );
}

export default IdeasWithProgress25;
