import Parse from 'parse';
import React from "react";
import {
    Button,
    Col,
    FormTextarea,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "shards-react";

import "./LocalStyles/CategorySelectStyle.css";

import { ReactComponent as AcceptIcon } from "../../images/accept_button.svg";
import { ReactComponent as CancelIcon } from "../../images/cancel_button.svg";

// Defult

// Selected

// Defult

// Selected
import { withTranslation } from 'react-i18next';


const selectedColor = '#157ffb';//'#ff9012';

class IdeaImplementFilterButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectionValue: '',
            sq1Color: '',
            sq2Color: '',
            sq3Color: '',
            sq4Color: '',
            titleColor1: 'black',
            titleColor2: 'black',
            titleColor3: 'black',
            titleColor4: 'black',
            handIcon: 'black',
            shieldIcon: 'black',
            chartIcon: 'black',
            clockIcon: 'black',
            puzzleIcon: 'black',
            gearIcon: 'black',
            data:[],
            showSecurity:'',
            showQuality:'',
            showProductivity:'',
            showAction:'',
            selectedPerson:this.props.selectedVal,
            selectedPercentage: 25,
            showConfirmationModal: false,
            temporaryPercentage: '',
            oldPercentage: '',
            updateComment: '',
            userName: '',
            showA3MODAL: false,
            showA3Btn: false,
            selectedFilter: 'View All'
          }
          this.fetchCategoryData = this.fetchCategoryData.bind(this);
          this.handleSelectedPersonChange = this.handleSelectedPersonChange.bind(this);
    }

    componentDidMount() {
        // this.fetchCategoryData();
        // const { currIdea } = this.props;
        
        // const progress = currIdea.get("progress")
        // const percentage = progress[0]
       
        // var currPercentage = percentage

        // if (currPercentage == 100 && !currIdea.get("result")) {
        //     this.setState({showA3MODAL: true})
        // } 


        
        // this.setState({selectedPercentage: currPercentage, temporaryPercentage: currPercentage, oldPercentage: currPercentage})

        // let currUser = Parse.User.current();
        // this.getUserName(currUser)
    }

    async getUserName(user) {
        var query = new Parse.Query(Parse.User);
        query.equalTo("objectId", user.id);
        const results = await query.find();
        const firstName = results[0].get('firstName');
        const lastName = results[0].get('lastName');
        const fullName = firstName + ' ' + lastName;
        this.setState({
          userName: fullName
        });
      }

    // componentDidUpdate(prevProps, prevState) {
       
    //     if (prevProps.currIdea.get("progress") !== this.props.currIdea.get("progress")) {
    //         alert('Changed progress.')
    //     }
        
       
    // }

    fetchCategoryData() {
        const className = "IdeaCategory";
  
        var ItemClass = Parse.Object.extend(className);
        var query = new Parse.Query(ItemClass);
        query.limit(1000)
        query.ascending("firstName").find()
        .then((results) => {
            const options = []
            var coaches = []


            if (this.props.type == 'Committee') {
                options.push({"value":'All Categories', "label":'All Categories'})
            }
            
            results.map((result, idx) => options.push({"value":result, "label":result.get("itemNameTrans").en}))
               
            
            
            this.setState({
                data: results, 
                options: options
            });
            // this.props.setResponsible(results[0], -1);
        }, (error) => {
            this.setState({
                data: []
            });
          // The object was not retrieved successfully.
          // error is a Parse.Error with an error code and message.
        });
      }

    changeSelectedValue(selectionValue) {
        if (selectionValue === 1) {
            this.setState({
                selectionValue: 'Seguridad', 
                sq1Color: selectedColor,
                sq2Color: '',
                puzzleIcon: 'black',
                gearIcon: selectedColor,
                titleColor1: selectedColor,
                titleColor2: 'black',
            })
            this.props.setFilter('innovacion');
        } else if (selectionValue === 2){
            this.setState({
                selectionValue: 'Calidad', 
                sq1Color: '',
                sq2Color: selectedColor,
                puzzleIcon: selectedColor,
                gearIcon: 'black',
                titleColor1: 'black',
                titleColor2: selectedColor,
            })
             this.props.setFilter('solucion');
        } 
    }

    handleSelectedPersonChange(event) {
        const {data} = this.state;
        // const idx = event.target.value;
         
        this.props.setResponsible(event, 0);
    }

    getFullName(result) {
        if (result) {
            return result.get("firstName") + ' ' + result.get("lastName");
        }
    }

    handleConfirmationModal(percentage) {
        this.setState({showConfirmationModal: true, temporaryPercentage: percentage})
    }

    changeSelectedPercentage() {
        this.setState({selectedPercentage: this.state.temporaryPercentage, showConfirmationModal: false}, () => {
            this.updateSelectedPercentage(this.state.temporaryPercentage)
            this.logActivityHistory()
            if (this.state.temporaryPercentage == 100) {
                this.props.showA3(true)
            }
        })
    }

    onTextChange = (event) => {
        
        const text = event.target.value
        this.setState({updateComment: text})
    }

    updateSelectedPercentage(percentage) {
        const { currIdea } = this.props;
        
        const percentageDiff = 100 - percentage
        const ideaPercentage = [percentage, percentageDiff]

        currIdea.set("progress", ideaPercentage)
        
        currIdea.save().then(() => {
            
        }).catch((error) => {
            alert(error)
        })

    }

    logActivityHistory() {
        const ActivityHistory = Parse.Object.extend("ActivityHistory");
        const activityHistory = new ActivityHistory();
        let oldPercentage = 25 
        if(this.state.selectedPercentage == 75)
            oldPercentage = 50
        else if(this.state.selectedPercentage == 100)
            oldPercentage = 75

        const details = 'Changed from ' + oldPercentage + '% to ' + this.state.selectedPercentage + '%'
        const description = this.state.updateComment.length>0?this.state.updateComment:'No comment.'
        
        activityHistory.set("type", 'Status Update');
        activityHistory.set("details", details);
        activityHistory.set("description", description)
        activityHistory.set("ideaNum", this.props.currIdea.get("num"));
        activityHistory.set("updatedByName",  this.state.userName);
        activityHistory.set("updatedBy", Parse.User.current().id)
        
        
        activityHistory.save().then(() => {
          // this.props.updateActivityHistory()
          this.setState({updateComment: ''})
          this.props.reloadData()
        }).catch((error) => {
          alert(error)
        })
      }

    changeFilter(filter) {
        this.setState({selectedFilter: filter})
        this.props.changeFilter(filter)
    }

    render() {
        const { selectedPerson, data, options, coaches, selectedFilter  } = this.state;
        const selectedPercentage = selectedFilter
        const { canClick, selectedVal, t } = this.props;
         
        return(
            <div>
            <Row>
                {/* Latest Orders */}
                <Col lg="10" className="m-auto">
                
                {/* <Row>
                    <Col>
                        <h6 className="mb-4" style={{fontWeight: 500,  color: '#633FDA', textAlign: 'center'}}>{'Idea Implementation Status'}</h6>
                    </Col>
                </Row> */}
              <div style={myStyles.container}>
                <Row style={{height: '100%'}}>
                    
                    <Col className={"hover-cursor"} style={selectedPercentage == 'View All'? myStyles.percentageColumnLeftSelected: myStyles.percentageColumnLeft}>
                        <div onClick={() => this.changeFilter('View All')}>
                            <p style={selectedPercentage == 'View All'? myStyles.innerPercentageSelected: myStyles.innerPercentage}>View All</p>
                        </div>
                        
                    </Col>
                    <Col className={"hover-cursor"} style={selectedPercentage == 'Coach'? myStyles.percentageColumnSelected: myStyles.percentageColumn}>
                        <div onClick={() => this.changeFilter('Coach')}>
                            <p style={selectedPercentage == 'Coach' ? myStyles.innerPercentageSelected: myStyles.innerPercentage}>Coach</p>
                        </div>
                    </Col>
                    <Col className={"hover-cursor"} style={selectedPercentage == 'Owner' ? myStyles.percentageColumnRightSelected: myStyles.percentageColumnRight}>
                        <div onClick={() => this.changeFilter('Owner')}>
                            <p style={selectedPercentage == 'Owner'? myStyles.innerPercentageSelected: myStyles.innerPercentage}>Owner</p>
                        </div>
                    </Col>
                    
                </Row>
              </div>
              </Col>
              </Row>
              <Modal open={this.state.showConfirmationModal}  centered backdrop toggle={() => this.setState({showConfirmationModal: false})}>
                      <ModalHeader>
                        <h5 className="m-auto" style={{fontWeight: 500, color: '#303030'}}>Update Status</h5>
                      </ModalHeader>
                      <ModalBody>
                       <Row>
                        <Col>
                        <label htmlFor="firstName" >{'What changed?'}</label>
                            <FormTextarea
                                style={{ minHeight: "120px" }}
                                id="comments"
                                placeholder={'Enter changes here...'}
                                value={this.state.updateComment}
                                onChange={(event) => this.onTextChange(event)}
                            />
                        </Col>
                       </Row>
                       <Row className="mt-4">
                          <div style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
                              <div className="ml-auto" style={{ display: 'flex', flexDirection: 'row'}}>
                                <div className='mr-2'>
                                    <AcceptIcon  className="functionalButton ml-auto" style={{height: 34, width: 34}} onClick={() => this.changeSelectedPercentage()}></AcceptIcon>
                                </div>
                                  <div>
                                      <CancelIcon className="functionalButton ml-auto" style={{height: 34, width: 34}} onClick={() => this.setState({showConfirmationModal: false})}></CancelIcon>
                                  </div>
                              </div>
                          </div>
                      </Row>
                      </ModalBody>
            </Modal>
            <Modal open={this.state.showA3MODAL}  centered backdrop toggle={() => this.setState({showA3MODAL: false})}>
                      <ModalHeader>
                        <h5 className="m-auto" style={{fontWeight: 500, color: '#303030'}}>{t("OPPORTUNITY_IMPLEMENTATION_SUMMAR")}</h5>
                      </ModalHeader>
                      <ModalBody>
                       <Row>
                        <Col>
                        <p htmlFor="firstName" >{t('IN_ORDER_TO_GET_YOUR_OPPORTUNITY')}</p>
                           
                        </Col>
                       </Row>
                       {/* <Row className="mt-4">
                          <div style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
                              <div className="ml-auto" style={{ display: 'flex', flexDirection: 'row'}}>
                                <div className='mr-2'>
                                    <AcceptIcon  className="functionalButton ml-auto" style={{height: 34, width: 34}} onClick={() => this.changeSelectedPercentage()}></AcceptIcon>
                                </div>
                                  <div>
                                      <CancelIcon className="functionalButton ml-auto" style={{height: 34, width: 34}} onClick={() => this.setState({showConfirmationModal: false})}></CancelIcon>
                                  </div>
                              </div>
                          </div>
                      </Row> */}
                      </ModalBody>
                      <ModalFooter>
                        <Button pill theme="light" onClick={() => this.setState({showA3MODAL: false})}>Cancel</Button>
                        <Button pill onClick={() => this.props.showA3(true)}>Complete</Button>
                      </ModalFooter>
            </Modal>
              </div>    
        )
    }
}

export default withTranslation()(IdeaImplementFilterButton);

const myStyles = {
    container: {
        height: 60,
        width: "100%",
        borderWidth: 2,
        borderColor: 'black',
        borderStyle: 'solid',
        borderRadius: 30,
        overflow: "hidden",
        // marginBottom: 60
    },
    innerPercentage: {
        fontSize:30,
        textAlign: 'center',
        opacity: 0.5,
        userSelect: "none",
    },
    innerPercentageSelected: {
        fontSize:30,
        textAlign: 'center',
        color: 'white',
        userSelect: "none",
    },
    innerDescription: {
        fontSize:12,
        textAlign: 'center',
    },
    innerDescriptionSelected: {
        fontSize:12,
        textAlign: 'center',
        textDecoration: 'underline',
        fontWeight: 600,
    },
    percentageColumn: {
        // backgroundColor: 'red',
        height: '100%',
        borderStyle: 'solid',
        borderRightWidth: 1,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 1,
        paddingTop: 5,
        paddingBottom: 5,
    },
    percentageColumnSelected: {
        backgroundColor: '#007bff',
        height: '100%',
        borderStyle: 'solid',
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        paddingTop: 5,
        paddingBottom: 5,
    },
    percentageColumnRight: {
        // backgroundColor: 'red',
        height: '100%',
        borderStyle: 'solid',
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        paddingTop: 5,
        paddingBottom: 5,
    },
    percentageColumnLeft: {
        // backgroundColor: 'red',
        // borderTopLeftRadius: 30,
        // borderBottomLeftRadius: 30,
        marginBottom: 60,
        paddingTop: 5,
        paddingBottom: 5,
        height: '100%',
        // marginLeft: '1.5%',
        borderStyle: 'solid',
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0
    },
    percentageColumnLeftSelected: {
        backgroundColor: '#007bff',
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        marginBottom: 60,
        paddingTop: 5,
        paddingBottom: 5,
        height: '100%',
        borderStyle: 'solid',
        borderRightWidth: 1,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0
    },
    percentageColumnRightSelected: {
        backgroundColor: '#007bff',
        borderTopRightRadius: 30,
        borderBottomRightRadius: 30,
        marginBottom: 60,
        paddingTop: 5,
        paddingBottom: 5,
        height: '100%',
        borderStyle: 'solid',
        borderRightWidth: 1,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0
    }

  }