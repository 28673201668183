import Parse from 'parse';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

export const options = {
  title: 'Opportunity Flow',
  width: '100%',
  height: '400px',
  chartArea: {
    width: '100%',
    height: '80%',
  },
  sankey: {
    tooltip: {
      isHtml: true,
      formatter: (row, size) => {
        const from = row[0].v;
        const to = row[1].v;
        const weight = row[2].v;
        return `<div><strong>From:</strong> ${from}<br/><strong>To:</strong> ${to}<br/><strong>Amount:</strong> ${weight}</div>`;
      }
    }
  }
};

export default function App({ opportunities }) {
  const [data, setData] = useState([['From', 'To', 'Count']]);
  const [hasUndefined, setHasUndefined] = useState(false);
  const [userDepartments, setUserDepartments] = useState({});

  useEffect(() => {
    const fetchUsers = async () => {
      const users = [];
      const userDeptMap = {};
      users.forEach(user => {
        userDeptMap[user.id] = user.get('department') || 'Undefined';
      });

      setUserDepartments(userDeptMap);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if(userDepartments.length === 0) 
      return;
    if (opportunities && opportunities.length > 0) {
      const transitionCounts = {};

      opportunities.forEach((opportunity) => {
        let originDepartment = opportunity.from;
        const department = opportunity.to || 'Undefined';

        // if (!originDepartment) {
        //   const proponentObj = opportunity.get('proponentObj');
        //   const proposerId = proponentObj.id || proponentObj.objectId;
        //   originDepartment = userDepartments[proposerId] || 'Undefined';
        //   if(originDepartment === 'Undefined') {
        //     setHasUndefined(true);
        //   }
        // }

        const key = `${originDepartment}-${department}`;
        if (transitionCounts[key]) {
          transitionCounts[key].weight += 1;
        } else {
          transitionCounts[key] = { from: originDepartment, to: department, weight: 1 };
        }
      });

      const transitions = Object.values(transitionCounts);

      // Remove undefined origin departments and add * to origin departments
      const filteredTransitions = transitions
        .filter(({ from }) => from !== undefined)
        .map(({ from, to, weight }) => [`${from} (Originating)`, to, weight]);

      const graphData = [
        ['From', 'To', 'Count'],
        ...filteredTransitions,
      ];

      setData(graphData);
    }
  }, [opportunities, userDepartments ]);

  if (data.length === 1) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Chart
        chartType="Sankey"
        data={data}
        options={options}
      />
      {hasUndefined && <small>Opportunities submitted before 31 March 2024 did not have an "Originating Department" field. The "Originating Department" for these opportunities might be tagged as "Undefined".</small>}
    </>
  );
}
