import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChart = ({ data }) => {
  const [series, setSeries] = useState([]);
  const [chartKey, setChartKey] = useState(Date.now());
  const [options, setOptions] = useState({
    chart: {
      type: 'pie',
      height: 400,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [{
            icon: '<i class="fa fa-download"></i>',
            title: 'Download Legend',
            class: 'custom-icon',
            click: function () {
            }
          }],
        },
        autoSelected: 'zoom',
      },
    },
    legend: {
      position: 'bottom',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
  });

  useEffect(() => {
    const mySeries = data.map((item) => item.value);
    const total = mySeries.reduce((acc, value) => acc + value, 0);

    const newOptions = {
      ...options,
      labels: data.map((item) => item.label),
      colors: data.map((item) => item.color),
      tooltip: {
        y: {
          formatter: function (value) {
            if (total === 0) return "0%";
            const percentage = (value / total * 100).toFixed(1);
            return `${percentage}%`;
          }
        },
      },
    };

    setSeries(mySeries);
    setOptions(newOptions);
    setChartKey(Date.now());

  }, [data]); 

  return (
    <ReactApexChart
      key={chartKey}
      options={options}
      series={series}
      type="pie"
      height={400}
    />
  );
};

export default PieChart;
