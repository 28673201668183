import React from "react";
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { fetchCommittees } from "../../services/tableService";
import "./LocalStyles/CategorySelectStyle.css";

class CommitteeBasicSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectionValue: '',
            data: [],
            selectedPerson: this.props.selectedVal,
        }
        this.fetchCategoryData = this.fetchCategoryData.bind(this);
        this.handleSelectedPersonChange = this.handleSelectedPersonChange.bind(this);
    }

    componentDidMount() {
        this.fetchCategoryData();
    }

    async fetchCategoryData() {
        const committess = await fetchCommittees();
        this.setState({
            options: committess.map((result) => ({ value: result, label: result.title }))
        });
    }

    handleSelectedPersonChange(event) {
        this.props.setResponsible(event, 0);
    }


    render() {
        const { options } = this.state;
        const { selectedVal } = this.props;
         
        return(<div  style={{width: '100%'}}>
            <Select
                value={selectedVal}
                onChange={this.handleSelectedPersonChange}
                options={options}
                placeholder={'Committee'}
                clearable={false}
                className="insideFont"
            />
        </div>)
    }
}

export default withTranslation()(CommitteeBasicSelect);