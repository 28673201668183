import Parse from 'parse';
import React from 'react';
import { Col, FormRadio, FormTextarea, Modal, ModalBody, ModalHeader, Row } from "shards-react";

import { ReactComponent as AcceptIcon } from "../../images/accept_button.svg";
import { ReactComponent as CancelIcon } from "../../images/cancel_button.svg";


class SaveForLaterModal extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
          message: this.props.text,
          idea: this.props.idea,
          responseInfo: false,
          open: false,
          pmoAligned: true
        }
      }
    
    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        const currentStatus =
          prevProps.isOpen 
        const nextStatus =
          this.props.isOpen
  
        if (currentStatus !== nextStatus) {
         this.setState({open: this.props.isOpen})
        }
      }

    toggle = () => {
        this.props.closeModal()
    }

    toggleInfo = () => {
        this.setState({
          responseInfo: !this.state.responseInfo
        });
    }

    onTextChange = (event) => {
        const text = event.target.value
        this.setState({message: text})
    }

    updateInfo = () => {
      if (this.state.message.length > 0) {
        this.props.changeText(this.state.message)
        this.props.changeSelection(this.state.pmoAligned)
      } else {
        alert('Your request cannot be empty.')
      }
      
    }

    updateIdea = () => {
      const { open, rfiMessage } = this.state;
      const { idea, type, closeModal } = this.props;


      if (rfiMessage.length > 0) {
        idea.set("rfiAnswer", rfiMessage)
        idea.set("status", 'SOMETIDA')
        idea.set("needsEvaluation", true)
        idea.set("customUpdatedAt", new Date())

        Parse.Object.saveAll([idea], {useMasterKey: true}).then(() => {
          this.closeModal()
        });
      } else {
        alert('Your request cannot be empty.')
      }
    }

    closeModal = () => {
      this.setState({rfiMessage: ''})
      this.props.closeModal()
    }
    

    render() {
        const { open, rfiMessage } = this.state;
        const { idea, type } = this.props;

        const hasIdea = idea != ''
        const shouldEditIdea = type == 'submitInfo'
        const tooltipMsg = 'Click on the drop-down menu below to see available committees.'

        return (
        <Modal open={open} centered backdrop toggle={() => this.toggle()}>
          <ModalHeader>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <h5 className="m-auto" style={{fontWeight: 500, color: '#303030'}}>Save for Later</h5>
              
                    {/* <a id={"ModalTooltipResponseInfo"} className="text-right" style={{ color: 'inherit'}} onClick={() => {
                        var myCopy = this.state.responseInfo
                        myCopy = !myCopy
                        this.setState({responseInfo: myCopy})
                    }}>
                        
                        <InfoIcon className="ml-2" style={{height: 12, width: 12}}></InfoIcon>
                        
                    </a>
                
                    
                    <Tooltip
                        open={this.state.responseInfo}
                        target={"#ModalTooltipResponseInfo"}
                        id={"ModalTooltipResponseInfo1"}
                        toggle={() => {this.toggleInfo()}}
                    >
                        {tooltipMsg}
                    </Tooltip> */}
            </div>
          </ModalHeader>
          <ModalBody>
            { hasIdea && 
             <Row>
              <Col md={12}>
                <p><strong>{idea.get("rfiQuestion")}</strong></p>
              </Col>
            </Row>
            }
            
            {/* <Row className="mb-4">
                <Col md={12}>
                    <label htmlFor="firstName" className="georgia">{'Available Committees'}</label>
                    <CategoryBasicSelect className="insideFont" evalType={"execution"} setResponsible={(res, idx) => this.changeFilterTypeValue(res, idx)} selectedVal={this.state.filterTypeValue}/>
                </Col>
            </Row> */}
            <Row className="mb-4">
                <Col md={12}>
                <label htmlFor="firstName" >Why are you saving this idea for later?</label>
                <FormTextarea
                    style={{ minHeight: "120px" }}
                    id="userBio"
                    placeholder={'Type response here'}
                    value={this.state.message}
                    onChange={(event) => this.onTextChange(event)}
                />
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                <label htmlFor="firstName" >Are you likely to approve this idea in the future?</label>
                <div>
                <FormRadio
                    inline
                    name="pmoAlignment"
                    checked={this.state.pmoAligned}
                    onChange={() => {
                        this.setState({ pmoAligned: true });
                    }}
                >
                    Yes
                </FormRadio>
                <FormRadio
                    inline
                    name="pmoAlignment"
                    checked={this.state.pmoAligned === null}
                    onChange={() => {
                        this.setState({ pmoAligned: null });
                    }}
                >
                    Maybe
                </FormRadio>

                </div>
                </Col>
            </Row>

            <Row className="mt-4">
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
                    <div className="ml-auto" style={{ display: 'flex', flexDirection: 'row'}}>
                        <div className='mr-2'>
                            <AcceptIcon  className="functionalButton ml-auto" style={{height: 34, width: 34}} onClick={() => shouldEditIdea?this.updateIdea():this.updateInfo()}></AcceptIcon>
                        </div>
                        <div>
                            <CancelIcon className="functionalButton ml-auto" style={{height: 34, width: 34}} onClick={() => this.closeModal()}></CancelIcon>
                        </div>
                    </div>
                </div>
            </Row>
          </ModalBody>
        </Modal>
        );
    }
  }

export default SaveForLaterModal