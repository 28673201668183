import React from "react";
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { fetchUserSupervisors } from "../../services/tableService";
import "./LocalStyles/CategorySelectStyle.css";

const selectedColor = '#157ffb';

class SupervisorSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectionValue: '',
            sq1Color: '',
            sq2Color: '',
            sq3Color: '',
            sq4Color: '',
            titleColor1: 'black',
            titleColor2: 'black',
            titleColor3: 'black',
            titleColor4: 'black',
            handIcon: 'black',
            shieldIcon: 'black',
            chartIcon: 'black',
            clockIcon: 'black',
            puzzleIcon: 'black',
            gearIcon: 'black',
            data:[],
            options: [],
            showSecurity:'',
            showQuality:'',
            showProductivity:'',
            showAction:'',
            selectedPerson:this.props.selectedVal,
          }
          this.fetchCategoryData = this.fetchCategoryData.bind(this);
          this.handleSelectedPersonChange = this.handleSelectedPersonChange.bind(this);
    }

    componentDidMount() {
        this.fetchCategoryData();
    }

    async fetchCategoryData() {
        try {
            const supervisors = await fetchUserSupervisors();
            const options = supervisors.map(supervisor => ({ value: supervisor.id, label: supervisor.title }));
            this.setState({ options, supervisor: options });
        } catch (error) {
            alert(error.message)
        }
    }

    changeSelectedValue(selectionValue) {
        if (selectionValue === 1) {
            this.setState({
                selectionValue: 'Seguridad', 
                sq1Color: selectedColor,
                sq2Color: '',
                puzzleIcon: 'black',
                gearIcon: selectedColor,
                titleColor1: selectedColor,
                titleColor2: 'black',
            })
            this.props.setFilter('innovacion');
        } else if (selectionValue === 2){
            this.setState({
                selectionValue: 'Calidad', 
                sq1Color: '',
                sq2Color: selectedColor,
                puzzleIcon: selectedColor,
                gearIcon: 'black',
                titleColor1: 'black',
                titleColor2: selectedColor,
            })
             this.props.setFilter('solucion');
        } 
    }

    handleSelectedPersonChange(event) {
        this.props.setResponsible(event, 0);
    }

    getFullName(result) {
        if (result) {
            return result.get("firstName") + ' ' + result.get("lastName");
        }
    }

    render() {
        const { options, coaches } = this.state;
        const { selectedVal, isMurmurattoUser } = this.props;

        let myValue = options.find(option => option.value === selectedVal);
    
        if (!myValue || (!myValue.value && !myValue.label))
            myValue =  { value: '', label: 'Select a supervisor' };

    
        const murmurattoObj = isMurmurattoUser && { value: 'N/A', label: 'N/A' };
    
        return (
            this.props.evalType === "execution" ?
                <div style={{ width: '100%' }}>
                    <Select
                        value={isMurmurattoUser ? murmurattoObj : myValue}
                        onChange={this.handleSelectedPersonChange}
                        options={isMurmurattoUser ? [] : options}
                        placeholder={'Supervisor'}
                        isClearable={false}
                        className="insideFont"
                    />
                </div>
                :
                <div style={{ minWidth: 250 }}>
                    <Select
                        value={selectedVal}
                        onChange={this.handleSelectedPersonChange}
                        options={coaches}
                        placeholder={'No Coach'}
                        className="insideFont"
                    />
                </div>
        );
    }
}    

export default withTranslation()(SupervisorSelect);
