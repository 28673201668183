import FuzzySearch from "fuzzy-search";
import React from "react";
import { withTranslation } from "react-i18next";
import ReactLoading from 'react-loading';
import ReactTable from "react-table";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";
import IdeaVerificationView from "../components/common/IdeaVerificationView";
import PageTitle from "../components/common/PageTitle";
import verifyInboxService from '../services/verifyInboxService';
import ResponsiveTable from "../components/responsive-table/ResponsiveTable";


class VerificationHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ideas: [],
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 5,
      isLoading: false,
      tableData: [],
      data: [],
      filteredData: [],
      showView: false, 
      selectedIdea: null
    };

    this.searcher = null;

    this.getStatusClass = this.getStatusClass.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.handleItemEdit = this.handleItemEdit.bind(this);
    this.handleItemDelete = this.handleItemDelete.bind(this);
    this.handleItemConfirm = this.handleItemConfirm.bind(this);
    this.handleItemViewDetails = this.handleItemViewDetails.bind(this);
    this.showIdea = this.showIdea.bind(this);
    this.dismissModal = this.dismissModal.bind(this);
  }

  componentDidMount() {
    this.fetchIdeas();
  }

  async fetchIdeas() {
    const {isLoading} = this.state;
    if (isLoading) return;
    this.setState({ isLoading: true });
    
    try {
      const ideas = await verifyInboxService.fetchVerifyInboxIdeas('history');
      this.setState({ ideas, filteredData: ideas });
      this.initSearcher();
    } catch (error) {
      this.setState({ isLoading: false });
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  initSearcher() {
    this.searcher = new FuzzySearch(this.state.tableData, ["desc", "cat", "prop"], {
      caseSensitive: false
    });
  }

  getStatusClass(status) {
    const statusMap = {
      Cancelled: "danger",
      Complete: "success",
      Pending: "warning"
    };

    return `text-${statusMap[status]}`;
  }

  handlePageSizeChange(e) {
    this.setState({
      ...this.state,
      pageSize: e.target.value
    });
  }

  handleItemEdit(row) {
    alert(`Editing transaction "${row.original.id}"!`);
  }

  handleItemDelete(row) {
    alert(`Deleting transaction "${row.original.id}"!`);
  }

  handleItemConfirm(row) {
    alert(`Confirming transaction "${row.original.id}"!`);
  }

  handleItemViewDetails(row) {
    alert(`Viewing details for "${row.original.id}"!`);
  }

  showIdea(index) {  
    const selectedIdea = this.state.ideas[index];
    this.setState({ 
      selectedIdea, 
      showView: true
    });
  }

  dismissModal() {
    this.setState({ showView: false });
  }

  getHistoryView() {
    const { selectedIdea } = this.state;
    return (
      <Container fluid className="main-content-container px-4 pb-4" style={{ backgroundColor: 'white' }}>
        <Row>
          <Col md="12" className="col d-flex align-items-center">
            <IdeaVerificationView dismissModal={this.dismissModal} ideaItem={selectedIdea} view="verify-history" onViewIdeaPress={this.dismissModal} />
          </Col>
        </Row>
      </Container>
    );
  }

  handleFilterSearch(e) {
    const searchTerm = e.target.value.toLowerCase();
    this.setState({ search: searchTerm }, () => {
      const filteredData = this.state.ideas.filter(idea => {
        if(idea.num === Number(searchTerm)) return true;
        return idea.title.toLowerCase().includes(searchTerm) ||
        idea.category.toLowerCase().includes(searchTerm) ||
        idea.status.toLowerCase().includes(searchTerm)
      });
      this.setState({ filteredData });
    });
  }

  render() {
    const { filteredData, isLoading, pageSize, pageSizeOptions, showView } = this.state;
    const { t } = this.props;

    if (showView) {
      return this.getHistoryView();
    }

    const tableColumns = [
      {
        Header: t("IDEA_UPDATE_NUM"),
        accessor: "num",
        maxWidth: 150,
        className: "text-center"
      },
      {
        Header: t("TITLE"),
        accessor: "title",
        className: "text-center",
        style: { 'white-space': 'unset' },
        minWidth: 150,
      },
      {
        Header: t("TRANSACTION_CATEGORY"),
        accessor: "category",
        maxWidth: 150,
        style: { 'white-space': 'unset', color: '#17c671' },
        className: "text-center"
      },
      {
        Header: t("TRANSACTION_PROPONENT"),
        accessor: "proponentName",
        maxWidth: 150,
        style: { 'white-space': 'unset' },
        className: "text-center"
      },
      {
        Header: t("TRANSACTION_STATUS"),
        accessor: "status",
        width: 150,
        className: "text-center",
        Cell: row => (
          <div className="d-table mx-auto">
            <Badge pill theme={getBadgeType(row.value)}>
              {row.value === 'SOMETIDA' ? 'Submitted' : row.value}
            </Badge>
          </div>
        )
      },
      {
        Header: "Action",
        accessor: "actions",
        sortable: false,
        width: 80,
        Cell: row => (
          <Badge style={{ cursor: 'pointer' }} outline pill theme="secondary" onClick={() => this.showIdea(row.index)}>
            View
          </Badge>
        )
      }
    ];

    const data = filteredData.map(idea => ({
      num: idea.num,
      title: idea.title,
      category: idea.category,
      proponentName: idea.proponent,
      status: idea.status,
      actions: idea
    }));

    return (
      <Container fluid className="main-content-container px-4 pb-4" style={{ backgroundColor: 'white' }}>
        <Row noGutters className="page-header pt-4 pb-2">
          <PageTitle title={t("TRANSACTION_VERIFIED")} subtitle={t("Verify Workflow")} className="text-sm-left mb-3" />
        </Row>
        <Row noGutters className="page-header pt-2 mb-4">
          {t("Verify History Workflow Instruction")}
        </Row>
        <Row noGutters className="mt-4 pt-4"> 
          <Col md="12">
            <Card className={'transparent-responsive-card lg:p-0 lg:mt-2'}>
            <CardHeader className="p-0 responsive-table-filter">
                <Container fluid className="file-manager__filters">
                  <Row>
                    <Col className="file-manager__filters__rows d-flex" md="6">
                      <span>{t("SHOW")}</span>
                      <FormSelect
                        size="sm"
                        value={this.state.pageSize}
                        onChange={this.handlePageSizeChange}
                      >
                        {pageSizeOptions.map((size, idx) => (
                          <option key={idx} value={size}>
                            {size + ' ' + t('ROWS')} 
                          </option>
                        ))}
                      </FormSelect>
                    </Col>

                    <Col className="file-manager__filters__search d-flex" md="6">
                      <InputGroup seamless size="sm" className="ml-auto">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>
                            <i className="material-icons">search</i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput onChange={this.handleFilterSearch} />
                      </InputGroup>
                    </Col>
                  </Row>
                </Container>
              </CardHeader>
              <CardBody className="p-0">
                {isLoading ? (
                  <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10em' }}>
                    <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
                  </div>
                ) : (
                  <ResponsiveTable
                    columns={tableColumns}
                    data={data}
                    pageSize={pageSize}
                    showPageSizeOptions={false}
                    resizable={false}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

function getBadgeType(itemStatus) {
  const statusMap = {
    Complete: "success",
    Saved: "warning",
    Canceled: "danger"
  };

  return statusMap[itemStatus];
}

export default withTranslation()(VerificationHistory);

