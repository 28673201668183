import Parse from "parse";
import React from "react";
import { withTranslation } from "react-i18next";
import ReactLoading from 'react-loading';
import { withRouter } from "react-router-dom";
import { Col, Container, Row } from "shards-react";
import IdeaFilterManager from "../components/administration/IdeaFilterManager";
import ManagementIdeaTable from "../components/administration/ManagementIdeaTable";
import IdeaVerificationForm from "../components/common/IdeaVerificationForm";
import IdeaViewCardNew from "../components/common/IdeaViewCardNew";
import IdeaViewCardNewAi from "../components/common/IdeaViewCardNewAi";
import PageTitle from "../components/common/PageTitle";
import ThankYouEvaluate from "../components/common/ThankYouEvaluate";
import { ReactComponent as NextIcon } from "../images/NextIcon.svg";
import { ReactComponent as NextIconValid } from "../images/NextIconSelected.svg";
import { ReactComponent as PreviousIcon } from "../images/PreviousIcon.svg";
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";
import { ReactComponent as HomeIcon } from "../images/home.svg";
import { ReactComponent as SubmitIcon } from "../images/submit_new_new.svg";
import { ReactComponent as SubmitIconNosel } from "../images/submit_new_nosel.svg";
import { fetchOpportunityById } from "../services/evaluateService";

class EvaluateIdea extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      categoryName: '',
      departmentName: '',
      statusName: '',
      ideaType: '',
      open: false,
      loadingIdea: false,
      viewIdea: false,
      ideaItem: null,
      title: 'Evaluate',
      ideaStage: 0,
      canGoNext: true,
      evaluationResponse: {
        status: '',
        economicImpact: '',
        timeFrame: '',
        recurringImpact: false,
        comments: '',
        ideaOwner: '',
        ideaCoach: ''
      },
      canSubmit: false,
      noMoreSteps: false,
      noMoreStepsTemp: false,
      resetFilters: false,
      evalComm: '',
      isDuplicate: false
    }
  }

  onCategoryChange(categoryName) {
    var selectedCategoryName = categoryName
    if (categoryName == "All" || categoryName == "Todas") {
      selectedCategoryName = ""
    }
    
    this.setState({
      categoryName: selectedCategoryName,
      resetFilters: false
    })
  }

  onDepartmentChange(categoryName) {
    var selectedCategoryName = categoryName
    if (categoryName == "All" || categoryName == "Todas") {
      selectedCategoryName = ""
    }

    this.setState({
      departmentName: selectedCategoryName,
      resetFilters: false
    })
  }

  onEvalCommChange(evalComm) {
    this.setState({
      evalComm: evalComm,
      resetFilters: false
    })
  }

  onTransferCommitteeChange(status) {
    this.onTypeChange('')
    this.setState({
      departmentName: '',
      categoryName: '',
      statusName: status ? 'Transfer Committee' : '',
      resetFilters: false,
    })
  }

  onStatusChange(statusName) {
    var selectedStatusName = ""
    if (statusName == "Submitted" || statusName == 'Sometidas') {
      selectedStatusName = "SOMETIDA"
    } else if (statusName == "Done" || statusName == 'Completadas') {
      selectedStatusName = "problema"
    }
    this.setState({
      statusName: selectedStatusName
    })
  }

  onTypeChange(ideaType) {
   
    this.setState({
      ideaType: ideaType
    })
  }

  onQuestionChange(question) {
    this.setState({
      ideaQuestion: question
    })
  }

  toggle(item) {
   
    this.setState({
      open: !this.state.open,
      ideaItem: item,
      viewIdea: false
    });
  }

  showIdea = async (item, isDuplicate) => {
    // Update the URL without reloading the page
    if (!isDuplicate) {
      if (!window.location.pathname.includes('/evaluate-')) {
        window.history.pushState({}, '', `/evaluate-${item.id}`);
      }
    }

    try {
      // Fetch the opportunity data by ID
      // const opportunityData = await fetchOpportunityById(item.id);
      
      // Set the fetched data into the state
      this.setState({
        open: true,
        ideaItem: item, // Set the fetched opportunity data
        viewIdea: true,
        isDuplicate,
        loadingIdea: false,
      });
    } catch (error) {
      console.error('Error fetching opportunity:', error);
      this.setState({ loadingIdea: false });
    }
  };

  deleteIdea(item) {
    const YourClassName = Parse.Object.extend('Idea');
    const objectToDelete = new YourClassName();

    objectToDelete.id = item.id;

    objectToDelete.destroy().then(
      (response) => {
        console.log('Item deleted successfully', response);
      },
      (error) => {
        console.error('Error deleting item', error);
      }
    );
  }

  dismissModal() {
    this.setState({
      open: !this.state.open
    });
  }

  componentDidMount() {
    const { match } = this.props;
    const ideaId = match.params.id;
    
    if (ideaId) {
      this.setState({ loadingIdea: true });
      this.loadIdea(ideaId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { ideaStage, dataSend } = this.state;
    const { match } = this.props;
    const ideaId = match.params.id;
    if (ideaStage === 3 && dataSend && dataSend.action !== "Approved") {
      window.location.href = '/evaluate';
    }
    
  }

  async loadIdea(ideaId) {
    const opportunity = await fetchOpportunityById(ideaId);
    this.showIdea(opportunity, opportunity.isDuplicate);
  }

  render() {
    const { canSubmit, statusName, ideaType, open, viewIdea, ideaQuestion, ideaStage, canGoNext, noMoreSteps } = this.state;
    const { t } = this.props;

    const changeIdeaStage = () => {
      const { ideaStage, evaluationResponse } = this.state;
      const newStage = ideaStage + 1

      if (newStage == 0) {
        this.setState({ title: 'Evaluate', ideaStage: newStage })
      } else if (newStage == 1) {
        this.setState({ title: 'Evaluate > Next Step', ideaStage: newStage, canSubmit: false, noMoreSteps: this.state.noMoreStepsTemp })

        if (!evaluationResponse.economicImpact || !evaluationResponse.timeFrame) {
          changeBtnStatus(false)
        }
      } else if (newStage == 2) {
        this.setState({ title: 'Evaluate > Next Step > Additional Details', ideaStage: newStage })

        if (!evaluationResponse.ideaOwner) {
          changeBtnStatus(false)
        }
      } else if (newStage == 3) {
        this.setState({ title: 'Evaluate > Next Step > Additional Details > Thank You', canSubmit: true })
      } else {
        this.dismissModal()
        this.setState({ title: 'Evaluate', ideaStage: 0, canSubmit: false, noMoreSteps: false })
      }
    }

    const submitEvaluation = () => {
      const ideaStage = 2
      this.setState({ title: 'Evaluate > Next Step > Additional Details > Thank You', ideaStage: ideaStage, canSubmit: true }, () => {
        this.setState({ title: 'Evaluate', ideaStage: 0, noMoreSteps: false })
      })
    }

    const changeIdeaStageBack = () => {
      const { ideaStage, evaluationResponse } = this.state;
      const newStage = ideaStage - 1
      const urlParams = new URLSearchParams(window.location.search);
      const backId = urlParams.get('back');
      
      if (backId) {
        window.location = `/evaluate-${backId}`;
        return;
      }

      if (newStage == -1) {
        if (window.location.pathname.includes('/evaluate-')) {
          window.history.pushState({}, '', `/evaluate`);
        }
        this.setState({ title: 'Evaluate', ideaStage: 0 })
        this.dismissModal()
      } else if (newStage == 0) {
        this.setState({ noMoreSteps: false })

        this.setState({ title: 'Evaluate ', ideaStage: newStage })

        if (!evaluationResponse.economicImpact || !evaluationResponse.timeFrame) {
          changeBtnStatus(false)
        }

        changeBtnStatus(true)
      } else if (newStage == 1) {
        this.setState({ title: 'Evaluate > Next Step', ideaStage: newStage })
        if (evaluationResponse.economicImpact && evaluationResponse.timeFrame) {
          changeBtnStatus(true)
        }
      } else if (newStage == 2) {
        this.setState({ title: 'Evaluate > Next Step > Additional Details', ideaStage: newStage })
      } else {
        this.setState({ title: 'Evaluate', ideaStage: 0 })
      }
    }

    const changeBtnStatus = (status) => {
      this.setState({ canGoNext: status })
    }

    const setFinished = (data) => {
      this.setState({ ideaStage: 3, dataSend: data })
    }

    return (
      this.state.loadingIdea ?  
       <Container fluid className="main-content-container px-4" style={{ backgroundColor: 'white', marginTop:'90px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Row noGutters className="page-header py-4" style={{ textAlign: 'center' }}>
          <Col>
            <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
          </Col>
        </Row>
      </Container>
    : (
      !open ? (
        <Container fluid className="main-content-container px-4" style={{ backgroundColor: 'white' }}>
          <Row noGutters className="page-header py-4">
            <PageTitle title={t("TRANSACTION_MANAGE")} subtitle={t("Verify Workflow")} className="text-sm-left mb-3" />
          </Row>

          <Row>
            <Col className="mb-4">
              <IdeaFilterManager onCategoryChange={this.onCategoryChange.bind(this)} onDepartmentChange={this.onDepartmentChange.bind(this)} onStatusChange={this.onStatusChange.bind(this)} onTypeChange={this.onTypeChange.bind(this)} onQuestionChange={this.onQuestionChange.bind(this)} onEvalCommChange={this.onEvalCommChange.bind(this)} onTransferCommitteeChange={this.onTransferCommitteeChange.bind(this)} reset={this.state.resetFilters} />
            </Col>
          </Row>

          <Row>
            <Col className="mb-4">
              <ManagementIdeaTable category={this.state.categoryName} department={this.state.departmentName} status={statusName} ideaType={ideaType} filterQuestion={ideaQuestion} onEditActionPress={this.toggle.bind(this)} onViewIdeaPress={this.showIdea.bind(this)} onDeleteIdeaPress={this.deleteIdea.bind(this)} evalComm={this.state.evalComm} />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid className="main-content-container px-4" style={{ backgroundColor: 'white' }}>
          <Row>
            <Col md="12" lg="12" className="mt-4 mx-auto">
              <Row>
                <Col md="12" lg="12">
                  <h3 className="m-auto" style={{ fontWeight: 600, color: '#303030' }}>{this.state.title}</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DivisorBarIcon></DivisorBarIcon>
                </Col>
              </Row>
            </Col>
          </Row>
          {!viewIdea ?
            <Row>
              <Col lg="12" className="m-auto">
                <IdeaVerificationForm dismissModal={this.dismissModal.bind(this)} ideaItem={this.state.ideaItem} />
                {/* {this.state.ideaItem.get("completed") == true ?
                  :
                  <EvaluateIdeaForm dismissModal={this.dismissModal.bind(this)} ideaItem={this.state.ideaItem} />
                } */}
              </Col>
            </Row> :
            <Row>
              <Col lg="12" className="m-auto">
                {ideaStage != 3 && (this.state.isDuplicate ? <IdeaViewCardNewAi canSubmit={canSubmit} setIdeaStage={(value)=>{this.setState({ideaStage: value})}} setNoMoreSteps={(value) => this.setState({noMoreSteps: value})} setFinishedSaving={(dataSend) => setFinished(dataSend)} evaluationData={this.state.evaluationResponse} dismissModal={this.dismissModal.bind(this)} ideaItem={this.state.ideaItem} changeStatus={(status) => changeBtnStatus(status)} ideaStage={this.state.ideaStage} onViewIdeaPress={this.toggle.bind(this)}/> : <IdeaViewCardNew canSubmit={canSubmit} setNoMoreSteps={(value) => this.setState({noMoreSteps: value})} setFinishedSaving={(dataSend) => setFinished(dataSend)} evaluationData={this.state.evaluationResponse} dismissModal={this.dismissModal.bind(this)} ideaItem={this.state.ideaItem} changeStatus={(status) => changeBtnStatus(status)} ideaStage={this.state.ideaStage} onViewIdeaPress={this.toggle.bind(this)}/>)}
                {(ideaStage === 3 && this.state.dataSend.action === "Approved") && <ThankYouEvaluate idea={this.state.ideaItem} dataSend={this.state.dataSend} />}
              </Col>
            </Row>
          }

          {!this.state.isDuplicate &&
          (<Row>
            <Col lg="6" className="mb-2 mr-auto">
              {ideaStage < 3 && <PreviousIcon className="functionalButton mr-auto d-block" style={{ minWidth: 140, maxWidth: 140 }} onClick={() => changeIdeaStageBack()}></PreviousIcon>}
            </Col>

            {(this.state.ideaItem.status !== 'Duplicate') &&
            (
              <Col lg="6" className="mb-2 ml-auto">
                {ideaStage < 3 && !noMoreSteps && !canGoNext && <NextIcon className="functionalButton ml-auto d-block" style={{ minWidth: 140, maxWidth: 140 }} ></NextIcon>}
                {ideaStage < 3 && !noMoreSteps && canGoNext && <NextIconValid className="functionalButton ml-auto d-block" style={{ minWidth: 140, maxWidth: 140 }} onClick={() => changeIdeaStage()}></NextIconValid>}
                {ideaStage < 3 && noMoreSteps && canGoNext && <SubmitIcon className="functionalButton ml-auto d-block" style={{ minWidth: 140, maxWidth: 140 }} onClick={() => submitEvaluation()}></SubmitIcon>}
                {ideaStage < 3 && noMoreSteps && !canGoNext && <SubmitIconNosel className="functionalButton ml-auto d-block" style={{ minWidth: 140, maxWidth: 140 }} ></SubmitIconNosel>}
                {(ideaStage === 3 && this.state.dataSend.action === "Approved") && <HomeIcon className="functionalButton ml-auto d-block mb-4" style={{ minWidth: 90, maxWidth: 90 }} onClick={() => {  window.location.href = '/evaluate'; }}></HomeIcon>}
              </Col>
            )
            }
          </Row>)
          }
        </Container>
      ))
    )
  }
}

export default withTranslation()(withRouter(EvaluateIdea));
