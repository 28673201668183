import axios from 'axios';
import { authUser } from './userService';

const API_URL = process.env.REACT_APP_EP_API;

const getMetrics = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/workflow/user-dashboard/metrics`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error.response ? error.response.data : error.message);
    throw error;
  }

};

export default {
    getMetrics,
};