import React from "react";
import { Bar } from "react-chartjs-2";

const chartData = {
  labels: ["September", "October", "November", "December", "January"],
  datasets: [
    {
      label: "Sentiment",
      data: [-1, -1, 0, 1, 1],
      backgroundColor: (context) => {
        const value = context.dataset.data[context.dataIndex];
        return value >= 0 ? "green" : "red";
      }
    }
  ]
};



const options = {
  // Modify the axis by adding scales
scales: {
  // to remove the labels
  // to remove the y-axis labels
  y: {
    ticks: {
      beginAtZero: true,
      suggestedMin: -1,
      suggestedMax: 1,
      callback: function(value) {
        switch (value) {
          case 1:
            return "Positive";
          case 0:
            return "Neutral";
          case -1:
            return "Negative";
          default:
            return "";
        }
      }
    },
    // to remove the y-axis grid
    grid: {
      drawBorder: false,
      display: false,
    },
  },
},
  plugins: {
    legend: {
        display: false
    },
  }
};



const SentimentBarChart = () => {
  return (
    <Bar
      data={chartData}
      options={options}
      height={50}
      width={100}
    />
  );
};

export default SentimentBarChart;
