import Parse from 'parse';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Col, Container, Row } from "shards-react";
import SmallSwitch from '../components/common/SmallSwitch';
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";
import colors from "../utils/colors";

function AdministrationConfiguration(smallStats) {
  const [title, setTitle] = useState('')
  const [categories, setCategories] = useState([])
  const [activeCategories, setActiveCategories] = useState('')
  const [dataCsv, setDataCsv] = useState([])
  const [editorContent, setEditorContent] = useState('');
  const [configuration, setConfiguration] = useState('');
  const [showEnps, setShowEnps] = useState(false)
  const [showDuplicate, setShowDuplicate] = useState(false)

  if(process.env.REACT_APP_PREMIUM_TAB !== '1' && process.env.NODE_ENV === 'production')
    return <></>;
  
  const setInitialTitle = async (user) => {
    setTitle('Premium Features')
  }

  useEffect(() => {
    // Update the document title using the browser API
    setInitialTitle()
    getEvaluationCriteria()

    const Configuration = Parse.Object.extend("Configuration");
    const query = new Parse.Query(Configuration);
    query.first().then((config) => {
      setShowEnps(config.get("showSurvey"))
      setShowDuplicate(config.get("showDuplicateTab"))
    } 
    );

  }, []);

  const getEvaluationCriteria = async() => {
    const Category = Parse.Object.extend("Configuration");
    const query = new Parse.Query(Category);
    const results = await query.find();

    if (results.length > 0) {
      setConfiguration(results[0])
      
      if (results[0] && results[0].get("evaluationCriteria")) 
        setEditorContent(results[0].get("evaluationCriteria"))
    }
  }

  const saveEvaluationCriteria = () => {
    const Configuration = Parse.Object.extend("Configuration");

    if (configuration) {
        configuration.set("evaluationCriteria", editorContent);
        configuration.set("customUpdatedAt", new Date());
        // Save configuration
        configuration.save().then((response) => {
            // You can use the "get" method to get the value of an attribute
            // Ex: response.get("<ATTRIBUTE_NAME>")
            alert('Your evaluation criteria was saved succesfully!')
          }, (error) => {
            // The save failed.
            // error is a Parse.Error with an error code and message.
          });
    } else {
        const newConfiguration = new Configuration();

        newConfiguration.set("evaluationCriteria", editorContent);
        newConfiguration.set("customUpdatedAt", new Date());
        newConfiguration.save().then((response) => {
          // You can use the "get" method to get the value of an attribute
          // Ex: response.get("<ATTRIBUTE_NAME>")
          alert('Your evaluation criteria was saved succesfully!')
        }, (error) => {
          // The save failed.
          // error is a Parse.Error with an error code and message.
        });
    }
  }

  const handleEnpsToggle = () => {
    setShowEnps(!showEnps)

    // Go to Configuration first object and set to value
    const Configuration = Parse.Object.extend("Configuration");
    const query = new Parse.Query(Configuration);
    query.first().then((config) => {
      config.set("showSurvey", !showEnps);
      config.save().then((response) => {
        
      }, (error) => {
        console.log(error)
      });
    });
  }
  
  const handleShowDuplicateToggle = () => {
    setShowDuplicate(!showDuplicate)
    const Configuration = Parse.Object.extend("Configuration");
    const query = new Parse.Query(Configuration);
    query.first().then((config) => {
      config.set("showDuplicateTab", !showDuplicate);
      config.save().then((response) => {
        
      }, (error) => {
        console.log(error)
      });
    });
  }
  

  return (
  <Container fluid className="main-content-container px-4 pb-4" style={{backgroundColor: 'white'}}>
    <Row>
      {/* Page Header :: Title */}
      <Col md="10" lg="10" className="mt-4 mx-auto">
        {/* <PageTitle title={t('Welcome back, Angel')} subtitle=" " className="text-sm-left" /> */}
        <Row>
          <Col md="8" lg="8">
            <h3 className="m-auto" style={{fontWeight: 600, color: '#303030'}}>{title}</h3>
          </Col>
          
         
          <Col xs="12" md="2" lg="2" className="col d-flex align-items-center ml-auto">

          </Col>
        </Row>
        <Row>
          <Col>
            <DivisorBarIcon></DivisorBarIcon>
          </Col>
        </Row>
        
      </Col>
      {/* Page Header :: Actions */}
      
    </Row>

    <Row className="mt-4">
      <Col md="10" className="m-auto">
        <h5 style={{fontWeight: 600, color: '#303030'}}>In-App Surveys
        <div style={{marginLeft: 8, display: "inline-block"}} className='my-auto'>
          <span style={{ backgroundColor: '#FFD700', color: 'black', fontSize: '12px', padding: '2px 6px', borderRadius: '4px', marginLeft: '8px' }}>
            Trial
          </span>
        </div>
        </h5>
        
      </Col>
      <Col md="10" className="mt-2 mx-auto">
      <div style={{display: 'flex'}}>
        <div className="my-auto" style={{height: '100%', width: 40, display: 'inline-block'}}>
          <SmallSwitch 
            isOn={showEnps}
            myKey={'turnOn' + Math.random()}
            handleToggle={handleEnpsToggle}
            onColor="#633FDA"
            title="On/Off"
          />
        </div>

        <div className="ml-4 my-auto" style={{height: '100%', display: 'inline-block'}}>
          <h6 className="my-auto" style={{fontWeight: 500,  color: '#303030',}}>eNPS</h6>
        </div>
        <div style={{marginLeft: 8, display: "inline-block"}} className='my-auto'>
          <span style={{
            backgroundColor: '#5794cd', // Blue color similar to the screenshot
            color: 'white',
            fontSize: '12px',
            padding: '2px 8px',
            borderRadius: '12px', // Pill-shaped rounded edges
            marginLeft: '8px',
            display: 'inline-block'
          }}>
            Beta
          </span>
        </div>
      </div>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col md="10" className="m-auto">
        <h5 style={{fontWeight: 600, color: '#303030'}}>Advanced Applications
        <div style={{marginLeft: 8, display: "inline-block"}} className='my-auto'>
          <span style={{ backgroundColor: '#FFD700', color: 'black', fontSize: '12px', padding: '2px 6px', borderRadius: '4px', marginLeft: '8px' }}>
            Trial
          </span>
        </div>
        </h5>
      </Col>
      <Col md="10" className="mt-2 mx-auto">
        <div style={{display: 'flex'}}>
          <div className="my-auto" style={{height: '100%', width: 40, display: 'inline-block'}}>
            <SmallSwitch 
              isOn={showDuplicate}
              myKey={'turnOn' + Math.random()}
              handleToggle={handleShowDuplicateToggle}
              onColor="#633FDA"
              title="On/Off"
            />
          </div>

          <div className="ml-4 my-auto" style={{height: '100%', display: 'inline-block'}}>
            <h6 className="my-auto" style={{fontWeight: 500,  color: '#303030',}}>Duplicate Opportunity Identifier</h6>
          </div>

          <div style={{marginLeft: 8, display: "inline-block"}} className='my-auto'>
          <span style={{
            backgroundColor: '#5794cd', // Blue color similar to the screenshot
            color: 'white',
            fontSize: '12px',
            padding: '2px 8px',
            borderRadius: '12px', // Pill-shaped rounded edges
            marginLeft: '8px',
            display: 'inline-block'
          }}>
            Beta
          </span>
        </div>
        </div>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col md="10" className="m-auto">
        <h5 style={{fontWeight: 600, color: '#303030'}}>Opportunity Approval Criteria</h5>
      </Col>
    </Row>
    
    <Row className="mt-4" style={{height: '250px'}}>
    <Col lg="10" className="mx-auto">
        <ReactQuill
        value={editorContent}
        onChange={setEditorContent}
        style={{ height: '200px' }}
        />
    </Col>
    </Row>
    <Row className="mt-4">
        <Col md="10" className="m-auto">
            <Button pill style={{backgroundColor:"#6e4db8", borderColor: "#6e4db8"}} onClick={() => saveEvaluationCriteria()}>Save Evaluation Criteria</Button>
        </Col>
    </Row>
    
    
  </Container>
  )}

AdministrationConfiguration.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

AdministrationConfiguration.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default AdministrationConfiguration;