import React from "react";
import { Col, Container, Row } from "shards-react";

import MoreInformationModalViewSubmittal from "../components/administration/MoreInformationModalViewSubmittal";
import LatestOrders from "../components/ecommerce/LatestOrders";
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";


import { withTranslation } from "react-i18next";
import EmployeeActivityDashboard from "./EmployeeActivityDashboard";
import MyIdeaInfo from "./MyIdeaInfo";

class SearchIdea extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      open: false,
      currIdea: null,
      canGoNext: false,
      goToSubmittal: false,
      canSubmit: false,
      canEdit: false,
      rfiOpen: false,
      idea: '',
      source: ''
    }

    this.openEvalForm = this.openEvalForm.bind(this);
  }

  openEvalForm(item, source) {
    if (item.status == "Response Needed") {
      this.setState({rfiOpen: true, open: false, currIdea: null, idea: item})
    } else {
      this.setState({
        currIdea: item,
        open: true,
        goToSubmittal: false,
        source: source
      });
    }
  }

  changeBtnStatus(status) {
    this.setState({
      canGoNext: status
    })
  }

  viewIdea = (idea) => {
    this.setState({ 
      open: true,
      goToSubmittal: true,
      currIdea: idea,
      rfiOpen: false
    })
  }

  closeModal = () => {
    this.setState({rfiOpen: false})
  }

  render() {
    const {open} = this.state;
    const {t} = this.props;
    return(
      !open?(
      <Container fluid className="main-content-container px-4">
        <MoreInformationModalViewSubmittal isOpen={this.state.rfiOpen} closeModal={this.closeModal} viewIdea={this.viewIdea} idea={this.state.idea} type="submitInfo"/>
        <Row>
            <Col md="12" lg="12" className="mt-4 mx-auto">
              <Row>
                <Col md="12" lg="12">
                  <h3 className="m-auto" style={{fontWeight: 600, color: '#303030'}}>{t("User Dashboard")}</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DivisorBarIcon></DivisorBarIcon>
                </Col>
              </Row>
            </Col>
        </Row>
  
        <Row>
          <Col lg="12" className="mx-auto">
            <EmployeeActivityDashboard/>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col md="12" lg="12" className="mt-4 mx-auto">
            <Row>
              <Col md="12" lg="12">
                <h3 className="m-auto" style={{fontWeight: 600, color: '#303030'}}>{t("YOUR_IDEA_HISTORY")}</h3>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg="12" className=" mx-auto">
            <LatestOrders onEvalBtnPress={(item, source) => this.openEvalForm(item, source)} source="your-ideas"/>
          </Col>
        </Row>
    
      </Container>
    ):
      <MyIdeaInfo goToSubmittal={this.state.goToSubmittal} currIdea={this.state.currIdea} updateCurrIdea={() => this.updateCurrIdea()} closeModal={() => this.setState({open: false})} source={this.state.source}/>
    )
  }

}

export default withTranslation()(SearchIdea);
