import Parse from 'parse';
import React from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Tooltip } from "shards-react";

import { ReactComponent as AcceptIcon } from "../../images/accept_button.svg";
import { ReactComponent as CancelIcon } from "../../images/cancel_button.svg";
import { ReactComponent as InfoIcon } from "../../images/info_icon.svg";

import XLSX from 'xlsx';






import CustomFileUpload from '../components-overview/CustomFileUpload';



class ImportDataModal extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
          message: this.props.text,
          idea: this.props.idea,
          responseInfo: false,
          open: this.props.open,
          file: '',
          fileData: [],
          repeatedUsers:[]
        }
      }
    
    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        const currentStatus =
          prevProps.open
        const nextStatus =
          this.props.open
  
        if (currentStatus !== nextStatus) {
         this.setState({open: this.props.open})
        }
      }

    toggle = () => {
        this.props.closeModal()
    }

    toggleInfo = () => {
        this.setState({
          responseInfo: !this.state.responseInfo
        });
    }

    onTextChange = (event) => {
        const text = event.target.value
        this.setState({message: text})
    }

    updateInfo = () => {
      if (this.state.message.length > 0) {
        this.props.changeText(this.state.message)
      } else {
        alert('Your request cannot be empty.')
      }
      
    }

    updateIdea = () => {
      const { rfiMessage } = this.state;
      const { idea } = this.props;

      if (rfiMessage.length > 0) {
        idea.set("rfiAnswer", rfiMessage)
        idea.set("status", 'SOMETIDA')
        idea.set("needsEvaluation", true)
        idea.set("customUpdatedAt", new Date())

        Parse.Object.saveAll([idea], {useMasterKey: true}).then(() => {
          this.closeModal()
        });
      } else {
        alert('Your request cannot be empty.')
      }
    }

    closeModal = () => {
      this.setState({open: false})
    //   this.props.closeModal()
    }
    
    changeFilterTypeValue(res, idx) {
      this.setState({
        filterTypeValue: res,
      });

      // alert(JSON.stringify(res))
    }

    selectFile(myFile) {
      const reader = new FileReader();
    
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
    
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    
        // Convert parsedData to an object with row headers as property names
        const convertedData = parsedData.map(row => {
          const obj = {};
          for (let i = 0; i < row.length; i++) {
            obj[parsedData[0][i]] = row[i];
          }
          return obj;
        });
    
        // Remove the first row (header row)
        convertedData.shift();
    
        this.setState({ file: myFile, fileData: convertedData });
      };
    
      reader.readAsArrayBuffer(myFile);
    }
    
    

    deleteFile() {
        this.setState({file: ''});
    }

    async uploadData() {
      const { fileData } = this.state;
      var repeatedUsers = [];
    
      const modifiedData = await Promise.all(fileData.map(async (dataPoint) => {
        // Category Data
        if (this.props.dataType == "category") {
          const IdeaCategory = Parse.Object.extend("IdeaCategory");
          const ideaCategory = new IdeaCategory();
    
          ideaCategory.set("itemNameTrans", { en: dataPoint["CATEGORY ENG"], es: dataPoint["CATEGORY 2ND LANGUAGE"] });
          ideaCategory.set("icon", "Number One");
          ideaCategory.set("show", false);
          ideaCategory.set("itemName", dataPoint["CATEGORY ENG"]);
          ideaCategory.set("categoryDescription", { en: dataPoint["ENG DESCRIPTION"], es: dataPoint["2ND LANG DESCRIPTION"] });
          ideaCategory.set("kpi", [
            { en: dataPoint["KPI 1"], es: "" },
            { en: dataPoint["KPI 2"], es: "" },
            { en: dataPoint["KPI 3"], es: "" },
          ]);
          ideaCategory.set("customUpdatedAt", new Date())
    
          return ideaCategory;
        } else if (this.props.dataType == "questions") {
          const IdeaQuestion = Parse.Object.extend("IdeaQuestion");
          const ideaQuestion = new IdeaQuestion();
    
          ideaQuestion.set("questionType", dataPoint["Question Type"]);
          ideaQuestion.set("questionTrans", {
            "en": dataPoint["Question ENG"],
            "es": dataPoint["Question 2ND LANGUAGE"]
          });
          ideaQuestion.set("question", dataPoint["Question ENG"]);
    
          if (dataPoint["Required"] == "Yes") {
            ideaQuestion.set("required", true);
          } else {
            ideaQuestion.set("required", false);
          }
    
          if (dataPoint["Yes/No"] == "Yes") {
            ideaQuestion.set("field", false);
          } else {
            ideaQuestion.set("field", true);
          }
    
          if (dataPoint["Question Type"] == "Category") {
            ideaQuestion.set("category", dataPoint["Category"]);
          } else if (dataPoint["Question Type"] == "Type") {
            ideaQuestion.set("category", dataPoint["Type"]);
    
            if (dataPoint["Type"] == "Innovation") {
              ideaQuestion.set("ideaType", "innovacion");
              ideaQuestion.set("filter", "innovacion");
            } else if (dataPoint["Type"] == "Continuous Improvement") {
              ideaQuestion.set("ideaType", "mejoras");
              ideaQuestion.set("filter", "mejoras");
            } else {
              ideaQuestion.set("ideaType", "solucion");
              ideaQuestion.set("filter", "solucion");
            }
          }
          ideaQuestion.set("customUpdatedAt", new Date())
    
          return ideaQuestion;
        } else if (this.props.dataType == "users") {
          const email = dataPoint["User Name (E-mail)"];
          const username = dataPoint["User Name (E-mail)"];
    
          // Query to check if the user already exists with the same email or username
          const existingUserQuery = new Parse.Query(Parse.User);
          existingUserQuery.equalTo("email", email);
          existingUserQuery.equalTo("username", username);
          const existingUser = await existingUserQuery.first({ useMasterKey: true });
    
          if (existingUser) {
            repeatedUsers.push(email); // Add the repeated user to the list
          }
    
          let role = 'user'; // Changed from const to let to allow modification
          const isCoach = dataPoint["Coach [Y/N]"] == 'Yes' ? true : false;
          const isVerification = dataPoint["Idea Verification Access [Y/N]"] == 'Yes' ? true : false;
          const isReward = dataPoint["Reward & Recognition [Y/N]"] == 'Yes' ? true : false;
          const isSupervisor = dataPoint["Supervisor/Manager [Y/N]"] == 'Yes' ? true : false;
          const isPmo = dataPoint["PMO Committee [Y/N]"] == 'Yes' ? true : false;
    
          if (dataPoint["SuperUser [Up to 3]"] == 'Yes') {
            role = 'super_user';
          }
    
          const randomPassword = Math.random().toString(36).slice(-8);
    
          const user = new Parse.User();
          user.set("isSupervisor", isSupervisor);
          user.set("role", role);
          user.set("coach", isCoach);
          user.set("password", randomPassword);
          user.set("firstName", dataPoint["First Name"]);
          user.set("supervisorEmail", '');
          user.set("lastName", dataPoint["Last Name"]);
          user.set("evaluationCriteria", []);
          user.set("username", dataPoint["User Name (E-mail)"]);
          user.set("email", dataPoint["User Name (E-mail)"]);
          user.set("department", dataPoint["Department/Team Name"]);
          user.set("notificationCount", 0);
          user.set("notificationMessages", []);
          user.set("reward", isReward);
          user.set("verification", isVerification);
          user.set("pmo", isPmo);
          user.set("gender", '');
          user.set("dob", '');
          user.set("customUpdatedAt", new Date())
    
          return user;
        } else if (this.props.dataType == "comms") {
          const name = dataPoint["Committee Name"];
          const members = dataPoint["Members"].split(', ');
          const departments = dataPoint["Departments"].split(', ');
          const categories = dataPoint["Categories"].split(', ');
          const ideaTypes = dataPoint["Idea Types"].split(', ');
    
          const formattedMembers = await Promise.all(members.map(async (email) => {
            return await this.getFormattedMember(email);
          }));
    
          const EvaluationCommittee = Parse.Object.extend("EvaluationCommittee");
          const evaluationCommittee = new EvaluationCommittee();
    
          evaluationCommittee.set("name", name);
          evaluationCommittee.set("members", formattedMembers);
          evaluationCommittee.set("canDelete", true);
          evaluationCommittee.set("departments", departments);
          evaluationCommittee.set("categories", categories);
          evaluationCommittee.set("ideaTypes", ideaTypes);
          evaluationCommittee.set("customUpdatedAt", new Date())

          return evaluationCommittee;
        } else if (this.props.dataType == "department") {
          const IdeaDepartment = Parse.Object.extend("IdeaDepartment");
          const ideaDepartment = new IdeaDepartment();
          ideaDepartment.set("itemName", dataPoint["DEPARTMENT ENG"]);
          ideaDepartment.set("itemNameTrans", { en: dataPoint["DEPARTMENT ENG"], es: dataPoint["DEPARTMENT 2ND LANGUAGE"] });
          ideaDepartment.set("show", false);
          ideaDepartment.set("departmentLeader", dataPoint["DEPARTMENT LEADER"]);
          ideaDepartment.set("customUpdatedAt", new Date())
    
          return ideaDepartment;
        }
      }));
    
      this.setState({ repeatedUsers });
    
      Parse.Object.saveAll(modifiedData, { useMasterKey: true })
        .then((result) => {
          this.props.onFinish();
        })
        .catch((error) => {
          console.error(error);
          this.props.onFinish();
        });
    }
    

    getFormattedMember = async(email) => {
      const className = "User";
      var ItemClass = Parse.Object.extend(className);
      var query = new Parse.Query(ItemClass);
      return query
      .doesNotExist("deletedAt")
      .equalTo("email", email).find()
      .then((results) => {
          const options = []
         
          results.map((result, idx) => {
              const fullName = result.get("firstName") + " " + result.get("lastName")
              options.push({"value":result, "label":fullName})
          })

          return options[0]
          
      }, (error) => {
          alert('Error getting user data.')
      });

      // return {"value":result, "label":fullName}
    }

    render() {
        const { open, rfiMessage } = this.state;
        const { idea, type } = this.props;
        
        const hasIdea = idea != ''
        const shouldEditIdea = type == 'submitInfo'
        const tooltipMsg = 'Click on the drop-down menu below to see available committees.'
        var title = 'Import Categories'

        if (this.props.dataType == "users") {
            title = "Import Users"
        }

        if (this.props.dataType == "questions") {
            title = "Import Questions"
        }

        if (this.props.dataType == "comms") {
          title = "Import Committees"
        }
        
        if (this.props.dataType == "department") {
          title = "Import Departments"
        }

        return (
          <Modal open={open} centered backdrop toggle={() => this.toggle()}>
            <ModalHeader>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h5 className="m-auto" style={{ fontWeight: 500, color: '#303030' }}>{title}</h5>
        
                <a id={"ModalTooltipResponseInfo"} className="text-right" style={{ color: 'inherit' }} onClick={() => {
                  var myCopy = this.state.responseInfo
                  myCopy = !myCopy
                  this.setState({ responseInfo: myCopy })
                }}>
                  <InfoIcon className="ml-2" style={{ height: 12, width: 12 }}></InfoIcon>
                </a>
        
                <Tooltip
                  open={this.state.responseInfo}
                  target={"#ModalTooltipResponseInfo"}
                  id={"ModalTooltipResponseInfo1"}
                  toggle={() => { this.toggleInfo() }}
                >
                  {tooltipMsg}
                </Tooltip>
              </div>
            </ModalHeader>
            <ModalBody>
              {hasIdea &&
                <Row>
                  <Col md={12}>
                    {/* <p><strong>{idea.get("rfiQuestion")}</strong></p> */}
                  </Col>
                </Row>
              }
              <Row className="mb-4">
                <Col md={12}>
                  <label htmlFor="firstName" className="georgia">{'Upload a Template'}</label>
                  <div>
                    <span><CustomFileUpload onFileSelect={(file) => this.selectFile(file)} myFile={this.state.file} /> {this.state.file && <Button theme="warning" onClick={() => this.deleteFile()}>{"Delete File"}</Button>}</span>
                  </div>
                  {/* <CommitteeBasicSelect className="insideFont" evalType={"execution"} setResponsible={(res, idx) => this.changeFilterTypeValue(res, idx)} selectedVal={this.state.filterTypeValue}/> */}
                </Col>
              </Row>
        
              {this.state.repeatedUsers.length > 0 && (
                <Row className="mb-4">
                  <Col md={12}>
                    <div className="alert alert-danger">
                      <strong>Repeated Users:</strong>
                      <p>Repeated users are not added to the system.</p>
                      <ul>
                        {this.state.repeatedUsers.map((user, index) => (
                          <li key={index}>{user}</li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                </Row>
              )}
        
              <Row className="mt-4">
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                  <div className="ml-auto" style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className='mr-2'>
                      <AcceptIcon className="functionalButton ml-auto" style={{ height: 34, width: 34 }} onClick={() => this.uploadData()}></AcceptIcon>
                    </div>
                    <div>
                      <CancelIcon className="functionalButton ml-auto" style={{ height: 34, width: 34 }} onClick={() => this.closeModal()}></CancelIcon>
                    </div>
                  </div>
                </div>
              </Row>
            </ModalBody>
          </Modal>
        );
    }
  }

export default ImportDataModal