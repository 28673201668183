import moment from 'moment';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Col, DatePicker, Row } from "shards-react";
import Swal from 'sweetalert2';
import { ReactComponent as ArrowDownIcon } from "../../images/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../images/arrow_up.svg";
import { changeTargetCompletionDate, reassignCoach, reassignImplementationLeader } from '../../services/implementService';
import { authUser } from "../../services/userService";
import colors from "../../utils/colors";
import ExecutionSelectNew from "../common/ExecutionSelectNew";
import IdeaAttachmentModal from '../common/IdeaAttachmentModal';

function OriginalIdeaInfo({open, currIdea, openIdea, changeShouldReload, canClick, view}) {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false)
  const [categoryIsOpen, setCategoryIsOpen] = useState(open)
  const [targetDate, setTargetDate] = useState(null)
  const [isSuperUser, setIsSuperUser] = useState(false)
  const [isCoach, setIsCoach] = useState(false)
  const [executionRes, setExecutionRes] = useState('')
  const [executionCoach, setExecutionCoach] = useState([])
  const [saveCoaches, setSaveCoaches] = useState([])

  
  const changeResponsible = (res, idx) => {
    setExecutionRes(res)
  }


  
  const changeCoach = (res, idx) => {
    if (res && res.length > 3) {
      Swal.fire("Error", "You can only select up to 3 coaches", "error");
      return;
    }

    setExecutionCoach(res)
    
    let setCoaches = null
    if(res){
      setCoaches = res.map(option => option.value)
    }
    
    setSaveCoaches(setCoaches)
  }

  const saveCoach = async () => {
    try {
      if (saveCoaches && saveCoaches.length > 0) {
        await reassignCoach(currIdea.id, saveCoaches);
        Swal.fire("Success", "Change completed. Please refresh the page to see the updated information.", "success");
      } else {
        Swal.fire("Error", "Please select a valid coaches", "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error"); 
    }
  }

  const changeOwner = async () => {
    try {
      const leader = executionRes.value

      if(leader.id){
        await reassignImplementationLeader(currIdea.id, leader.id);
        Swal.fire("Success", "Change completed. Please refresh the page to see the updated information.", "success");
      }else{
        Swal.fire("Error", "Please select a valid implementation leader", "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  }

  const setInitialTitle = async (user) => {
    if (currIdea.completionTargetDate) {
      setTargetDate(currIdea.completionTargetDate)
    } 
  }


  useEffect(() => {
    setInitialTitle()
    const currentUser = authUser();
    const isSuperUser = currentUser.roles.includes("super_user") || currentUser.roles.includes("murmuratto")
    const isCoach = currentUser.coach == true
    setIsSuperUser(isSuperUser)
    setIsCoach(isCoach)
  }, []);

  const getTeamMembers  = () => {
    const teamMemberObjs = currIdea.teamMembers
      
    if (teamMemberObjs) {
      return currIdea.teamMembers.join(" • ");
    }else {
      return "N/A";
    }
  }


  const getCoach = () => {
    if (currIdea.coaches && currIdea.coaches.length > 0) {
      return currIdea.coaches.join(", ");
    } else {
      return "N/A";
    }
  }

  const changeTargetDate = async (newDeadline) => {
    setTargetDate(newDeadline)
    currIdea.completionTargetDate = newDeadline
    try {
      await changeTargetCompletionDate(currIdea.id, newDeadline)
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    } 
  }

  
  const formatDate = (dateString) => {
    if(!dateString) return 'N/A';
    const date = moment(dateString);

    if (!date.isValid()) {
      console.error('Fecha inválida:', dateString);
      return 'Fecha inválida';
    }

    return date.format('DD MMMM YYYY');
  };

  return (
    <Row>
      {/* Latest Orders */}
      <Col lg="12" className="m-auto">
              <div style={{width: '100%', borderStyle: 'solid',borderColor: 'black', borderWidth: 2, borderRadius: 5, marginBottom: 5, display: 'flex'}}>
                <div className="ml-4 my-auto" style={{height: '100%', display: 'inline-block'}}>
                    <h6 className="my-auto" style={{fontWeight: 500,  color: '#303030',}}>{t("IdeaDetails") + " : #" +  + currIdea.num + " " + currIdea.title}</h6>
                </div>

                { categoryIsOpen && <ArrowUpIcon onClick={() => setCategoryIsOpen(false)} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowUpIcon> }
                { !categoryIsOpen && <ArrowDownIcon onClick={() => setCategoryIsOpen(true)} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowDownIcon>}
              
              </div>
              { categoryIsOpen &&
              <div className="mt-2"style={{backgroundColor: '#F6F6F6' }}>
                <Row form className="mx-4 mt-2">
                  <Col lg="12" className="mt-2">
                    <Row className="mt-4">
                        <Col md="3">
                          <label htmlFor="firstName" className="georgia">{t('creator')}</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{currIdea.proponent}</h6>
                        </Col>
                        <Col md="3">
                          <label htmlFor="firstName" className="georgia">{t('TITLE')}</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{currIdea.title}</h6>
                        </Col>
                        <Col md="3">
                          <label htmlFor="firstName" className="georgia">{t('CATEGORY')}</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{currIdea.category['name']}</h6>
                        </Col>
                        <Col md="3">
                          <label htmlFor="firstName" className="georgia">{t('TYPE')}</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{currIdea.type}</h6>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md="3">
                          <label htmlFor="firstName" className="georgia">{t('START_DATE')}</label>
                         
                              <h6 style={{fontWeight: 500,  color: '#303030'}}>
                                 {currIdea.startedAt ? formatDate(currIdea.startedAt) : 'N/A'}
                              </h6>
                            
                         
                        </Col>
                        <Col md="3">
                          <div><label htmlFor="firstName" className="georgia">{t('TARGET_COMPLETION_DATE')}</label>        </div>                 
                            {canClick == false?
                              <h6 style={{fontWeight: 500,  color: '#303030'}}>
                                 {targetDate ? formatDate(targetDate) : 'N/A'}
                              </h6>
                            :
                            <DatePicker
                              placeholderText={targetDate ? formatDate(targetDate) : 'Select Date'}
                              dropdownMode="select"
                              className="text-center"
                              onChange={(newDate) => changeTargetDate(newDate)} 
                              // selected={targetDate ? new Date(targetDate) : null}
                          /> 
                            }    
                        </Col>
                        <Col md="3">
                          <label htmlFor="firstName" className="georgia">{t('IMPLEMENTATION_LEADER')}</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{currIdea.implementationLeader|| 'N/A'}</h6>
                        </Col>
                        <Col md="3">
                          <label htmlFor="firstName" className="georgia">Coach</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{getCoach()}</h6>
                        </Col>
                    </Row>
                    { isSuperUser && view !== 'userDashboard' &&
                    <Row className="mt-4">
                    <Col md="3">
                      <label htmlFor="firstName" className="georgia">{t('REASSIGN')} {t('IMPLEMENTATION_LEADER')}</label>
                      <ExecutionSelectNew className="insideFont" evalType={'execution'} setResponsible={(res, idx) => changeResponsible(res, idx)} selectedVal={executionRes}/>
                      {executionRes && <Button className="ml-auto mt-3" pill  onClick={() => changeOwner()}>Save New Owner</Button>}
                    </Col>
                    <Col md="3">
                      <label htmlFor="firstName" className="georgia">{t('REASSIGN')} Coach</label>
                      <ExecutionSelectNew className="insideFont" evalType={'coach'} setResponsible={(res, idx) => changeCoach(res, idx)} selectedVal={executionCoach}/>
                      {executionCoach.length ? <Button className="ml-auto mt-3" pill onClick={() => saveCoach()}>Save New Coach</Button> : ''}
                    </Col>
                    </Row>}
                    {getTeamMembers() && 
                    <Row className="mt-4">
                        <Col md="12">
                          <label htmlFor="firstName" className="georgia">Team Members</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{getTeamMembers()}</h6>
                        </Col>
                    </Row>}
                    {currIdea.evaluation && 
                      <Row className="mt-4">
                        <Col md="12">
                          <label htmlFor="firstName" className="georgia">Evaluation Comments</label>
                          <h6 style={{fontWeight: 500,  color: '#303030'}}>{currIdea.evaluation.comment}</h6>
                          <p className="georgia">By {currIdea.evaluation.evaluatedBy} | {moment(currIdea.evaluation.evaluatedAt).format("DD MMMM YYYY")}</p>
                        </Col>
                    </Row>}
                    {currIdea.evaluation && currIdea.evaluation.attachment && (
                   <>
                    <Row className="mt-4">
                           <Col md="12">
                            <label htmlFor="firstName" className="georgia">Evaluation Attachment</label>
                            <Button onClick={() => setModalVisible(true)} style={{display: 'flex'}} >View Attachment</Button>  
                          </Col>
                    </Row>
                    <IdeaAttachmentModal ideaItem={currIdea.evaluation.attachment} onClick={setModalVisible} isVisible={modalVisible} type="evaluationFile"/>
                    </>
                    )}
                  </Col>
                       
                        
                    <Col lg="12" className="mt-2">
                        <Row className="mb-4 ml-auto">
                            <Col md="10">
                            </Col>
                            <Col md="2" className="ml-auto">
                              <Button className="ml-auto" pill style={{backgroundColor:"#6e4db8", borderColor: "#6e4db8"}} onClick={() => openIdea()}>
                                  {t('SubmittalDetails')}
                              </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                            </div>
                            }
                    </Col>
                    </Row>
                )}


  
  
  OriginalIdeaInfo.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

OriginalIdeaInfo.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default OriginalIdeaInfo;
