import Parse from 'parse';
import React from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink
} from "shards-react";
import { authUser, purgeCache } from '../../../../services/userService';
import { sendResetEmail } from '../../../../services/emailService';

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      userName: '', 
      email: ''
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  componentWillMount() {
    this.getUserName();
  }

  // async getUserName(user) {
  //   var query = new Parse.Query(Parse.User);
  //   query.equalTo("objectId", user.id);
  //   const results = await query.find();
  //   const firstName = results[0].get('firstName');
  //   const lastName = results[0].get('lastName');
  //   const fullName = firstName + ' ' + lastName;
  //   const email = results[0].get("username")
     
  //   this.setState({
  //     userName: fullName, 
  //     email: email
  //   });
  // }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  logOut() {
    purgeCache();
    window.location.href = '/login';
  }

  async resetPassword() {
   const user = authUser();
   try {
    const sendEmailResponse = await sendResetEmail(user.email);
    alert(sendEmailResponse.message);
   } catch (error) {
    alert(error.message);
   }
  }

  getUserName = async () => {
    const auth = await authUser();
    
    if (auth === null) { 
      return
    }

    this.setState({
      userName: `${auth.firstName} ${auth.lastName}`, 
      email: `${auth.email}`, 
    });
  }

  render() {
    const { userName } = this.state;
    const { t } = this.props;
    
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3" style={{
          color: '#9ea8b9',
          fontSize: '14px',
          padding: '0px'
        }}>
          <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../images/avatars/user.png")}
            alt={userName}
            style={{
              width: '30px',
              height: '30px'
            }}
          />{" "}
          <span className="d-none d-md-inline-block">{userName}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem className="text-success" onClick={this.resetPassword}>
            <i className="material-icons text-success"></i> {t('RESET_PASSWORD')}
          </DropdownItem>
          <DropdownItem className="text-danger" onClick={this.logOut}>
            <i className="material-icons text-danger"></i> {t('LOGOUT')}
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

export default withTranslation() (withRouter(UserActions));