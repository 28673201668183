import axios from 'axios';
import { authUser } from './userService';

const API_URL = process.env.REACT_APP_EP_API;

const getCategories = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/administration/categories`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error.response ? error.response.data : error.message);
    throw error;
  }

};


const deleteCategories = async (categoryId) => {
  try {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;

    const formData = new FormData();
    formData.append('categoryId', categoryId);

    const response = await axios.delete(`${API_URL}/administration/categories`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    localStorage.removeItem(`categories_${loggedUser.id}`);
  
    return response.data;
  } catch (error) {
    console.error('Error fetching opportunity ideas:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const updateCategories = async (categoryId, categoryData) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  try {
      const formData = new FormData();
      formData.append('categoryId', categoryId);

      if (categoryData.name && categoryData.name.en) {
        formData.append('name[en]', categoryData.name.en);
      }
      if (categoryData.name && categoryData.name.es) {
        formData.append('name[es]', categoryData.name.es);
      }
      if (categoryData.description && categoryData.description.en) {
        formData.append('description[en]', categoryData.description.en);
      }
      if (categoryData.description && categoryData.description.es) {
        formData.append('description[es]', categoryData.description.es);
      }
      
      formData.append('icon', categoryData.iconName);

      // Añadir otros campos solo si contienen datos
      if (categoryData.startDate) {
        formData.append('startDate', categoryData.startDate);
      }
      if (categoryData.endDate) {
        formData.append('endDate', categoryData.endDate);
      }
        
      if (categoryData.kpi) {
        categoryData.kpi.forEach((kpiItem, index) => {
          formData.append(`kpis[${index}][en]`, kpiItem.en);
          formData.append(`kpis[${index}][es]`, kpiItem.es);
        });      
      }

      if (categoryData.categoriesLimit) {
        formData.append('categoriesLimit', categoryData.categoriesLimit);
      }

      formData.append('active', categoryData.active || false);
      
      const response = await axios.patch(`${API_URL}/administration/categories`, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`, 
          'Content-Type': 'multipart/form-data',
        },
      });
      
      localStorage.removeItem(`categories_${loggedUser.id}`);


      return response.data;
  } catch (error) {
      let errorMessage = 'Error updating category.';
      
      if (error.response && error.response.data && Array.isArray(error.response.data.errors) && error.response.data.errors.length > 0) {
          errorMessage = error.response.data.errors[0];
      } else if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
      } else if (error.message) {
          errorMessage = error.message;
      }

      throw errorMessage;
  }
};

const createdCategories = async (categoryData) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  try {
      const formData = new FormData();

      formData.append('name[en]', categoryData.name.en);
      formData.append('name[es]', categoryData.name.es);

      formData.append('description[en]', categoryData.description.en);
      formData.append('description[es]', categoryData.description.es);
     
      formData.append('icon', categoryData.iconName);

      if (categoryData.startDate && categoryData.startDate.trim() !== '') {
        formData.append('startDate', categoryData.startDate);
      } 

      if (categoryData.endDate && categoryData.endDate.trim() !== '') {
          formData.append('endDate', categoryData.endDate);
      }

      categoryData.kpi.forEach((kpiItem, index) => {
        formData.append(`kpis[${index}][en]`, kpiItem.en);
        formData.append(`kpis[${index}][es]`, kpiItem.es);
      });

      formData.append('active', categoryData.active || false);

      formData.append('categoriesLimit', categoryData.categoriesLimit);
      
      const response = await axios.post(`${API_URL}/administration/categories`, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`, 
          'Content-Type': 'multipart/form-data',
        },
      });

      localStorage.removeItem(`categories_${loggedUser.id}`);
      
      return response.data;
  } catch (error) {
        let errorMessage = 'Error creating category.';
        
        if (error.response && error.response.data && Array.isArray(error.response.data.errors) && error.response.data.errors.length > 0) {
            errorMessage = error.response.data.errors[0];
        } else if (error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
        } else if (error.message) {
            errorMessage = error.message;
        }

        alert(errorMessage);
        console.error('Error creating category:', errorMessage);
        throw error;
  }
};

const downloadDataCategories = async () => {
  try {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const response = await axios.get(`${API_URL}/administration/categories`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      },
      params: {
        action: 'export'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching opportunity ideas:', error.response ? error.response.data : error.message);
    throw error;
  }
};


export default {
  getCategories,
  downloadDataCategories,
  deleteCategories,
  updateCategories,
  createdCategories
};