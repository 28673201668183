import Parse from 'parse';
import React from 'react';
import { Col, FormTextarea, Modal, ModalBody, ModalHeader, Row, Tooltip } from "shards-react";
import { ReactComponent as AcceptIcon } from "../../images/accept_button.svg";
import { ReactComponent as CancelIcon } from "../../images/cancel_button.svg";
import { ReactComponent as InfoIcon } from "../../images/info_icon.svg";

import CommitteeBasicSelect from '../common/CommitteeBasicSelect';


class TransferCommitteeModal extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
          message: this.props.text,
          idea: this.props.idea,
          responseInfo: false,
          open: false,
          filterTypeValue: '',
        }
      }
    
    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        const currentStatus =
          prevProps.isOpen 
        const nextStatus =
          this.props.isOpen
  
        if (currentStatus !== nextStatus) {
         this.setState({open: this.props.isOpen})
        }
      }

    toggle = () => {
        this.props.closeModal()
    }

    toggleInfo = () => {
        this.setState({
          responseInfo: !this.state.responseInfo
        });
    }

    onTextChange = (event) => {
        const text = event.target.value
        this.setState({message: text})
    }

    updateInfo = () => {
      if (this.state.message.length > 0 && this.state.filterTypeValue) {
        this.props.changeCommittee(this.state.filterTypeValue)
        this.props.changeText(this.state.message)
      } else {
        alert('Your request cannot be empty.')
      }
      
    }

    updateIdea = () => {
      const { open, rfiMessage } = this.state;
      const { idea, type, closeModal } = this.props;


      if (rfiMessage.length > 0) {
        idea.set("rfiAnswer", rfiMessage)
        idea.set("status", 'SOMETIDA')
        idea.set("needsEvaluation", true)
        idea.set("customUpdatedAt", new Date())


        Parse.Object.saveAll([idea], {useMasterKey: true}).then(() => {
          this.closeModal()
        });
      } else {
        alert('Your request cannot be empty.')
      }
    }

    closeModal = () => {
      this.setState({rfiMessage: ''})
      this.props.closeModal()
    }
    
    changeFilterTypeValue(res, idx) {
      this.setState({
        filterTypeValue: res,
      });

      // alert(JSON.stringify(res))
    }

    render() {
        const { open, rfiMessage } = this.state;
        const { idea, type } = this.props;

        const hasIdea = idea != ''
        const shouldEditIdea = type == 'submitInfo'
        const tooltipMsg = 'Click on the drop-down menu below to see available committees.'

        return (
        <Modal open={open} centered backdrop toggle={() => this.toggle()}>
          <ModalHeader>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <h5 className="m-auto" style={{fontWeight: 500, color: '#303030'}}>Transfer to Another Committee</h5>
              
                    {/* <a id={"ModalTooltipResponseInfo"} className="text-right" style={{ color: 'inherit'}} onClick={() => {
                        var myCopy = this.state.responseInfo
                        myCopy = !myCopy
                        this.setState({responseInfo: myCopy})
                    }}>
                        
                        <InfoIcon className="ml-2" style={{height: 12, width: 12}}></InfoIcon>
                        
                    </a>
                
                    
                    <Tooltip
                        open={this.state.responseInfo}
                        target={"#ModalTooltipResponseInfo"}
                        id={"ModalTooltipResponseInfo1"}
                        toggle={() => {this.toggleInfo()}}
                    >
                        {tooltipMsg}
                    </Tooltip> */}
            </div>
          </ModalHeader>
          <ModalBody>
            { hasIdea && 
             <Row>
              <Col md={12}>
                <p><strong>{idea.get("rfiQuestion")}</strong></p>
              </Col>
            </Row>
            }
            <Row className="mb-4">
                <Col md={12}>
                    <label htmlFor="firstName" className="georgia">{'Available Committees'} *</label>
                    <CommitteeBasicSelect className="insideFont" evalType={"execution"} setResponsible={(res, idx) => this.changeFilterTypeValue(res, idx)} selectedVal={this.state.filterTypeValue}/>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                <label htmlFor="firstName" className="georgia">{'Add Reason for Transfering Committee *'}</label>
                <FormTextarea
                    style={{ minHeight: "120px" }}
                    id="userBio"
                    placeholder={'Type response here'}
                    value={rfiMessage}
                    onChange={(event) => this.onTextChange(event)}
                />
                </Col>
            </Row>
            <Row className="mt-4">
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
                    <div className="ml-auto" style={{ display: 'flex', flexDirection: 'row'}}>
                        <div className='mr-2'>
                            <AcceptIcon  className="functionalButton ml-auto" style={{height: 34, width: 34}} onClick={() => shouldEditIdea?this.updateIdea():this.updateInfo()}></AcceptIcon>
                        </div>
                        <div>
                            <CancelIcon className="functionalButton ml-auto" style={{height: 34, width: 34}} onClick={() => this.closeModal()}></CancelIcon>
                        </div>
                    </div>
                </div>
            </Row>
          </ModalBody>
        </Modal>
        );
    }
  }

export default TransferCommitteeModal