import axios from 'axios';
const keyCache = `user`;

const API_URL = process.env.REACT_APP_EP_API;

export const authUser = () => {
  const cachedAuth = localStorage.getItem(keyCache);
  if (cachedAuth)
    return JSON.parse(cachedAuth);

  return updateUser();
};

export const updateUser = () => {
  let currentUser = localStorage.getItem(keyCache);
  if (!currentUser) return null;

  const currentUserJson = JSON.parse(currentUser);
  currentUserJson.fullName = `${currentUser.firstName} ${currentUser.lastName}`;
  
  localStorage.setItem(keyCache, JSON.stringify(currentUserJson));

  return currentUserJson;
};


export const purgeCache = () => {
  localStorage.removeItem(keyCache);
};


// Function to perform login
export const apiLogin = async (email, password, company) => {
  try {
    const formData = new URLSearchParams();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('company', company);

    const response = await axios.post(`${API_URL}/auth/login`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    // Handle response
   

    // Cache the authenticated user
    localStorage.setItem(keyCache, JSON.stringify(response.data));

    return response.data;
  } catch (error) {
    // Handle error
    console.error('Login failed:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const validateResetToken = async (token, company, newPassword) => {
  const formData = new FormData();
  formData.append('token', token);
  formData.append('company', company);
  formData.append('newPassword', newPassword);

  try {
    const response = await fetch(`${API_URL}/auth/validate-reset-token`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      body: formData
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
};


export const checkUpdateMe = async (currentUser) => {
  try {
    const response = await axios.get(`${API_URL}/me`, {
      headers: {
        Authorization: `Bearer ${currentUser.accessToken}`,
      },
    });

    const updatedUser = {
      ...response.data,
      accessToken: currentUser.accessToken,
      refreshToken: currentUser.refreshToken
    };

    localStorage.setItem('user', JSON.stringify(updatedUser));
    return updatedUser;
  } catch (error) {
    console.error('Session check failed:', error.response ? error.response.data : error.message);
    if (error.response && error.response.status === 401) {
      purgeCache();
      window.location.href = '/login'; 
    }
    throw error;
  }
};

export function getSubdomain() {
  const hostname = window.location.hostname;

  if (hostname.endsWith('murmuratto.com')) {
    const parts = hostname.split('.');
    if (parts.length > 2) {
      return parts.slice(0, -2).join('.');
    }
  }
  return null;
}
