import Parse from 'parse';
import React from "react";
import { Redirect } from "react-router-dom";
// Layout Types
import { DefaultLayout, IconSidebar } from "./layouts";

// Route Views
import Analytics from "./views/Analytics";
import OnlineStore from "./views/OnlineStore";
import SearchIdea from "./views/SearchIdea";
import SubmitIdeaNew from "./views/SubmitIdeaNew";

import ChangePassword from "./views/ChangePassword";
import ForgotPassword from "./views/ForgotPassword";
import Login from "./views/Login";
import Register from "./views/Register";
import ResetPassword from "./views/ResetPassword";

import IdeaVerificationInbox from "./views/IdeaVerificationHistory";
import RecognitionHistory from "./views/RecognitionHistory";
import TransactionHistory from "./views/TransactionHistory";
import VerificationHistory from "./views/VerificationHistory";



import Administration from "./views/Administration";
import AdministrationCategories from "./views/AdministrationCategories";
import AdministrationCatsDepts from "./views/AdministrationCatsDepts";
import AdministrationComite from "./views/AdministrationComite";
import AdministrationCommittees from "./views/AdministrationCommittees";
import AdministrationCommitteesBeta from "./views/AdministrationCommitteesBeta";
import AdministrationConfiguration from "./views/AdministrationConfiguration";
import AdministrationDepartments from "./views/AdministrationDepartments";
import AdministrationMonthlyReports from "./views/AdministrationMonthlyReports";
import AdministrationPreguntas from "./views/AdministrationPreguntas";
import AdministrationQuestions from "./views/AdministrationQuestions";
import AdministrationUsers from "./views/AdministrationUsers";
import AdministrationUsersNew from "./views/AdministrationUsersNew";
import EmployeeActivityDashboard from "./views/EmployeeActivityDashboard";
import EnterpriseAllSites from "./views/EnterpriseAllSites";
import EnterpriseLocal from "./views/EnterpriseLocal";
import EnterpriseR1 from "./views/EnterpriseR1";
import EnterpriseR2 from "./views/EnterpriseR2";
import EvaluateIdea from "./views/EvaluateIdea";
import Intelligence from "./views/Intelligence";
import RecognitionInbox from "./views/RecognitionInbox";
import SearchIdeasToImplement from "./views/SearchIdeasToImplement";

const BlankIconSidebarLayout = ({ children }) => (
  <IconSidebar noNavbar noFooter>
    {children}
  </IconSidebar>
);



// const parseUser = Parse.User.current();

// // Check if user is an admin
// const isAdmin = parseUser && parseUser.get('role') === 'admin';

// // Check if user is an employee
// const isEmployee = parseUser && parseUser.get('role') === 'employee';

// // Check if user is a manager
// const isManager = parseUser && parseUser.get('role') === 'manager';
// // const PrivateRoute = ({component: Component, ...rest}) => (

// )

const getRedirect = () => {
  const parseUser = Parse.User.current();

  // Check if user is an admin
  const isAnalytics = parseUser && parseUser.get('role') === 'analytics';

  // Check if user is an employee
  const isEnterprise = parseUser && parseUser.get('role') === 'enterprise';

  if (isAnalytics) {
    return <Redirect to="/analytics" />
  } else if (isEnterprise){
    return <Redirect to="/enterprise-r1" />
  } else {
    return <Redirect to="/submit" />
  }
}

const analyticsRoutes = [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    protected: true,
    component: () => getRedirect()
  },//<Redirect to="/idea-management-2" />
  {
    path: "/analytics",
    layout: DefaultLayout,
    protected: true,
    component: Analytics
  },
  
  {
    path: "/login",
    layout: BlankIconSidebarLayout,
    protected: false,
    component: Login
  },
  {
    path: "/register",
    layout: BlankIconSidebarLayout,
    component: Register
  },
  {
    path: "/forgot-password",
    layout: BlankIconSidebarLayout,
    component: ForgotPassword
  },
  {
    path: "/reset-password",
    layout: BlankIconSidebarLayout,
    component: ResetPassword
  },
  {
    path: "/change-password",
    layout: BlankIconSidebarLayout,
    component: ChangePassword
  },
  {
    path: "/enterprise-local",
    layout: DefaultLayout,
    protected: true,
    component: EnterpriseLocal
  },
  {
    path: "/reports",
    layout: DefaultLayout,
    protected: true,
    component: Analytics
  },
];



const defaultRoutes = [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    protected: true,
    component: () => getRedirect()
  },//<Redirect to="/idea-management-2" />
  {
    path: "/reports",
    layout: DefaultLayout,
    protected: true,
    component: Analytics
  },
  {
    path: "/enterprise-r1",
    layout: DefaultLayout,
    protected: true,
    component: EnterpriseR1
  },
  {
    path: "/enterprise-r2",
    layout: DefaultLayout,
    protected: true,
    component: EnterpriseR2
  },
  {
    path: "/enterprise-local",
    layout: DefaultLayout,
    protected: true,
    component: EnterpriseLocal
  },
  {
    path: "/enterprise-all-sites",
    layout: DefaultLayout,
    protected: true,
    component: EnterpriseAllSites
  },
  {
    path: "/premium",
    layout: DefaultLayout,
    protected: true,
    component: AdministrationConfiguration
  },
  {
    path: "/david",
    layout: DefaultLayout,
    protected: true,
    component: AdministrationMonthlyReports
  },
  {
    path: "/intelligence",
    layout: DefaultLayout,
    protected: true,
    component: Intelligence
  },
  {
    path: "/activity-dashboard",
    layout: DefaultLayout,
    protected: true,
    component: EmployeeActivityDashboard
  },
  {
    path: "/administracion",
    layout: DefaultLayout,
    protected: true,
    component: Administration
  },
  {
    path: "/administracion-user",
    layout: DefaultLayout,
    protected: true,
    component: AdministrationUsers
  },
  {
    path: "/administration-users",
    layout: DefaultLayout,
    protected: true,
    component: AdministrationUsersNew
  },
  {
    path: "/analytics",
    layout: DefaultLayout,
    protected: true,
    component: Analytics
  },
  {
    path: "/administration-questions",
    layout: DefaultLayout,
    protected: true,
    component: AdministrationQuestions
  },
  {
    path: "/administration-committees",
    layout: DefaultLayout,
    protected: true,
    component: AdministrationCommittees
  },
  {
    path: "/administration-committees-beta",
    layout: DefaultLayout,
    protected: true,
    component: AdministrationCommitteesBeta
  },
  {
    path: "/administracion-comite",
    layout: DefaultLayout,
    protected: true,
    component: AdministrationComite
  },
  {
    path: "/administracion-preguntas",
    layout: DefaultLayout,
    protected: true,
    component: AdministrationPreguntas
  },
  {
    path: "/administracion-departamentos-comites",
    layout: DefaultLayout,
    protected: true,
    component: AdministrationCatsDepts
  },
  {
    path: "/administration-categories",
    layout: DefaultLayout,
    protected: true,
    component: AdministrationCategories
  },
  {
    path: "/administration-departments",
    layout: DefaultLayout,
    protected: true,
    component: AdministrationDepartments
  },
  {
    path: "/submit",
    layout: DefaultLayout,
    protected: true,
    component: SubmitIdeaNew,
  },
  {
    path: "/user-dashboard",
    layout: DefaultLayout,
    protected: true,
    component: SearchIdea
  },
  {
    path: "/idea-management",
    layout: DefaultLayout,
    protected: true,
    component: OnlineStore
  },
  {
    path: "/evaluate",
    layout: DefaultLayout,
    protected: true,
    component: EvaluateIdea
  },
  {
    path: "/evaluate-:id",
    layout: DefaultLayout,
    protected: true,
    component: EvaluateIdea
  },

  {
    path: "/implement",
    layout: DefaultLayout,
    protected: true,
    component: SearchIdeasToImplement
  },
  {
    path: "/login",
    layout: BlankIconSidebarLayout,
    protected: false,
    component: Login
  },
  {
    path: "/register",
    layout: BlankIconSidebarLayout,
    component: Register
  },
  {
    path: "/forgot-password",
    layout: BlankIconSidebarLayout,
    component: ForgotPassword
  },
  {
    path: "/reset-password",
    layout: BlankIconSidebarLayout,
    component: ResetPassword
  },
  {
    path: "/change-password",
    layout: BlankIconSidebarLayout,
    component: ChangePassword
  },
  {
    path: "/evaluate_history",
    layout: DefaultLayout,
    component: TransactionHistory,
    protected: true,
  },
  {
    path: "/verify-history",
    layout: DefaultLayout,
    component: VerificationHistory,
    protected: true,
  },
  {
    path: "/verify",
    layout: DefaultLayout,
    component: IdeaVerificationInbox,
    protected: true,
  },
  {
    path: "/reward-recognize",
    layout: DefaultLayout,
    component: RecognitionInbox,
    protected: true,
  },
  {
    path: "/reward-recognize-history",
    layout: DefaultLayout,
    component: RecognitionHistory,
    protected: true,
  },
];

export { analyticsRoutes, defaultRoutes };
