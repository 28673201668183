import React from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup
} from "shards-react";


class IdeaQuestionsNew extends React.Component {
  constructor(props){
    super(props); 
 
    this.state = {
      ideas:[],
      filteredQuestions:[],
      filter:'Todas'
    }
  }

  componentDidMount() {
    this.fetchNewData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shouldUpdate !== this.props.shouldUpdate) {
      this.fetchNewData()
    }
  }

  fetchNewData = async () => {
    try {
        this.setState({
          ideas: this.props.questions,
          filteredQuestions: this.props.questions
        });
    } catch (error) {
        alert('Hubo un error en la búsqueda. Favor de intentar luego.1');
    }
};


  massageData(results) {
    const modifiedData = results.map((idea) => {
      const newData = {
        "Question Type": idea.get("questionType"),
        "Question ENG": idea.get("questionTrans").en,
        "Question 2ND LANGUAGE": idea.get("questionTrans").es,
        "Category": idea.get("questionType") == 'Category'?idea.get('category'):'',
        "Type": idea.get("questionType") == 'type'?idea.get('category'):'',
        "Required":idea.get("required")?'Y':'N',
        "Yes/No": !idea.get("field")?'Y':'N',
        "Comment Box": idea.get("field")?'Y':'N',
      }

      return(newData)
    })

    this.props.setDataCsv(modifiedData)
  }


  handleCategoryChange(event) {
    const newCategory = event.target.value;
    const { ideas } = this.state;
    var filteredData;
    if (newCategory !== 'Todas') {
      filteredData = ideas.filter(item => item.get("category") === newCategory);
    }  else {
      filteredData = ideas;
    }
    
    this.setState({
      filter: newCategory,
      filteredQuestions: filteredData
    });
  }

  deleteItem(item) {

    const canDelete = window.confirm('Are you sure you want to delete this question?')
    if (canDelete) {
      item.destroy({})
      .then((item) => {
      // Execute any logic that should take place after the object is saved.
        this.fetchNewData();
        
      }, (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        alert('Hubo un error en la operacion: ' + error.message);
      });
    } 
    
  }

  editItem(item) {
    this.props.editItem(item)
  }

  render() {
    const { filteredQuestions} = this.state;
    const { t } = this.props;

    return(
      
            <table className="table mb-0">
              <thead className="py-2 bg-light text-semibold border-bottom">
                <tr>
                  <th className="text-center">{t("QUESTION")}</th>
                  <th className="text-center">{t("CATEGORY")}</th>
                  <th className="text-center">{t("STATUS")}</th>
                  <th className="text-right">{t("ACTIONS")}</th>
                </tr>
              </thead>
              <tbody>
                {filteredQuestions.map((item, idx) => (
                  <tr key={idx}>
                    <td className="lo-stats__order-details text-center">
                      {item.question.en}
                    </td>
                    <td className="lo-stats__total text-center text-success">
                      {item.questionType === 'category' ? item.category.title : item.type.title}
                    </td>
                    <td className="lo-stats__items text-center">{item.required ? 'Required' : 'Not Required'}</td>
                    <td className="lo-stats__actions">
                      <ButtonGroup className="d-table ml-auto">
                        {/* <Button size="sm" theme="white" onClick={e => this.editItem(item)}>
                          Editar
                        </Button> */}
                        <Button size="sm" theme="white" onClick={() => this.editItem(item)}>
                          Edit
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
    )
  }
}

/**
 * Returns the badge type for a specific
 */

export default withTranslation()(IdeaQuestionsNew);
