import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Row,
  FormSelect
} from "shards-react";

import "./LocalStyles/CategorySelectStyle.css"

// Default
import clockImage from "../../images/stopwatch.svg";
import handImage from "../../images/hand.svg";
import chartImage from "../../images/line-chart.svg";
import shieldImage from "../../images/shield.svg";

// Selected
import clockImageSelected from "../../images/stopwatch_blue.svg";
import handImageSelected from "../../images/hand_blue.svg";
import chartImageSelected from "../../images/line-chart_blue.svg";
import shieldImageSelected from "../../images/shield_blue.svg";

// Default
import { ReactComponent as UserImage } from "../../images/user.svg";
import { ReactComponent as GearsImage } from "../../images/gears.svg";

// Selected
import puzzleImageSelected from "../../images/puzzle_blue.svg";
import gearsImageSelected from "../../images/gears_blue.svg";
import { withTranslation } from 'react-i18next';

import administrationService from '../../services/administrationService';

import Select from 'react-select';

const selectedColor = '#157ffb';

class EmployeeSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectionValue: '',
            sq1Color: '',
            sq2Color: '',
            sq3Color: '',
            sq4Color: '',
            titleColor1: 'black',
            titleColor2: 'black',
            titleColor3: 'black',
            titleColor4: 'black',
            handIcon: 'black',
            shieldIcon: 'black',
            chartIcon: 'black',
            clockIcon: 'black',
            puzzleIcon: 'black',
            gearIcon: 'black',
            data: [],
            showSecurity: '',
            showQuality: '',
            showProductivity: '',
            showAction: '',
            selectedPerson: this.props.selectedVal,
            options: []
          }

          this.fetchUsers = this.fetchUsers.bind(this);
          this.handleSelectedPersonChange = this.handleSelectedPersonChange.bind(this);
    }

    componentDidMount() {
        this.fetchUsers();
    }

    async fetchUsers() {
        try {
            const response = await administrationService.getUsers(); // Assuming you are passing the service as a prop
            const options = response.map(user => ({
                value: user.id,
                label: `${user.firstName} ${user.lastName}`
            }));

            this.setState({
                data: response,
                options: options
            });
        } catch (error) {
            console.error('Error fetching users:', error);
            this.setState({
                data: []
            });
        }
    }

    changeSelectedValue(selectionValue) {
        if (selectionValue === 1) {
            this.setState({
                selectionValue: 'Seguridad',
                sq1Color: selectedColor,
                sq2Color: '',
                puzzleIcon: 'black',
                gearIcon: selectedColor,
                titleColor1: selectedColor,
                titleColor2: 'black',
            });
            this.props.setFilter('innovacion');
        } else if (selectionValue === 2) {
            this.setState({
                selectionValue: 'Calidad',
                sq1Color: '',
                sq2Color: selectedColor,
                puzzleIcon: selectedColor,
                gearIcon: 'black',
                titleColor1: 'black',
                titleColor2: selectedColor,
            });
            this.props.setFilter('solucion');
        }
    }

    handleSelectedPersonChange(event) {
        this.props.setResponsible(event, 0);
    }

    render() {
        const { selectedPerson, options } = this.state;
        const { selectedVal, t, evalType } = this.props;

        return (
            evalType === "execution" ? (
                <div style={{ width: '100%' }}>
                    <Select
                        value={selectedVal}
                        onChange={this.handleSelectedPersonChange}
                        options={options}
                        placeholder={'Members'}
                        clearable={false}
                        isMulti
                        className="insideFont"
                    />
                </div>
            ) : (
                <div style={{ minWidth: 250 }}>
                    <Select
                        value={selectedVal}
                        onChange={this.handleSelectedPersonChange}
                        options={options}
                        placeholder={'No Coach'}
                        className="insideFont"
                    />
                </div>
            )
        );
    }
}

export default withTranslation()(EmployeeSelect);

const myStyles = {
    container: {
        marginRight: 10,
        marginLeft: 10
    }
};
