import React from "react";

const Topic = ({ text, repetitions }) => {
  const fontSize = 10 + repetitions * 2;

  return (
    <div
      className="topic"
      style={{ fontSize: `${fontSize}px`, backgroundColor: 'lightgray' }}
    >
      {text}
    </div>
  );
};

const Topics = ({ topics }) => {
  return (
    <div className="topics">
      {topics.map((topic) => (
        <Topic key={topic.text} text={topic.text} repetitions={topic.repetitions} />
      ))}
    </div>
  );
};

export default Topics;
