import { any } from "prop-types";
import React from "react";
import { Button, Col, Container, Modal, Row } from "shards-react";

import RecognitionTable from "../components/administration/RecognitionTable";
import PageTitle from "../components/common/PageTitle";

import RecognitionForm from "../components/common/RecognitionForm";
import { ReactComponent as PreviousIcon } from "../images/PreviousIcon.svg";
import { ReactComponent as SubmitIcon } from "../images/submit_new_new.svg";
import { ReactComponent as SubmitIconNoSelect } from "../images/submit_new_nosel.svg";


import { withTranslation } from 'react-i18next';
import { updateRecognitionRewardById } from "../services/recognizeService";
import CompletedIdeaFormNew from "./CompletedIdeaFormNew";

class RecognitionInbox extends React.Component { 

  constructor(props) {
    super(props)

    this.state = {
      categoryName: '',
      statusName: '',
      departmentNamee: '',
      open: false,
      ideaItem: any,
      viewIdea: true,
      showPreviousIcon: true,
      showNextIcon: false,
      showSubmitIcon: false,
      confirmModal: false,
      isNextEnabled: false,
      formKey: 0,
    }
  }

  onCategoryChange(categoryName) {
     
    this.setState({
      categoryName: categoryName
    })
  }

  onTypeChange(ideaType) {
    this.setState({
      ideaType: ideaType
    })
  }

  onStatusChange(statusName) {
     
    this.setState({
      statusName: statusName
    })
  }

  onDepartmentChange(categoryName) {
    
    var selectedCategoryName = categoryName
    if (categoryName == "All" || categoryName == "Todas") {
      selectedCategoryName = ""
    }

  

    this.setState({
      departmentName: selectedCategoryName
    })

    
  }

  toggle(item) {
     
    this.setState({
      open: true,
      ideaItem: item,
      viewIdea: false
    });
  }

  toggleModal(){
    
    this.setState({
      confirmModal: !this.state.confirmModal
    })
  }

  showIdea(item) {
      this.setState({
      open: true,
      ideaItem: item,
      viewIdea: true,
    });
  }

  dismissModal() {
    this.setState({
      open: !this.state.open,
      viewIdea: false,
    });
  }

  handleConfirm = async () => {
    try {
      if (this.state.ideaItem.id) {
        // Prefilled: use PATCH to update the recognition
        const recognitionId = this.state.ideaItem.id; // Assuming recognitionId is available from props

        await updateRecognitionRewardById(recognitionId);
        alert("Recognition updated successfully.");
      }
    } catch (error) {
      alert(`Error submitting recognition reward: ${error.message || "Something went wrong."}`);
    }
  }

  handlePreviousClick = () => {
    // Implement logic for previous button click
    // Example: navigate to the previous step or close modal
    // You can set state or call functions based on your requirements
    // For example:
    this.setState({
      open: false, // Close the modal
      viewIdea: false, // Update view state
    });
  };

  handleNextClick = () => {
    // Implement logic for next button click
    // Example: navigate to the next step or perform an action
    // You can set state or call functions based on your requirements
    // For example:
    this.setState({
      open: true, // Open a modal or proceed to the next step
      viewIdea: true, // Update view state
    });
  };

  handleSubmitClick = () => {
    // Implement logic for submit button click
    // Example: submit form data or perform an action
    // You can set state or call functions based on your requirements
    // For example:
    this.setState({
      open: true, // Open a modal or proceed to the submission process
      viewIdea: false, // Update view state
    });
  };

  setNextEnabled = (isEnabled) => {
    this.setState({ isNextEnabled: isEnabled });
  }

  refreshRecognitionForm = () => {
    this.toggleModal(); 
    // this.setState(prevState => ({
    //   formKey: prevState.formKey + 1
    // }));
  }

  render() {
    const {categoryName, statusName, open, viewIdea, isNextEnabled, formKey} = this.state;

    return(
      !open?(
      <Container fluid className="main-content-container px-4">

    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title={'Inbox'} subtitle={'Recognition Workflow'} className="text-sm-left mb-3" />
    </Row>

        <Row>
          <Col className="mb-4">
            <RecognitionTable category={categoryName} status={statusName} onViewIdeaPress={this.showIdea.bind(this)} onEditActionPress={this.toggle.bind(this)}/>
          </Col>
        </Row>
      </Container>)
  :
  (!viewIdea ? 
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title={'Overview'} subtitle={'Recognition Workflow'} className="text-sm-left mb-3" />
    </Row>
    <Row>
        <Col className="mb-4">
          <RecognitionForm
            key={formKey}
            dismissModal={this.dismissModal.bind(this)}
            ideaItem={this.state.ideaItem}
            setNextEnabled={this.setNextEnabled}
          />
        </Col>
    </Row>
    <Row>
        <Col lg="11" className="mb-4 mr-auto">
          {this.state.showPreviousIcon && (
            <PreviousIcon
              className="functionalButton mr-auto d-block"
              style={{ minWidth: 140, maxWidth: 140}}
              onClick={this.handlePreviousClick}
            ></PreviousIcon>
          )}
        </Col>
        <Col lg="1" className="mb-4" style={{flex:0}}>
          {this.state.showPreviousIcon && 
          isNextEnabled ? (
            <SubmitIcon
              className="functionalButton mr-auto d-block"
              onClick={() => this.toggleModal()}
            ></SubmitIcon>
          ) : <SubmitIconNoSelect className=" mr-auto d-block"></SubmitIconNoSelect>}
        </Col>
      </Row>
      <Modal open={this.state.confirmModal} centered backdrop toggle={() => this.toggleModal()}>
        <Row style={{minWidth: '600px', display: 'block', justifyContent: 'center'}} className="mt-4 floating-buttons-recognize pb-4">
              <div className="text-center mt-2 pb-2">
                <h5 style={{ fontWeight: 500, color: '#303030' }}>Are you done awarding rewards + recognitions?</h5>
                <hr />
              </div>
              <div className="text-center">
                <Button pill outline className="btn-outline-primary-bold" style={{ marginRight: '10px', padding: "5px 25px" }} onClick={() => { this.refreshRecognitionForm(); }}>No</Button>
                <Button pill outline className="btn-outline-primary-bold" style={{ padding:"5px 20px" }} onClick={() => this.handleConfirm()}>Yes, Submit</Button>
              </div>
          </Row>
      </Modal>
  </Container>
  :
     <CompletedIdeaFormNew currIdea={this.state.ideaItem}  closeModal={() => {this.setState({open: false})}} closeA3={() => this.setState({open: false})} stage="verified" customTitle="Recognition"></CompletedIdeaFormNew>

     )
    )
  }
}

export default withTranslation()(RecognitionInbox);

