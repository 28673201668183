import React from 'react';
import { Button, Modal, Row } from "shards-react";
import { submitRFI } from '../../services/userDashboardService.js';

class NewRecognitionModal extends React.Component {
  constructor(props) {
    super(props)  

    const { idea } = this.props
    this.state = {
      open: false,
    }
  }

  componentDidUpdate(prevProps) {
    const currentStatus =
      prevProps.isOpen 
    const nextStatus =
      this.props.isOpen

    if (currentStatus !== nextStatus) {
      this.setState({open: this.props.isOpen})
    }
  }

  toggle = () => {
    this.props.closeModal()
  }

  toggleInfo = () => {
    this.setState({
      responseInfo: !this.state.responseInfo
    });
  }

  onTextChange = (event) => {
      const text = event.target.value
      this.setState({rfiMessage: text})
  }

  updateRfi = () => {
    if (this.state.rfiMessage.length > 0) {
      this.props.changeText(this.state.rfiMessage)
    } else {
      alert('Your request cannot be empty.')
    }
  }

  updateIdea = async (rfi) => {
    const { rfiMessage } = this.state;
    if (rfiMessage.length > 0) {
      try {
        await submitRFI(rfi.id, rfiMessage);
        if(!this.props.hiddenSubmittal){
          this.viewIdea()
        }else{
          const idea = {...this.props.idea, status: "SOMETIDO"}
          console.log('idea', idea)
          this.props.updateIdea(idea)
        }
        this.toggle();

      } catch (error) {
        if(error.response.data){
          if(error.response.data.error)
            alert(error.response.data.error.message);
          else if(error.response.data.message)
            alert(error.response.data.message);
        }else
          alert(error);

        console.error('Error submitting evaluation:', error);
      }
    } else {
      alert('Your request cannot be empty.');
    }
  }

  closeModal = () => {
    this.props.closeModal()
  }
    
  viewIdea = () => {
    this.props.viewIdea(this.props.idea)
  }

  render() {
      const { open } = this.state;
     
      return (
      <Modal open={open} centered backdrop toggle={() => this.toggle()}>
        <Row style={{minWidth: '600px', display: 'block', justifyContent: 'center'}} className="mt-4 floating-buttons-recognize pb-4">
              <div className="text-center mt-2 pb-2">
                <h5 style={{ fontWeight: 500, color: '#303030' }}>Are you done awarding rewards + recognitions?</h5>
                <hr />
              </div>
              <div className="text-center">
                <Button pill outline className="btn-outline-primary-bold" style={{ marginRight: '10px', padding: "5px 25px" }} onClick={() => this.props.closeModal()}>No</Button>
                <Button pill outline className="btn-outline-primary-bold" style={{ padding:"5px 20px" }} onClick={() => this.props.finishRecognition()}>Yes, Submit</Button>
              </div>
          </Row>
      </Modal>
      );
    }
  }

export default NewRecognitionModal