const API_URL = process.env.REACT_APP_EP_API;


export const sendEmail = async (data) => {
  try {
    const response = await fetch(`${API_URL}/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
};


export const sendTroubleEmail = async (data) => {
  try {
    const response = await fetch(`${API_URL}/auth/trouble`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(data).toString()
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export const sendResetEmail = async (email, company = 'demo') => {
  let formData = new FormData();
  
  formData.append('email', email);
  formData.append('company', company);

  try {
    const response = await fetch(`${API_URL}/auth/forgot-password`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        // Do not manually set 'Content-Type' here
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};
