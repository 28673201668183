import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { Chart } from 'react-charts'
import { Pie } from 'react-chartjs-2'
import {
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
  CardFooter,
} from "shards-react";
import Parse from 'parse';


import colors from "../utils/colors";
import {withTranslation} from 'react-i18next';


import XLSX from 'xlsx';
import { saveAs } from 'file-saver';


const blueColor = '#3A7BBB'
const orangeColor = '#FD902c'
const greenColor = '#7FA86F'
const goldColor = '#DDB153'

const MyChart = (props) => {
   
   
  const {t} = props;
  const data = React.useMemo(
    () => [
      {
        label: 'Completed',
        data: [['Departamento 1', 25], ['Departamento 2', 45], ['Departamento 3', 5]]
      },
      {
        label: 'In Progress',
        data: [['Departamento 1', 20], ['Departamento 2', 4], ['Departamento 3', 4]]
      },
      {
        label: 'Submitted',
        data: [['Departamento 1', 5], ['Departamento 2', 11], ['Departamento 3', 9]]
      },
    ],
    []
  )

  const series = React.useMemo(
    () => ({
      type: 'bar'
    }),
    []
  )

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'left'},
      { type: 'linear', stacked:'true', position: 'bottom' },
    ],
    []
  )

  const barChart = (
    // A react-chart hyper-responsively and continuusly fills the available
    // space of its parent element automatically
    <div
      style={{
        width: '400px',
        height: '300px'
      }}
    >
       <Chart data={props.data} series={series} axes={axes} tooltip/>
    </div>
    
  )

  return barChart
}

const ProgressPerCategoryChart = (props) => {
  const data = React.useMemo(
    () => [
      {
        label: '0%',
        data: [['Seguridad', 25], ['Calidad', 45], ['Productividad', 5], ['Accion Inmediata', 5]]
      },
      {
        label: '25%',
        data: [['Seguridad', 25], ['Calidad', 45], ['Productividad', 5], ['Accion Inmediata', 5]]
      },
      {
        label: '50%',
        data: [['Seguridad', 25], ['Calidad', 45], ['Productividad', 5], ['Accion Inmediata', 5]]
      },
      {
        label: '75%',
        data: [['Seguridad', 25], ['Calidad', 45], ['Productividad', 5], ['Accion Inmediata', 5]]
      },
      {
        label: '100%',
        data: [['Seguridad', 25], ['Calidad', 45], ['Productividad', 5], ['Accion Inmediata', 5]]
      },
    ],
    []
  )

  const series = React.useMemo(
    () => ({
      type: 'bar'
    }),
    []
  )

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'left' },
      {type: 'linear', stacked:'true', position: 'bottom' }
    ],
    []
  )

  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum[0],
      position: 'left',
      type: 'ordinal',
      primary: true
    }),
    []
  )
  const secondaryAxes = React.useMemo(
    () => (
      {
        getValue: (datum) => datum[1]/1000 + "k",
        type: 'linear',
        position: 'bottom' 
      }
    ),
    []
  )

  const barChart = (
    // A react-chart hyper-responsively and continuusly fills the available
    // space of its parent element automatically
    <div
      style={{
        width: '400px',
        height: '300px'
      }}
    >
      {/* <NewChart></NewChart> */}
      {/* <Chart initialWidth={100} type='bar' data={props.data}  series={series} axes={axes} tooltip /> */}
      <Chart initialWidth={100} type='bar' data={props.data}  series={series} axes={axes} tooltip />
    </div>
   
  )

  return barChart
}

function IdeasPendingEvaluationPerDeptChart() {
  const data = React.useMemo(
    () => [
      {
        label: 'Ideas Pendientes de Evaluacion',
        data: [['Departamento 1', 1]]
      },
      {
        label: 'Ideas Pendientes de Evaluacion',
        data: [['Departamento 2', 2]]
      },
    ],
    []
  )

  const series = React.useMemo(
    () => ({
      type: 'bar'
    }),
    []
  )

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'left' },
      { type: 'linear', stacked:'false', position: 'bottom' },
    ],
    []
  )

  const barChart = (
    // A react-chart hyper-responsively and continuusly fills the available
    // space of its parent element automatically
    <div
      style={{
        maxWidth: '400px',
        height: '300px'
      }}
    >
      <Chart data={data} initialWidth={100} series={series} axes={axes} tooltip style={{width: '100%'}}/>
    </div>
  )

  return barChart
}

class Analytics extends React.Component { 

  constructor(props) {
    super(props)

    this.state = {
      ideas:[],
      numSubmitted: '',
      numCompleted:'',
      numInProgress:'',
      numPendingEval: '',
      departments: '',
      categories: [],
      otherCategories: [],
      ideaByDepartmentData: [],
      progressByCategoryData:[],
      returnsByCategoryData:[],
      actualReturnsByCategoryData:[],
      completedIdeas:[],
      completeIdeas:[],
      ideasInProgress:[],
      ideasPerCats:[],
      ideaResults:[],
      showChart: false,
      showIdeasByDeptChart: false,
      showProgressByCatChart: false,
      showEarningsByCatChart: false,
      exportData:{},
      ideaDataCsv: [],
      verifications: [],
      showChartActual: false,
    }
    
    this.getIdeas = this.getIdeas.bind(this);
    this.getResults = this.getResults.bind(this);
    this.getDepartments = this.getDepartments.bind(this);
    this.getCategories = this.getCategories.bind(this);
  }

  componentWillMount() {
    this.getIdeas();
    this.getResults();
    // this.getDepartments();
  }

  async getVerification() {
    const VerifyObject = Parse.Object.extend("Verification");
    const displayLimit = 1000;
    const query = new Parse.Query(VerifyObject);
    query.limit(displayLimit)
    const results = await query.find();
    
    const editedResults = results.map((verification) => {
      const idea = this.state.ideas.find(idea => idea.id === verification.get("idea").id);
      verification.set("category", idea.get("category"))
      verification.category = idea.get("category")
      return verification
    })
    this.setupActualEarningsByCategoryData(editedResults)
    // this.setState({verifications: editedResults}, this.setupActualEarningsByCategoryData())
  }

  async getIdeas() {
    const IdeaObject = Parse.Object.extend("Idea");
    const displayLimit = 1000;
    const query = new Parse.Query(IdeaObject);
    query.limit(displayLimit)
    const results = await query.find();
    const completedIdeas = results.filter(idea => idea.get("progress")[0] == 100);
    const ideasInProgress = results.filter(idea => idea.get("progress")[0] > 0 && idea.get("progress")[0] < 100);
    const ideasPendingEval = results.filter(idea => idea.get("status") == "SOMETIDA");
    
    // query.equalTo("needsEvaluation", true);
    // query.equalTo("completed", false);
    // query.descending("createdAt");

    this.setState({ideas: results,
      completedIdeas: completedIdeas, 
      ideasInProgress: ideasInProgress, 
      numCompleted: String(completedIdeas.length),
      numInProgress: String(ideasInProgress.length),
      numSubmitted: String(results.length), 
      numPendingEval: String(ideasPendingEval.length),
    }, () => {
      this.getDepartments()
    })
  }

  async getResults() {
    const IdeaObject = Parse.Object.extend("Result");
    const query = new Parse.Query(IdeaObject);
    const results = await query.find();
    this.setState({ideaResults: results});
  }

  async getCategories() {
    const Category = Parse.Object.extend("IdeaCategory");
    const query = new Parse.Query(Category);
    const results = await query.find();
    this.setState({categories: results}, () => this.setupProgressByCategoryData());
  }

  async getDepartments() {
    this.getCategories();
    // Modify Idea Data
    this.downloadIdeaData()
    const Department = Parse.Object.extend("IdeaDepartment");
    const query = new Parse.Query(Department);
    const results = await query.find();
    this.setState({departments: results}, () => {
      this.getVerification();
      this.setupIdeaByDepartmentData()});
  }

  setupIdeaByDepartmentData() {
    const {ideas, categories, completedIdeas, ideasInProgress, departments, numCompleted, numSubmitted, numInProgress} = this.state;
    var inProgressData = [];
    // var completedData = [];
    for (var i in departments) {
      const deptName = departments[i].get("itemName");
      const filteredDataCount = ideasInProgress.filter(idea => idea.get("department") == deptName)
      const newNum = filteredDataCount.length;
      inProgressData.push([deptName, newNum])
    }

    var completedData = [];
    var completedIdeas1 = [];
    var colors = [greenColor, blueColor, orangeColor, goldColor, 'red'];
    for (var i in departments) {
      const deptName = departments[i].get("itemName");
      const filteredDataCount = completedIdeas.filter(idea => idea.get("department") == deptName)
      const newNum = filteredDataCount.length;
      completedData.push([deptName, newNum]);
      completedIdeas1.push({ title: deptName, value: newNum, color: colors[i]});
    }

    var ideasPerCategory = []
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName)
      const newNum = filteredDataCount.length;
      ideasPerCategory.push({ title: categoryName, value: newNum, color: colors[i]});
    }

    var submittedData = [];
    for (var i in departments) {
      const deptName = departments[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("department") == deptName)
      const newNum = filteredDataCount.length;
      submittedData.push([deptName, newNum])
    }
    
    const exportData = {
      'Submitted': submittedData,
      'In Progress':ideasPerCategory,
      "Completed":completedData
    }

     
     
    

    const results = [{
      label: 'Completed',
      data: completedData
    },
    {
      label: 'In Progress',
      data: inProgressData
    },
    {
      label: 'Submitted',
      data: submittedData
    }, []];

     
    this.setState({ideaByDepartmentData: results, exportData: ideas, completeIdeas: completedIdeas1, ideasPerCats: ideasPerCategory, showIdeasByDeptChart: true});
  }

  setupProgressByCategoryData() {

    this.setupEarningsByCategoryData()
    
    const {ideas, completedIdeas, ideasInProgress, departments, progressByCategoryData, categories} = this.state;
     
    var zeroData = [];
    // var completedData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 0)
      const newNum = filteredDataCount.length;
      zeroData.push([categoryName, newNum])
    }

    var twentyFiveData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 25)
      const newNum = filteredDataCount.length;
      twentyFiveData.push([categoryName, newNum])
    }

    var fiftyData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 50)
      const newNum = filteredDataCount.length;
      fiftyData.push([categoryName, newNum])
    }

    var seventyFiveData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 75)
      const newNum = filteredDataCount.length;
      seventyFiveData.push([categoryName, newNum])
    }

    var hundredData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea =>  idea.get("category") == categoryName && idea.get("progress")[0] == 100)
      const newNum = filteredDataCount.length;
      hundredData.push([categoryName, newNum])
    }

    const results = [{
      label: '0%',
      data: zeroData
    },
    {
      label: '25%',
      data: twentyFiveData
    },
    {
      label: '50%',
      data: fiftyData
    },
    {
      label: '75%',
      data: seventyFiveData
    },
    {
      label: '100%',
      data: hundredData
    }, []];
     

    this.setState({progressByCategoryData: results, showProgressByCatChart: true});
  }

  downloadIdeaData() {
    const modifiedDataPromises = this.state.ideas.map((idea) => {
      const labels = idea.get("teamMembers") && idea.get("teamMembers").map(member => member.label);
      const labelString =  idea.get("teamMembers") && labels.join(", ");
      const newData = {
        "Idea Originator": idea.get("proponentName") || "N/A",
        "Idea Number": idea.get("num") || "N/A",
        "Idea Title": idea.get("title") || "N/A",
        "Description": idea.get("description") || "N/A",
        "Idea Category": idea.get("category") || "N/A",
        "Idea Type": idea.get("ideaType") || "N/A",
        "Department": idea.get("department") || "N/A",
        "Submitted": idea.get("date") || "N/A",
        "Implementation Owner": idea.get("responsibleName") || "N/A",
        "Implementation Status (Progress %)": idea.get("progress")[0] || "N/A",
        "Expected Return": idea.get("expectedReturn") || "N/A",
        "Team Members": idea.get("teamMembers")? labelString: "N/A",
      };

      if (idea.get("status") === "SOMETIDA") {
        newData["Idea Stage"] = "Submitted";
      } else {
        newData["Idea Stage"] = idea.get("status") || "N/A";
      }
  
      if (idea.get("result")) {
        // Assuming you're using Parse as the backend, you can query the verification class
        const VerificationClass = Parse.Object.extend("Verification");
        const query = new Parse.Query(VerificationClass);
        query.equalTo("idea", idea); // Assuming "idea" is the field name linking to the current idea
  
        return query.first().then((verificationData) => {
          if (verificationData) {
            // Assuming you have a field named "money" in the verification class
            newData["Actual Return"] = verificationData.get("money") || "N/A";
          }
          return newData;
        });
      }
  
      return Promise.resolve(newData);
    });
  
    Promise.all(modifiedDataPromises)
      .then((modifiedData) => {
        
        this.setState({ ideaDataCsv: modifiedData });
      })
      .catch((error) => {
        console.error("Error downloading idea data:", error);
      });
  }
  
  

  // downloadIdeaData() {
  //   const modifiedData = this.state.ideas.map((idea) => {
  //     const newData = {
  //       "Idea Originator": idea.get("proponentName"),
  //       "Idea Number": idea.get("num"),
  //       "Idea Title": idea.get("title"),
  //       "Description": idea.get("description"),
  //       "Idea Category": idea.get("category"),
  //       "Idea Type": idea.get("ideaType"),
  //       "Department": idea.get("department"),
  //       "Submitted": idea.get("date"),
  //       "Idea Stage": idea.get("status"),
  //       "Implementation Owner": idea.get("responsibleName"),
  //       "Implementation Status (Progress %)": idea.get("progress")[0],
  //     }

  //     return(newData)
  //   })
  //    
  //   this.setState({ideaDataCsv: modifiedData})
  // }

  setupEarningsByCategoryData() {
    const {ideas, completedIdeas, ideasInProgress, departments, progressByCategoryData, categories} = this.state;
     
    var zeroData = [];
    // var completedData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName)
      var earnings = 0
      for (var index in filteredDataCount) {
        // earnings = earnings + filteredDataCount[index]
       earnings = earnings + filteredDataCount[index].get("expectedReturn")
      }
      
      zeroData.push([categoryName, earnings])
       
    }

    const results = [{
      label: 'Returns',
      data: zeroData
    }, []];
    
     
    this.setState({returnsByCategoryData: results, showChart: true});
  }

  

  setupActualEarningsByCategoryData(verifications) {
    const {ideas, categories} = this.state;
    
    var zeroData = [];
    // var completedData = [];
     
    
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      
      const filteredDataCount = verifications.filter(verification => verification.get("category") == categoryName)
       
       
      
      var earnings = 0

      for (var index in filteredDataCount) {
        // earnings = earnings + filteredDataCount[index]
        //  
        earnings = earnings + filteredDataCount[index].get("money")
      }

       
      zeroData.push([categoryName, earnings])
       
    }

    const results = [{
      label: 'Returns',
      data: zeroData
    }, []];
    
     
    this.setState({actualReturnsByCategoryData: results, showChartActual: true});
  }

  downloadCsv() {
    const { data } = this.state;

    // Convert JSON data to CSV string
    const csvString = this.jsonToCsv(data);

    // Create a Blob object from the CSV string
    const blob = new Blob([csvString], { type: 'text/csv' });

    // Save the Blob object as a file using FileSaver.js
    saveAs(blob, 'data.csv');
  }

  downloadXlsx() {
    const { ideaDataCsv } = this.state;

    const data = ideaDataCsv;

    // Create a new workbook object
    const wb = XLSX.utils.book_new();

    // Create a new worksheet object
    const ws = XLSX.utils.json_to_sheet(data);

    // Add the worksheet object to the workbook object
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Convert the workbook object to a binary string
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Create a Blob object from the binary string
    const blob = new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' });

    // Save the Blob object as a file using FileSaver.js
    saveAs(blob, 'data.xlsx');
  }

  jsonToCsv(data) {
    const columns = Object.keys(data[0]);
    const rows = data.map(obj => columns.map(col => obj[col]));

    return [columns.join(','), ...rows.map(row => row.join(','))].join('\n');
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  
  async deleteUsersWithoutIdeas() {
    const query = new Parse.Query(Parse.User);
    query.include('proponentObj');
    const users = await query.find({ useMasterKey: true });
    const ideas = await new Parse.Query('Idea').find({ useMasterKey: true });
    const usersWithIdeas = [];

    const emailList = [
      {
        "email": "eric_aguirre___alvarado@baxter.com"
      },
      {
        "email": "josue_alicea_diaz@baxter.com"
      },
      {
        "email": "eugenio_alicea_miranda@baxter.com"
      },
      {
        "email": "samuel_almestica_bernier@baxter.com"
      },
      {
        "email": "jorge_alvarado@baxter.com"
      },
      {
        "email": "marietta_alvarez_rodriguez@baxter.com"
      },
      {
        "email": "abimael_alvarez@baxter.com"
      },
      {
        "email": "alejandro_aponte_alabarces@baxter.com"
      },
      {
        "email": "sheila_aponte_mateo@baxter.com"
      },
      {
        "email": "felix_arizmendi_rivera@baxter.com"
      },
      {
        "email": "melvin_arroyo_melendez@baxter.com"
      },
      {
        "email": "yomarie_aviles@baxter.com"
      },
      {
        "email": "zulmary_baergas@baxter.com"
      },
      {
        "email": "neysa_baerga_colon@baxter.com"
      },
      {
        "email": "yislene_baerga_colon@baxter.com"
      },
      {
        "email": "zulmarie_bajandas@baxter.com"
      },
      {
        "email": "haydee_banks@baxter.com"
      },
      {
        "email": "hector_barbosa_medina@baxter.com"
      },
      {
        "email": "daniel_bermudez_santiago@baxter.com"
      },
      {
        "email": "edith_bernardi_santiago@baxter.com"
      },
      {
        "email": "yodalis_berrios_amaro@baxter.com"
      },
      {
        "email": "liz_bilbraut_pirela@baxter.com"
      },
      {
        "email": "jose_borges@baxter.com"
      },
      {
        "email": "julio_bosques@baxter.com"
      },
      {
        "email": "carlos_caban_rivera@baxter.com"
      },
      {
        "email": "guillermo_cancel@baxter.com"
      },
      {
        "email": "santos_candelario@baxter.com"
      },
      {
        "email": "nadly_carattini_santiago@baxter.com"
      },
      {
        "email": "edwin_cartagena_aponte@baxter.com"
      },
      {
        "email": "felipe_castillo_perez@baxter.com"
      },
      {
        "email": "ivonne_castro_rodriguez@baxter.com"
      },
      {
        "email": "elizabeth_centeno_lopez@baxter.com"
      },
      {
        "email": "yamil_cintron_lebron1@baxter.com"
      },
      {
        "email": "orlando_cintron_romero@baxter.com"
      },
      {
        "email": "omar_cintron_santiago@baxter.com"
      },
      {
        "email": "faustino_cintron@baxter.com"
      },
      {
        "email": "alejandro_claudio_medina@baxter.com"
      },
      {
        "email": "noely_claudio_rodriguez@baxter.com"
      },
      {
        "email": "giancarlo_clavell_gierbolini@baxter.com"
      },
      {
        "email": "milexy_collazo@baxter.com"
      },
      {
        "email": "marcos_colon_berrios@baxter.com"
      },
      {
        "email": "fabiola_colon_santos@baxter.com"
      },
      {
        "email": "linsay_colon@baxter.com"
      },
      {
        "email": "olga_colon_figueroa@baxter.com"
      },
      {
        "email": "gerardo_colon_lopez@baxter.com"
      },
      {
        "email": "carlos_colon_maldonado@baxter.com"
      },
      {
        "email": "rolando_colon_martell@baxter.com"
      },
      {
        "email": "jonathan_colon_rivera@baxter.com"
      },
      {
        "email": "antonio_colon@baxter.com"
      },
      {
        "email": "pedro_correa_rivera@baxter.com"
      },
      {
        "email": "joan_cortes_padilla@baxter.com"
      },
      {
        "email": "ismael_cruz_garcia@baxter.com"
      },
      {
        "email": "marvin_cruz@baxter.com"
      },
      {
        "email": "mary_cuevas_rodriguez@baxter.com"
      },
      {
        "email": "francis_curet@baxter.com"
      },
      {
        "email": "nelson_davila_castro@baxter.com"
      },
      {
        "email": "ariel_a_de_hoyos_rodriguez@baxter.com"
      },
      {
        "email": "denis_de_jesus_de_jesus@baxter.com"
      },
      {
        "email": "julio_de_la_cruz_ramos@baxter.com"
      },
      {
        "email": "alfredo_dejesus@baxter.com"
      },
      {
        "email": "ruben_del_valle@baxter.com"
      },
      {
        "email": "edgardo_diaz_olmeda@baxter.com"
      },
      {
        "email": "alexis_diaz_laureano@baxter.com"
      },
      {
        "email": "sasha_diaz_mendez@baxter.com"
      },
      {
        "email": "felix_diaz@baxter.com"
      },
      {
        "email": "claribel_echandy_gierbolini@baxter.com"
      },
      {
        "email": "luis_escalante_ramirez@baxter.com"
      },
      {
        "email": "edgard_estrada@baxter.com"
      },
      {
        "email": "laisa_feliciano_amaro@baxter.com"
      },
      {
        "email": "marjorie_feliciano_torres@baxter.com"
      },
      {
        "email": "angela_felix_gomez@baxter.com"
      },
      {
        "email": "alberto_felix_vazquez@baxter.com"
      },
      {
        "email": "pedro_felix@baxter.com"
      },
      {
        "email": "daniel_fernandez_lebron@baxter.com"
      },
      {
        "email": "cecilia_ferrer_cruz@baxter.com"
      },
      {
        "email": "jose_ferrer_rivera@baxter.com"
      },
      {
        "email": "marilyn_figueroa@baxter.com"
      },
      {
        "email": "peter_flores_vazquez@baxter.com"
      },
      {
        "email": "arnaldo_fonseca_crespo@baxter.com"
      },
      {
        "email": "rafael_franceschi@baxter.com"
      },
      {
        "email": "maria_fernanda_gamba@baxter.com"
      },
      {
        "email": "miguel_garcia1@baxter.com"
      },
      {
        "email": "juan_garcia_colon@baxter.com"
      },
      {
        "email": "victor_garcia_ruiz@baxter.com"
      },
      {
        "email": "juan_gomez_rivera@baxter.com"
      },
      {
        "email": "enrique_gonzalez_delgado@baxter.com"
      },
      {
        "email": "manuel_gonzalez1@baxter.com"
      },
      {
        "email": "alexander_gonzalez_olmo@baxter.com"
      },
      {
        "email": "xiomara_gonzalez_rodriguez@baxter.com"
      },
      {
        "email": "antonio_gonzalez_rosa@baxter.com"
      },
      {
        "email": "angel_gonzalez_zayas@baxter.com"
      },
      {
        "email": "jessimar_gonzalez@baxter.com"
      },
      {
        "email": "alejandro_goycochea@baxter.com"
      },
      {
        "email": "zilkia_gratacos@baxter.com"
      },
      {
        "email": "johanna_guilbe@baxter.com"
      },
      {
        "email": "victor_guzman1@baxter.com"
      },
      {
        "email": "sandra_guzman_rosado@baxter.com"
      },
      {
        "email": "marcos_guzman_ortiz@baxter.com"
      },
      {
        "email": "linda_guzman_sostre@baxter.com"
      },
      {
        "email": "gerardo_hernandez_alamo@baxter.com"
      },
      {
        "email": "efrain_hernandez@baxter.com"
      },
      {
        "email": "rafael_hernandez_gonzalez@baxter.com"
      },
      {
        "email": "jose_hernandez3@baxter.com"
      },
      {
        "email": "irmary_hernandez_ramos@baxter.com"
      },
      {
        "email": "eliezer_hernandez_santana@baxter.com"
      },
      {
        "email": "alberto_hernandez@baxter.com"
      },
      {
        "email": "jose_hernandez_rodriguez@baxter.com"
      },
      {
        "email": "juan_hidalgo_mojica@baxter.com"
      },
      {
        "email": "clifford_hourston@baxter.com"
      },
      {
        "email": "israel_huertas_mendez@baxter.com"
      },
      {
        "email": "fernando_ildefonso_rivera@baxter.com"
      },
      {
        "email": "rene_irizarry_gonzalez@baxter.com"
      },
      {
        "email": "jose_irizarry_gracia@baxter.com"
      },
      {
        "email": "ruth_n_la_santa@baxter.com"
      },
      {
        "email": "felix_laboy@baxter.com"
      },
      {
        "email": "rafael_laboy@baxter.com"
      },
      {
        "email": "maria_lebron_miranda@baxter.com"
      },
      {
        "email": "luis_lebron_merced@baxter.com"
      },
      {
        "email": "ilia_lebron_santell@baxter.com"
      },
      {
        "email": "alexis_lopez_leon@baxter.com"
      },
      {
        "email": "roberto_lopez_sampayo@baxter.com"
      },
      {
        "email": "ida_lopez@baxter.com"
      },
      {
        "email": "neishka_lugo_ortiz@baxter.com"
      },
      {
        "email": "danishka_lugo_nieves@baxter.com"
      },
      {
        "email": "jovanny_madera_zaccheus@baxter.com"
      },
      {
        "email": "ada_madera_santiago@baxter.com"
      },
      {
        "email": "natalia_maldonado@baxter.com"
      },
      {
        "email": "luis_manautou_hernandez@baxter.com"
      },
      {
        "email": "sadrac_marquez_lopez@baxter.com"
      },
      {
        "email": "saul_martinez_mateo@baxter.com"
      },
      {
        "email": "jorge_luis_martinez@baxter.com"
      },
      {
        "email": "nixa_martinez_calez@baxter.com"
      },
      {
        "email": "marlene_martinez_rivera@baxter.com"
      },
      {
        "email": "thais_martinez@baxter.com"
      },
      {
        "email": "wilfredo_melendez_gonzalez@baxter.com"
      },
      {
        "email": "juan_carlos_mercado_aguayo@baxter.com"
      },
      {
        "email": "jaime_montaner@baxter.com"
      },
      {
        "email": "jose_montanez_diaz@baxter.com"
      },
      {
        "email": "bryan_montero@baxter.com"
      },
      {
        "email": "jose_montes_nieves@baxter.com"
      },
      {
        "email": "migdoel_morales_antuna@baxter.com"
      },
      {
        "email": "norienne_morales_cotto@baxter.com"
      },
      {
        "email": "michael_morales_torres@baxter.com"
      },
      {
        "email": "florencio_morales@baxter.com"
      },
      {
        "email": "rene_muniz@baxter.com"
      },
      {
        "email": "geovanna_nazario@baxter.com"
      },
      {
        "email": "william_neris_pagan@baxter.com"
      },
      {
        "email": "luis_elias_nieves@baxter.com"
      },
      {
        "email": "ricardo_nieves_zayas@baxter.com"
      },
      {
        "email": "miriam_orama_irizarry@baxter.com"
      },
      {
        "email": "esther_orengo@baxter.com"
      },
      {
        "email": "luis_ortiz_vega@baxter.com"
      },
      {
        "email": "raquel_ortiz_leon@baxter.com"
      },
      {
        "email": "leez_ortizpueyo@baxter.com"
      },
      {
        "email": "dennisse_ortiz_reyes@baxter.com"
      },
      {
        "email": "rolando_ortiz_sanchez@baxter.com"
      },
      {
        "email": "carmen_i_ortiz@baxter.com"
      },
      {
        "email": "emmanuel_ortiz_santiago@baxter.com"
      },
      {
        "email": "miguel_ortiz_torres@baxter.com"
      },
      {
        "email": "roberto_pabon_garcia@baxter.com"
      },
      {
        "email": "francis_padilla_lebron@baxter.com"
      },
      {
        "email": "lymari_padilla_santiago@baxter.com"
      },
      {
        "email": "yolanda_pagan_rodriguez@baxter.com"
      },
      {
        "email": "javier_pena@baxter.com"
      },
      {
        "email": "joel_pena_torres@baxter.com"
      },
      {
        "email": "edgardo_perez_falcon@baxter.com"
      },
      {
        "email": "glidden_perez_nieves@baxter.com"
      },
      {
        "email": "ilianie_perez_ruiz@baxter.com"
      },
      {
        "email": "elsa_perez@baxter.com"
      },
      {
        "email": "grace_persia@baxter.com"
      },
      {
        "email": "luis_pinero_garcia@baxter.com"
      },
      {
        "email": "jose_polanco1@baxter.com"
      },
      {
        "email": "giovanny_quinones_cruz@baxter.com"
      },
      {
        "email": "hector_ramirez_mateo@baxter.com"
      },
      {
        "email": "ana_ramos_miranda@baxter.com"
      },
      {
        "email": "jeovanny_ramos@baxter.com"
      },
      {
        "email": "william_ramos@baxter.com"
      },
      {
        "email": "edward_rentas_diaz@baxter.com"
      },
      {
        "email": "ramon_reyes_serrano@baxter.com"
      },
      {
        "email": "andres_fernando_riascos@baxter.com"
      },
      {
        "email": "luis_rivera@baxter.com"
      },
      {
        "email": "pedro_rivera_enchautegui@baxter.com"
      },
      {
        "email": "desiree_rivera_gonzalez@baxter.com"
      },
      {
        "email": "yamil_a_rivera_gonzalez@baxter.com"
      },
      {
        "email": "luz_e_rivera@baxter.com"
      },
      {
        "email": "omar_rivera_rosado@baxter.com"
      },
      {
        "email": "edna_rivera_amaro@baxter.com"
      },
      {
        "email": "lydia_rivera_berrios@baxter.com"
      },
      {
        "email": "angel_rivera_garcia@baxter.com"
      },
      {
        "email": "juan_rivera_gonzalez@baxter.com"
      },
      {
        "email": "jesus_rivera_guzman@baxter.com"
      },
      {
        "email": "yolanda_rivera_lopez@baxter.com"
      },
      {
        "email": "miguel_rivera_medina@baxter.com"
      },
      {
        "email": "roberto_rivera_pagan@baxter.com"
      },
      {
        "email": "jorge_rivera_ramos@baxter.com"
      },
      {
        "email": "jose_rivera_santini@baxter.com"
      },
      {
        "email": "brian_rivera_vazquez@baxter.com"
      },
      {
        "email": "margie_rivera_ruiz@baxter.com"
      },
      {
        "email": "gloribel_roche@baxter.com"
      },
      {
        "email": "erick_rocher@baxter.com"
      },
      {
        "email": "janluis_rodriguez_maldonado@baxter.com"
      },
      {
        "email": "saul_rodriguez@baxter.com"
      },
      {
        "email": "jose_rodriguez_ortiz@baxter.com"
      },
      {
        "email": "irving_rodriguez_caraballo@baxter.com"
      },
      {
        "email": "cesar_rodriguez_cartagena@baxter.com"
      },
      {
        "email": "marilolie_rodriguez_malave@baxter.com"
      },
      {
        "email": "naylin_rodriguez_sanguinetti@baxter.com"
      },
      {
        "email": "juan_rodriguez_suliveras@baxter.com"
      },
      {
        "email": "carmen_rodriguez@baxter.com"
      },
      {
        "email": "hector_rodriguez_velez@baxter.com"
      },
      {
        "email": "evat_rodriguez@baxter.com"
      },
      {
        "email": "german_rojas_rodriguez@baxter.com"
      },
      {
        "email": "edgar_roldan@baxter.com"
      },
      {
        "email": "nicolas_rolon_rodriguez@baxter.com"
      },
      {
        "email": "sheila_roman_blasini@baxter.com"
      },
      {
        "email": "luis_romero@baxter.com"
      },
      {
        "email": "marylin_roque@baxter.com"
      },
      {
        "email": "yazmin_rosado_alicea@baxter.com"
      },
      {
        "email": "peter_rosario@baxter.com"
      },
      {
        "email": "shakira_rosario_quinones@baxter.com"
      },
      {
        "email": "ismael_ruiz_rivera@baxter.com"
      },
      {
        "email": "sanchez_rivera_giana@baxter.com"
      },
      {
        "email": "kermit_sanchez_vazquez@baxter.com"
      },
      {
        "email": "jenniffer_sanchez_torres@baxter.com"
      },
      {
        "email": "idelis_sanchez_vega@baxter.com"
      },
      {
        "email": "luis_sanchez@baxter.com"
      },
      {
        "email": "gloria_sandell_caraballo@baxter.com"
      },
      {
        "email": "alyssa_santiago_constantino@baxter.com"
      },
      {
        "email": "kermit_santiago_rivera@baxter.com"
      },
      {
        "email": "angel_santiago_felix@baxter.com"
      },
      {
        "email": "adaline_santiago_perez@baxter.com"
      },
      {
        "email": "erick_santiago_quesada@baxter.com"
      },
      {
        "email": "antonio_santiago_rodriguez@baxter.com"
      },
      {
        "email": "luis_santiago_vazquez@baxter.com"
      },
      {
        "email": "carlos_r_santiago@baxter.com"
      },
      {
        "email": "carlos_t_santiago@baxter.com"
      },
      {
        "email": "diana_santiago@baxter.com"
      },
      {
        "email": "luz_maritza_santiago@baxter.com"
      },
      {
        "email": "karla_santos_colon@baxter.com"
      },
      {
        "email": "wendy_santos_de_leon@baxter.com"
      },
      {
        "email": "nilsa_serrano@baxter.com"
      },
      {
        "email": "irene_serrano@baxter.com"
      },
      {
        "email": "stephanie_solis_amaro@baxter.com"
      },
      {
        "email": "miguel_solivan_colon@baxter.com"
      },
      {
        "email": "miguel_soto_cruz@baxter.com"
      },
      {
        "email": "ivan_suarez@baxter.com"
      },
      {
        "email": "david_torres@baxter.com"
      },
      {
        "email": "maria_torres_sanchez@baxter.com"
      },
      {
        "email": "damaris_torres_cordero@baxter.com"
      },
      {
        "email": "rafael_torres_cruz@baxter.com"
      },
      {
        "email": "maria_torres_gomez@baxter.com"
      },
      {
        "email": "jose_torres_iraola@baxter.com"
      },
      {
        "email": "carlos_j_torres@baxter.com"
      },
      {
        "email": "zue_torres_maldonado@baxter.com"
      },
      {
        "email": "onaida_torres@baxter.com"
      },
      {
        "email": "yanixa_torres_mercado@baxter.com"
      },
      {
        "email": "jose_torres-vazquez@baxter.com"
      },
      {
        "email": "carlos_r_torres@baxter.com"
      },
      {
        "email": "darlenne_torres@baxter.com"
      },
      {
        "email": "erick_torres@baxter.com"
      },
      {
        "email": "ricardo_i_torres@baxter.com"
      },
      {
        "email": "jose_valentin_morales@baxter.com"
      },
      {
        "email": "edgardo_valentin_gutierrez@baxter.com"
      },
      {
        "email": "lussan_valentin_martinez@baxter.com"
      },
      {
        "email": "brendaliz_valentin@baxter.com"
      },
      {
        "email": "heidee_valles_vazquez@baxter.com"
      },
      {
        "email": "hector_vazquez_alvarado@baxter.com"
      },
      {
        "email": "marisol_vazquez_dinguis@baxter.com"
      },
      {
        "email": "nancy_vazquez@baxter.com"
      },
      {
        "email": "denise_vazquez_velez@baxter.com"
      },
      {
        "email": "johnny_vazquez_morales@baxter.com"
      },
      {
        "email": "julia_vazquez@baxter.com"
      },
      {
        "email": "gabriel_vega1@baxter.com"
      },
      {
        "email": "alexander_vega_morales@baxter.com"
      },
      {
        "email": "hector_vega_rivera@baxter.com"
      },
      {
        "email": "guayte_vigo_gonzalez@baxter.com"
      },
      {
        "email": "ricardo_wloczuk_davila@baxter.com"
      },
      {
        "email": "luis_yordan_frau@baxter.com"
      },
      {
        "email": "miguel_zayas@baxter.com"
      },
      {
        "email": "yamilka_zayas@baxter.com"
      }
    ]
  
    ideas.forEach((idea) => {
      const proponentObj = idea.get('proponentObj');
      if (proponentObj) {
        const email = proponentObj.get('email');
        if (!usersWithIdeas.includes(email)) {
          usersWithIdeas.push(email);
        }
      }
    });
  
    const emailsToDelete = users.filter((user) => {
      const email = user.get('email');
      const domain = email.split('@')[1];
      return !usersWithIdeas.includes(email) && !email.includes('cognitiocorporation') && !email.includes('murmuratto') && !emailList.some(obj => obj.email === email);
    }).map((user) => user);
  
  
     
  
    emailsToDelete.map((user) => {
      this.deleteUser(user)
    });
  
    // const results = await Promise.all(promises);
    // alert('Finished deleting users not active.')
  
    // return results;
  }
  
  deleteUser(user) {
    
    
      user.destroy({ useMasterKey: true}).then((item) => {
         
      })
    }
  
  

    render() {
      const {t} = this.props;
      const { numCompleted, numInProgress, numPendingEval} = this.state;
       
      const labels = ['Completed Ideas', 'Ideas on Progress', 'Pending for Evaluation']
      const datasets = [{
        data: [Number.parseInt(numCompleted), Number.parseInt(numInProgress), Number.parseInt(numPendingEval)],
        backgroundColor: ['#3f8cf4', '#2961a7', '#ed7333', '#5e40d2', '#7ec2fa', '#9dd64c' ]
      }]

      
      var smallStats = [
        {
          label: t('SUBMITTED_IDEAS'),
          value: this.state.numSubmitted,
          percentage: "12.4%",
          increase: true,
          chartLabels: [null, null, null, null, null],
          decrease: false,
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: colors.primary.toRGBA(0.1),
              borderColor: colors.primary.toRGBA(),
              data: [9, 3, 3, 9, 9]
            }
          ]
        },
        {
          label: t('COMPLETED_IDEAS'),
          value: this.state.numCompleted,
          percentage: "7.21%",
          increase: false,
          chartLabels: [null, null, null, null, null],
          decrease: true,
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: colors.success.toRGBA(0.1),
              borderColor: colors.success.toRGBA(),
              data: [3.9, 4, 4, 9, 4]
            }
          ]
        },
        {
          label: t('IDEAS_ON_PROGRESS'),
          value: this.state.numInProgress,
          percentage: "3.71%",
          increase: true,
          chartLabels: [null, null, null, null, null],
          decrease: false,
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: colors.warning.toRGBA(0.1),
              borderColor: colors.warning.toRGBA(),
              data: [6, 6, 9, 3, 3]
            }
          ]
        },
        {
          label: t('PENDING_FOR_EVALUATION'),
          value: this.state.numPendingEval,
          percentage: "3.71%",
          increase: true,
          chartLabels: [null, null, null, null, null],
          decrease: false,
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: colors.warning.toRGBA(0.1),
              borderColor: colors.warning.toRGBA(),
              data: [6, 6, 9, 3, 3]
            }
          ]
        },
      ];

      return (
        <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      {/* Page Header :: Title */}
      <PageTitle title={t('VIEW_REPORTS')} subtitle={t('REPORTS')} className="text-sm-left mb-3" />

      {/* Page Header :: Actions */}
      

      {/* Page Header :: Datepicker */}
      {/* <Col sm="4" className="d-flex">
        <RangeDatePicker className="justify-content-end" />
      </Col> */}
    </Row>

    {/* Small Stats Blocks */}
    <Row>
      {smallStats.map((stats, idx) => (
        <Col key={idx} md="6" lg="3" className="mb-4">
          <Card>
            <CardBody>
              <CardSubtitle>{stats.label}</CardSubtitle>
                <h3>{stats.value}</h3>
            </CardBody>
          </Card>
        </Col>
        ))}

      <Col lg="6" sm="12" className="mb-4">
        <Card>
        <CardBody>
          <CardTitle>{t('IDEAS_BY_PROGRESS')}</CardTitle>
          <div
            style={{
              width: '400px',
              height: '300px'
            }}
          >
             <Pie data={{labels: labels, datasets: datasets}}  options={{ maintainAspectRatio: false, responsive: 'true' }} />
          </div>
         
        </CardBody>
        <CardFooter>
          {/* <Button>{t('DOWNLOAD_DATA')} &rarr;</Button> */}
          </CardFooter>
      </Card>
          {/* <Chart data={data} axes={axes} /> */}
      </Col>
        
       <Col lg="6" sm="12" className="mb-4">
       <Card>
      <CardBody>
        <CardTitle>{t('IDEAS_BY_DEPARTMENT')}</CardTitle>
        {this.state.showIdeasByDeptChart &&<MyChart height={80} width={80} initialWidth={80} data={this.state.ideaByDepartmentData}></MyChart>}
      </CardBody>
      <CardFooter>
        </CardFooter>
    </Card>
        
      </Col>

      <Col lg="6" sm="12" className="mb-4">
       <Card>
      <CardBody>
        <CardTitle>{t('PROGRESS_BY_CATEGORY')}</CardTitle>
        {this.state.showProgressByCatChart && <ProgressPerCategoryChart data={this.state.progressByCategoryData}></ProgressPerCategoryChart>}
      </CardBody>
      <CardFooter>
        
        </CardFooter>
    </Card>
        
      </Col>

      {/* Earnings */}
      <Col lg="6" sm="12" className="mb-4">
       <Card>
      <CardBody>
        <CardTitle>{t('EXPECTED_EARNINGS_BY_CATEGORY')}</CardTitle>
        {this.state.showChart && <ProgressPerCategoryChart data={this.state.returnsByCategoryData}></ProgressPerCategoryChart>}
      </CardBody>
      <CardFooter>
       
        </CardFooter>
    </Card>
       
      </Col>

      {/* Actual Earnings */}
      <Col lg="6" sm="12" className="mb-4">
       <Card>
      <CardBody>
        <CardTitle>{t('ACTUAL_EARNINGS_BY_CATEGORY')}</CardTitle>
        {this.state.showChartActual && <ProgressPerCategoryChart data={this.state.actualReturnsByCategoryData}></ProgressPerCategoryChart>}
      </CardBody>
      <CardFooter>
       
        </CardFooter>
    </Card>
        
      </Col>
    </Row>
    
  </Container>
      )
    }
  }
// const Analytics = ({ smallStats }) => (
//   <Container fluid className="main-content-container px-4">
//     <Row noGutters className="page-header py-4">
//       {/* Page Header :: Title */}
//       <PageTitle title="Ver Reportes" subtitle="Reportes" className="text-sm-left mb-3" />

//       {/* Page Header :: Actions */}
//       {/* <Col xs="12" sm="4" className="col d-flex align-items-center">
//         <ButtonGroup size="sm" className="d-inline-flex mb-3 mb-sm-0 mx-auto">
//           <Button theme="white" tag={NavLink} to="/analytics">
//             Personal
//           </Button>
//           <Button theme="white" tag={NavLink} to="/ecommerce">
//             Admin
//           </Button>
//         </ButtonGroup>
//       </Col> */}

//       {/* Page Header :: Datepicker */}
//       {/* <Col sm="4" className="d-flex"> */}
//         {/* <RangeDatePicker className="justify-content-end" /> */}
//       {/* </Col> */}
//     </Row>

//     {/* Small Stats Blocks */}
//     <Row>
//       {smallStats.map((stats, idx) => (
//         <Col key={idx} md="6" lg="4" className="mb-4">
//           <SmallStats
//             id={`small-stats-${idx}`}
//             chartData={stats.datasets}
//             chartLabels={stats.chartLabels}
//             label={stats.label}
//             value={stats.value}
//             percentage={stats.percentage}
//             increase={stats.increase}
//             decrease={stats.decrease}
//           />
//         </Col>
//       ))}
//     </Row>

//     <Row>
//       {/* Top Referrals */}
//       <Col lg="6" sm="6" className="mb-4">
//         <IdeasTable/>
//         {/* <UserPerformance/> */}
//       </Col>

//       {/* Goals Overview */}
//       <Col lg="6" className="mb-4">
//         <GoalsOverview title="Idea Progress"/>
//       </Col>
//     </Row>
//   </Container>
// );

Analytics.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

Analytics.defaultProps = {
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default withTranslation()(Analytics);