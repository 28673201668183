import React from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';

const LineChart = ({ data, type }) => {
    const firstSeriesName = type === 'evaluation' ? 'Ideas Reviewed' : 'Econ FCST';
  const [options, setOptions] = React.useState({
    chart: {
      type: 'line',
      height: 200,
      toolbar: {
        show: false,
      },
    },
    series: [{
      name: firstSeriesName,
      data: data[0].data,
      markers: {
        size: 6,
        dataLabels: {
          enabled: true
        }
      }
    }, {
      name: 'Ideas Approved',
      data: data[1].data,
      markers: {
        size: 6,
        dataLabels: {
          enabled: true
        }
      }
    }],
    // Always show from min to max
    
    xaxis: {
      categories: Array.from(Array(7)).map((_, i) => moment().subtract(1, 'month').subtract(i, 'month').format('MMM')).reverse(),
    },
  });

  return <Chart options={options} series={options.series} type="line" height={200} />;
};

export default LineChart;
