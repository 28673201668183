import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { Chart } from 'react-charts'
import {
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
  CardFooter,
} from "shards-react";
import Parse from 'parse';
import colors from "../utils/colors";
import {withTranslation} from 'react-i18next';
import UserSessionsChart from "./UserSessionsChart";
import Topics from "./Topics";
import EnpsChart from "./EnpsChart";
import SentimentBarChart from "./SentimentBarChart";

const blueColor = '#3A7BBB'
const orangeColor = '#FD902c'
const greenColor = '#7FA86F'
const goldColor = '#DDB153'

class Intelligence extends React.Component { 

  constructor(props) {
    super(props)

    this.state = {
      ideas:[],
      numSubmitted: '',
      numCompleted:'',
      numInProgress:'',
      numPendingEval: '',
      departments: '',
      categories: '',
      ideaByDepartmentData: [],
      progressByCategoryData:[],
      returnsByCategoryData:[],
      actualReturnsByCategoryData:[],
      completedIdeas:[],
      completeIdeas:[],
      ideasInProgress:[],
      ideasPerCats:[],
      ideaResults:[],
      showChart: false,
      showIdeasByDeptChart: false,
      showProgressByCatChart: false,
      showEarningsByCatChart: false,
      exportData:{},
      ideaDataCsv: [],
      verifications: [],
      showChartActual: false,
    }
    
    this.getIdeas = this.getIdeas.bind(this);
    this.getResults = this.getResults.bind(this);
    this.getDepartments = this.getDepartments.bind(this);
    this.getCategories = this.getCategories.bind(this);
  }

  componentWillMount() {
    this.getIdeas();
    this.getResults();
    // this.getDepartments();
  }

  async getVerification() {
    const VerifyObject = Parse.Object.extend("Verification");
    const displayLimit = 1000;
    const query = new Parse.Query(VerifyObject);
    query.limit(displayLimit)
    const results = await query.find();
    
    const editedResults = results.map((verification) => {
      const idea = this.state.ideas.find(idea => idea.id === verification.get("idea").id);
      verification.set("category", idea.get("category"))
      verification.category = idea.get("category")
      return verification
    })
    this.setupActualEarningsByCategoryData(editedResults)
    // this.setState({verifications: editedResults}, this.setupActualEarningsByCategoryData())
  }

  async getIdeas() {
    const IdeaObject = Parse.Object.extend("Idea");
    const displayLimit = 1000;
    const query = new Parse.Query(IdeaObject);
    query.limit(displayLimit)
    const results = await query.find();
    const completedIdeas = results.filter(idea => idea.get("progress")[0] == 100);
    const ideasInProgress = results.filter(idea => idea.get("progress")[0] > 0 && idea.get("progress")[0] < 100);
    const ideasPendingEval = results.filter(idea => idea.get("needsEvaluation") == true);
    
    // query.equalTo("needsEvaluation", true);
    // query.equalTo("completed", false);
    // query.descending("createdAt");

    this.setState({ideas: results,
      completedIdeas: completedIdeas, 
      ideasInProgress: ideasInProgress, 
      numCompleted: String(completedIdeas.length),
      numInProgress: String(ideasInProgress.length),
      numSubmitted: String(results.length), 
      numPendingEval: String(ideasPendingEval.length),
    }, () => {
      this.getDepartments()
    })
  }

  async getResults() {
    const IdeaObject = Parse.Object.extend("Result");
    const query = new Parse.Query(IdeaObject);
    const results = await query.find();
    this.setState({ideaResults: results});
  }

  async getCategories() {
    const Category = Parse.Object.extend("IdeaCategory");
    const query = new Parse.Query(Category);
    const results = await query.find();
    this.setState({categories: results}, () => this.setupProgressByCategoryData());
  }

  async getDepartments() {
    this.getCategories();
    // Modify Idea Data
    this.downloadIdeaData()
    const Department = Parse.Object.extend("IdeaDepartment");
    const query = new Parse.Query(Department);
    const results = await query.find();
    this.setState({departments: results}, () => {
      this.getVerification();
      this.setupIdeaByDepartmentData()});
  }

  setupIdeaByDepartmentData() {
    const {ideas, categories, completedIdeas, ideasInProgress, departments, numCompleted, numSubmitted, numInProgress} = this.state;
    var inProgressData = [];
    // var completedData = [];
    for (var i in departments) {
      const deptName = departments[i].get("itemName");
      const filteredDataCount = ideasInProgress.filter(idea => idea.get("department") == deptName)
      const newNum = filteredDataCount.length;
      inProgressData.push([deptName, newNum])
    }

    var completedData = [];
    var completedIdeas1 = [];
    var colors = [greenColor, blueColor, orangeColor, goldColor, 'red'];
    for (var i in departments) {
      const deptName = departments[i].get("itemName");
      const filteredDataCount = completedIdeas.filter(idea => idea.get("department") == deptName)
      const newNum = filteredDataCount.length;
      completedData.push([deptName, newNum]);
      completedIdeas1.push({ title: deptName, value: newNum, color: colors[i]});
    }

    var ideasPerCategory = []
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName)
      const newNum = filteredDataCount.length;
      ideasPerCategory.push({ title: categoryName, value: newNum, color: colors[i]});
    }

    var submittedData = [];
    for (var i in departments) {
      const deptName = departments[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("department") == deptName)
      const newNum = filteredDataCount.length;
      submittedData.push([deptName, newNum])
    }
    
    const exportData = {
      'Submitted': submittedData,
      'In Progress':ideasPerCategory,
      "Completed":completedData
    }

     
    

    const results = [{
      label: 'Completed',
      data: completedData
    },
    {
      label: 'In Progress',
      data: inProgressData
    },
    {
      label: 'Submitted',
      data: submittedData
    }, []];

     
    this.setState({ideaByDepartmentData: results, exportData: ideas, completeIdeas: completedIdeas1, ideasPerCats: ideasPerCategory, showIdeasByDeptChart: true});
  }

  setupProgressByCategoryData() {

    this.setupEarningsByCategoryData()
    
    const {ideas, completedIdeas, ideasInProgress, departments, progressByCategoryData, categories} = this.state;
     
    var zeroData = [];
    // var completedData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 0)
      const newNum = filteredDataCount.length;
      zeroData.push([categoryName, newNum])
    }

    var twentyFiveData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 25)
      const newNum = filteredDataCount.length;
      twentyFiveData.push([categoryName, newNum])
    }

    var fiftyData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 50)
      const newNum = filteredDataCount.length;
      fiftyData.push([categoryName, newNum])
    }

    var seventyFiveData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 75)
      const newNum = filteredDataCount.length;
      seventyFiveData.push([categoryName, newNum])
    }

    var hundredData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea =>  idea.get("category") == categoryName && idea.get("progress")[0] == 100)
      const newNum = filteredDataCount.length;
      hundredData.push([categoryName, newNum])
    }

    const results = [{
      label: '0%',
      data: zeroData
    },
    {
      label: '25%',
      data: twentyFiveData
    },
    {
      label: '50%',
      data: fiftyData
    },
    {
      label: '75%',
      data: seventyFiveData
    },
    {
      label: '100%',
      data: hundredData
    }, []];
     

    this.setState({progressByCategoryData: results, showProgressByCatChart: true});
  }

  downloadIdeaData() {
    const modifiedData = this.state.ideas.map((idea) => {
      const newData = {
        "Idea Originator": idea.get("proponentName"),
        "Idea Number": idea.get("num"),
        "Idea Title": idea.get("title"),
        "Description": idea.get("description"),
        "Idea Category": idea.get("category"),
        "Idea Type": idea.get("ideaType"),
        "Department": idea.get("department"),
        "Submitted": idea.get("date"),
        "Idea Stage": idea.get("status"),
        "Implementation Owner": idea.get("responsibleName"),
        "Implementation Status (Progress %)": idea.get("progress")[0],
      }

      return(newData)
    })
     
    this.setState({ideaDataCsv: modifiedData})
  }

  setupEarningsByCategoryData() {
    const {ideas, completedIdeas, ideasInProgress, departments, progressByCategoryData, categories} = this.state;
     
    var zeroData = [];
    // var completedData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName)
      var earnings = 0
      for (var index in filteredDataCount) {
        // earnings = earnings + filteredDataCount[index]
       earnings = earnings + filteredDataCount[index].get("expectedReturn")
      }
      
      zeroData.push([categoryName, earnings])
       
    }

    const results = [{
      label: 'Returns',
      data: zeroData
    }, []];
    
     
    this.setState({returnsByCategoryData: results, showChart: true});
  }

  setupActualEarningsByCategoryData(verifications) {
    const {ideas, categories} = this.state;
    
    var zeroData = [];
    // var completedData = [];
     
    
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      
      const filteredDataCount = verifications.filter(verification => verification.get("category") == categoryName)
       
       
      
      var earnings = 0

      for (var index in filteredDataCount) {
        // earnings = earnings + filteredDataCount[index]
        //  
        earnings = earnings + filteredDataCount[index].get("money")
      }

       
      zeroData.push([categoryName, earnings])
       
    }

    const results = [{
      label: 'Returns',
      data: zeroData
    }, []];
    
     
    this.setState({actualReturnsByCategoryData: results, showChartActual: true});
  }

    render() {
      const {t} = this.props;
      const { numCompleted, numInProgress, numPendingEval} = this.state;
       
      const labels = ['Completed Ideas', 'Ideas on Progress', 'Pending for Evaluation']
      const datasets = [{
        data: [Number.parseInt(numCompleted), Number.parseInt(numInProgress), Number.parseInt(numPendingEval)],
        backgroundColor: ['blue', 'green', 'red']
      }]
      const isPmo = Parse.User.current().get("pmo") == true

      
      var smallStats = [
        {
          label: 'Sentiment',
          value: 'Neutral',
          percentage: "12.4%",
          increase: true,
          chartLabels: [null, null, null, null, null],
          decrease: false,
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: colors.primary.toRGBA(0.1),
              borderColor: colors.primary.toRGBA(),
              data: [9, 3, 3, 9, 9]
            }
          ]
        },
        {
          label: 'eNPS',
          value: 9.2,
          percentage: "7.21%",
          increase: false,
          chartLabels: [null, null, null, null, null],
          decrease: true,
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: colors.success.toRGBA(0.1),
              borderColor: colors.success.toRGBA(),
              data: [3.9, 4, 4, 9, 4]
            }
          ]
        },
      ];

      return (
        <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      {/* Page Header :: Title */}
      <PageTitle title={t('VIEW_REPORTS')} subtitle={t('REPORTS')} className="text-sm-left mb-3" />

      {/* Page Header :: Actions */}
      

      {/* Page Header :: Datepicker */}
      {/* <Col sm="4" className="d-flex">
        <RangeDatePicker className="justify-content-end" />
      </Col> */}
    </Row>

    {/* Small Stats Blocks */}
    <Row>
      {smallStats.map((stats, idx) => (
        <Col key={idx} md="12" lg="3" className="mb-4">
          <Card>
            <CardBody>
              <CardSubtitle>{stats.label + ' (January)'}</CardSubtitle>
                <h3>{stats.value}</h3>
            </CardBody>
          </Card>
        </Col>
        ))}

      <Col lg="12" sm="12" className="mb-4">
        <Card>
        <CardBody>
          <CardTitle>{'Trending Topics'}</CardTitle>
          <Topics topics={[]}></Topics>
         
        </CardBody>
        <CardFooter>
          {/* <Button>{t('DOWNLOAD_DATA')} &rarr;</Button> */}
          </CardFooter>
      </Card>
          {/* <Chart data={data} axes={axes} /> */}
      </Col>
        
       <Col lg="12" sm="12" className="mb-4">
       <Card>
      <CardBody>
        <CardTitle>{'User Sessions'}</CardTitle>
       <UserSessionsChart></UserSessionsChart>
      </CardBody>
      <CardFooter>
        </CardFooter>
    </Card>
        
      </Col>

      <Col lg="6" sm="12" className="mb-4">
       <Card>
      <CardBody>
        <CardTitle>{'Sentiment'}</CardTitle>
        <SentimentBarChart></SentimentBarChart>
      </CardBody>
      <CardFooter>
        
        </CardFooter>
    </Card>
        
      </Col>

      {/* Earnings */}
      <Col lg="6" sm="12" className="mb-4">
       <Card>
      <CardBody>
        <CardTitle>{'eNPS'}</CardTitle>
       <EnpsChart></EnpsChart>
        
      </CardBody>
      <CardFooter>
       
        </CardFooter>
    </Card>
       
      </Col>
    </Row>
    
  </Container>
      )
    }
  }


Intelligence.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

Intelligence.defaultProps = {
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default withTranslation()(Intelligence);