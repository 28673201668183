import moment from 'moment';
import Parse from 'parse';
import React from "react";
import Select from 'react-select';
import {
  Col,
  Form,
  FormCheckbox,
  FormInput,
  Row
} from "shards-react";

import { object } from "prop-types";
import EmployeeSelect from "./EmployeeSelect";
import ExecutionSelectNew from "./ExecutionSelectNew";



// Defult
import { ReactComponent as AcceptIcon } from "../../images/accept_button.svg";
import { ReactComponent as CancelIcon } from "../../images/cancel_button.svg";
import { ReactComponent as DollarImage } from "../../images/dollar-symbol.svg";
import { ReactComponent as HandImage } from "../../images/hand.svg";
import { ReactComponent as ChartImage } from "../../images/line-chart.svg";
import { ReactComponent as ShieldImage } from "../../images/shield.svg";
import { ReactComponent as TeamImage } from "../../images/team.svg";
import { ReactComponent as TimeImage } from "../../images/time.svg";

// New
import { ReactComponent as UrgentImage } from '../../images/Icons_Idle_01_Urgent.svg';
import { ReactComponent as ProductivityImage } from "../../images/Icons_Idle_02_Productivity.svg";
import { ReactComponent as TrophyImage } from "../../images/Icons_Idle_04_Trophy.svg";
import { ReactComponent as Shield2Image } from "../../images/Icons_Idle_05_Shield.svg";
import { ReactComponent as DollarSignImage } from "../../images/Icons_Idle_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImage } from "../../images/Icons_Idle_07_Number One.svg";
import { ReactComponent as CheckmarkImage } from "../../images/check1.svg";
import selectIdeaImage from '../../images/selected.png';

// New Selected
import { withTranslation } from 'react-i18next';
import { ReactComponent as UrgentImageSelected } from '../../images/Icons_Selected_01_Urgent.svg';
import { ReactComponent as ProductivityImageSelected } from "../../images/Icons_Selected_02_Productivity.svg";
import { ReactComponent as TrophyImageSelected } from "../../images/Icons_Selected_04_Trophy.svg";
import { ReactComponent as Shield2ImageSelected } from "../../images/Icons_Selected_05_Shield.svg";
import { ReactComponent as DollarSignImageSelected } from "../../images/Icons_Selected_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImageSelected } from "../../images/Icons_Selected_07_Number One.svg";
import { ReactComponent as CheckmarkImageSelected } from "../../images/check1_selected.svg";
import administrationService from '../../services/administrationService';
import categoryService from '../../services/categoryService';
import DepartmentSelectBeta from "./DepartmentSelectBeta";
import SimpleCategorySelectBeta from "./SimpleCategorySelectBeta";
import ResponseRequired from './ResponseRequired';

class EditCommitteeForm extends React.Component {
  constructor(props) {
    super(props);

    // Refs
    this.galleryRef = React.createRef();

    this.state = {
      data: [],
      ideaQuestions: [],
      answers: [],
      category: null,
      isNew: false,
      department: null,
      date: '',
      categoryQuestions: [],
      filterQuestions: [],
      selectedFilterQ: [],
      filterQAnswers: [],
      categoryQAnswers: [],
      ideaDescription: null,
      file: null,
      buttonNext: true,
      descriptionValid: '',
      remainingCharacters: 250,
      visible: true,
      filterVisible: false,
      filterQuestionsVisible: false,
      ideaQuestionsVisible: false,
      hideNextButton: false,
      userName: 'User Name',
      sectionTitle: '',
      formButtonTitle: 'Continuar',
      ideaNumber: '#00008',
      status: '',
      executionResObj: object,
      selectedBenefit: '',
      selectedResult: '',
      money: '',
      selectedImprovement: '',
      selectedImpact: '',
      selectedCoachBackup: '',
      isRecognized: '',
      page: 2,
      responseInfo: false,
      responseInfo2: false,
      selectedStatus: ''.length,
      expectedReturn: '',
      timeUnit: '',
      language: 'en',
      executionRes: 0,
      coachRes: '',
      recurringImpact: false,
      comment: '',
      categoryDuration: false,
      startDate: '',
      endDate: '',
      selectedLanguage: {
        value: 'English',
        label: 'English'
      },
      categoryTitle: '',
      categoryTitleSpanish: '',
      categoryInformation: '',
      categoryInformationSpanish: '',
      hasEnglish: false,
      hasSpanish: false,
      employees: [],
      committeeTitle: '',
      members: [],
      filterValue: '',
      isTransferCommittee: false,
      committeeId: '',

    }

    this.change = this.change.bind(this);
    this.setCategory = this.setCategory.bind(this);
    this.setDate = this.setDate.bind(this);
    this.setIdeaDescription = this.setIdeaDescription.bind(this);
    this.selectFile = this.selectFile.bind(this);
    this.showNext = this.showNext.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.saveIdea = this.saveIdea.bind(this);
    this.showNext = this.showNext.bind(this);
    this.changeMoney = this.changeMoney.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.setEvalStatus = this.setEvalStatus.bind(this);
    this.setCategoryTitle = this.setCategoryTitle.bind(this);
    this.setLanguage = this.setLanguage.bind(this);

  }

  componentDidUpdate(prevProps) {
    if (prevProps.canSubmit !== this.props.canSubmit) {
      this.submitEvaluation()
    }
  }

  async componentDidMount() {
    await this.loadInitialSettings()


  }

  async getAllUsers() {
    var query = new Parse.Query(Parse.User);
    const results = await query.findAll();
    return results
  }

  async loadInitialSettings() {
    const { categoryData } = this.props;

    const categoryName = categoryData.title

    let cMembers;
    if (categoryData.members) {
      cMembers = categoryData.members.map((item) => {
        return { value: item.id, label: item.title }
      })
    }

    var selectedDepartment = []
    var selectedCategory = []
    var selectedFilterType = []

    if (categoryData.departments) {
      selectedDepartment = categoryData.departments.map((item) => {
        return { value: item.id, label: item.title }
      })
    }

    if (categoryData.categories) {
      selectedCategory = categoryData.categories.map((item) => {
        return { value: item.id, label: item.title }
      })
    }

    if (categoryData.types) {
      selectedFilterType = categoryData.types.map((item) => {
        return { value: item.id, label: item.title }
      })
    }

    const committeeId = categoryData.id ? categoryData.id : ''
    const cLeader = categoryData.leader ? categoryData.leader : ''
    const isTransCom = categoryData.transferCommittee ? true : false
    console.log('categoryData', categoryData)
    let isNew = false
    if (!categoryData.title) isNew = true
    const commLeader = { value: cLeader.id, label: cLeader.title }
    this.setState({ isNew, committeeId, filterValue: selectedFilterType, committeeTitle: categoryName, employees: cMembers, department: selectedDepartment, category: selectedCategory, executionRes: commLeader, isTransferCommittee: isTransCom })

  }

  getDate() {
    this.setState({
      date: new Date()
    });
  }

  async getUserName(user) {
    // dvar query = new Parse.Query(Parse.User);
    // query.equalTo("objectId", user.id);
    // const results = await query.find();
    // const firstName = results[0].get('firstName');
    // const lastName = results[0].get('lastName');
    // const fullName = firstName + ' ' + lastName;
    // this.setState({
    //   userName: fullName
    // });
  }

  handleSubmit() {
    const { formButtonTitle } = this.state;

    if (formButtonTitle == "Continuar") {
      this.showNext();
    } else {
      var isInvalid = false
      this.state.answers.forEach((item, idx) => {
        //  
        if (item.required && !item.answer) {
          isInvalid = true
        }
      });

      if (isInvalid) {
        alert("Por favor conteste todas las preguntas requeridas.");
      } else {
        alert("Su IDEA ha sido sometida.");
      }
    }
  }

  showNext() {
    const isShowingForm = this.state.visible;
    const isShowingFilter = this.state.filterVisible;
    const isShowingFilterQuestions = this.state.filterQuestionsVisible;
    const isShowingQuestions = this.state.ideaQuestionsVisible;

    if (isShowingForm && !isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {

      const { category, department, descriptionValid } = this.state;

      if (!category || !department || !descriptionValid) {
        alert("Por favor seleccione una categoria, un departamento y asegurese de que la descripcion de la idea sea valida.");
      } else {
        this.setState({
          visible: !isShowingForm,
          filterVisible: !isShowingFilter,
          buttonState: false,
          hideNextButton: true,
        });
      }
    } else if (!isShowingForm && isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {
      this.setState({
        hideNextButton: false,
        filterVisible: !isShowingFilter,
        filterQuestionsVisible: !isShowingFilterQuestions,
        buttonState: false,
      });
    } else if (!isShowingForm && !isShowingFilter && isShowingFilterQuestions && !isShowingQuestions) {
      var allAnswered = false;
      //  
      //  
      var isInvalid = false
      this.state.filterQAnswers.forEach((item, idx) => {
        //  
        if (!item.answer) {
          isInvalid = true
        }
      });

      if (isInvalid) {
        alert("Por favor conteste todas las preguntas.");
      } else {
        this.setState({
          filterQuestionsVisible: !isShowingFilterQuestions,
          ideaQuestionsVisible: !isShowingQuestions,
          buttonState: false,
          formButtonTitle: 'Someter',
        });
      }
    } else if (!isShowingForm && !isShowingFilter && !isShowingFilterQuestions && isShowingQuestions) {
      //  
      var isInvalid = false
      this.state.answers.forEach((item, idx) => {
        //  
        if (item.required && !item.answer) {
          isInvalid = true
        }
      });

      if (isInvalid) {
        alert("Please answer all required questions.");
      } else {
        alert('¡Congrats! Thanks for submitting your idea.',
          this.resetIdeaForm());
      }
    }
    //  
    //  
    //  
    //  
    //  
  }

  resetIdeaForm() {
    window.location.reload();
  }


  async fetchCategories() {

    try {
      const categories = await categoryService.getCategories();

      this.setState({ data: categories });
    } catch (error) {

    }
  }


  fetchDepartments() {
    const className = "IdeaDepartment";

    var ItemClass = Parse.Object.extend(className);
    var query = new Parse.Query(ItemClass);
    query.find()
      .then((results) => {
        //  
        this.setState({
          departments: results
        });
      }, (error) => {
        this.setState({
          departments: []
        });
      });
  }

  handleFilterChange(newFilter) {
    const newCategory = newFilter;
    const { filterQuestions } = this.state;

    var filteredData;
    if (newCategory !== 'Todas') {
      filteredData = filterQuestions.filter(item => item.get("filter") === newCategory);
    } else {
      filteredData = filterQuestions;
    }

    this.setState({
      selectedFilterQ: filteredData
    }, this.addFilterAnswer(filteredData));
    //  
    this.showNext();
  }

  handleCategoryChange(selectedCategory) {
    const newCategory = selectedCategory;
    const { ideaQuestions } = this.state;

    var filteredData = ideaQuestions.filter(item => item.get("category") === newCategory);

    this.setState({
      categoryQuestions: filteredData
    }, this.addAnswer(filteredData));
  }

  onSubmit = e => {
    e.preventDefault();
    alert('Form submitted');
  };

  saveIdea() {
    // Simple syntax to create a new subclass of Parse.Object.
    var Idea = Parse.Object.extend("Idea");
    // Create a new instance of that class.
    var ideaInfo = new Idea();

    const currentUser = Parse.User.current();
    const userId = currentUser.get("username");

    // IDEA Properties
    ideaInfo.set("proponent", userId);
    ideaInfo.set("department", this.state.department);
    ideaInfo.set("category", this.state.category);
    ideaInfo.set("date", this.state.date);
    ideaInfo.set("description", this.state.ideaDescription);
    ideaInfo.set("file", this.state.file);
    ideaInfo.set("status", "saved");
    ideaInfo.set("progress", [0, 100]);
  }

  resetForm() {
    this.setState({ proponent: '', department: '', category: '', ideaDescription: '', date: new Date(), file: '', remainingCharacters: 250, descriptionValid: '' });
  }

  change(event) {
    this.setState({ department: event.target.value });
  }

  setCategory(categoryName) {
    this.setState({
      category: categoryName
    })

    this.handleCategoryChange(categoryName)
  }

  setFilter(filterName) {
    this.handleFilterChange(filterName);
  }

  clickedPrint() {

  }

  setDate(ideaDate) {
    this.setState({
      date: ideaDate
    })
    //  
  }

  filterQuestionAnswerChange(event, idx) {
    this.state.filterQAnswers[idx].answer = event.target.value;
  }

  questionAnswerChange(event, idx) {
    this.state.answers[idx].answer = event.target.value;
  }

  setIdeaDescription(event) {
    const description = event.target.value;
    const maxCharacters = 250;
    const charCount = maxCharacters - description.length
    var isValid = null;

    if (charCount < 0 && description.length > 0) {
      isValid = false
    } else if (charCount > 0 && description.length > 0) {
      isValid = true
    } else {
      isValid = null
    }

    //  
    this.setState({
      descriptionValid: isValid,
      ideaDescription: description,
      remainingCharacters: charCount
    })
    //  
  }

  selectFile(file) {
    //  
  }

  addAnswer(filteredQuestions) {
    filteredQuestions.map((item, idx) => (
      //  
      this.setState((prevState) => ({
        answers: [...prevState.answers, { question: item.get("question"), answer: "", required: item.get("required") }],
      }))
    ))
    //  
  }

  addFilterAnswer(filteredData) {
    //  

    var newItems = [];
    filteredData.forEach((item, idx) => {
      newItems.push({ question: item.get("question"), answer: '' });
    })

    this.setState({ filterQAnswers: newItems }, () => {
      //  
    });
  }

  submitEvaluation() {
    const { status, ideaDescription, userName, committeeResObj } = this.state;

    var ideaItem = this.props.ideaItem;
    var comments = ideaItem.get("comments");
    var newStatus = '';
    var percentage = [25, 75];
    var mayNeedEval = false

    // Verify idea to check if it leaves the evaluation inbox or not
    if (status == 'Proyecto' || status == 'Otro') {
      mayNeedEval = true
    }




    switch (status) {
      case "Devuelta":
        // code block
        newStatus = 'Idea Devuelta - Mas Informacion';
        break;
      case "Espera":
        // code block
        newStatus = 'Idea en Espera';
        break;
      case "No Perseguido":
        // code block
        newStatus = 'No Perseguido';
        percentage = [0, 100];
        break;
      case "Ejecutar":
        // code block
        newStatus = 'Ejecutar - Just Do It';
        break;

      case "Ejecutar Proyecto":
        // code block
        newStatus = 'Ejecutar - Just Do It - Proyecto';
        break;
      case "Proyecto":
        // code block
        newStatus = 'Idea Proyecto';
        percentage = [0, 100];
        break;
      default:
        newStatus = 'SOMETIDA';
        percentage = [0, 100];
      // code block
    }



    // Comments
    var newComment = { "comment": ideaDescription, "date": new Date(), "user": userName, "progress": [25, 75] };
    if (ideaDescription && ideaDescription.length > 0) {
      comments.push(newComment)
    }

    const responsibleName = this.state.executionRes.label;
    const resUser = this.state.executionRes;

    ideaItem.set("status", newStatus);
    ideaItem.set("comments", comments);
    ideaItem.set("needsEvaluation", mayNeedEval);
    ideaItem.set("progress", percentage);

    if (status == 'Otro') {
      var otherCom = committeeResObj.get('value');
      ideaItem.set("department", otherCom);
    } else {
      ideaItem.set("responsibleName", responsibleName);
    }

    ideaItem.set("responsible", resUser.value);
    ideaItem.set("edited", false);
    ideaItem.set("customUpdatedAt", new Date());

    if (newStatus == 'No Perseguido') {
      if (window.confirm('Did you have a conversation with your employee? If you did, please click OK.')) this.saveIdeaItem(ideaItem)
    } else {
      this.saveIdeaItem(ideaItem)
    }
  }

  saveIdeaItem(ideaItem) {
    const { setFinishedSaving } = this.props;
    Parse.Object.saveAll([ideaItem], { useMasterKey: true }).then(() => {
      if (this.state.status == 'Otro') {
        alert('Su evaluacion ha sido sometida. ¡Gracias!', setFinishedSaving());
      } else {
        setFinishedSaving()
      }
    });
  }

  changeStatus(selection) {
    this.setState({ status: selection });
  }

  changeEvaluationResponse() { }

  async getHumanResourceUsers() {
    var query = new Parse.Query(Parse.User);
    query.equalTo("humanResources", true);
    const results = await query.findAll();
    return results
  }

  async setVerificationNotifications() {
    const allUsers = await this.getHumanResourceUsers();
    for (var u in allUsers) {
      allUsers[u].increment("notificationCount");
      var responsibleNotifications = allUsers[u].get("notificationMessages");
      responsibleNotifications.unshift("'¡Congrats! You have ideas to evaluate.'");
      allUsers[u].set("notificationMessages", responsibleNotifications);
      allUsers[u].set("customUpdatedAt", new Date());
      await allUsers[u].save().catch(error => {
        // This will error, since the Parse.User is not authenticated
      });
    }
  }

  async setNotifications() {
    const responsibleMsg = '¡Congrats! Your idea has been verified.';

    var ideaItem = this.props.ideaItem;

    var responsibleUser = ideaItem.get("responsible");

    var responsibleNotifications = responsibleUser.get("notificationMessages");
    responsibleNotifications.unshift(responsibleMsg);

    responsibleUser.increment("notificationCount");
    responsibleUser.set("notificationMessages", responsibleNotifications);
    responsibleUser.set("customUpdatedAt", new Date());

    var users = new Parse.Query("Users");

    if (this.state.isRecognized) {
      await Parse.Object.saveAll(users, { useMasterKey: true }).then(() => this.setVerificationNotifications())
        .catch(error => {

        });
    } else {
      await Parse.Object.saveAll(users, { useMasterKey: true })
        .catch(error => {

        });
    }
  }

  changeBenefit(response) {
    this.setState({
      selectedBenefit: response
    });
  }

  changeResults(response) {
    this.setState({
      selectedResult: response
    });
  }

  changeImprovement(response) {
    this.setState({
      selectedImprovement: response
    });
  }

  changeMoney(response) {
    this.setState({
      money: response
    });
  }

  changeImpact(response) {
    this.setState({
      selectedImpact: response
    });
  }

  changeBackup(response) {
    this.setState({
      selectedCoachBackup: response
    });
  }

  changeMoney(response) {
    const inputMoney = parseFloat(response.target.value);

    this.setState({
      money: inputMoney
    })
  }

  changeRecognition(response) {
    this.setState({
      isRecognized: response
    });
  }

  downloadFile(file) {
    //  
    if (file != null) {
      const newWindow = window.open(file._url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    } else {
      alert('No file found...');
    }
  }

  getDate(date) {
    //  
    return moment(date).format('DD / MM / YYYY');
  }

  getIcon(name, fillColor) {

    const newIcons = [
      {
        normal: <UrgentImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <UrgentImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <ProductivityImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <ProductivityImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <CheckmarkImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <CheckmarkImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <TrophyImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <TrophyImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <Shield2Image className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <Shield2ImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <DollarSignImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <DollarSignImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
      {
        normal: <NumberOneImage className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />,
        selected: <NumberOneImageSelected className="mr-auto d-block" style={{ minWidth: 80, maxWidth: 80 }} />
      },
    ]

    switch (name) {
      case 'HandImage':
        return <HandImage className="mx-auto d-block" style={{ minWidth: 80, maxWidth: 80, fill: fillColor }} />;
      case 'ShieldImage':
        return <ShieldImage className="mx-auto d-block" style={{ minWidth: 80, maxWidth: 80, fill: fillColor }} />;
      case 'ChartImage':
        return <ChartImage className="mx-auto d-block" style={{ minWidth: 80, maxWidth: 80, fill: fillColor }} />;
      case 'TeamImage':
        return <TeamImage className="mx-auto d-block" style={{ minWidth: 80, maxWidth: 80, fill: fillColor }} />;
      case 'DollarImage':
        return <DollarImage className="mx-auto d-block" style={{ minWidth: 80, maxWidth: 80, fill: fillColor }} />;
      case 'ClockImage':
        return <TimeImage className="mx-auto d-block" style={{ minWidth: 80, maxWidth: 80, fill: fillColor }} />;
      //New Icons
      case 'Urgent':
        return newIcons[0].selected;
      case 'Productivity':
        return newIcons[1].selected;
      case 'Checkmark':
        return newIcons[2].selected;
      case 'Trophy':
        return newIcons[3].selected;
      case 'Shield':
        return newIcons[4].selected;
      case 'Dollar':
        return newIcons[5].selected;
      case 'Number One':
        return newIcons[6].selected;
      case 'Approve':
        return newIcons[0].selected;
      case 'Do not Pursue':
        return newIcons[1].selected;
      case 'Save for Later':
        return newIcons[2].selected;
      case 'Request information':
        return newIcons[3].selected;
      case 'Project Idea':
        return newIcons[4].selected;
      case 'Transfer Committee':
        return newIcons[5].selected;
      default:
        return <img src={selectIdeaImage} width="200" height="200" />//<SelectIdeaImage className="mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
    }
  }
  toggle() {
    // alert('hover')
    this.setState({
      responseInfo: !this.state.responseInfo
    });
  }

  setEvalStatus(status) {

    this.setState({
      selectedStatus: status
    })
  }

  setCategoryTitle(event) {
    const title = event.target.value;
    if (this.state.selectedLanguage.value == "English") {
      this.setState({
        committeeTitle: title,
      })
    } else {
      this.setState({
        committeeTitle: title,
      })
    }
  }

  setLanguage(unit) {

    this.setState({ selectedLanguage: unit })
    // this.loadInitialSettings()
    // if (this.state.expectedReturn && unit.label) {
    //   this.props.changeStatus(true)
    // }
  }

  changeResponsible(res, idx) {
    console.log(res)
    const commLeader = { value: res.value.id, label: res.value.fullName }
    console.log(commLeader)
    this.setState({
      executionRes: commLeader,
    });
  }

  changeCoach(res, idx) {
    this.setState({
      coachRes: res,
    });

  }

  changeCategoryInformation(res) {
    const comment = res.target.value

    if (this.state.selectedLanguage.value == "English") {
      this.setState({
        categoryInformation: comment
      })
    } else {
      this.setState({
        categoryInformationSpanish: comment
      })
    }
  }

  goNext() {
    const currIndex = this.galleryRef.current.getCurrentIndex()
    this.galleryRef.current.slideToIndex(currIndex + 1)
  }

  goPrev() {
    const currIndex = this.galleryRef.current.getCurrentIndex()
    this.galleryRef.current.slideToIndex(currIndex - 1)
  }

  async updateIdea() {
    const {
      filterValue,
      department,
      category,
      employees,
      committeeTitle,
      isTransferCommittee,
      executionRes,
      committeeId
    } = this.state;

    const { refreshIdea } = this.props;

    // Validation for committee title
    if (!committeeTitle || committeeTitle.length === 0) {
      alert('Please add a committee name.');
      return;
    }

    const departments = department && department.length > 0
      ? department.map((dep) => dep.value)
      : undefined;

    const categories = category && category.length > 0
      ? category.map((cat) => cat.value)
      : undefined;

    const types = filterValue && filterValue.length > 0
      ? filterValue.map((type) => type.value)
      : undefined;

    // Prepare committee data for submission
    let committeeData = {
      name: committeeTitle,
      membersIds: employees ? employees.map(emp => emp.value) : undefined,
      transferCommittee: isTransferCommittee,
      categoriesIds: isTransferCommittee ? [] : categories,
      departmentsIds: isTransferCommittee ? [] : departments,
      typesIds: isTransferCommittee ? [] : types,
      customUpdatedAt: new Date()
    };

    if (executionRes) {
      committeeData.leaderId = executionRes.value;
    }

    try {
      if (committeeId) {
        committeeData.committeeId = committeeId;
        await administrationService.updateCommittee(committeeData);
      } else {
        await administrationService.createCommittee(committeeData);
      }
      this.props.setFinishedSaving()
      refreshIdea();
    } catch (error) {
      if (error.response.data.error)
        alert(error.response.data.error.message);
      else if (error.response.data.message) {
        if (error.response.data.errors) {
          alert(error.response.data.errors.join('\n\n'));
        } else {
          alert(error.response.data.message);
        }
      } else
        alert(error);
    }
  }

  updatePreviousLeader = (email, newEmail, comm) => {
    const query = new Parse.Query(Parse.User);
    query.equalTo("username", email);

    if (email != newEmail) {

      const query = new Parse.Query(Parse.User);
      query.equalTo("username", email);
      query.first().then((user) => {
        user.set("commLeader", false)
        user.set("supervisedComm", "")
        user.set("customUpdatedAt", new Date());

        Parse.Object.saveAll(user, { useMasterKey: true })
          .then((result) => {
            this.updateCommLeader(newEmail, comm.get("name"))
          })
          .catch((error) => {
            alert(error)
          });

      })
    } else {
      // alert('Same')
      // this.updateDepartmentLeader(newEmail,department.get("itemNameTrans").en)
    }
  }

  updateCommLeader = async (email, categoryTitle) => {
    // User the email to get the user object from parse server and then update the department leader
    const query = new Parse.Query(Parse.User);
    query.equalTo("username", email);

    // alert('New Email: ' + email)
    query.first().then((user) => {

      user.set("commLeader", true)
      user.set("supervisedComm", categoryTitle)
      user.set("customUpdatedAt", new Date());

      // user.save({ useMasterKey: true }).then(() => alert('Saved')).catch((error) => alert(error))
      Parse.Object.saveAll(user, { useMasterKey: true })
        .then((result) => {
          // The object was saved successfully.
          // alert('Saved')
        })
        .catch((error) => {
          alert(error)
        });
    })
  }



  handleSubmit(event) {
    const { committees, value, evaluationType, questionText, selectedMembers } = this.state;

    const className = "EvaluationCommittee";
    const PropertyItem = Parse.Object.extend(className);
    const newItem = new PropertyItem();
    var parseObjs = []

    if (evaluationType == '' || value == '') {
      alert('Por favor asegurese de escoger el respectivo metodo de evaluacion.');
    } else {
      newItem.set("members", selectedMembers);
      newItem.set("evaluationType", evaluationType);
      newItem.set("value", value);
      newItem.set("name", questionText);
      newItem.set("canDelete", true);
      newItem.set("customUpdatedAt", new Date());

      Parse.Object.saveAll([newItem], { useMasterKey: true })
        .then((item) => {
          // Execute any logic that should take place after the object is saved.
          if (evaluationType == 'manage') {
            selectedMembers.forEach((obj) => {
              const member = obj.value
              if (member.get("role") != 'super_user') {
                const type = this.getType(value)

                if (value == 'PMO') {
                  member.set("pmo", true);
                }

                member.set("role", type);
                parseObjs.push(member);
              }

            });
          } else {
            selectedMembers.forEach((obj) => {
              const member = obj.value
              if (member.get("role") != 'super_user') {

                var evaluationCriteria = member.get("evaluationCriteria")
                evaluationCriteria.push(value)
                member.set("role", 'evaluation');
                member.set("evaluationCriteria", evaluationCriteria);
                parseObjs.push(member);
              }
            });
          }
          Parse.Object.saveAll(parseObjs, { useMasterKey: true }).then(() => {
            this.resetForm()
            alert('¡La operacion fue exitosa!');
          });
        }, (error) => {

          // Execute any logic that should take place if the save fails.
          // error is a Parse.Error with an error code and message.
          alert('Hubo un error en la operacion: ' + error.message);
        });
    }
  }

  getType(value) {
    switch (value) {
      case 'Evaluación':
        // code block
        return 'evaluation'
      case 'Verificación':
        // code block
        return 'verification'
      case 'Reconocimiento':
        // code block
        return 'recognition'
      case 'PMO':
        // code block
        return 'evaluation'
      default:
      // code block
    }
  }

  isIconRepeated(categoryTitle, iconName) {
    const { data } = this.state;
    if (data.some(e => e.get("icon") === iconName && e.get("itemName") !== categoryTitle)) {
      return true
    }
  }



  deleteIdea(isCancel) {

    const { categoryData, refreshIdea } = this.props;
    if (this.state.isNew) {
      refreshIdea(isCancel)
      return
    }


    const canDelete = window.confirm('Are you sure you want to delete this committee?');

    if (canDelete) {
      // Assuming categoryData contains the committee ID
      administrationService.deleteCommittee(categoryData.id)
        .then(() => {
          // Refresh the ideas or UI after successful deletion
          refreshIdea();
        })
        .catch((error) => {
          console.error('Error deleting committee:', error);
          alert('Failed to delete committee. Please try again later.');
        });
    }
  }


  changeDepartment = (department, i) => {
    this.setState({ department: department })
  }

  changeCategory = (category, i) => {
    this.setState({ category: category })
  }

  changeEmployees = (employees, i) => {
    this.setState({ employees: employees })
  }

  changeFilter = (event, action) => {
    var filterValue = event

    // alert(action.action)

    if (action.action == 'select-option') {
      const copy = [...this.state.filterValue]
      filterValue = filterValue.filter((v, i) => !v.label.includes('All '))
    }

    if (action.action == 'remove-value') {
      const copy = [...this.state.filterValue]
      filterValue = copy.filter((v, i) => v.label !== action.removedValue.label);
    }
    this.setState({ filterValue: filterValue })
  }

  render() {
    const { employees, category, department, executionRes } = this.state
    const { t } = this.props;
    const transComColSize = this.state.isTransferCommittee ? "8" : "4"

    return (
      <div className="edit-user-details" >
        <Form className="py-4"
          onSubmit={this.onSubmit}
          noValidate
        >
          <Row>
            <Col form className="ml-3">
              <FormCheckbox style={{ zIndex: 1 }} checked={this.state.isTransferCommittee} onChange={() => this.setState({ isTransferCommittee: !this.state.isTransferCommittee })}>Transfer Committee</FormCheckbox>
            </Col>

          </Row>
          {/* VISIBILITY */}
          <div >
            <Row form >
              {/* Left Part */}
              {!this.state.isTransferCommittee && <Col lg="4" className="mx-auto">
                <Row form>
                  {/* Proponent */}
                  <Col md="12" className="form-group">
                    <Row className="mt-2">
                      <Col>
                        <label htmlFor="firstName" className="georgia">Department: </label>

                        <DepartmentSelectBeta className="insideFont" evalType={'execution'} type="Committee" setResponsible={(res, idx) => this.changeDepartment(res, idx)} selectedVal={department} isMurmurattoUser={false} />

                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <label htmlFor="firstName" className="georgia">Category: </label>
                        <SimpleCategorySelectBeta className="insideFont" evalType={'execution'} type="Committee" setResponsible={(res, idx) => this.changeCategory(res, idx)} selectedVal={category} />
                      </Col>
                    </Row>


                  </Col>

                </Row>
              </Col>}

              {/* Right Part */}



              <Col lg={transComColSize} className="mx-auto">
                <Row form className="mt-2">
                  <Col md="12" className="form-group">
                    <label htmlFor="firstName" className="georgia">Committee Name: </label>
                    {/* <IdeaStatusSelect setEvalStatus={this.setEvalStatus}></IdeaStatusSelect> */}
                    {/* <ImageGallery ref={this.galleryRef} originalHeight={100} originalWidth={100} showThumbnails={false} showFullscreenButton={false} showPlayButton={false} items={images} /> */}
                    <FormInput
                      id="categoryName"
                      placeholder={'Committee Name'}
                      value={this.state.committeeTitle}
                      onChange={this.setCategoryTitle}
                      className="insideFont"
                      style={{ height: 38 }}
                    />
                  </Col>
                </Row>
                {!this.state.isTransferCommittee && <Row className="mt-2">
                  <Col md="12" className="form-group">
                    <label htmlFor="firstName" className="georgia">Type: </label>
                    {/* <FormSelect className="insideFont" id="feInputState" onChange={(event) => this.changeFilter(event)} value={this.state.filterValue}>
                                      <option  >{t('FILTER_ALL')}</option>
                                      <option  >{t('FILTER_INNOVATION')}</option>
                                      <option  >{t('FILTER_PROBLEM_F')}</option>
                                      <option  >{t('FILTER_IMPROVEMENT')}</option>
                                  </FormSelect> */}
                    <Select
                      value={this.state.filterValue}
                      onChange={(newValue, action) => this.changeFilter(newValue, action)}
                      options={[
                        // {"value":t('FILTER_ALL'), "label":t('FILTER_ALL')},
                        { "value": 'innovation', "label": t('FILTER_INNOVATION') },
                        { "value": 'problem-solving', "label": t('FILTER_PROBLEM_F') },
                        { "value": 'continuous-improvement', "label": t('FILTER_IMPROVEMENT') },
                      ]}
                      placeholder="All Types"
                      isMulti
                      clearable={true}
                      className="insideFont"
                    />
                  </Col>
                </Row>}

              </Col>

              {/* Middle Part */}

              <Col lg="3" className="mx-auto">
                <Row form>
                  {/* Proponent */}
                  <Col md="12" className="form-group">
                    <Row className="mt-2">
                      <Col>

                        <label htmlFor="firstName" className="georgia">Committee Leader: *</label>
                        <ExecutionSelectNew className="insideFont" evalType={'execution1'} setResponsible={(res, idx) => this.changeResponsible(res, idx)} selectedVal={executionRes} />
                      </Col>
                    </Row>
                  </Col>

                  {/* Proponent */}
                  <Col md="12" className="form-group">
                    <Row className="mt-2">
                      <Col>
                        <label htmlFor="firstName" className="georgia">Committee Members: </label>
                        <EmployeeSelect className="insideFont" evalType={'execution'} type="Committee" setResponsible={(res, idx) => this.changeEmployees(res, idx)} selectedVal={employees} />
                      </Col>
                    </Row>
                  </Col>

                </Row>
              </Col>



            </Row>
            <Row >
              <Col md={"3"} className="ml-auto">
                <ResponseRequired />
              </Col>
            </Row>
            <Row >
              <Col md={"3"} className="ml-auto">
                <Row>
                  <Col md="3" className="ml-auto" style={{ textAlign: 'right' }}>
                    <AcceptIcon className="functionalButton" style={{ height: 34, width: 34 }} onClick={() => this.updateIdea()}></AcceptIcon>
                  </Col>


                  <Col md="1" className="mr-auto">
                    <CancelIcon className="functionalButton" style={{ height: 34, width: 34 }} onClick={() => this.deleteIdea(this.state.isNew)}></CancelIcon>
                  </Col>

                </Row>
              </Col>
            </Row>



          </div>

        </Form>
      </div>
    );
  }
}




export default withTranslation()(EditCommitteeForm);