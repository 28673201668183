import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";

const chartData = {
  labels: ["September", "October", "November", "December", "January"],
  datasets: [
    {
      label: "User Sessions",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgba(75,192,192,0.4)",
      borderColor: "rgba(75,192,192,1)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56]
    }
  ]
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  }
};

const UserSessionsChart = () => {
  const [data, setData] = useState(chartData);

  useEffect(() => {
    setData({
      ...data,
      datasets: [
        {
          ...data.datasets[0],
          data: data.labels.map(() => Math.floor(Math.random() * 100))
        }
      ]
    });
  }, []);

  return (
    <Line
      data={data}
      options={options}
      height={50}
      width={100}
    />
  );
};

export default UserSessionsChart;
