import React from "react";
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { fetchTeam } from '../../services/tableService';
import "./LocalStyles/CategorySelectStyle.css";

class ExecutionSelectNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectionValue: '',
      sq1Color: '',
      sq2Color: '',
      sq3Color: '',
      sq4Color: '',
      titleColor1: 'black',
      titleColor2: 'black',
      titleColor3: 'black',
      titleColor4: 'black',
      handIcon: 'black',
      shieldIcon: 'black',
      chartIcon: 'black',
      clockIcon: 'black',
      puzzleIcon: 'black',
      gearIcon: 'black',
      data: [],
      options: [],
      showSecurity: '',
      showQuality: '',
      showProductivity: '',
      showAction: '',
      selectedPerson: this.props.selectedVal,
    };

    this.fetchCategoryData = this.fetchCategoryData.bind(this);
    this.handleSelectedPersonChange = this.handleSelectedPersonChange.bind(this);
    this.handleSelectedPersonChangeMulti = this.handleSelectedPersonChangeMulti.bind(this);
  }

  componentDidMount() {
    this.fetchCategoryData();
  }

  async fetchCategoryData() {
    try {
      const results = await fetchTeam();

      const options = results.map(result => ({
        value: result,
        label: result.fullName,
      }));

      const coaches = results.filter(result => result.isCoach === true).map(result => ({
        value: result,
        label: result.fullName,
      }));

      this.setState({
        data: results,
        coaches: coaches,
        options: options
      });
    } catch (error) {
      console.error('Error fetching category data:', error);
      this.setState({ data: [] });
    }
  }

  handleSelectedPersonChange(event) {
    this.props.setResponsible(event, 0);
  }

  handleSelectedPersonChangeMulti(event) {
    if (event && event.length <= 3) {
      this.props.setResponsible(event, 0);
    } else if (!event) {
      this.props.setResponsible('', 0);
    }
  }

  

  render() {
    const { options, coaches } = this.state;
    const { selectedVal, t } = this.props;

    let filtered = '';
    let myValue = '';

    if (selectedVal) {
      filtered = options.filter(row => row.label === selectedVal.label);
      if (filtered.length > 0) {
        myValue = filtered[0];
      }
    }

    const customSelectStyles = {
      menu: (provided) => ({ ...provided, zIndex: 9999 }),
      control: (provided) => ({ ...provided, zIndex: 9999 }),
    };

    return (
      this.props.evalType === "execution1" ?
        <div style={{ width: '100%' }}>
          <Select
            value={myValue}
            onChange={this.handleSelectedPersonChange}
            options={options}
            placeholder={'Choose employee(s)'}
            clearable={false}
            className="insideFont"
          />
        </div>
        :
        (this.props.evalType === "execution" ?
          <div style={{ width: '100%' }}>
            <Select
              value={myValue}
              onChange={this.handleSelectedPersonChange}
              options={options}
              placeholder={'Choose employee(s)'}
              clearable={false}
              className="insideFont"
            />
          </div>
          :
          (this.props.evalType === "recognition" ?
            <div style={{ minWidth: 250 }}>
              <Select
                value={selectedVal}
                onChange={this.handleSelectedPersonChangeMulti}
                options={options} // Use options for recognition items
                placeholder={t('Choose up to 3 employee(s)')}
                className="insideFont"
                isMulti // Multi-select
                styles={customSelectStyles}
              />
            </div>
            :
            <div style={{ minWidth: 250 }}>
              <Select
                value={selectedVal}
                onChange={this.handleSelectedPersonChangeMulti}
                options={coaches}
                placeholder={t('Choose up to 3 coach(s)')}
                className="insideFont"
                isMulti
              />
            </div>
          )
        )
    );
  }
}

export default withTranslation()(ExecutionSelectNew);
