import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Col, Row } from "shards-react";
import { ReactComponent as ArrowDownIcon } from "../../images/arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "../../images/arrow_up.svg";
import administrationService from '../../services/administrationService';
import colors from "../../utils/colors";
import EditDepartmentForm from '../common/EditDepartmentForm';
import SmallSwitch from '../common/SmallSwitch';


function DepartmentItem({category, open = false, updateCategories}) {
  const { t } = useTranslation();
  const [ideaStage, setIdeaStage] = useState(1)
  const [canGoNext, setCanGoNext] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const [departmentStatus, setDepartmentStatus] = useState(category.active)
  const [categoryIsOpen, setCategoryIsOpen] = useState(open)

  const setFinished = () => {
    setIdeaStage(3)
  }

  const changeBtnStatus = (status) => {
   
   setCanGoNext(status)
  }

  const changeContinueBtnStatus = (status) => {
   
   setCanContinue(status)
  }

  const updateIdeaCategoryStatus = async (category, status) => {
    
    const departmentData = {
      departmentId: category.id,       // The ID of the department
      name: category.name,             // Assuming `name` is an object with 'en' and 'es' fields
      departmentLeader: category.leader, // Assuming you have the leader info in the category object
      active: !status,                 // Toggle the active status
    };
  
    try {
      // Call the service to update the department status
      await administrationService.updateDepartment(departmentData);
      setDepartmentStatus(!status);
    } catch (error) {
      // Handle error response
      const message = error.response.data.message || 'An error occurred while updating the department';
      console.error('Error updating department:', message);
      alert(`Error updating department: ${message}`);
    }
  };

  const categoryNameEnglish = category.name ? category.name.en : ''
  const categoryNameSpanish = category.name ? category.name.es : ''

  
  useEffect(() => {
    if (categoryNameEnglish == '') {
      setCategoryIsOpen(true)
    }
  }, []);

  return (
    <Row>
      {/* Latest Orders */}
      <Col lg="10" className="m-auto">
              <div style={{width: '100%', borderStyle: 'solid',borderColor: 'black', borderWidth: 2, borderRadius: 5, marginBottom: 5, display: 'flex'}}>

                {category.id && <div className="ml-4 my-auto" style={{height: '100%', width: 40, display: 'inline-block'}}>
                  <SmallSwitch 
                      isOn={departmentStatus}
                      myKey={'turnOn' + Math.random()}
                      handleToggle={() => updateIdeaCategoryStatus(category, departmentStatus)}
                      onColor="#79de75"
                      title="On/Off"
                  />
                </div>}

                <div className="ml-4 my-auto" style={{height: '100%', display: 'inline-block'}}>
                    <h6 className="my-auto" style={{fontWeight: 500,  color: '#303030',}}>{categoryNameEnglish?categoryNameEnglish:"New Department"}</h6>
                </div>

                { categoryIsOpen && <ArrowUpIcon onClick={() => setCategoryIsOpen(false)} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowUpIcon> }
                { !categoryIsOpen && <ArrowDownIcon onClick={() => setCategoryIsOpen(true)} className="mr-2 ml-auto my-auto" style={{height: 16, width: 16, display: 'inline-block'}}></ArrowDownIcon>}
              
              </div>
              { categoryIsOpen &&
              <div style={{backgroundColor: '#F6F6F6',}}>
               <EditDepartmentForm refreshIdea={updateCategories} categoryData={category} canSubmit={canSubmit} setFinishedSaving={() => setFinished()} ideaStage={ideaStage} changeStatus={(status) => changeBtnStatus(status)} changeContinueStatus={(status) => changeContinueBtnStatus(status)} changeIdeaStage={() => this.changeIdeaStage()}/>
              </div>
              }
      </Col>
    </Row>
  )}

DepartmentItem.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

DepartmentItem.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default DepartmentItem;
