import React from 'react';
import ApexCharts from 'apexcharts';
import moment from 'moment';

const AreaChart = ({ data }) => {
  const chartRef = React.useRef(null);

  React.useEffect(() => {
    const today = moment();
    const categories = Array.from(Array(6)).map((_, i) => today.subtract(1, 'month').format('MMM')).reverse();

    const options = {
      chart: {
        type: 'area',
        height: 100,
        sparkline: {
          enabled: true
        },
        parentHeightOffset: 0,
      },
      series: [{
        name: 'Impacto Economico',
        data: data,
      }],
      toolbar:{
        show: false
      },
      grid: {
        show: false, 
      },
      dataLabels: {
        enabled: false
      },
      zoom: {
        enabled: false
      },
      // Always show from min to max
      xaxis: {
        show: false,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        show: false,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => chart.destroy();
  }, [data]);

  return <div ref={chartRef}></div>;
};

export default AreaChart;
