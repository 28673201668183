import Parse from 'parse';
import { object } from "prop-types";
import React from "react";
import Select from 'react-select';
import {
  Col,
  DatePicker,
  Form,
  FormInput,
  FormTextarea,
  Row,
  Tooltip
} from "shards-react";
import { ReactComponent as AcceptIcon } from "../../images/accept_button.svg";
import { ReactComponent as CancelIcon } from "../../images/cancel_button.svg";
import { ReactComponent as CheckmarkImage } from "../../images/check1.svg";
import { ReactComponent as DollarImage } from "../../images/dollar-symbol.svg";
import { ReactComponent as HandImage } from "../../images/hand.svg";
import { ReactComponent as UrgentImage } from '../../images/Icons_Idle_01_Urgent.svg';
import { ReactComponent as ProductivityImage } from "../../images/Icons_Idle_02_Productivity.svg";
import { ReactComponent as TrophyImage } from "../../images/Icons_Idle_04_Trophy.svg";
import { ReactComponent as Shield2Image } from "../../images/Icons_Idle_05_Shield.svg";
import { ReactComponent as DollarSignImage } from "../../images/Icons_Idle_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImage } from "../../images/Icons_Idle_07_Number One.svg";
import { ReactComponent as InfoIcon } from "../../images/info_icon.svg";
import { ReactComponent as LeftIcon } from "../../images/left_icon.svg";
import { ReactComponent as ChartImage } from "../../images/line-chart.svg";
import { ReactComponent as NotSelectedLanguageIcon } from "../../images/not_selected_language.svg";
import { ReactComponent as RedIcon } from "../../images/red_icon.svg";
import { ReactComponent as RightIcon } from "../../images/right_icon.svg";
import selectIdeaImage from '../../images/selected.png';
import { ReactComponent as SelectedLanguageIcon } from "../../images/selected_language.svg";
import { ReactComponent as ShieldImage } from "../../images/shield.svg";
import { ReactComponent as TeamImage } from "../../images/team.svg";
import { ReactComponent as TimeImage } from "../../images/time.svg";
import ExecutionSelectNew from "./ExecutionSelectNew";

// New Selected
import { ReactComponent as CheckmarkImageSelected } from "../../images/check1_selected.svg";
import { ReactComponent as UrgentImageSelected } from '../../images/Icons_Selected_01_Urgent.svg';
import { ReactComponent as ProductivityImageSelected } from "../../images/Icons_Selected_02_Productivity.svg";
import { ReactComponent as TrophyImageSelected } from "../../images/Icons_Selected_04_Trophy.svg";
import { ReactComponent as Shield2ImageSelected } from "../../images/Icons_Selected_05_Shield.svg";
import { ReactComponent as DollarSignImageSelected } from "../../images/Icons_Selected_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImageSelected } from "../../images/Icons_Selected_07_Number One.svg";

// Import Category Icons
import CheckmarkImageLocal from "../../images/check1.svg";
import UrgentImageLocal from '../../images/Icons_Idle_01_Urgent.svg';
import ProductivityImageLocal from "../../images/Icons_Idle_02_Productivity.svg";
import TrophyImageLocal from "../../images/Icons_Idle_04_Trophy.svg";
import Shield2ImageLocal from "../../images/Icons_Idle_05_Shield.svg";
import DollarSignImageLocal from "../../images/Icons_Idle_06_Dollar Sign.svg";
import NumberOneImageLocal from "../../images/Icons_Idle_07_Number One.svg";

import categoryService from "../../services/categoryService";
import { authUser } from '../../services/userService';

import { withTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import Switch from "./Switch.js";

const images = [
  {
    original: UrgentImageLocal,
    thumbnail: UrgentImageLocal,
    name: 'Urgent'
  },
  {
    original: ProductivityImageLocal,
    thumbnail: ProductivityImageLocal,
    name: 'Productivity'
  },
  {
    original: CheckmarkImageLocal,
    thumbnail: CheckmarkImageLocal,
    name: 'Checkmark'
  },
  {
    original: TrophyImageLocal,
    thumbnail: TrophyImageLocal,
    name: 'Trophy'
  },
  {
    original: Shield2ImageLocal,
    thumbnail: Shield2ImageLocal,
    name: 'Shield Image'
  },
  {
    original: DollarSignImageLocal,
    thumbnail: DollarSignImageLocal,
    name: 'Dollar Sign'
  },
  {
    original: NumberOneImageLocal,
    thumbnail: NumberOneImageLocal,
    name: 'Number One'
  },
]

class EditCategoryForm extends React.Component {
    constructor(props) {
        super(props);
        const { categoryData } = props;

        this.galleryRef = React.createRef();

        this.state = {
          data:[],
          ideaQuestions: [],
          answers:[],
          category:null,
          department:null,
          date: '',
          categoryQuestions: [],
          filterQuestions: [],
          selectedFilterQ: [],
          filterQAnswers: [],
          categoryQAnswers: [],
          ideaDescription: null,
          file:null, 
          buttonNext:true,
          descriptionValid:'',
          remainingCharacters: 250,
          visible: true,
          filterVisible: false,
          filterQuestionsVisible: false, 
          ideaQuestionsVisible: false,
          hideNextButton: false,
          userName: 'User Name',
          sectionTitle:'',
          formButtonTitle: 'Continuar',
          ideaNumber: '#00008',
          status: '',
          executionResObj: object,
          selectedBenefit:'',
          selectedResult: '',
          money: '',
          selectedImprovement: '',
          selectedImpact: '',
          selectedCoachBackup: '',
          isRecognized: '',
          page: 2,
          responseInfo: false,
          responseInfo2: false,
          selectedStatus: ''.length,
          expectedReturn: '',
          timeUnit: '',
          language: 'en',
          executionRes: 0,
          icon: '',
          coachRes: '',
          recurringImpact: false,
          comment: '',
          categoryDuration: categoryData.startDate || false,
          startDate: '',
          endDate: '',
          active: false,
          selectedLanguage: {
            value:'English',
            label:'English'
          }, 
          categoryTitle: '',
          categoryTitleSpanish: '',
          categoryInformation: '',
          categoryInformationSpanish: '',
          hasEnglish: false,
          hasSpanish: false,
          kpi1:'',
          kpi2:'',
          kpi3:'',
          kpi:[],
          activeKpis: {
            English: categoryData.kpis && categoryData.kpis.some(kpi => kpi.en && kpi.en.trim() !== ''),
            Spanish: categoryData.kpis && categoryData.kpis.some(kpi => kpi.es && kpi.es.trim() !== '')
          },
          activeCategoriesCount: 0,
          categoriesLimit: 4,
          startIndex: categoryData.id === 'new-category' ? 0 : images.findIndex((image) => image.name === categoryData.icon)
        }

        this.change = this.change.bind(this);
        this.setCategory = this.setCategory.bind(this);
        this.setKpi = this.setKpi.bind(this);
        this.setDate = this.setDate.bind(this);
        this.setIdeaDescription = this.setIdeaDescription.bind(this);
        this.showNext = this.showNext.bind(this);
        this.addAnswer = this.addAnswer.bind(this);
        this.showNext = this.showNext.bind(this);
        this.changeMoney = this.changeMoney.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.setEvalStatus = this.setEvalStatus.bind(this);
        this.setCategoryTitle = this.setCategoryTitle.bind(this);
        this.setLanguage = this.setLanguage.bind(this);

    }

    componentDidUpdate(prevProps) {
      if (prevProps.canSubmit !== this.props.canSubmit) {
        this.submitEvaluation()
      }
    }

    componentDidMount() {
      this.loadInitialSettings()
      let currUser = authUser();
      this.getUserName(currUser);
    }

    loadInitialSettings() {
      const { categoryData } = this.props;
      
      const categoryName =  categoryData.name && categoryData.name.en ? categoryData.name.en : '';
      const categoryNameSpanish = categoryData.name && categoryData.name.es ? categoryData.name.es : '';
      
      const categoryInformation = categoryData.description && categoryData.description.en ? categoryData.description.en : '';
      const categoryInformationSpanish = categoryData.description && categoryData.description.es ? categoryData.description.es : '';
      var kpi = []
      var showKpi = false

      if (categoryData.kpis && categoryData.kpis.length > 0) {
        const nonEmptyKpis = categoryData.kpis.filter(kpi => kpi.en && kpi.en.trim() !== '' || kpi.es && kpi.es.trim() !== '');

        if (nonEmptyKpis.length > 0) {
            kpi = nonEmptyKpis;
            showKpi = true;
        } else {
            kpi = [{
                en: '',
                es: ''
            }, {
                en: '',
                es: ''
            }, {
                en: '',
                es: ''
            }];
            showKpi = false;
        }
    } else {
        kpi = [{
            en: '',
            es: ''
        }, {
            en: '',
            es: ''
        }, {
            en: '',
            es: ''
        }];
        showKpi = false;
    }
      
      // Status On/Off
      const categoryStatus = categoryData.active

      // English / Spanish Setup
      const hasEnglish = categoryData.name && categoryData.name.en ? categoryData.name.en !== '' : false;
      const hasSpanish = categoryData.name && categoryData.name.es ? categoryData.name.es !== '' : false;

      this.setState({categoryOn: categoryStatus, active: categoryStatus,categoryTitle: categoryName, categoryTitleSpanish: categoryNameSpanish, categoryInformationSpanish: categoryInformationSpanish, categoryInformation: categoryInformation, hasEnglish: hasEnglish, hasSpanish: hasSpanish, kpi: kpi, recurringImpact: showKpi, startDate: categoryData.startDate || null, endDate: categoryData.endDate || null })
    }


    async getUserName(user) {
      this.setState({
        userName: user.fullName
      });
    }

    handleSubmit() {
      const { formButtonTitle } = this.state;
      
      if (formButtonTitle == "Continuar") {
        this.showNext();
      } else {
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
          //  
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas requeridas.");
        } else {
        alert("Su IDEA ha sido sometida.");
      }
    }
  }

    showNext() {
      const isShowingForm = this.state.visible;
      const isShowingFilter = this.state.filterVisible;
      const isShowingFilterQuestions = this.state.filterQuestionsVisible;
      const isShowingQuestions = this.state.ideaQuestionsVisible;

      if (isShowingForm && !isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {

        const { category, department, descriptionValid } = this.state;

        if (!category || !department || !descriptionValid) {
          alert("Por favor seleccione una categoria, un departamento y asegurese de que la descripcion de la idea sea valida.");
        } else {
          this.setState({
            visible: !isShowingForm,
            filterVisible: !isShowingFilter,
            buttonState: false,
            hideNextButton: true,
          });
        }
      } else if (!isShowingForm && isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {
        this.setState({
          hideNextButton: false,
          filterVisible: !isShowingFilter,
          filterQuestionsVisible: !isShowingFilterQuestions,
          buttonState: false,
        });
      } else if (!isShowingForm && !isShowingFilter && isShowingFilterQuestions && !isShowingQuestions) {
        var isInvalid = false
        this.state.filterQAnswers.forEach((item,idx) => {
          //  
          if (!item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas.");
        } else {
          this.setState({
            filterQuestionsVisible: !isShowingFilterQuestions,
            ideaQuestionsVisible: !isShowingQuestions,
            buttonState: false,
            formButtonTitle: 'Someter',
          });
        }
      } else if (!isShowingForm && !isShowingFilter && !isShowingFilterQuestions && isShowingQuestions) {
        //  
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
          //  
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Please answer all required questions.");
        } else {
          alert('¡Congrats! Thanks for submitting your idea.', 
          this.resetIdeaForm());
        }
      }
    }

    resetIdeaForm() {
      window.location.reload();
    }


    fetchQuestions() {
      const className = "IdeaCategory";
      const ItemClass = Parse.Object.extend(className);
      const query = new Parse.Query(ItemClass);
      query.doesNotExist("deleted_at");

      query.limit(1000000).find()
      .then((results) => {
        //  
          this.setState({
              data: results
          });
      }, (error) => {
          this.setState({
              data: []
          });
      });
    }

    fetchFilterQuestions() {
      const className = "FilterQuestion";

      var ItemClass = Parse.Object.extend(className);
      var query = new Parse.Query(ItemClass);
      query.find()
      .then((results) => {
        //  
          this.setState({
              filterQuestions: results
          });
      }, (error) => {
          this.setState({
              filterQuestions: []
          });
        // The object was not retrieved successfully.
        // error is a Parse.Error with an error code and message.
      });
    }

    handleFilterChange(newFilter) {
      const newCategory = newFilter;
      const { filterQuestions } = this.state;
      //  
      //  
      
      var filteredData;
      if (newCategory !== 'Todas') {
        filteredData = filterQuestions.filter(item => item.get("filter") === newCategory);
      }  else {
        filteredData = filterQuestions;
      }
      
      this.setState({
        selectedFilterQ: filteredData
      }, this.addFilterAnswer(filteredData));
      //  
      this.showNext();
    }

    handleCategoryChange(selectedCategory) {
      const newCategory = selectedCategory;
      const { ideaQuestions } = this.state;

      var filteredData = ideaQuestions.filter(item => item.get("category") === newCategory );
            
      this.setState({
        categoryQuestions: filteredData
      }, this.addAnswer(filteredData));
    }

    onSubmit = e => {
      e.preventDefault();
      alert('Form submitted');
    };

    resetForm() {
      this.setState({proponent: '', department: '', category: '', ideaDescription:'', date: new Date(),file: '', remainingCharacters: 250, descriptionValid:''});
    }

    change(event) {
      // this.setState({category: event.target.value});
      this.setState({department: event.target.value});
      //  
    }

    setCategory(categoryName) {
      this.setState({
        category: categoryName
      })

      this.handleCategoryChange(categoryName)
    }

    setFilter(filterName) {
      this.handleFilterChange(filterName);
    }

    clickedPrint() {}

    countActiveCategoriesWithDate = () => {
      const { data, startDate, endDate } = this.state
      if(!startDate || !endDate) return 0;

      const activeOnDate = data.filter(category => {
        if(!category.startDate || !category.endDate || category.active) return false;
      
        const startDateCategory = new Date(category.startDate);
        const endDateCategory = new Date(category.endDate);

        return (startDate <= endDateCategory && startDate >= startDateCategory) ||
              (endDate >= startDateCategory && endDate <= endDateCategory);
      }).length;

      const activeCategories = data.filter(category => category.active).length;

      return activeCategories + activeOnDate;
    }

    setDate(ideaDate) {
      this.setState({
        date: ideaDate
      })
    }

    filterQuestionAnswerChange(event, idx) {
      this.state.filterQAnswers[idx].answer = event.target.value;
    }

    questionAnswerChange(event, idx) {
      this.state.answers[idx].answer = event.target.value;
    }

    setIdeaDescription(event) {
      const description = event.target.value;
      const maxCharacters = 250;
      const charCount = maxCharacters - description.length
      var isValid = null;

      if (charCount < 0 && description.length > 0) {
        isValid = false
      } else if (charCount > 0 && description.length > 0) {
        isValid = true
      } else {
        isValid = null
      }

      //  
      this.setState({
        descriptionValid: isValid,
        ideaDescription: description,
        remainingCharacters: charCount
      })
      //  
    }

    addAnswer(filteredQuestions) {
      filteredQuestions.map((item, idx) => (
        //  
        this.setState((prevState) => ({
          answers: [...prevState.answers, {question:item.get("question"), answer:"", required: item.get("required")}],
        }))
      ))
      //  
    }

    addFilterAnswer(filteredData) {
      //  
    
      var newItems = [];
      filteredData.forEach((item, idx) => {
        newItems.push({question:item.get("question"), answer:''});
      })

      this.setState({ filterQAnswers: newItems}, () => {
          //  
        });
    }


    changeStatus(selection) {
      this.setState({status: selection});
       
    }

    changeEvaluationResponse() {
    }

    async getHumanResourceUsers() {
      var query = new Parse.Query(Parse.User);
      query.equalTo("humanResources", true);
      const results = await query.find();
       
      return results
    }

    async setVerificationNotifications() {
    }

     changeBenefit(response) {
        this.setState({
          selectedBenefit: response
        });
      }

    changeResults(response) {
    this.setState({
        selectedResult: response
    });
    }

    changeImprovement(response) {
    this.setState({
        selectedImprovement: response
    });
    }

    changeMoney(response) {
    this.setState({
        money: response
    });
    }

    changeImpact(response) {
    this.setState({
        selectedImpact: response
    });
    }

    changeBackup(response) {
    this.setState({
        selectedCoachBackup: response
    });
    }

    changeMoney(response) {
      const inputMoney = parseFloat(response.target.value);
       
      this.setState({
        money: inputMoney
      })
    }

    changeRecognition(response) {
      this.setState({
          isRecognized: response
      });
      }

    downloadFile(file) {
        //  
        if (file != null) {
            const newWindow = window.open(file._url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else {
            alert('No file found...');
        }
    }

    getIcon(name, fillColor) {
      const newIcons = [
          {normal: <UrgentImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <UrgentImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <ProductivityImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <ProductivityImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <CheckmarkImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <CheckmarkImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <TrophyImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <TrophyImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <Shield2Image className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <Shield2ImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <DollarSignImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <DollarSignImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
          {normal: <NumberOneImage className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>,
           selected: <NumberOneImageSelected className="mr-auto d-block" style={{minWidth: 80, maxWidth:80}}/>
          },
      ]

      switch(name) {
          case 'HandImage':
            return <HandImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'ShieldImage':
              return <ShieldImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'ChartImage':
              return <ChartImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'TeamImage':
              return <TeamImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'DollarImage':
              return <DollarImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          case 'ClockImage':
              return <TimeImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
          //New Icons
          case 'Urgent':
              return newIcons[0].selected;
          case 'Productivity':
              return newIcons[1].selected;
          case 'Checkmark':
              return newIcons[2].selected;
          case 'Trophy':
              return newIcons[3].selected;
          case 'Shield':
              return newIcons[4].selected;
          case 'Dollar':
              return newIcons[5].selected;
          case 'Number One':
              return newIcons[6].selected;
          case 'Approve':
              return newIcons[0].selected;
          case 'Do not Pursue':
              return newIcons[1].selected;
          case 'Save for Later':
              return newIcons[2].selected;
          case 'Request information':
              return newIcons[3].selected;
          case 'Project Idea':
              return newIcons[4].selected;
          case 'Transfer Committee':
              return newIcons[5].selected;
          default:
            return <img src={selectIdeaImage} width="200" height="200" />//<SelectIdeaImage className="mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
        }
  }
    toggle() {
      // alert('hover')
      this.setState({
        responseInfo: !this.state.responseInfo
      });
    }

    setEvalStatus(status) {
       
      this.setState({
        selectedStatus: status
      })
    }

    setCategoryTitle(event) {
      const title = event.target.value;

      // Confirm Length
      // if (title.length >= 25) {
      //   alert('Title should contain less than 25 characters.')
      // } else {
        if (this.state.selectedLanguage.value === "English") {
          this.setState({
            categoryTitle: title,
          })
        } else {
          this.setState({
            categoryTitleSpanish: title,
          })
        }
    
      // } 

      //  
    }

    setLanguage(unit) {
      const { kpi } = this.state;

      const showKpiEn = kpi.some(kpiItem => kpiItem.en && kpiItem.en.trim() !== '');
      const showKpiEs = kpi.some(kpiItem => kpiItem.es && kpiItem.es.trim() !== '');
  
      const showKpi = unit.value === "Spanish" ? showKpiEs : showKpiEn;
  
      this.setState({ selectedLanguage: unit, recurringImpact: showKpi });
  }

    changeResponsible(res, idx) {
      this.setState({
        executionRes: res,
      });
      
      if (res) {
        this.props.changeStatus(true)
      }
       
    }

    changeCoach(res, idx) {
      this.setState({
        coachRes: res,
      });
       
    }

    changeCategoryInformation(res) {
      const comment = res.target.value

      if (this.state.selectedLanguage.value == "English") {
        this.setState({
          categoryInformation: comment
        })
      } else {
        this.setState({
          categoryInformationSpanish: comment
        })
      }
    }

    goNext() {
      const currIndex = this.galleryRef.current.getCurrentIndex()
      this.galleryRef.current.slideToIndex(currIndex + 1)
    }

    goPrev() {
      const currIndex = this.galleryRef.current.getCurrentIndex()
      this.galleryRef.current.slideToIndex(currIndex - 1)
    }

    async updateIdea () {
      const { startDate, endDate, active,kpi, categoryDuration,categoryTitle, categoryTitleSpanish, categoryInformationSpanish, categoryInformation, categoriesLimit} = this.state;
      const {categoryData, refreshIdea} = this.props;
      if(categoryDuration){
        if(startDate > endDate){
          alert('Start date should be less than end date');
          return;
        }
        if(!startDate || !endDate){
          alert('Please select start and end date');
          return;
        }
        if(startDate > new Date()){
          alert('Start date should be less than current date');
          return;
        }
      }

      const iconIndex = this.galleryRef.current.getCurrentIndex()
      const iconName = images[iconIndex].name

      const categoryDataToUpdate = {
        id: categoryData.id,
        name: {
          en: categoryTitle, 
          es: categoryTitleSpanish
        },
        description: {
          en: categoryInformation,
          es: categoryInformationSpanish
        },
        startDate,
        endDate,
        kpi,
        categoriesLimit,
        iconName,
        active: active || false,
      };

      let dataReturn;
      if(categoryData.id == 'new-category') {
        dataReturn = await categoryService.createdCategories(categoryDataToUpdate);
        alert('Category created successfully!');
      }else{
        try{
          dataReturn = await categoryService.updateCategories(categoryData.id, categoryDataToUpdate);
          alert('Category updated successfully!');
          this.props.setFinishedSaving(true);
        }catch(e){
          alert(e);
        }
      }

      if(dataReturn)refreshIdea(dataReturn.categoryId, false)
    }

    async deleteIdea() {
      const {categoryData, refreshIdea} = this.props;

      if(categoryData.id !== 'new-category') {
        const canDelete = window.confirm('Are you sure you want to delete this category?');
        if (canDelete) {
          await categoryService.deleteCategories(categoryData.id);
          alert('Category deleted successfully!');

          refreshIdea(null, true)
        }
      }
    }

  setKpi(event, index) {
    const {  kpi } = this.state;
    const kpiData = event.target.value;
    
    const kpiCopy = [...kpi]
    const selectedKpi = {...kpi[index]}
    
    // Confirm Length
    if (kpiData.length >= 30) {
      alert('Kpi should contain less than 30 characters.')
    } else {
      if (this.state.selectedLanguage.value == "English") {
        const selectedKpiCopy = {...selectedKpi, en: kpiData}
        kpiCopy[index] = selectedKpiCopy
      } else {
        const selectedKpiCopy = {...selectedKpi, es: kpiData}
        kpiCopy[index] = selectedKpiCopy
      }

      this.setState({
        kpi: kpiCopy
      })
    }
  }

  render() {
        const {kpi, categoryTitleSpanish, startIndex, categoryInformationSpanish, selectedLanguage, categoryTitle, categoryInformation, coachRes, executionRes } = this.state
        const { ideaStage } = this.props;

        const customStyles = {
          control: base => ({
            ...base,
            height: 35,
            minHeight: 35
          })
        }; 

        const hasEnglish = this.state.categoryTitle != '' && this.state.categoryInformation != ''
        const hasSpanish = this.state.categoryTitleSpanish!= '' && this.state.categoryInformationSpanish != ''

        return(

            <div className="edit-user-details" > 
                    <Form className="py-4"
                      onSubmit={this.onSubmit}
                      noValidate
                      >
                        
                        {/* VISIBILITY */}
                        <div >
                        <Row form >
                          {/* Left Part */}
                          <Col lg="4" className="mx-auto">
                            <Row form>
                              {/* Proponent */}
                              <Col md="12" className="form-group">
                                <Row className="mt-2">
                                  <Col>
                                    <label htmlFor="firstName" className="georgia">Choose Language: </label>
                                    <Select
                                        value={selectedLanguage}
                                        className="insideFont"
                                        placeholder='English'
                                        styles={customStyles}
                                        onChange={this.setLanguage}
                                        options={[
                                          {
                                            value:'English',
                                            label:'English'
                                          }, 
                                          {
                                            value:'Spanish',
                                            label:'Spanish'
                                          }
                                        ]}z
                                      />
                                  </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col>
                                        <label htmlFor="firstName" className="georgia">Category Name: *</label>
                                        <FormInput
                                            id="categoryName"
                                            placeholder={'Category name'}
                                            value={this.state.selectedLanguage.value === "English" ? categoryTitle : categoryTitleSpanish}
                                            onChange={this.setCategoryTitle}
                                            className="insideFont"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col>
                                        <label htmlFor="firstName" className="georgia">Category Information: * </label>
                                        <FormTextarea 
                                        value={this.state.selectedLanguage.value == "English"?categoryInformation:categoryInformationSpanish}
                                        style={{ minHeight: "80px" }}
                                        id="ideaQuestion"
                                        className="insideFont"
                                        placeholder="Type Category Description Here..."
                                        onChange={(event) => this.changeCategoryInformation(event)}
                                        required>
                                        </FormTextarea>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col>
                                        <Switch 
                                            isOn={this.state.activeKpis[this.state.selectedLanguage.value]}
                                            myKey={'kpi'}
                                            handleToggle={() => {
                                               if (this.state.activeKpis[this.state.selectedLanguage.value] === true) {
                                              this.setState(prevState => {
                                                  const updatedKpi = [...prevState.kpi];
                                                  if (this.state.selectedLanguage.value === "English") {
                                                      updatedKpi[0].en = '';
                                                      updatedKpi[1].en = '';
                                                      updatedKpi[2].en = '';
                                                  }
                                                  if (this.state.selectedLanguage.value === "Spanish") {
                                                      updatedKpi[0].es = '';
                                                      updatedKpi[1].es = '';
                                                      updatedKpi[2].es = '';
                                                  }

                                                  return { kpi: updatedKpi };
                                              });
                                          }

                                          this.setState({
                                              activeKpis: {
                                                  ...this.state.activeKpis, 
                                                  [this.state.selectedLanguage.value]: !this.state.activeKpis[this.state.selectedLanguage.value]
                                              }
                                          });
                                        }}
                                          onColor="#633FDA"
                                          title="KPIs/Metrics"
                                        />
                                        {this.state.activeKpis[this.state.selectedLanguage.value] && 
                                         <div>
                                                <FormInput
                                                    id="kpi1"
                                                    placeholder={'KPI 1'}
                                                    value={
                                                        this.state.selectedLanguage.value === "English" 
                                                        ? (kpi[0] && kpi[0].en ? kpi[0].en : '')
                                                        : (kpi[0] && kpi[0].es ? kpi[0].es : '')
                                                    }
                                                    onChange={(event) => this.setKpi(event, 0)}
                                                    className="insideFont mb-2"
                                                />
                                                 <FormInput
                                                    id="kpi2"
                                                    placeholder={'KPI 2'}
                                                    value={
                                                        this.state.selectedLanguage.value === "English" 
                                                        ? (kpi[1] && kpi[1].en ? kpi[1].en : '')
                                                        : (kpi[1] && kpi[1].es ? kpi[1].es : '')
                                                    }
                                                    onChange={(event) => this.setKpi(event, 1)}
                                                    className="insideFont mb-2"
                                                />
                                                 <FormInput
                                                    id="kpi3"
                                                    placeholder={'KPI 3'}
                                                    value={
                                                        this.state.selectedLanguage.value === "English" 
                                                        ? (kpi[2] && kpi[2].en ? kpi[2].en : '')
                                                        : (kpi[2] && kpi[2].es ? kpi[2].es : '')
                                                    }
                                                    onChange={(event) => this.setKpi(event, 2)}
                                                    className="insideFont"
                                                />
                                         </div>
                                        }
                                    </Col>
                                </Row>
                              </Col>
    
                            </Row>
                          </Col>

                          {/* Divisor Line */}
                          <Col lg="1" className="mx-auto">
                            {/* <div style={{height: 300, width: 10, color: 'blue'}}></div> */}
                            <div className="mx-auto" style={{height: '100%', width: 1, backgroundColor: '#BABABA'}}></div>
                          </Col>

                          {/* Right Part */}
                          

                          {ideaStage == 1 && 
                            <Col lg="5" className="mx-auto">
                              <Row form className="mt-2">
                                <Col md="6" className="form-group">
                                <label htmlFor="firstName" className="georgia">Icons * </label>
                                  <Row md="12" style={{backgrounColor: 'red'}}>
                                    <Col>
                                      <Row>
                                        <div style={{backgrounColor: 'white'}}>
                                            <ImageGallery images={images} startIndex={startIndex} ref={this.galleryRef} originalHeight={100} originalWidth={100} showThumbnails={false} showFullscreenButton={false} showPlayButton={false} items={images} showNav={false} />
                                        </div>
                                      </Row>
                                    </Col>
                                    <Col className="mt-auto ml-2 mb-2 flex">
                                      <Row className={"mt-auto"}>
                                        <LeftIcon className="functionalButton" style={{width: 20, height: 20}} onClick={() => this.goNext()}></LeftIcon>
                                      </Row>
                                      <Row className={"mt-2"}>
                                        <RightIcon className="functionalButton" style={{width: 20, height: 20}} onClick={() => this.goPrev()}></RightIcon>
                                      </Row>
                                    </Col>
                                    
                                  </Row>
                                </Col>
         
                              </Row>
                              <Row form className="mt-4">
                                <Col md="12" className="form-group">
                                <Switch 
                                    isOn={this.state.categoryDuration}
                                    handleToggle={() => {
                                      const categoryDuration = !this.state.categoryDuration
                                      if(!categoryDuration) {
                                        this.setState({startDate: null, endDate: null}) 
                                      }
                                      
                                      this.setState({ categoryDuration })
                                    }}
                                    onColor="#633FDA"
                                    title="Category Duration"
                                    myKey={'categoryDuration'+this.state.id}
                                />
                                { this.state.categoryDuration && 
                                <div>
                                <Row>
                                    <Col md="2" className="my-auto">
                                    <label htmlFor="firstName" className="georgia">Start </label>
                                    </Col>
                                    <Col className="my-auto" md="9">
                                    <DatePicker
                                        value={this.state.startDate}
                                        placeholderText={this.state.startDate}
                                        dropdownMode="select"
                                        className="text-center"
                                        onChange={(date) => this.setState({startDate: date})} 
                                        required
                                        style={{height: 20}}
                                    />
                                    {/* } */}
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="2" className="my-auto">
                                    <label htmlFor="firstName" className="georgia">End </label>
                                    </Col>
                                    <Col className="my-auto" md="9">
                                    <DatePicker
                                        value={this.state.endDate}
                                        placeholderText={this.state.endDate}
                                        dropdownMode="select"
                                        className="text-center"
                                        onChange={(date) => this.setState({endDate: date})} 
                                        required
                                        style={{height: 20}}
                                    />
                                    {/* } */}
                                    </Col>
                                </Row>
                                </div>
                                }
                                </Col>
                              </Row>
                              <Row form >
                                <Col md="8" className="form-group">
                                  <label htmlFor="firstName" className="georgia">Configured Languages </label>
                                  <Row>
                                      <Col md="3">
                                      <h6 style={{fontWeight: 500,  color: '#303030'}}>{'English'}</h6>
                                      </Col>
                                      <Col className="mb-auto" md="1">
                                      { hasEnglish && <SelectedLanguageIcon style={{height: 20, width: 20}}></SelectedLanguageIcon>}
                                      { !hasEnglish && <NotSelectedLanguageIcon style={{height: 16, width: 16}}></NotSelectedLanguageIcon>}
                                      {/* } */}
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col md="3">
                                      <h6 style={{fontWeight: 500,  color: '#303030'}}>{'Spanish'}</h6>
                                      </Col>
                                      <Col className="mb-auto" md="1">
                                      { hasSpanish && <SelectedLanguageIcon style={{height: 20, width: 20}}></SelectedLanguageIcon>}
                                      { !hasSpanish && <NotSelectedLanguageIcon style={{height: 16, width: 16}}></NotSelectedLanguageIcon>}
                                      {/* } */}
                                      </Col>
                                  </Row>
                                </Col>
                                <Col md="4" className="mt-auto">
                                <Row>
                                  <Col md="4" className="ml-auto">
                                   
                                  </Col>
                                  <Col md="8">
                                    <Row>
                                      <Col md="5">
                                        <AcceptIcon  className="functionalButton" style={{height: 34, width: 34}} onClick={() => this.updateIdea()}></AcceptIcon>
                                      </Col>
                                      {this.props.categoryData.id !== 'new-category' &&
                                      <Col md="5">
                                        <CancelIcon className="functionalButton" style={{height: 34, width: 34}} onClick={() => this.deleteIdea()}></CancelIcon>
                                      </Col>
                                      }
                                    </Row>
                                  </Col>
                                </Row>
                                </Col>
                              </Row>
                            </Col>
                          }
                          
                          {ideaStage == 2 && 
                            <Col lg="6">
                              <Row className="mt-4">
                                  <Col md="6">
                                    <label htmlFor="firstName" className="georgia">Choose how to proceed: </label>
                                    <Row>
                                      <Col>
                                      {this.getIcon(this.state.selectedStatus, 'Black')}
                                          <div className="mr-auto" style={{width: '100%', backgrounColor: 'black'}}>
                                            <h6 style={{fontWeight: 500,  color: '#303030'}}>{this.state.selectedStatus}</h6>
                                          </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="6">
                                    <Row className="mt-2">
                                      <Col>
                                        <label htmlFor="firstName" className="georgia">Employee Response Date</label>
                                        <h6 style={{fontWeight: 500,  color: '#303030'}}></h6>
                                      </Col>
                                    </Row>
                                    <Row className="mt-2">
                                    <Col>
                                        <label htmlFor="firstName" className="georgia">Idea Status</label>
                                        <Row>
                                          <Col md="7">
                                            <h6 style={{fontWeight: 500,  color: '#303030'}}>{'Pending'}</h6>
                                          </Col>
                                          <Col className="mb-auto" md="1">
                                   
                                            <RedIcon style={{height: 16, width: 16}}></RedIcon>
                                            {/* } */}
                                          </Col>
                                          <Col md="1" className="mb-auto">
                                            <a id={"TooltipResponseInfo2"} className="text-right" style={{ color: 'inherit'}} onClick={() => {
                                                const myCopy = this.state.responseInfo2
                                                myCopy = !myCopy
                                                this.setState({responseInfo2: myCopy})
                                            }}>
                                                
                                                <InfoIcon style={{height: 16, width: 16}}></InfoIcon>
                                                
                                            </a>
                                          </Col>
                                         
                                          <Tooltip
                                            open={this.state.responseInfo2}
                                            target={"#TooltipResponseInfo2"}
                                            id={"TooltipResponseInfo2"}
                                            toggle={() => {this.toggle()}}
                                            >
                                            Type Category Description. Lorem ipsum dolor sit amet, consectetuer adipi- scing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volut-!
                                          </Tooltip>
                                      </Row>

                                      </Col>
                                    </Row>
                                    
                                  </Col>
                              </Row>

                              {/* Subject Matter Comments */}
                              <Row form className="mt-4">
                                <Col md="12" className="form-group">
                                  <label htmlFor="firstName" className="georgia">Subject-Matter Expert Comments:</label>
                                  <h6 style={{fontWeight: 500,  color: '#303030'}}>{this.state.comment}</h6>
                                </Col>
                              </Row>

                              <Row form className="mt-4">
                                <Col md="12" className="form-group">
                                  <label className="georgia">{'Estimate economic/output impact'}</label>
                                  <Row>
                                    <Col>
                                      <h6 style={{fontWeight: 500,  color: '#303030'}}>{'$'+this.state.expectedReturn}</h6>
                                    </Col>
                                    <Col md="4">
                                      <h6 style={{fontWeight: 500,  color: '#303030'}}>{this.state.timeUnit}</h6>
                                    </Col>
                                    <Col md="4">
                                      <Switch 
                                        isOn={this.state.recurringImpact}
                                        disabled
                                        // handleToggle={() => this.setState({hasTeam: !hasTeam})}
                                        onColor="#633FDA"
                                        title="Recurring Impact"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                              { this.state.selectedStatus !== "Do not Pursue" &&
                              <Row form className="mt-4">
                                <Col md="12" className="form-group">
                                  <h6 style={{fontWeight: 500,  color: '#303030'}}>{'Choose an Implementation Leader *'}</h6>
                                    <ExecutionSelectNew className="insideFont" evalType={'execution'} setResponsible={(res, idx) => this.changeResponsible(res, idx)} selectedVal={executionRes}/>
                                  <br/>
                                  <h6 style={{fontWeight: 500,  color: '#303030'}}>{'Choose an Coach'}</h6>
                                    <ExecutionSelectNew className="insideFont" evalType={'coach'} setResponsible={(res, idx) => this.changeCoach(res, idx)} selectedVal={coachRes}/>
                                </Col>
                              </Row>
                              }
                            </Col>
                          }
                        </Row>
                        </div>
                        </Form>
                  </div>
          );
    }
}




 export default withTranslation()(EditCategoryForm);