import React from "react";
import {
    Button,
    Col,
    FormTextarea,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "shards-react";

import { withTranslation } from 'react-i18next';
import { ReactComponent as AcceptIcon } from "../../images/accept_button.svg";
import { ReactComponent as CancelIcon } from "../../images/cancel_button.svg";
import { updateStatus } from '../../services/implementService';
import "./LocalStyles/CategorySelectStyle.css";

const selectedColor = '#157ffb';

class IdeaPercentageBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectionValue: '',
            sq1Color: '',
            sq2Color: '',
            sq3Color: '',
            sq4Color: '',
            titleColor1: 'black',
            titleColor2: 'black',
            titleColor3: 'black',
            titleColor4: 'black',
            handIcon: 'black',
            shieldIcon: 'black',
            chartIcon: 'black',
            clockIcon: 'black',
            puzzleIcon: 'black',
            gearIcon: 'black',
            data: [],
            showSecurity: '',
            showQuality: '',
            showProductivity: '',
            showAction: '',
            selectedPerson: this.props.selectedVal,
            selectedPercentage: 0,
            showConfirmationModal: false,
            temporaryPercentage: '',
            oldPercentage: '',
            updateComment: '',
            userName: '',
            showA3MODAL: false,
            showA3Btn: false,
        };
        this.handleSelectedPersonChange = this.handleSelectedPersonChange.bind(this);
    }

    async getProgress() {
        const { currIdea, view } = this.props;
        if (view !== 'userDashboard') {
            if (currIdea.progress === 100 && !currIdea.implement) {
                this.setState({ showA3MODAL: true });
            }
        }
        this.setState({
            selectedPercentage: currIdea.progress,
            temporaryPercentage: currIdea.progress,
            oldPercentage: currIdea.progress
        });
    }

    componentDidMount() {
        this.getProgress();
    }

    async getUserName(user) {
        const firstName = 'firstName-IdeaPercentageBar';
        const lastName = 'lastName';
        const fullName = firstName + ' ' + lastName;
        this.setState({ userName: fullName });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currIdea !== this.props.currIdea) {
            this.getProgress();
        }
    }

    changeSelectedValue(selectionValue) {
        if (selectionValue === 1) {
            this.setState({
                selectionValue: 'Seguridad',
                sq1Color: selectedColor,
                sq2Color: '',
                puzzleIcon: 'black',
                gearIcon: selectedColor,
                titleColor1: selectedColor,
                titleColor2: 'black',
            });
            this.props.setFilter('innovacion');
        } else if (selectionValue === 2) {
            this.setState({
                selectionValue: 'Calidad',
                sq1Color: '',
                sq2Color: selectedColor,
                puzzleIcon: selectedColor,
                gearIcon: 'black',
                titleColor1: 'black',
                titleColor2: selectedColor,
            });
            this.props.setFilter('solucion');
        }
    }

    handleSelectedPersonChange(event) {
        this.props.setResponsible(event, 0);
    }

    getFullName(result) {
        if (result) {
            return result.get("firstName") + ' ' + result.get("lastName");
        }
    }

    handleConfirmationModal(percentage) {
        
        this.setState({ updateComment: '' });
        if (percentage == 100 && this.props.incompleteTasks.length > 0) {
            alert('Please mark all actions as completed before moving forward.');
            this.props.markTasksRed(this.props.incompleteTasks);
        } else {
            this.setState({ showConfirmationModal: true, temporaryPercentage: percentage });
        }
    }

    changeSelectedPercentage() {
        this.props.updatePercentage(this.state.temporaryPercentage)
        this.setState({ selectedPercentage: this.state.temporaryPercentage, showConfirmationModal: false }, () => {
            this.updateSelectedPercentage(this.state.temporaryPercentage);
            if (this.state.temporaryPercentage == 100) {
                this.props.showA3(true);
            }
        });
    }

    onTextChange = (event) => {
        this.setState({ updateComment: event.target.value });
    }

    updateSelectedPercentage = async (percentage) => {
        const { currIdea } = this.props;
        try {
            await updateStatus(currIdea.id, this.state.updateComment, percentage);
        } catch (error) {
            alert('Failed to update status: ' + error.message);
        }
    };

    render() {
        const { selectedPercentage } = this.state;
        const { canClick, t } = this.props;

        return (
            <div>
                <Row>
                    <Col lg="12" className="m-auto">
                        <Row>
                            <Col>
                                <h6 className="mb-4" style={{ fontWeight: 500, color: '#633FDA', textAlign: 'center' }}>{t('Idea Implementation Status')}</h6>
                            </Col>
                        </Row>
                        <div style={myStyles.container} className={canClick !== false ? 'canClick' : ''}>
                            <Row style={{ height: '100%' }}>
                                <Col style={selectedPercentage === 25 ? myStyles.percentageColumnLeftSelected : myStyles.percentageColumnLeft}>
                                    <div>
                                        <p style={selectedPercentage === 25 ? myStyles.innerPercentageSelected : myStyles.innerPercentage}>25%</p>
                                    </div>
                                </Col>
                                <Col style={selectedPercentage === 50 ? myStyles.percentageColumnSelected : myStyles.percentageColumn}>
                                    <div onClick={() => { canClick !== false && this.handleConfirmationModal(50) }}>
                                        <p style={selectedPercentage === 50 ? myStyles.innerPercentageSelected : myStyles.innerPercentage}>50%</p>
                                    </div>
                                </Col>
                                <Col style={selectedPercentage === 75 ? myStyles.percentageColumnSelected : myStyles.percentageColumn}>
                                    <div onClick={() => { canClick !== false && this.handleConfirmationModal(75) }}>
                                        <p style={selectedPercentage === 75 ? myStyles.innerPercentageSelected : myStyles.innerPercentage}>75%</p>
                                    </div>
                                </Col>
                                <Col style={selectedPercentage === 100 ? myStyles.percentageColumnRightSelected : myStyles.percentageColumnRight}>
                                    <div onClick={() => { canClick !== false && this.handleConfirmationModal(100) }}>
                                        <p style={selectedPercentage === 100 ? myStyles.innerPercentageSelected : myStyles.innerPercentage}>100%</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row style={{ height: '100%', marginTop: 10 }}>
                            <Col>
                                <div>
                                    <p style={selectedPercentage === 25 ? myStyles.innerDescriptionSelected : myStyles.innerDescription}>{t('EVALUATED')}</p>
                                </div>
                            </Col>
                            <Col>
                                <div onClick={() => { canClick !== false && this.handleConfirmationModal(50) }}>
                                    <p className="functionalButtonProg" style={selectedPercentage == 50 ? myStyles.innerDescriptionSelected : myStyles.innerDescription}>Implementation Started</p>
                                </div>
                            </Col>
                            <Col>
                                <div onClick={() => { canClick !== false && this.handleConfirmationModal(75) }}>
                                    <p className="functionalButtonProg" style={selectedPercentage == 75 ? myStyles.innerDescriptionSelected : myStyles.innerDescription}>Implemented</p>
                                </div>
                            </Col>
                            <Col>
                                <div onClick={() => { canClick !== false && this.handleConfirmationModal(100) }}>
                                    <p className="functionalButtonProg" style={selectedPercentage == 100 ? myStyles.innerDescriptionSelected : myStyles.innerDescription}>{"Analysis & Conclusion"}</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Modal open={this.state.showConfirmationModal} centered backdrop toggle={() => this.setState({ showConfirmationModal: false })}>
                    <ModalHeader>
                        <h5 className="m-auto" style={{ fontWeight: 500, color: '#303030' }}>Update Status</h5>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <label htmlFor="firstName">{'What changed?'}</label>
                                <FormTextarea
                                    style={{ minHeight: "120px" }}
                                    id="comments"
                                    placeholder={'Enter changes here...'}
                                    value={this.state.updateComment}
                                    onChange={(event) => this.onTextChange(event)}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <div className="ml-auto" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className='mr-2'>
                                        <AcceptIcon className="functionalButton ml-auto" style={{ height: 34, width: 34 }} onClick={() => {this.changeSelectedPercentage()}}></AcceptIcon>
                                    </div>
                                    <div>
                                        <CancelIcon className="functionalButton ml-auto" style={{ height: 34, width: 34 }} onClick={() => this.setState({ showConfirmationModal: false })}></CancelIcon>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </ModalBody>
                </Modal>
                <Modal open={this.state.showA3MODAL} centered backdrop toggle={() => this.setState({ showA3MODAL: false })}>
                    <ModalHeader>
                        <h5 className="m-auto" style={{ fontWeight: 500, color: '#303030' }}>{t("OPPORTUNITY_IMPLEMENTATION_SUMMARY")}</h5>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <p htmlFor="firstName">{t('IN_ORDER_TO_GET_YOUR_OPPORTUNITY')}</p>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button pill theme="light" onClick={() => this.setState({ showA3MODAL: false })}>Cancel</Button>
                        <Button pill onClick={() => this.props.showA3(true)}>Complete</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(IdeaPercentageBar);

const myStyles = {
    container: {
        height: 60,
        width: "100%",
        borderWidth: 2,
        borderColor: 'black',
        borderStyle: 'solid',
        borderRadius: 30,
        overflow: "hidden"
    },
    innerPercentage: {
        fontSize: 30,
        textAlign: 'center',
        opacity: 0.5
    },
    innerPercentageSelected: {
        fontSize: 30,
        textAlign: 'center',
        color: 'white'
    },
    innerDescription: {
        fontSize: 12,
        textAlign: 'center',
    },
    innerDescriptionSelected: {
        fontSize: 12,
        textAlign: 'center',
        textDecoration: 'underline',
        fontWeight: 600,
    },
    percentageColumn: {
        height: '100%',
        borderStyle: 'solid',
        borderRightWidth: 1,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 1,
        paddingTop: 5,
        paddingBottom: 5,
    },
    percentageColumnSelected: {
        backgroundColor: '#007bff',
        height: '100%',
        borderStyle: 'solid',
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        paddingTop: 5,
        paddingBottom: 5,
    },
    percentageColumnRight: {
        height: '100%',
        borderStyle: 'solid',
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        paddingTop: 5,
        paddingBottom: 5,
    },
    percentageColumnLeft: {
        marginBottom: 60,
        paddingTop: 5,
        paddingBottom: 5,
        height: '100%',
        borderStyle: 'solid',
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0
    },
    percentageColumnLeftSelected: {
        backgroundColor: '#007bff',
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        marginBottom: 60,
        paddingTop: 5,
        paddingBottom: 5,
        height: '100%',
        borderStyle: 'solid',
        borderRightWidth: 1,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0
    },
    percentageColumnRightSelected: {
        backgroundColor: '#007bff',
        borderTopRightRadius: 30,
        borderBottomRightRadius: 30,
        marginBottom: 60,
        paddingTop: 5,
        paddingBottom: 5,
        height: '100%',
        borderStyle: 'solid',
        borderRightWidth: 1,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0
    }
};