import React from "react";
import PropTypes from "prop-types";
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { fetchAllCategories, fetchDepartments } from "../../services/tableService";

class DepartmentSelectBeta extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      options: [],
      selectedPerson: this.props.selectedVal
    };
  }

  componentDidMount() {
    this.fetchCategoryData();
  }

  async fetchCategoryData() {
    const { t } = this.props;
    let options = [];
    try {
      const results = await fetchDepartments();

      // Map the results to appropriate format for react-select options
      results.map((result) =>
        options.push({
          value: result.id,
          label: result.transcription.en || result.title
        })
      );

      // Add "All Categories" option if the component type is 'Committee'
      // if (this.props.type === 'Committee') {
      //   options.unshift({ value: 'All Departments', label: t('All Departments') });
      // }

      this.setState({ data: results, options });
    //   this.props.setResponsible(results[0], -1); // Set the first option by default if needed

    } catch (error) {
      console.error('Error fetching departments:', error);
      this.setState({ data: [] });
    }
  }

  handleSelectedPersonChange = (selectedOptions, action) => {
    let filterValue = selectedOptions;

    // Filter out 'All Categories' if selected in multi-select mode
    if (action.action === 'select-option') {
      filterValue = selectedOptions.filter((option) => !option.label.includes('All '));
    }

    // Remove a value from selection
    if (action.action === 'remove-value') {
      filterValue = this.props.selectedVal.filter((val) => val.label !== action.removedValue.label);
    }

    // Set the selected person using parent handler
    this.props.setResponsible(filterValue, 0);
  };

  render() {
    const { selectedVal, t, evalType } = this.props;
    const { options } = this.state;

    return (
      <div style={{ width: '100%' }}>
        {evalType === "execution" ? (
          <Select
            isMulti
            value={selectedVal}
            onChange={this.handleSelectedPersonChange}
            options={options}
            placeholder={t('EVAL_COMMITTEE_DEPARTMENT')}
            className="insideFont"
          />
        ) : (
          <div style={{ minWidth: 250 }}>
            <Select
              value={selectedVal}
              onChange={this.handleSelectedPersonChange}
              options={options}
              placeholder={t('No Coach')}
              className="insideFont"
            />
          </div>
        )}
      </div>
    );
  }
}

DepartmentSelectBeta.propTypes = {
  selectedVal: PropTypes.array.isRequired,
  setResponsible: PropTypes.func.isRequired,
  evalType: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default withTranslation()(DepartmentSelectBeta);
