import PropTypes from 'prop-types';
import React from "react";
import { withTranslation } from 'react-i18next';
import { Button } from "shards-react";
import { uploadFile } from "../../services/fileService";

class CustomFileWithProgressUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileName: '',
      uploadProgress: 0,
      file: null,
      error: '',
    };
  }

  selectFile = async (file) => {
    const fileName = (file[0].name).replace(/[^a-zA-Z0-9.-]/g, '');
    const fileSizeMB = file[0].size / 1024 / 1024;
    const maxFileSizeMB = this.props.maximumSizeFile;

    if (fileSizeMB > maxFileSizeMB) {
      this.setState({ 
        error: `This file is too big. Please select a file that is less than ${maxFileSizeMB} MB.`,
        fileName: '',
        file: null
      });
      return;
    }

    this.setState({ file: new File([file[0]], fileName), error: '', uploadProgress: 1 });
    try {
      const additionalData = {};
      const onProgressUpdate = this.props.onProgressUpdate;

      const uploadCallback = (progressEvent) => {
        onProgressUpdate(this.state.uploadProgress)
        
        const percentage = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
        this.setState({ uploadProgress: percentage });
        onProgressUpdate(percentage);
      };

      const response = await uploadFile(file[0], additionalData, uploadCallback);

      this.setState({ uploadProgress: 100 });
      this.props.onFileUpload(response);
    } catch (error) {
      this.deleteFile();
      console.error(error);

      if(error.response.data){
        if(error.response.data.error)
          this.setState({ error: error.response.data.error.message });
        else if(error.response.data.message)
          this.setState({ error: error.response.data.message });
      }else
        this.setState({ error });
    }
  };

  deleteFile = () => {
    this.setState({
      fileName: '',
      uploadProgress: 0,
      file: null,
      error: '',
    });
    this.props.onFileUpload(null);
    if (this.fileInputRef) {
      this.fileInputRef.value = "";
    }
  };
  
  onChange = e => {
    if (e.target.files.length > 0) {
      this.setState({ fileName: e.target.files[0].name });
      this.selectFile(e.target.files);
    }
  };

  render() {
    const { t } = this.props;
    const { fileName, uploadProgress, error } = this.state;
    
    return (
      <div>
        <div className="custom-file">
          <input
            ref={el => this.fileInputRef = el}
            name="selectedFile"
            type="file"
            className="custom-file-input"
            id="customFile"
            onChange={this.onChange}
          />
          <label className="custom-file-label" htmlFor="customFile">
            {fileName || t('CHOOSE_FILE')}
          </label>
        </div>
        <div className="file-size-info mt-1">
           {t('MAXIMUM_FILE_SIZE')+ ' ' + this.props.maximumSizeFile } MB
        </div>
        {error && <div className="error-message">{error}</div>}
        {uploadProgress > 0 && (
          <div className="progress">
            <div
              className={`progress-bar ${uploadProgress === 100 && 'full'}`}
              role="progressbar"
              style={{ width: `${uploadProgress}%` }}
            >
              {uploadProgress}%
            </div>
          </div>
        )}
        {uploadProgress === 100 && (
          <Button onClick={this.deleteFile} type="button" className="mt-2" theme="warning">
           {t("DELETE_FILE")}
          </Button>
        )}
      </div>
    );
  }
}

export default withTranslation()(CustomFileWithProgressUpload);

CustomFileWithProgressUpload.defaultProps = {
  onProgressUpdate: () => {},
  onFileUpload: () => {},
};

CustomFileWithProgressUpload.propTypes = {
  onProgressUpdate: PropTypes.func,
  onFileUpload: PropTypes.func,
  maximumSizeFile: PropTypes.number.isRequired
};
