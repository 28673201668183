import axios from 'axios';
import { authUser } from './userService'; 

const API_URL = process.env.REACT_APP_EP_API;

export const fetchRecognizeInbox = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  try {
    const response = await axios.get(`${API_URL}/workflow/recognize?context=inbox`, {
      headers: authHeader
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching recognize inbox:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchRecognizeHistory = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  try {
    const response = await axios.get(`${API_URL}/workflow/recognize?context=history`, {
      headers: authHeader
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching recognize history:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchRecognizeOpportunityById = async (opportunityId) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  try {
    const response = await axios.get(`${API_URL}/opportunity/${opportunityId}?viewFrom=recognize`, {
      headers: authHeader
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching recognize opportunity by ID:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const submitRecognizeEvaluation = async (data) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };
  
    const formData = new FormData();
  
    // Conditionally append each field only if it has a value
    if (data.action) formData.append("action", data.action);
    if (data.reason) formData.append("reason", data.reason);
    if (data.question) formData.append("question", data.question);
    if (data.likelyToApproveInFuture) formData.append("likelyToApproveInFuture", data.likelyToApproveInFuture);
    if (data.fileId) formData.append("fileId", data.fileId);
    if (data.economicImpactAmount) formData.append("forecast[economicImpactAmount]", data.economicImpactAmount);
    if (data.impactTime) formData.append("forecast[impactTime]", data.impactTime);
    if (data.recurringImpact) formData.append("forecast[recurringImpact]", data.recurringImpact);
    if (data.committeeId) formData.append("committeeId", data.committeeId);
    if (data.implementationLeaderId) formData.append("implementationLeaderId", data.implementationLeaderId);
    if (data.coachIds) formData.append("coachIds", data.coachIds);
  
    try {
      const response = await axios.post(
        `${API_URL}/workflow/recognize/${data.opportunityId}`, 
        formData, 
        { headers: authHeader }
      );
      return response.data;
    } catch (error) {
      console.error('Error submitting recognize evaluation:', error.response ? error.response.data : error.message);
      throw error;
    }
  };

  export const submitRecognitionReward = async (recognitionId, data) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };
  
    const formData = new FormData();
    console.log(data.attachment)
    // Append required fields
    data.level && formData.append("level", data.level);
    data.ownerId && formData.append("ownerId", data.ownerId);
    data.type && formData.append("type", data.type);
    data.comments && formData.append("comments", data.comments);
    data.attachment.fileId && formData.append("attachment", data.attachment.fileId);
    data.attachment.fileId && formData.append("fileId", data.attachment.fileId); // Both append if necessary
    data.selectedDate && formData.append("targetDate", data.selectedDate);

  
    // Append employeeIds one by one
    if (Array.isArray(data.employeeIds) && data.employeeIds.length > 0) {
      data.employeeIds.forEach((id, index) => {
        formData.append(`employeeIds`, id);
      });
    }
  
    // Conditionally append economicReward and amountReward
    if (data.amountReward && data.amountReward.trim() !== '') {
      formData.append("economicReward", 'true');  // Set true if there's a reward amount
      formData.append("amountReward", data.amountReward);
    } else {
      formData.append("economicReward", 'false');  // Set false if no reward amount
    }
  
    try {
      const response = await axios.post(
        `${API_URL}/workflow/recognize/${recognitionId}`,
        formData,
        { headers: authHeader }
      );
      return response.data;
    } catch (error) {
      console.error('Error submitting recognition reward:', error.response ? error.response.data : error.message);
      throw error;
    }
  };
  
  export const deleteRecognizeById = async (opportunityId, recognitionId) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };
  
    const formData = new FormData();
    
    // Append the recognition ID to the form data
    formData.append("recognizeId", recognitionId);
  
    try {
      const response = await axios.delete(`${API_URL}/workflow/recognize/${opportunityId}`, {
        headers: {
          ...authHeader,
          'Content-Type': 'multipart/form-data'
        },
        data: formData // Send form data in the DELETE request
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting recognize by ID:', error.response ? error.response.data : error.message);
      throw error;
    }
  };
  
  
  export const updateRecognitionRewardById = async (recognitionId, data) => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };
  
    const formData = new FormData();
  
    try {
      const response = await axios.patch(
        `${API_URL}/workflow/recognize/${recognitionId}`,
        formData,
        { 
          headers: {
            ...authHeader,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating recognition reward:', error.response ? error.response.data : error.message);
      throw error;
    }
  };
  