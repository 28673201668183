import moment from 'moment';
import Parse from 'parse';
import { object } from "prop-types";
import React from "react";
import { withTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Col,
  DatePicker,
  Form,
  FormInput,
  FormRadio,
  FormTextarea,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'shards-react';
import Swal from 'sweetalert2';
import { ReactComponent as BarsImage } from "../../images/bars.svg";
import { ReactComponent as DollarImage } from "../../images/dollar-symbol.svg";
import { ReactComponent as LineChartImage } from "../../images/line-chart.svg";
import { ReactComponent as RibbonImage } from "../../images/ribbon.svg";
import verifyInboxService from '../../services/verifyInboxService';
import CustomFileWithProgressUpload from "../components-overview/CustomFileWithProgressUpload";
import FormSectionTitle from "../edit-user-profile/FormSectionTitle";

class IdeaVerificationForm extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        data:[],
        ideaQuestions: [],
        answers:[],
        category:null,
        comments: null,
        department:null,
        date: '',
        categoryQuestions: [],
        filterQuestions: [],
        selectedFilterQ: [],
        filterQAnswers: [],
        categoryQAnswers: [],
        ideaDescription: null,
        file:null, 
        buttonNext:true,
        descriptionValid:'',
        remainingCharacters: 250,
        visible: true,
        filterVisible: false,
        filterQuestionsVisible: false, 
        ideaQuestionsVisible: false,
        hideNextButton: false,
        userName: 'User Name',
        sectionTitle:'',
        formButtonTitle: 'Continuar',
        ideaNumber: '#00008',
        status: '',
        executionRes: 0,
        executionResObj: object,
        selectedBenefit:'',
        selectedResult: '',
        money: '',
        selectedImprovement: '',
        selectedImpact: '',
        selectedCoachBackup: '',
        isRecognized: '',
        type: '',
        uploadProgress: 100,
        
        modalMailToVisible: false,
        emailSubject: '',
        emailBody: '',
      }

      this.change = this.change.bind(this);
      this.setCategory = this.setCategory.bind(this);
      this.setDate = this.setDate.bind(this);
      this.setIdeaDescription = this.setIdeaDescription.bind(this);
      this.showNext = this.showNext.bind(this);
      this.addAnswer = this.addAnswer.bind(this);
      this.saveIdea = this.saveIdea.bind(this);
      this.showNext = this.showNext.bind(this);
      this.changeMoney = this.changeMoney.bind(this);
      this.changeType = this.changeType.bind(this);

      this.toggleMailToModal = this.toggleMailToModal.bind(this);
      this.sendMailTo = this.sendMailTo.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
    }

    toggleMailToModal() {
      this.setState(prevState => ({
        modalMailToVisible: !prevState.modalMailToVisible,
        emailSubject: '',
        emailBody: '',
      }));
    }

    componentDidMount() {
      let currUser = Parse.User.current();
      this.getUserName(currUser);
      this.fetchNewData();
      this.fetchQuestions();
      this.fetchFilterQuestions();
      this.getDate();
    }

    getDate() {
      this.setState({
        date: new Date()
      });
    }

    async getUserName(user) {
      this.setState({
        userName: 'nombre12'
      });
    }

    handleSubmit() {
      const { formButtonTitle } = this.state;
      
      if (formButtonTitle == "Continuar") {
        this.showNext();
      } else {
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
          //  
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas requeridas.");
        } else {
        alert("Su IDEA ha sido sometida.");
      }
    }
  }

    showNext() {
      const isShowingForm = this.state.visible;
      const isShowingFilter = this.state.filterVisible;
      const isShowingFilterQuestions = this.state.filterQuestionsVisible;
      const isShowingQuestions = this.state.ideaQuestionsVisible;

      if (isShowingForm && !isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {

        const { category, department, descriptionValid } = this.state;

        if (!category || !department || !descriptionValid) {
          alert("Por favor seleccione una categoria, un departamento y asegurese de que la descripcion de la idea sea valida.");
        } else {
          this.setState({
            visible: !isShowingForm,
            filterVisible: !isShowingFilter,
            buttonState: false,
            hideNextButton: true,
          });
        }
      } else if (!isShowingForm && isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {
        this.setState({
          hideNextButton: false,
          filterVisible: !isShowingFilter,
          filterQuestionsVisible: !isShowingFilterQuestions,
          buttonState: false,
        });
      } else if (!isShowingForm && !isShowingFilter && isShowingFilterQuestions && !isShowingQuestions) {
        var allAnswered = false;
        //  
        //  
        var isInvalid = false
        this.state.filterQAnswers.forEach((item,idx) => {
          //  
          if (!item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas.");
        } else {
          this.setState({
            filterQuestionsVisible: !isShowingFilterQuestions,
            ideaQuestionsVisible: !isShowingQuestions,
            buttonState: false,
            formButtonTitle: 'Someter',
          });
        }
      } else if (!isShowingForm && !isShowingFilter && !isShowingFilterQuestions && isShowingQuestions) {
        //  
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
          //  
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Please answer all required questions.");
        } else {
          alert('¡Congrats! Thanks for submitting your idea.', 
          this.resetIdeaForm());
        }
      }
      //  
      //  
      //  
      //  
      //  
    }

    resetIdeaForm() {
      window.location.reload();
    }

    fetchNewData() {
      const className = "IdeaDepartment";

      var ItemClass = Parse.Object.extend(className);
      var query = new Parse.Query(ItemClass);

      query.find()
      .then((results) => {
          this.setState({
              data: results
          });
          //  
      }, (error) => {
          this.setState({
              data: []
          });
        // The object was not retrieved successfully.
        // error is a Parse.Error with an error code and message.
      });
    }

    fetchQuestions() {
      const className = "IdeaQuestion";

      var ItemClass = Parse.Object.extend(className);
      var query = new Parse.Query(ItemClass);

      query.find()
      .then((results) => {
        //  
          this.setState({
              ideaQuestions: results
          });
      }, (error) => {
          this.setState({
              data: []
          });
        // The object was not retrieved successfully.
        // error is a Parse.Error with an error code and message.
      });
    }

    fetchFilterQuestions() {
      const className = "FilterQuestion";

      var ItemClass = Parse.Object.extend(className);
      var query = new Parse.Query(ItemClass);
      query.find()
      .then((results) => {
        //  
          this.setState({
              filterQuestions: results
          });
      }, (error) => {
          this.setState({
              filterQuestions: []
          });
        // The object was not retrieved successfully.
        // error is a Parse.Error with an error code and message.
      });
    }

    handleFilterChange(newFilter) {
      const newCategory = newFilter;
      const { filterQuestions, selectedFilterQ } = this.state;
      //  
      //  
      
      var filteredData;
      if (newCategory !== 'Todas') {
        filteredData = filterQuestions.filter(item => item.get("filter") === newCategory);
      }  else {
        filteredData = filterQuestions;
      }
      
      this.setState({
        selectedFilterQ: filteredData
      }, this.addFilterAnswer(filteredData));
      //  
      this.showNext();
    }

    handleCategoryChange(selectedCategory) {
      const newCategory = selectedCategory;
      const { ideaQuestions } = this.state;

      var filteredData = ideaQuestions.filter(item => item.get("category") === newCategory );
            
      this.setState({
        categoryQuestions: filteredData
      }, this.addAnswer(filteredData));
    }

    onSubmit = e => {
      e.preventDefault();
      alert('Form submitted');
    };

    saveIdea() {
      // Simple syntax to create a new subclass of Parse.Object.
      var Idea = Parse.Object.extend("Idea");
      // Create a new instance of that class.
      var ideaInfo = new Idea();

      const currentUser = Parse.User.current();
      const userId = currentUser.get("username");
    
      // IDEA Properties
      ideaInfo.set("proponent", userId);
      ideaInfo.set("department",this.state.department);
      ideaInfo.set("category", this.state.category);
      ideaInfo.set("date", this.state.date);
      ideaInfo.set("description", this.state.ideaDescription);
      ideaInfo.set("file", this.state.file);
      ideaInfo.set("status", "saved");
      ideaInfo.set("progress", [0,100]);
      ideaInfo.set("customUpdatedAt", new Date());
    }

    resetForm() {
      this.setState({proponent: '', department: '', category: '', ideaDescription:'', date: new Date(),file: '', remainingCharacters: 250, descriptionValid:''});
    }

    change(event) {
      // this.setState({category: event.target.value});
      this.setState({department: event.target.value});
      //  
    }

    setCategory(categoryName) {
      this.setState({
        category: categoryName
      })

      this.handleCategoryChange(categoryName)
      //  
    }

    setFilter(filterName) {
      //  
      // this.setState({sectionTitle: (filterName === "innovacion")?"INNOVACION":"RESOLUCION DE PROBLEMAS"});
      this.handleFilterChange(filterName);
    }

    clickedPrint() {
       
    }

    setDate(ideaDate) {
      this.setState({
        date: ideaDate
      })
      //  
    }

    filterQuestionAnswerChange(event, idx) {
      //  
      //  
      // const newObj = {'question':this.state.filterQuestions[idx], 'answer': event.target.value }
      this.state.filterQAnswers[idx].answer = event.target.value;
      //  
      // const newArray = this.state.filterQAnswers
      // this.setState({filterQAnswers: newArray},  
      // this.setState({department: event.target.value});
      //  
    }

    questionAnswerChange(event, idx) {
      //  
      //  
      // const newObj = {'question':this.state.filterQuestions[idx], 'answer': event.target.value }
      this.state.answers[idx].answer = event.target.value;
      //  
      // const newArray = this.state.filterQAnswers
      // this.setState({filterQAnswers: newArray},  
      // this.setState({department: event.target.value});
      //  
    }

    setIdeaDescription(event) {
      const description = event.target.value;
      const maxCharacters = 250;
      const charCount = maxCharacters - description.length
      var isValid = null;

      if (charCount < 0 && description.length > 0) {
        isValid = false
      } else if (charCount > 0 && description.length > 0) {
        isValid = true
      } else {
        isValid = null
      }

      //  
      this.setState({
        descriptionValid: isValid,
        ideaDescription: description,
        remainingCharacters: charCount
      })
      //  
    }

    addAnswer(filteredQuestions) {
      filteredQuestions.map((item, idx) => (
        //  
        this.setState((prevState) => ({
          answers: [...prevState.answers, {question:item.get("question"), answer:"", required: item.get("required")}],
        }))
      ))
      //  
    }

    addFilterAnswer(filteredData) {
      //  
    
      var newItems = [];
      filteredData.forEach((item, idx) => {
        newItems.push({question:item.get("question"), answer:''});
      })

      this.setState({ filterQAnswers: newItems}, () => {
          //  
        });
    }

    async submitEvaluation() {
      try {
          const { selectedResult, money, selectedCoachBackup, selectedImpact, selectedImprovement, isRecognized, type, file, uploadProgress, comments } = this.state;
          const { t } = this.props;
          var ideaItem = this.props.ideaItem;
         
          const moneyValue = parseFloat(money);
  
          // Alert for missing fields
          if (selectedResult === '' || selectedImprovement === '' || isRecognized === '' || money === '' || uploadProgress < 100) {
            if (uploadProgress !== 100) {
              alert('Please wait for the file to finish uploading.');
              return;
            }
            
            alert('Please fill out all required fields.');
            return;
          }
          
          const verificationData = {
              action: 'submit',
              goalsMet: selectedResult,
              processImprovement: selectedImprovement,
              financialImpact: moneyValue,
              replicationPotential: selectedImpact === 'Yes',
              additionalComments: comments,
              fileId: file ? file.fileId : null,
          };
  
          // Call the submitVerification service
          const verificationResponse = await verifyInboxService.submitVerification(ideaItem.id, verificationData);
  
          alert(verificationResponse.message);
          this.props.dismissModal()
          
      } catch (error) {
          console.error('Error submitting evaluation:', error);
          alert(error.message);
      }
  }

  
  

    changeStatus(selection) {
      this.setState({status: selection});
       
    }

    changeResponsible(res, idx) {
       
      this.setState({
        executionResObj: res,
        executionRes: idx
      });
       
    }

    async getHumanResourceUsers() {
      var query = new Parse.Query(Parse.User);
      query.equalTo("humanResources", true);
      const results = await query.find();
       
      return results
    }

    async setVerificationNotifications() {
      const allUsers= await this.getHumanResourceUsers();
      for (var u in allUsers) {
        allUsers[u].increment("notificationCount");
        var responsibleNotifications = allUsers[u].get("notificationMessages");
        responsibleNotifications.unshift("'¡Congrats! You have ideas to evaluate.'");
        allUsers[u].set("notificationMessages", responsibleNotifications);
        allUsers[u].set("customUpdatedAt", new Date());
        await allUsers[u].save().catch(error => {
          // This will error, since the Parse.User is not authenticated
        });
      }
    }

    async setNotifications() {
      const responsibleMsg = '¡Congrats! Your idea has been verified.';

      var ideaItem = this.props.ideaItem;

      var responsibleUser = ideaItem.get("responsible");

      var responsibleNotifications = responsibleUser.get("notificationMessages");
      responsibleNotifications.unshift(responsibleMsg);

      responsibleUser.increment("notificationCount");
      responsibleUser.set("notificationMessages", responsibleNotifications);
      responsibleUser.set("customUpdatedAt", new Date());

      var users = new Parse.Query("Users");

      if(this.state.isRecognized) {
        await Parse.Object.saveAll(users, {useMasterKey: true}).then(()=> this.setVerificationNotifications())
        .catch(error => {
           
       });
      } else {
        await Parse.Object.saveAll(users, {useMasterKey: true})
        .catch(error => {
           
       });
      }
    }

    changeBenefit(response) {
        this.setState({
          selectedBenefit: response
        });
      }

    changeResults(response) {
    this.setState({
        selectedResult: response
    });
    }

    changeImprovement(response) {
    this.setState({
        selectedImprovement: response
    });
    }

    changeType(response) {
      this.setState({
        type: response
      });
    }

    changeImpact(response) {
      this.setState({
        selectedImpact: response
      });
    }

    changeBackup(response) {
      this.setState({
        selectedCoachBackup: response
      });
    }

    changeMoney(e) {
      const inputValue = e.target.value;
    
      if (inputValue === '' || /^[0-9]*\.?[0-9]*$/.test(inputValue)) {
        this.setState({ money: inputValue === '' ? null : parseFloat(inputValue) || inputValue });
      } else {
        e.target.value = inputValue.slice(0, -1);
      }
    }
    
    changeRecognition(response) {
      this.setState({
        isRecognized: response
      });
    }


    async sendMailTo() {
      const { emailSubject, emailBody } = this.state;
     
      try {
        
        const data = {
          subject: emailSubject,
          body: emailBody,
        };
        const response = await verifyInboxService.requestInformation(this.props.ideaItem.id, data);
        if (response.message) {
          Swal.fire("Email Sent", response.message, "success");
        } 
      } catch (error) {
        Swal.fire("Error", error.message, "error");
      } finally {
        this.toggleMailToModal();
      }
    }



    handleInputChange(event) {
      const { name, value } = event.target;
      this.setState({ [name]: value });
    }
    
    render() {
      const { modalMailToVisible, emailSubject, emailBody } = this.state;
      const {visible, hideNextButton } = this.state
      const formVisibilityState = visible? 'block' : 'none';
      const nextButtonVisibilityState = !hideNextButton? 'inline' : 'none';
      const ideaItem = this.props.ideaItem;
      const { t } = this.props;
        return(

                  <Card small className="edit-user-details mb-4">
                    {/* <ProfileBackgroundPhoto /> */}
    
                    <CardBody className="p-0">
    
                      {/* Form Section Title :: General */}
                      <Form className="py-4"
                      onSubmit={this.onSubmit}
                      noValidate
                      >
                        <FormSectionTitle
                          title={ideaItem.title}
                          description=""
                        />
                        {/* VISIBILITY */}
                        <div style={{display: formVisibilityState}}>
                        <Row form className="mx-4">
                          <Col lg="12">
                            <Row form>
                              {/* Proponent */}
                              <Col md="3" className="form-group">
                                <label htmlFor="firstName">{t("IDEA_MANAGE_PROPONENT")}</label>
                                <FormInput
                                  id="firstName"
                                  value={ideaItem.proponent}
                                  onChange={() => {}}
                                  required
                                  disabled
                                />
                              </Col>
    
                              {/* Date */}
                              <Col md="3" className="form-group">
                              <label htmlFor="lastName">{t("SUBMIT_IDEA_Date")}</label>
                              <InputGroup>
                                  <InputGroupAddon type="append">
                                    <InputGroupText>
                                      <i className="material-icons">&#xE916;</i>
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <DatePicker
                                    placeholderText={moment(ideaItem.submittedAt).format('DD MMMM YYYY')}
                                    dropdownMode="select"
                                    className="text-center"
                                    readOnly = "true"
                                    required
                                  />
                                </InputGroup>
                              </Col>
                              {/* Department */}
                              <Col md="3" className="form-group">
                                <label htmlFor="userLocation">{t("IDEA_MANAGE_DEPARTMENT")}</label>
                                <FormInput
                                  id="firstName"
                                  value={ideaItem.department}
                                  onChange={() => {}}
                                  required
                                  disabled
                                />
                              </Col>
                              {/* Department */}
                              <Col md="3" className="form-group">
                                <label htmlFor="userLocation">{t("IDEA_MANAGE_CATEGORY")}</label>
                                <FormInput
                                  id="firstName"
                                  value={ideaItem.category}
                                  onChange={() => {}}
                                  required
                                  disabled
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
    
                        <br/>

                        <FormSectionTitle
                          title={t("EVAL_IDEA_VERIFICATION")}
                          description=""
                        />
                        <Row form className="mx-4">
                        {/* Categoria */}
                            <Col md="4" className="form-group">
                                {/* <EvaluationSelect setCategory={(selection) => this.changeStatus(selection)}/> */}
                                <Row form className="mx-4">
                                <Col md="3"><BarsImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                <Col md="9">
                                <p className="mb-2">{t("EVAL_IDEA_BENEFITS")}: *</p>
                                <FormRadio
                                    inline
                                    name="results"
                                    checked={this.state.selectedResult === true}
                                    onChange={() => {
                                    this.changeResults(true);
                                    }}
                                >
                                    {t("EVAL_IDEA_YES")}
                                </FormRadio>
                                <FormRadio
                                    inline
                                    name="results"
                                    checked={this.state.selectedResult=== false}
                                    onChange={() => {
                                    this.changeResults(false);
                                    }}
                                >
                                    {t("EVAL_IDEA_NO")}
                                </FormRadio>
                                </Col>
                                </Row>
                            </Col>
                            <Col md="4" className="form-group">
                                {/* <EvaluationSelect setCategory={(selection) => this.changeStatus(selection)}/> */}
                                <Row form className="mx-4">
                                <Col md="3"><LineChartImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                <Col md="9">
                                <p className="mb-2">{t("EVAL_IDEA_IMPROVEMENT")}: *</p>
                                <FormRadio
                                    inline
                                    name="improvement"
                                    checked={this.state.selectedImprovement === true}
                                    onChange={() => {
                                    this.changeImprovement(true);
                                    }}
                                >
                                    {t("EVAL_IDEA_YES")}
                                </FormRadio>
                              <FormRadio
                                    inline
                                    name="improvement"
                                    checked={this.state.selectedImprovement === false}
                                    onChange={() => {
                                    this.changeImprovement(false);
                                    }}
                                >
                                    {t("EVAL_IDEA_NO")}
                                </FormRadio>
                                </Col>
                                </Row>
                            </Col>
                            {/* <Col md="4" className="form-group">
                                <Row form className="mx-4">
                                <Col md="3"><UserImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                <Col md="9">
                                <p className="mb-2">El coach apoyó en la gestion de la idea:</p>
                                <FormRadio
                                    inline
                                    name="backup"
                                    checked={this.state.selectedCoachBackup === true}
                                    onChange={() => {
                                    this.changeBackup(true);
                                    }}
                                >
                                    Si
                                </FormRadio>
                                <FormRadio
                                    inline
                                    name="backup"
                                    checked={this.state.selectedCoachBackup=== false}
                                    onChange={() => {
                                    this.changeBackup(false);
                                    }}
                                >
                                    No
                                </FormRadio>
                                </Col>
                                </Row>
                            </Col> */}
                             <Col md="4" className="form-group">
                                {/* <EvaluationSelect setCategory={(selection) => this.changeStatus(selection)}/> */}
                                <Row form className="mx-4">
                                    <Col md="3"><DollarImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                    <Col md="9">
                                        <p className="mb-2">{t("EVAL_IDEA_FINRES")}: *</p>
                                        <FormInput onChange={this.changeMoney} placeholder="USD $" value={this.state.money}/>
                                    </Col>
                                </Row>
                                <br/>
                                {/* <Row form className="mx-4">
                                    <Col md="3"></Col>
                                    <Col md="9">
                                    <p className="mb-2">Type:</p>
                                    
                                    <FormRadio
                                      inline
                                      name="type"
                                      checked={this.state.type=== 'Cost Avoidance'}
                                      onChange={() => {
                                      this.changeType('Cost Avoidance');
                                      }}
                                  >
                                      Cost Avoidance
                                  </FormRadio>
                                  <FormRadio
                                      inline
                                      name="type"
                                      checked={this.state.type === 'VIP'}
                                      onChange={() => {
                                      this.changeType('VIP');
                                      }}
                                  >
                                      VIP
                                  </FormRadio>
                                    </Col>
                                </Row> */}
                            </Col>
                        </Row>
                        <Row form className="mx-4">
                        {/* Categoria */}
                            {/* <Col md="4" className="form-group">
                                <Row form>
                                    <Col md="3"><DollarImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                    <Col md="9">
                                        <p className="mb-2">Resultado financiero:</p>
                                        <FormInput onChange={this.changeMoney} placeholder="USD $" />
                                    </Col>
                                </Row>
                            </Col> */}
                            
                            <Col md="4" className="form-group">
                                {/* <EvaluationSelect setCategory={(selection) => this.changeStatus(selection)}/> */}
                                <Row form className="mx-4">
                                <Col md="3"><RibbonImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                <Col md="9">
                                {/* <p className="mb-2">{t("EVAL_IDEA_RECOG")}:</p> */}
                                <p className="mb-2">{t("REPLICATED_AREA_BUSINESS")} *</p>
                                
                                <FormRadio
                                    inline
                                    name="isRecognized"
                                    checked={this.state.isRecognized === true}
                                    onChange={() => {
                                    this.changeRecognition(true);
                                    }}
                                >
                                    {t("EVAL_IDEA_YES")}
                                </FormRadio>
                                <FormRadio
                                    inline
                                    name="isRecognized"
                                    checked={this.state.isRecognized=== false}
                                    onChange={() => {
                                    this.changeRecognition(false);
                                    }}
                                >
                                    {t("EVAL_IDEA_NO")}
                                </FormRadio>
                                </Col>
                                </Row>
                            </Col>
                            <Col md="4" className="form-group">
                                {/* <EvaluationSelect setCategory={(selection) => this.changeStatus(selection)}/> */}
                                <Row form className="mx-4">
                                <Col md="3"><RibbonImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                <Col md="9">
                                {/* <p className="mb-2">{t("EVAL_IDEA_RECOG")}:</p> */}
                                <p className="mb-2">{t("ADDITIONAL_COMMENTS")}:</p>
                                
                                  <FormInput onChange={(event)=>{
                                    this.setState({comments: event.target.value})
                                  }} placeholder="Write comments..." />
                                </Col>
                                </Row>
                            </Col>
                            <Col md="4" className="form-group">
                                {/* <EvaluationSelect setCategory={(selection) => this.changeStatus(selection)}/> */}
                                <Row form className="mx-4">
                                {/* <Col md="3"><DocumentImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col> */}
                                <Col md="9" className="ml-4">
                                {/* <p className="mb-2">{t("EVAL_IDEA_IMPACT")}:</p> */}
                                
                                <p className="mb-2">{t("Add an attachment")}:</p>
                                <span><CustomFileWithProgressUpload maximumSizeFile={10}
                                onProgressUpdate={(progress) => {
                                  this.setState({uploadProgress: progress})
                                }}
                                onFileUpload={(file) => {
                                  this.setState({ file })
                                }}
                                /></span> 
                                </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row form className="mx-4">
                        {/* Categoria */}
                            {/* <Col md="4" className="form-group">
                                <Row form>
                                    <Col md="3"><DollarImage className="mx-auto d-block" style={{minWidth: 50, maxWidth:50, minHeight: 50, maxHeight: 50,fill: 'black'}}/></Col>
                                    <Col md="9">
                                        <p className="mb-2">Resultado financiero:</p>
                                        <FormInput onChange={this.changeMoney} placeholder="USD $" />
                                    </Col>
                                </Row>
                            </Col> */}
                            
                        </Row>
                        </div>
                        </Form>
                    </CardBody>
                    <CardFooter className="border-top">
                      <ButtonGroup size="sm" className="ml-auto d-table mr-3">
                        <Button theme="secondary" style={{marginRight:10}} onClick={this.toggleMailToModal}>Request Additional Information</Button>
                        <Button pill theme="accent" onClick={this.submitEvaluation.bind(this)} style={{display: nextButtonVisibilityState}} >{t("EVAL_IDEA_BUTTON")}</Button>
                      </ButtonGroup>
                    </CardFooter>
                     <Modal open={modalMailToVisible} toggle={this.toggleMailToModal}>
                    <ModalHeader>Request Additional Information</ModalHeader>
                    <ModalBody>
                      <Form>
                        <Row form>
                          <Col md="12" className="form-group">
                            <p htmlFor="emailSubject" style={{marginBottom:0}}>Subject:</p>
                            <FormInput
                              id="emailSubject"
                              name="emailSubject"
                              value={emailSubject}
                              onChange={this.handleInputChange}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="12" className="form-group">
                            <p htmlFor="emailBody" style={{marginBottom:0}}>Body:</p>
                            <FormTextarea
                              id="emailBody"
                              name="emailBody"
                              type="textarea"
                              style={{height: 200}}
                              value={emailBody}
                              onChange={this.handleInputChange}
                            />
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                    <ModalFooter>
                      <Button theme="secondary" onClick={this.toggleMailToModal}>
                        Cancel
                      </Button>
                      <Button theme="primary" onClick={this.sendMailTo}>
                        Send Email
                      </Button>
                    </ModalFooter>
                  </Modal>
                  </Card>
          );
    }
}




export default withTranslation()(IdeaVerificationForm);
