import { toInteger } from "lodash";
import moment from 'moment';
import Parse from 'parse';
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import ReactTable from "react-table";
import {
    Card, CardBody,
    CardFooter, CardTitle, Col, Container, Row
} from "shards-react";
import AreaChart from "../components/charts/AreaChart";
import LineChart from "../components/charts/LineChart";
import MultiLineChart from "../components/charts/MultiLineChart";
import SankeyChart from "../components/charts/SankeyChart";
import PageTitle from "../components/common/PageTitle";
import colors from "../utils/colors";

const blueColor = '#3A7BBB'
const orangeColor = '#FD902c'
const greenColor = '#7FA86F'
const goldColor = '#DDB153'

const parseInstance2 = Parse.initialize(
  'myappId',
  'FerB90c4hukp'
);
Parse.serverURL = 'https://baxter-server.murmuratto.com/parse';

class EnterpriseR2 extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      ideas: [],
      numSubmitted: '',
      numCompleted: '',
      numInProgress: '',
      numPendingEval: '',
      numCompletedOther: '',
      numInProgressOther: '',
      numPendingEvalOther: '',
      numSubmittedOther: '',
      departments: '',
      categories: [],
      otherCategories: [],
      ideaByDepartmentData: [],
      ideasPerSite: [],
      ideasBySiteData: [],
      progressByCategoryData: [],
      returnsByCategoryData: [],
      actualReturnsByCategoryData: [],
      completedIdeas: [],
      completeIdeas: [],
      ideasInProgress: [],
      ideasPerCats: [],
      ideaResults: [],
      showChart: false,
      showIdeasByDeptChart: false,
      showProgressByCatChart: false,
      showEarningsByCatChart: false,
      exportData: {},
      ideaDataCsv: [],
      verifications: [],
      otherVerifications: [],
      showChartActual: false,
      otherIdeaResults: [],
      selectedOption: 'All Sites',
      showIdeasBySiteData: false,
      numVerifiedOther: '',
      numVerified: '',
      numEmployees: 0,
      numEmployeesOther: 0,
      selectedDate: 'All Time',
      selectedDateValue: 'All Time'

    }

    this.getIdeas = this.getIdeas.bind(this);
    this.getResults = this.getResults.bind(this);
    this.getDepartments = this.getDepartments.bind(this);
    this.getCategories = this.getCategories.bind(this);
  }

  componentWillMount() {
    this.getIdeas();
    this.getResults();
    this.getUsers();
    this.getDepartments();
    this.tryFetch()
    this.getOtherVerification()
    this.getOtherCategories()
    this.getUsersOther()
    // this.getNumOfEmployeesWhoHaveSubmittedIdeas()
  }

  handleOptionChange = (newSite) => {
    this.setState({
      selectedOption: newSite.target.value
    });
  }

  handleDateChange = (newDate) => {
    if (newDate.target.value == 'All Time') {
      this.setState({
        selectedDate: 'All Time',
        selectedDateValue: newDate.target.value
      }, () => {      
        this.getIdeas();
        this.getResults();
        this.getUsers();
        this.tryFetch()
        this.getOtherVerification()
        this.getOtherCategories()
        this.getUsersOther()
      });
    } else {
      const numOfDays = parseInt(newDate.target.value)
    
      // Use moment to get a date that is numOfDays ago
      const correctedDate = moment().subtract(numOfDays, 'days').startOf('day')

      this.setState({
        selectedDate: correctedDate,
        selectedDateValue: newDate.target.value
      }, () => {      
        this.getIdeas();
        this.getResults();
        this.getUsers();
        this.tryFetch()
        this.getOtherVerification()
        this.getOtherCategories()
        this.getUsersOther()
      });
    }

    
  }

  async getUsers() {
    const  { selectedDate } = this.state;
    const query = new Parse.Query(Parse.User);

    const queryDate = selectedDate
    if (queryDate !==  'All Time') {
      query.greaterThan("createdAt", queryDate.toISOString())
    }

    const results = await query.find();
    this.setState({
      numEmployees: results.length
    })
  }

  async tryFetch() {
    const { selectedDate } = this.state;

    var correctUrl = 'https://baxter-server.murmuratto.com/parse/classes/Idea?limit=500'

    if (selectedDate !== 'All Time') {
      const queryDate = selectedDate
      correctUrl = 'https://baxter-server.murmuratto.com/parse/classes/Idea?limit=500&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}'
    }


    fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
        'X-Parse-Master-Key': 'FerB90c4hukp'
      }
    })
      .then(response => response.json())
      .then(data => {
        // const Idea = Parse.Object.extend('Idea');
        const results = data.results.map(result => {
          const Idea = Parse.Object.extend('Idea');
          var newIdea = new Idea();

          for (const property in result) {
            if (result.hasOwnProperty(property)) {
              newIdea.set(property, result[property]);
            }
          }

          return newIdea
        });

         

        const completedIdeas = results.filter(idea => idea.get("progress")[0] == 100);

        const ideasInProgress = results.filter(idea => idea.get("progress")[0] > 0 && idea.get("progress")[0] < 100);
        const ideasPendingEval = results.filter(idea => idea.get("needsEvaluation") == true);
        const ideasUnderFifity = results.filter(idea => idea.get("progress")[0] >= 25 && idea.get("progress")[0] < 50);
        const ideasEvaluated = ideasUnderFifity.filter(idea => idea.get("evaluatedBy") !== undefined);

  
        const verifiedIdeas = results.filter(idea => idea.has("verification"));
        
        
        this.getNumOfEmployeesWhoHaveSubmittedIdeas(results)

        this.setState({ otherIdeaResults: results, numVerifiedOther: verifiedIdeas.length,numCompletedOther: String(completedIdeas.length), numInProgressOther: String(ideasInProgress.length), numPendingEvalOther: String(ideasPendingEval.length), numSubmittedOther: String(results.length) }, () => this.setupIdeasBySiteData());
      })
      .catch(error => console.error(error));
  }

  async getOtherVerification() {
    const { selectedDate } = this.state;

    var correctUrl = 'https://baxter-server.murmuratto.com/parse/classes/Verification?limit=500'

    if (selectedDate !== 'All Time') {
      const queryDate = selectedDate
      correctUrl = 'https://baxter-server.murmuratto.com/parse/classes/Verification?limit=500&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}'
    }

    fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
        'X-Parse-Master-Key': 'FerB90c4hukp'
      }
    })
      .then(response => response.json())
      .then(data => {
        // const Idea = Parse.Object.extend('Idea');
        const results = data.results.map(result => {
          const Idea = Parse.Object.extend('Verification');
          var newIdea = new Idea();

          for (const property in result) {
            if (result.hasOwnProperty(property)) {
              newIdea.set(property, result[property]);
            }
          }

          return newIdea
        });

       

        


        this.setState({ otherVerifications: results });
      })
      .catch(error => console.error(error));
  }

  async getOtherCategories() {
    const { selectedDate } = this.state;

    var correctUrl = 'https://baxter-server.murmuratto.com/parse/classes/IdeaCategory'

    if (selectedDate !== 'All Time') {
      const queryDate = selectedDate
      correctUrl = 'https://baxter-server.murmuratto.com/parse/classes/IdeaCategory&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}'
    }
    
    fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
        'X-Parse-Master-Key': 'FerB90c4hukp'
      }
    })
      .then(response => response.json())
      .then(data => {
        // const Idea = Parse.Object.extend('Idea');
        const results = data.results.map(result => {
          const Idea = Parse.Object.extend('IdeaCategory');
          var newIdea = new Idea();

          for (const property in result) {
            if (result.hasOwnProperty(property)) {
              newIdea.set(property, result[property]);
            }
          }

          return newIdea
        });

        this.setState({ otherCategories: results });
      })
      .catch(error => console.error(error));
  }

  async getUsersOther() {
    const { selectedDate } = this.state;

    var correctUrl = 'https://baxter-server.murmuratto.com/parse/classes/_User?limit=500'

    if (selectedDate !==  'All Time') {
      const queryDate = selectedDate
      correctUrl = 'https://baxter-server.murmuratto.com/parse/classes/_User?limit=500&where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}'
    }

    fetch(correctUrl, {
      headers: {
        'X-Parse-Application-Id': 'myappID',
        'X-Parse-Master-Key': 'FerB90c4hukp'
      }
    })
      .then(response => response.json())
      .then(data => {
        // const Idea = Parse.Object.extend('Idea');
        const results = data.results   
        this.setState({ numEmployeesOther: results.length });
      })
      .catch(error => console.error(error));
  }

  getNumOfEmployeesWhoHaveSubmittedIdeas(ideas) {
    const ideasByProponent = ideas.reduce((countByProponent, idea) => {
      const proponent = idea.get('proponent');
      countByProponent[proponent] = (countByProponent[proponent] || 0) + 1;
      return countByProponent;
    }, {});

    const numIdeasByProponent = Object.keys(ideasByProponent).length;
    
   return numIdeasByProponent
  }

  async getVerification() {
    const { selectedDate } = this.state;
    const VerifyObject = Parse.Object.extend("Verification");
    const displayLimit = 1000;
    const query = new Parse.Query(VerifyObject);
    query.limit(displayLimit)
  
    const queryDate = selectedDate
    if (queryDate !==  'All Time') {
      
      
      // where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}'
      // alert(queryDate)
      query.greaterThan("createdAt", new Date(queryDate))
    }

    const results = await query.find();

    var editedResults = []
    if (this.state.ideas.length > 0) {
      editedResults = results.map((verification) => {
        const idea = this.state.ideas.find(idea => idea.id === verification.get("idea").id);
        verification.set("category", idea.get("category"))
        verification.category = idea.get("category")
        return verification
      })
    } else {
      editedResults = []
    }
   
    this.setupActualEarningsByCategoryData(editedResults)
    this.setState({verifications: editedResults})
    // this.setState({verifications: editedResults}, this.setupActualEarningsByCategoryData())
  }

  setupNumberOfIdeasByCategoryData() {
    const { ideas, categories, otherIdeaResults } = this.state;
  
    const zeroData = categories.map((category) => {
      const categoryName = category.get('itemName');
      const filteredDataCount = ideas.filter((idea) => idea.get('category') === categoryName);
  
      const numOfIdeas = filteredDataCount.length;
      return { x: categoryName, y: numOfIdeas };
    });
  
    const series = [
      {
        name: 'Ideas',
        data: zeroData
      }
    ];

    const demoData = {
      series: series,
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        yaxis: {
          title: {
            text: 'Category'
          }
        },
        xaxis: {
          title: {
            text: 'Number of Ideas'
          },
          labels: {
            formatter: function(val) {
              return val
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            formatter: function(val) {
              return '$' + val.toLocaleString()
            }
          }
        }
      }
    };


  
    return demoData;
  }
  

  async getIdeas() {
    const { otherIdeaResults, selectedDate } = this.state;
    const IdeaObject = Parse.Object.extend("Idea");
    const displayLimit = 1000;
    const query = new Parse.Query(IdeaObject);
    query.limit(displayLimit)

    const queryDate = selectedDate
    if (queryDate !==  'All Time') {
      
      
      // where={"createdAt":{"$gt":{"__type":"Date","iso":"' + queryDate.toISOString() + '"}}}'
      // alert(queryDate)
      query.greaterThan("createdAt", new Date(queryDate))
    }

    const results = await query.find();
    const completedIdeas = results.filter(idea => idea.get("progress")[0] == 100);
    const ideasInProgress = results.filter(idea => idea.get("progress")[0] > 0 && idea.get("progress")[0] < 100);
    const ideasPendingEval = results.filter(idea => idea.get("needsEvaluation") == true);
    // const verifiedIdeas = results.filter(idea => idea.get("verification") != '');
    const verifiedIdeas = results.filter(idea => idea.has("verification"));
    

    // query.equalTo("needsEvaluation", true);
    // query.equalTo("completed", false);
    // query.descending("createdAt");

    this.setState({
      ideas: results,
      completedIdeas: completedIdeas,
      verifiedIdeas: verifiedIdeas.length,
      numVerified: verifiedIdeas.length,
      ideasInProgress: ideasInProgress,
      numCompleted: String(completedIdeas.length),
      numInProgress: String(ideasInProgress.length),
      numSubmitted: String(results.length),
      numPendingEval: String(ideasPendingEval.length),
    }, () => {
       
      this.getDepartments()
    })
  }

  async getIdeasOtherSource() {
    const IdeaObject = parseInstance2.Object.extend("Idea");
    const displayLimit = 1000;
    const query = new parseInstance2.Query(IdeaObject);
    query.limit(displayLimit)
    const results = await query.find();
    const completedIdeas = results.filter(idea => idea.get("progress")[0] == 100);
    const ideasInProgress = results.filter(idea => idea.get("progress")[0] > 0 && idea.get("progress")[0] < 100);
    const ideasPendingEval = results.filter(idea => idea.get("needsEvaluation") == true);

    // query.equalTo("needsEvaluation", true);
    // query.equalTo("completed", false);
    // query.descending("createdAt");
    alert(completedIdeas.length)

    this.setState({
      ideas: results,
      completedIdeas: completedIdeas,
      ideasInProgress: ideasInProgress,
      numCompleted: String(completedIdeas.length),
      numInProgress: String(ideasInProgress.length),
      numSubmitted: String(results.length),
      numPendingEval: String(ideasPendingEval.length),
    })
  }



  async getResults() {
    const  { selectedDate } = this.state;
    const IdeaObject = Parse.Object.extend("Result");
    const query = new Parse.Query(IdeaObject);

    const queryDate = selectedDate
    if (queryDate !==  'All Time') {
      query.greaterThan("createdAt", queryDate.toISOString())
    }

    const results = await query.find();
    this.setState({ ideaResults: results });
  }

  async getCategories() {
    const Category = Parse.Object.extend("IdeaCategory");
    const query = new Parse.Query(Category);
    const results = await query.find();
    this.setState({ categories: results }, () => this.setupProgressByCategoryData());
  }

  async getDepartments() {
    this.getCategories();
    // Modify Idea Data
    this.downloadIdeaData()
    const Department = Parse.Object.extend("IdeaDepartment");
    const query = new Parse.Query(Department);
    const results = await query.find();
    this.setState({ departments: results }, () => {
      this.getVerification();
      // this.setupIdeaByDepartmentData()
    });
  }

  setupIdeaByDepartmentData() {
    const { ideas, categories, completedIdeas, ideasInProgress, departments, numCompleted, numSubmitted, numInProgress } = this.state;
    var inProgressData = [];
    // var completedData = [];
    for (var i in departments) {
      const deptName = departments[i].get("itemName");
      const filteredDataCount = ideasInProgress.filter(idea => idea.get("department") == deptName)
      const newNum = filteredDataCount.length;
      inProgressData.push([deptName, newNum])
    }

    var completedData = [];
    var completedIdeas1 = [];
    var colors = [greenColor, blueColor, orangeColor, goldColor, 'red'];
    for (var i in departments) {
      const deptName = departments[i].get("itemName");
      const filteredDataCount = completedIdeas.filter(idea => idea.get("department") == deptName)
      const newNum = filteredDataCount.length;
      completedData.push([deptName, newNum]);
      completedIdeas1.push({ title: deptName, value: newNum, color: colors[i] });
    }

    var ideasPerCategory = []
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName)
      const newNum = filteredDataCount.length;
      ideasPerCategory.push({ title: categoryName, value: newNum, color: colors[i] });
    }

    var submittedData = [];
    for (var i in departments) {
      const deptName = departments[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("department") == deptName)
      const newNum = filteredDataCount.length;
      submittedData.push([deptName, newNum])
    }

    const exportData = {
      'Submitted': submittedData,
      'In Progress': ideasPerCategory,
      "Completed": completedData
    }

     


    const results = [{
      label: 'Completed',
      data: completedData
    },
    {
      label: 'In Progress',
      data: inProgressData
    },
    {
      label: 'Submitted',
      data: submittedData
    }, []];

     
    this.setState({ ideaByDepartmentData: results, exportData: ideas, completeIdeas: completedIdeas1, ideasPerCats: ideasPerCategory, showIdeasByDeptChart: true });
  }

  // setupIdeaByStatusData() {
  //   const { ideas, categories, completedIdeas, ideasInProgress, departments, numCompleted, numSubmitted, numInProgress } = this.state;
  //   var inProgressData = [];
  //   // var completedData = [];
  //   for (var i in departments) {
  //     const deptName = departments[i].get("itemName");
  //     const filteredDataCount = ideasInProgress.filter(idea => idea.get("department") == deptName)
  //     const newNum = filteredDataCount.length;
  //     inProgressData.push([deptName, newNum])
  //   }

  //   var completedData = [];
  //   var completedIdeas1 = [];
  //   var colors = [greenColor, blueColor, orangeColor, goldColor, 'red'];
  //   for (var i in departments) {
  //     const deptName = departments[i].get("itemName");
  //     const filteredDataCount = completedIdeas.filter(idea => idea.get("department") == deptName)
  //     const newNum = filteredDataCount.length;
  //     completedData.push([deptName, newNum]);
  //     completedIdeas1.push({ title: deptName, value: newNum, color: colors[i] });
  //   }

  //   var ideasPerCategory = []
  //   for (var i in categories) {
  //     const categoryName = categories[i].get("itemName");
  //     const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName)
  //     const newNum = filteredDataCount.length;
  //     ideasPerCategory.push({ title: categoryName, value: newNum, color: colors[i] });
  //   }

  //   var submittedData = [];
  //   for (var i in departments) {
  //     const deptName = departments[i].get("itemName");
  //     const filteredDataCount = ideas.filter(idea => idea.get("department") == deptName)
  //     const newNum = filteredDataCount.length;
  //     submittedData.push([deptName, newNum])
  //   }

  //   const exportData = {
  //     'Submitted': submittedData,
  //     'Evaluated': '',
  //     'In Progress': ideasPerCategory,
  //     'Verified': ideasPerCategory,
  //     "Completed": completedData
  //   }

  //    


  //   const results = [{
  //     label: 'Completed',
  //     data: completedData
  //   },
  //   {
  //     label: 'In Progress',
  //     data: inProgressData
  //   },
  //   {
  //     label: 'Submitted',
  //     data: submittedData
  //   },
  //   {
  //     label: 'Verified',
  //     data: ''
  //   },
  //   {
  //     label: 'Evaluated',
  //     data: ''
  //   }
    
  //   , []];

  //    
  //   this.setState({ ideaByDepartmentData: results, exportData: ideas, completeIdeas: completedIdeas1, ideasPerCats: ideasPerCategory, showIdeasByDeptChart: true });
  // }

  setupIdeasBySiteData() {
    const { ideas, otherIdeaResults } = this.state;
    const allIdeasNum = ideas.concat(otherIdeaResults).length;
    const site1IdeasNum = ideas.length;
    const site2IdeasNum = otherIdeaResults.length;

    // [
    //   {
    //     label: 'Completed',
    //     data: [['Site 1', 25], ['Site 2', 45], ['Site 3', 5]]
    //   },
    //   {
    //     label: 'In Progress',
    //     data: [['Site 1', 20], ['Site 2', 4], ['Site 3', 4]]
    //   },
    //   {
    //     label: 'Submitted',
    //     data: [['Site 1', 5], ['Site 2', 11], ['Site 3', 9]]
    //   },
    // ],

    const results = [{
      label: 'All Sites',
      data: [allIdeasNum]
    },
    {
      label: 'Site 1',
      data: [site1IdeasNum]
    },
    {
      label: 'Site 2',
      data: [site2IdeasNum]
    }, []];

     

    this.setState({ ideasBySiteData: results, showIdeasBySiteData: true });

  }

  setupProgressByCategoryData() {

    this.setupEarningsByCategoryData()

    const { ideas, completedIdeas, ideasInProgress, departments, progressByCategoryData, categories } = this.state;
     
    var zeroData = [];
    // var completedData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 0)
      const newNum = filteredDataCount.length;
      zeroData.push([categoryName, newNum])
    }

    var twentyFiveData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 25)
      const newNum = filteredDataCount.length;
      twentyFiveData.push([categoryName, newNum])
    }

    var fiftyData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 50)
      const newNum = filteredDataCount.length;
      fiftyData.push([categoryName, newNum])
    }

    var seventyFiveData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 75)
      const newNum = filteredDataCount.length;
      seventyFiveData.push([categoryName, newNum])
    }

    var hundredData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName && idea.get("progress")[0] == 100)
      const newNum = filteredDataCount.length;
      hundredData.push([categoryName, newNum])
    }

    const results = [{
      label: '0%',
      data: zeroData
    },
    {
      label: '25%',
      data: twentyFiveData
    },
    {
      label: '50%',
      data: fiftyData
    },
    {
      label: '75%',
      data: seventyFiveData
    },
    {
      label: '100%',
      data: hundredData
    }, []];
     

    this.setState({ progressByCategoryData: results, showProgressByCatChart: true });
  }

  downloadIdeaData() {
    const modifiedData = this.state.ideas.map((idea) => {
      const newData = {
        "Idea Originator": idea.get("proponentName"),
        "Idea Number": idea.get("num"),
        "Idea Title": idea.get("title"),
        "Description": idea.get("description"),
        "Idea Category": idea.get("category"),
        "Idea Type": idea.get("ideaType"),
        "Department": idea.get("department"),
        "Submitted": idea.get("date"),
        "Idea Stage": idea.get("status"),
        "Implementation Owner": idea.get("responsibleName"),
        "Implementation Status (Progress %)": idea.get("progress")[0],
      }

      return (newData)
    })
     
    this.setState({ ideaDataCsv: modifiedData })
  }

  setupEarningsByCategoryData() {
    const { ideas, completedIdeas, ideasInProgress, departments, progressByCategoryData, categories } = this.state;
     
    var zeroData = [];
    // var completedData = [];
    for (var i in categories) {
      const categoryName = categories[i].get("itemName");
      const filteredDataCount = ideas.filter(idea => idea.get("category") == categoryName)
      var earnings = 0
      for (var index in filteredDataCount) {
        // earnings = earnings + filteredDataCount[index]
        earnings = earnings + filteredDataCount[index].get("expectedReturn")
      }

      zeroData.push([categoryName, earnings])
       
    }

    const results = [{
      label: 'Returns',
      data: zeroData
    }, []];

     
    this.setState({ returnsByCategoryData: results, showChart: true });
  }

  // Setup expected return by site
  setupEarningsBySiteData(type) {
    const {ideas, otherIdeaResults } = this.state;

    const correctField = 'expectedReturn'
    
    var expectedEarningsSite1 = 0
    if (ideas) {
      for (var i in ideas) {
        expectedEarningsSite1 = expectedEarningsSite1 + ideas[i].get(correctField)
      }
    }

    var expectedEarningsSite2 = 0
    if (otherIdeaResults) {
      for (var i in otherIdeaResults) {
        expectedEarningsSite2 = expectedEarningsSite2 + otherIdeaResults[i].get(correctField)
      }
    }

    return [expectedEarningsSite1, expectedEarningsSite2]

  }

  // Setups actual return by site
  setupActualEarningsBySiteData() {
    const { verifications, otherVerifications } = this.state;
    
    var actualEarningsSite1 = 0
    if (verifications) {
      for (var i in verifications) {
        actualEarningsSite1 = actualEarningsSite1 + verifications[i].get("money")
      }
    }

    var actualEarningsSite2 = 0
    if (otherVerifications) {
      for (var i in otherVerifications) {
        actualEarningsSite2 = actualEarningsSite2 + otherVerifications[i].get("money")
      }
    }

    return [actualEarningsSite1, actualEarningsSite2]
  }

  setupActualEarningsByCategoryData(verifications) {
    const { ideas, categories, } = this.state;

    var zeroData = [];
    // var completedData = [];
     

    for (var i in categories) {
      const categoryName = categories[i].get("itemName");

      const filteredDataCount = verifications.filter(verification => verification.get("category") == categoryName)
       
       

      var earnings = 0

      for (var index in filteredDataCount) {
        // earnings = earnings + filteredDataCount[index]
        //  
        earnings = earnings + filteredDataCount[index].get("money")
      }

       
      zeroData.push([categoryName, earnings])
       
    }

    const results = [{
      label: 'Returns',
      data: zeroData
    }, []];

     
    this.setState({ actualReturnsByCategoryData: results, showChartActual: true });
  }

  getOfficialNums() {
    const { numCompleted, numInProgress, numPendingEval, numCompletedOther, numInProgressOther, numPendingEvalOther, selectedOption, numSubmitted, numSubmittedOther, ideas, otherIdeaResults } = this.state;

    var officialNums = {}

    // Check if ideas and other ideas loaded


    if (selectedOption == 'All Sites') {

      officialNums.numCompleted = String(parseInt(numCompleted) + parseInt(numCompletedOther))
      officialNums.numInProgress = String(parseInt(numInProgress) + parseInt(numInProgressOther))
      officialNums.numPendingEval = String(parseInt(numPendingEval) + parseInt(numPendingEvalOther))
      officialNums.numSubmitted = String(parseInt(numSubmitted) + parseInt(numSubmittedOther))

    } else if (selectedOption == 'Site 1') {
      officialNums.numCompleted = parseInt(numCompleted)
      officialNums.numInProgress = parseInt(numInProgress)
      officialNums.numPendingEval = parseInt(numPendingEval)
      officialNums.numSubmitted = parseInt(numSubmitted)
    } else if (selectedOption == 'Site 2') {
      officialNums.numCompleted = parseInt(numCompletedOther)
      officialNums.numInProgress = parseInt(numInProgressOther)
      officialNums.numPendingEval = parseInt(numPendingEvalOther)
      officialNums.numSubmitted = parseInt(numSubmittedOther)
    }

    return officialNums

  }

  getDemoData() {
    const { numCompleted, numInProgress, numPendingEval, numCompletedOther, numInProgressOther, numPendingEvalOther, selectedOption, numSubmitted, numSubmittedOther, ideas, otherIdeaResults } = this.state;

    const demoData = {
      series: [
        {
          name: 'Ideas per Site',
          data: [
           
          ]
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        xaxis: {
          categories: ['Site 1', 'Site 2'],
        },
        yaxis: {
          title: {
            text: 'Number of Ideas'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val
            }
          }
        }
      }
    };

    if (selectedOption == 'All Sites') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: ideas.length,
        },
        {
          x: 'Site 2',
          y: otherIdeaResults.length,
        }
      ]

    } else if (selectedOption == 'Site 1') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: ideas.length,
        },
      ]

    } else if (selectedOption == 'Site 2') {
      
      demoData.series[0].data = [
        {
          x: 'Site 2',
          y: otherIdeaResults.length,
        },
      ]

    }

    return demoData
  }

  getCorrectIdeasByType() {
    const { numCompleted, numInProgress, numPendingEval, numCompletedOther, numInProgressOther, numPendingEvalOther, selectedOption, numSubmitted, numSubmittedOther, ideas, otherIdeaResults } = this.state;


    const innovationIdeas = ideas.filter(idea => idea.get("ideaType") == "innovacion").length
    const problemSolvingIdeas = ideas.filter(idea => idea.get("ideaType") == "problema").length
    const continuousImprovementIdeas = ideas.filter(idea => idea.get("ideaType") == "mejoras").length

    const innovationIdeasOther = otherIdeaResults.filter(idea => idea.get("ideaType") == "innovacion").length
    const problemSolvingIdeasOther = otherIdeaResults.filter(idea => idea.get("ideaType") == "problema").length
    const continuousImprovementIdeasOther = otherIdeaResults.filter(idea => idea.get("ideaType") == "mejoras").length

    var ideasByType = {}
    var datasets = []

    var data = ''

    if (selectedOption == 'All Sites') {

      data = [
        { label: 'Innovation', value: innovationIdeas + innovationIdeasOther, color: '#008FFB' },
        { label: 'Problem Solving', value: problemSolvingIdeas + problemSolvingIdeasOther, color: '#00E396' },
        { label: 'Continuous Improvement', value: continuousImprovementIdeas + continuousImprovementIdeasOther, color: '#CED4DC' },
      ];

     
    } else if (selectedOption == 'Site 1') {

      data = [
        { label: 'Innovation', value: innovationIdeas, color: '#008FFB' },
        { label: 'Problem Solving', value: problemSolvingIdeas, color: '#00E396' },
        { label: 'Continuous Improvement', value: continuousImprovementIdeas, color: '#CED4DC' },
      ];


    } else if (selectedOption == 'Site 2') {

      data = [
        { label: 'Innovation', value: innovationIdeasOther, color: '#008FFB' },
        { label: 'Problem Solving', value: problemSolvingIdeasOther, color: '#00E396' },
        { label: 'Continuous Improvement', value: continuousImprovementIdeasOther, color: '#CED4DC' },
      ];


    }

    

    

    return data

  }

  getEarningsBySiteData(type) {
    const { numCompleted, numInProgress, numPendingEval, numCompletedOther, numInProgressOther, numPendingEvalOther, selectedOption, numSubmitted, numSubmittedOther, ideas, otherIdeaResults } = this.state;
    const expectedEarningsPerSite = this.setupEarningsBySiteData(type)
    const expectedEarningSite1 = expectedEarningsPerSite[0]
    const expectedEarningsSite2 = expectedEarningsPerSite[1]
    const expectedEarningsAllSites = expectedEarningSite1 + expectedEarningsSite2

    const demoData = {
      series: [
        {
          name: 'Earnings per Site',
          data: []
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        yaxis: {
          title: {
            text: 'Site'
          }
        },
        xaxis: {
          title: {
            text: 'Expected Earnings'
          },
          labels: {
            formatter: function(val) {
              return '$' + val.toLocaleString()
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            formatter: function(val) {
              return '$' + val.toLocaleString()
            }
          }
        }
      }
    };
    

    if (selectedOption == 'All Sites') {


      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: expectedEarningSite1,
        },
        {
          x: 'Site 2',
          y: expectedEarningsSite2,
        }
      ]

    } else if (selectedOption == 'Site 1') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: expectedEarningSite1,
        },
      ]

    } else if (selectedOption == 'Site 2') {

      demoData.series[0].data = [
        {
          x: 'Site 2',
          y: expectedEarningsSite2,
        }
      ]

    }

    return demoData

  }

  getActualEarningsBySiteData() {
    const { numCompleted, numInProgress, numPendingEval, numCompletedOther, numInProgressOther, numPendingEvalOther, selectedOption, numSubmitted, numSubmittedOther, ideas, otherIdeaResults } = this.state;
    const expectedEarningsPerSite = this.setupActualEarningsBySiteData()
    const expectedEarningSite1 = expectedEarningsPerSite[0]
    const expectedEarningsSite2 = expectedEarningsPerSite[1]
    const expectedEarningsAllSites = expectedEarningSite1 + expectedEarningsSite2

    var demoData = {
      series: [
        {
          name: 'Earnings per Site',
          data: []
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        yaxis: {
          title: {
            text: 'Site'
          }
        },
        xaxis: {
          title: {
            text: 'Expected Earnings'
          },
          labels: {
            formatter: function(val) {
              return '$' + val.toLocaleString()
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            formatter: function(val) {
              return '$' + val.toLocaleString()
            }
          }
        }
      }
    };

    if (selectedOption == 'All Sites') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: expectedEarningSite1,
        },
        {
          x: 'Site 2',
          y: expectedEarningsSite2,
        }
      ]

    } else if (selectedOption == 'Site 1') {

      demoData = [
        demoData.series[0].data = [
          {
            x: 'Site 1',
            y: expectedEarningSite1,
          },
        ]
      ]

    } else if (selectedOption == 'Site 2') {

      demoData.series[0].data = [
        {
          x: 'Site 2',
          y: expectedEarningsSite2,
        }
      ]

    }

    return demoData

  }

  getPercentageIdeasEmployeesBySite() {
    const { numEmployees, numEmployeesOther, numCompleted, numInProgress, numPendingEval, numCompletedOther, numInProgressOther, numPendingEvalOther, selectedOption, numSubmitted, numSubmittedOther, ideas, otherIdeaResults } = this.state;
    const expectedEarningsPerSite = this.setupActualEarningsBySiteData()
    const expectedEarningSite1 = expectedEarningsPerSite[0]
    const expectedEarningsSite2 = expectedEarningsPerSite[1]
    const expectedEarningsAllSites = expectedEarningSite1 + expectedEarningsSite2

    const numEmployeesWithIdeasSite1 = this.getNumOfEmployeesWhoHaveSubmittedIdeas(ideas)
    const numEmployeesWithIdeasSite2 = this.getNumOfEmployeesWhoHaveSubmittedIdeas(otherIdeaResults)
    // alert(numEmployees)

    const demoData = {
      series: [
        {
          name: 'Employee Participation',
          data: [
           
          ]
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        xaxis: {
          categories: ['Site 1', 'Site 2'],
        },
        yaxis: {
          title: {
            text: 'Employee Participation'
          },
          max: 100,
          labels: {
            formatter: function (value) {
              return value + '%';
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val
            }
          }
        }
      }
    };

    const percentageSite1 = (numEmployeesWithIdeasSite1/numEmployees) * 100
    const percentageSite2 = (numEmployeesWithIdeasSite2/numEmployeesOther) * 100

    // alert(numEmployeesOther)

    if (selectedOption == 'All Sites') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: percentageSite1,
        },
        {
          x: 'Site 2',
          y: percentageSite2,
        }
      ]


    } else if (selectedOption == 'Site 1') {

      demoData.series[0].data = [
        {
          x: 'Site 1',
          y: percentageSite1,
        },
      ]

    } else if (selectedOption == 'Site 2') {
      demoData.series[0].data = [
        {
          x: 'Site 2',
          y: percentageSite2,
        },
      ]

    }

    return demoData

  }

  getIdeasByStatusData() {
    const { numCompleted, numInProgress, numPendingEval, numCompletedOther, numInProgressOther, numPendingEvalOther, selectedOption, numSubmitted, numSubmittedOther, ideas, otherIdeaResults, numVerified, numVerifiedOther } = this.state;

    const demoData = {
      series: [
        {
          name: 'Ideas by Status',
          data: [
           
          ]
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
 
        xaxis: {
          categories: ['Submitted', 'Evaluated', 'In Progress', 'Verified', 'R&R'],
        },
        yaxis: {
          title: {
            text: 'Number of Ideas'
          },
          labels: {
            formatter: function (value) {
              return value ;
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val
            }
          }
        }
      }
    };

    if (selectedOption == 'All Sites') {

      demoData.series[0].data = [
        {
          x: 'Submitted',
          y: toInteger(numPendingEval) + toInteger(numPendingEvalOther),
        },
        {
          x: 'Evaluated',
          y: toInteger(numCompleted) + toInteger(numCompletedOther),
        },
        {
          x: 'In Progress',
          y: toInteger(numInProgress) + toInteger(numInProgressOther),
        },
        {
          x: 'Verified',
          y: toInteger(numVerified) + toInteger(numVerifiedOther),
        },
        {
          x: 'R&R',
          y: 0,
        },
      ]

    } else if (selectedOption == 'Site 1') {

      demoData.series[0].data = [
        {
          x: 'Submitted',
          y: toInteger(numPendingEval),
        },
        {
          x: 'Evaluated',
          y: toInteger(numCompleted) ,
        },
        {
          x: 'In Progress',
          y: toInteger(numInProgress) ,
        },
        {
          x: 'Verified',
          y: toInteger(numVerified) ,
        },
        {
          x: 'R&R',
          y: 0,
        },
      ]

    } else if (selectedOption == 'Site 2') {

      demoData.series[0].data = [
        {
          x: 'Submitted',
          y: toInteger(numPendingEvalOther),
        },
        {
          x: 'Evaluated',
          y: toInteger(numCompletedOther),
        },
        {
          x: 'In Progress',
          y: toInteger(numInProgressOther),
        },
        {
          x: 'Verified',
          y: toInteger(numVerifiedOther),
        },
        {
          x: 'R&R',
          y: 0,
        },
      ]

    }

    return demoData
  }

  render() {
    const { t } = this.props;
    const { numCompleted, numInProgress, numPendingEval } = this.state;
     
    const labels = ['Completed Ideas', 'Ideas on Progress', 'Pending for Evaluation']
    const datasets = [{
      data: [Number.parseInt(numCompleted), Number.parseInt(numInProgress), Number.parseInt(numPendingEval)],
      backgroundColor: ['blue', 'green', 'red']
    }]
    const isPmo = Parse.User.current().get("pmo") == true

    const officialNums = this.getOfficialNums()

    const demoData = this.getDemoData()

    const ideasByType = this.getCorrectIdeasByType()

    const ideasPerCategory = this.setupNumberOfIdeasByCategoryData()

    const officialNumSubmitted = officialNums.numSubmitted
    const officialNumCompleted = officialNums.numCompleted
    const officialNumInProgress = officialNums.numInProgress
    const officialNumPendingEval = officialNums.numPendingEval

    // Expected earning per site
    const expectedEarningsBySiteData = this.getEarningsBySiteData('expected')
    const actualEarningsBySiteData = this.getActualEarningsBySiteData()
    
    // Ideas by status
    const ideasByStatusData = this.getIdeasByStatusData()

    // Ideas per employee
    const ideasPerEmployeeData = this.getPercentageIdeasEmployeesBySite()

    // Idea flow diagram
    const data = [
        { from: 'Source A', to: 'Destination X', value: 10 },
        { from: 'Source A', to: 'Destination Y', value: 5 },
        { from: 'Source B', to: 'Destination X', value: 7 },
        { from: 'Source B', to: 'Destination Y', value: 15 }
      ];
      
      
    //   ENPS Score
    const lineChartData = [0, 3, 4, 6, 6.5, 8.2];
    const approvalRateData = [
      {
        name: "Ideas Reviewed",
        data: [120, 135, 155, 170, 190, 200, 220]
      },
      {
        name: "Ideas Approved",
        data: [60, 75, 90, 105, 120, 135, 150]
      }
    ];

    const econImpactApprovalRate = [
      {
        name: "Econ FCST",
        data: Array.from(Array(6)).map(() => Math.floor(Math.random() * 100))
      },
      {
        name: "Ideas Reviewed",
        data: [20, 30, 45, 55, 70, 80]
      }
    ];
    

    // Area chart data
    const areaChartData = [6, 6.5, 8, 7.5, 8, 8.2];
    const ideasPerUserData = [0.5, 1, 2.1, 1, 0.1, 2];

    var smallStats = [
      {
        label: 'eNPS Score',
        value: 7.5,
        percentage: "12.4%",
        increase: true,
        chartLabels: [null, null, null, null, null],
        decrease: false,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.primary.toRGBA(0.1),
            borderColor: colors.primary.toRGBA(),
            data: [9, 3, 3, 9, 9]
          }
        ]
      },
      {
        label: 'eNPS Change ',
        value: 0.5,
        percentage: "7.21%",
        increase: false,
        chartLabels: [null, null, null, null, null],
        decrease: true,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.success.toRGBA(0.1),
            borderColor: colors.success.toRGBA(),
            data: [3.9, 4, 4, 9, 4]
          }
        ]
      },
      {
        label: 'Employee Sentiment',
        value: 0,
        percentage: "3.71%",
        increase: true,
        chartLabels: [null, null, null, null, null],
        decrease: false,
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: colors.warning.toRGBA(0.1),
            borderColor: colors.warning.toRGBA(),
            data: [6, 6, 9, 3, 3]
          }
        ]
      },
    ];

    // Top Individual Contributors
    const tableColumnsIndCont = [
      {
        Header: "Employee Name",
        accessor: "title",
        className: "text-center"
      },
      {
        Header: "Qty",
        accessor: "qty",
        className: "text-center",
        style: { 'white-space': 'unset' },
        maxWidth: 60,
      },
    ];

    const indContData = [
      {
        "id": 245,
        "title": 'John Doe Setup',
        "qty": 5
      },
      {
        "id": 246,
        "title": 'Jane Smith Marketing',
        "qty": 7
      },
      {
        "id": 247,
        "title": 'Bob Johnson Finance',
        "qty": 10
      },
      {
        "id": 248,
        "title": 'Samantha Lee HR',
        "qty": 3
      },
      {
        "id": 249,
        "title": 'David Kim IT',
        "qty": 8
      }
    ];
    
    const teamContData = [
      {
        "id": 245,
        "title": 'Setup',
        "qty": 5
      },
      {
        "id": 246,
        "title": 'Marketing',
        "qty": 7
      },
      {
        "id": 247,
        "title": 'Finance',
        "qty": 10
      },
      {
        "id": 248,
        "title": 'HR',
        "qty": 3
      },
      {
        "id": 249,
        "title": 'IT',
        "qty": 8
      }
    ];
    
    

    // Top Team Contributions
    const tableColumnsTeamCont = [
      {
        Header: "Department Name",
        accessor: "title",
        className: "text-center"
      },
      {
        Header: "Qty",
        accessor: "qty",
        className: "text-center",
        style: { 'white-space': 'unset' },
        maxWidth: 60,
      },
    ];

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          {/* Page Header :: Title */}
          {/* <Col xs="12" sm="12" className="col d-flex align-items-center justify-content-end">
            <FormSelect value={this.state.selectedOption} onChange={(selectedOption) => this.handleOptionChange(selectedOption)} style={{ width: '20%' }}>
              <option value="All Sites">All Sites</option>
              <option value="Site 1">Site 1</option>
              <option value="Site 2">Site 2</option>
            </FormSelect>
            <FormSelect value={this.state.selectedDateValue} onChange={(selectedDate) => this.handleDateChange(selectedDate)} style={{ width: '20%' }}>
              <option value="All Time">All Time</option>
              <option value="30">30 Days</option>
              <option value="60">60 Days</option>
              <option value="90">90 Days</option>
              <option value="365">1 Year</option>
            </FormSelect>
          </Col> */}
          <PageTitle title={'Employee Participation'} subtitle={t('REPORTS')} className="text-sm-left mb-3" />

          {/* Page Header :: Actions */}

          {/* Page Header :: Datepicker */}
          {/* <Col sm="4" className="d-flex">
        <RangeDatePicker className="justify-content-end" />
      </Col> */}
        </Row>

        {/* Small Stats Blocks */}
        <Row>
          {smallStats.map((stats, idx) => (
            <Col key={idx} md="6" lg="4" className="mb-4">
              <Card>
              <CardBody style={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>
              <CardTitle style={{textAlign: 'center', marginTop: 32, marginBottom: 20}}>{stats.label}</CardTitle>
              <div style={{textAlign: 'center', marginBottom: 32, display: 'flex', justifyContent: 'center'}}>
                {isNaN(stats.value) ? <ReactLoading type={'spokes'} color={'#633FDA'} width={40} height={40} /> : <h3>{stats.value ==0?'Neutral':stats.value}</h3>}
              </div>
             
                {/* <CardBody>
                  <CardSubtitle>{stats.label}</CardSubtitle>
                 
                  {isNaN(stats.value) ? <ReactLoading type={'spokes'} color={'#633FDA'} width={40} height={40} /> : <h3>{stats.value}</h3>}

                </CardBody> */}
                </CardBody>
              </Card>
            </Col>
          ))}

          <Col lg="12" sm="12" className="mb-4">
            <Card>
              <CardBody>
                <CardTitle>{'Employee NPS'}</CardTitle>
                
                {/* <SankeyChart data={data} /> */}
                <LineChart data={lineChartData}></LineChart>
              
              </CardBody>
              <CardFooter>
              </CardFooter>
            </Card>

          </Col>

          <Col lg="6" sm="12" className="mb-4">
            <Card>
              <CardBody style={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>
              <CardTitle style={{textAlign: 'center', marginTop: 32, marginBottom: 32}}>{'Avg. Participation (YTD)'}</CardTitle>
              <h2 style={{textAlign: 'center'}}>3%</h2>
              <AreaChart data={areaChartData} ></AreaChart>
                  {/* <Pie data={{ labels: ideasByType.labels, datasets: ideasByType.datasets }} options={{ maintainAspectRatio: false, responsive: 'true' }} /> */}
                  
                  {/* <PieChart data={ideasByType}></PieChart> */}
                

              </CardBody>
              
            </Card>
            {/* <Chart data={data} axes={axes} /> */}
          </Col>

          <Col lg="6" sm="12" className="mb-4">
            <Card>
              <CardBody style={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>
              <CardTitle style={{textAlign: 'center', marginTop: 32, marginBottom: 32}}>{'Avg. Ideas per User (YTD)'}</CardTitle>
              <h2 style={{textAlign: 'center'}}>0.5</h2>
              <AreaChart data={ideasPerUserData} ></AreaChart>
                  {/* <Pie data={{ labels: ideasByType.labels, datasets: ideasByType.datasets }} options={{ maintainAspectRatio: false, responsive: 'true' }} /> */}
                  
                  {/* <PieChart data={ideasByType}></PieChart> */}
                

              </CardBody>
              
            </Card>
            {/* <Chart data={data} axes={axes} /> */}
          </Col>

          <Col lg="6" sm="12" className="mb-4">
            <Card>
            <CardBody>
              <CardTitle>{'Top Individual Contributors'}</CardTitle>
             
              <ReactTable
                columns={tableColumnsIndCont}
                data={indContData}
                pageSize={5}
                showPageSizeOptions={false}
                resizable={false}
              />
            </CardBody>
              
            </Card>
          </Col>

           <Col lg="6" sm="12" className="mb-4">
            <Card>
            <CardBody>
              <CardTitle>{'Top Team Contributions'}</CardTitle>
              <ReactTable
                columns={tableColumnsIndCont}
                data={teamContData}
                pageSize={5}
                showPageSizeOptions={false}
                resizable={false}
              />
            </CardBody>
              
            </Card>
          </Col>                

          {/* Sankey */}
          <Col lg="12" sm="12" className="mb-4">
            <Card>
            <CardBody>
              <CardTitle>{'Employee Insights Flow'}</CardTitle>
              <SankeyChart></SankeyChart>
            </CardBody>
              
            </Card>
          </Col>

          {/* Earnings */}
          <Col lg="12" sm="12" className="mb-4">
            <Card>
            <CardBody>
              <CardTitle>{'Trending Topics'}</CardTitle>
              {/* <TreeMap></TreeMap> */}
            </CardBody>
              
            </Card>
          
          </Col>


          <Col lg="12" sm="12" className="mt-4 mb-4">
            <PageTitle title={'Committee Performance'} subtitle={''} className="text-sm-left mb-3" />
          </Col>

          <Col lg="4" sm="12" className="mb-4">
            <Card>
              <CardBody style={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>
              <CardTitle style={{textAlign: 'center', marginTop: 32, marginBottom: 32}}>{'Avg. Approval (%)'}</CardTitle>
              <h2 style={{textAlign: 'center'}}>50%</h2>
              <AreaChart data={areaChartData} ></AreaChart>
                  {/* <Pie data={{ labels: ideasByType.labels, datasets: ideasByType.datasets }} options={{ maintainAspectRatio: false, responsive: 'true' }} /> */}
                  
                  {/* <PieChart data={ideasByType}></PieChart> */}
                

              </CardBody>
              
            </Card>
            {/* <Chart data={data} axes={axes} /> */}
          </Col>

          <Col lg="4" sm="12" className="mb-4">
            <Card>
              <CardBody style={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>
              <CardTitle style={{textAlign: 'center', marginTop: 32, marginBottom: 32}}>{'Avg. Econ FCST. (USD)'}</CardTitle>
              <h2 style={{textAlign: 'center'}}>$10,000</h2>
              <AreaChart data={ideasPerUserData} ></AreaChart>
                  {/* <Pie data={{ labels: ideasByType.labels, datasets: ideasByType.datasets }} options={{ maintainAspectRatio: false, responsive: 'true' }} /> */}
                  
                  {/* <PieChart data={ideasByType}></PieChart> */}
                

              </CardBody>
              
            </Card>
            {/* <Chart data={data} axes={axes} /> */}
          </Col>

          <Col lg="4" sm="12" className="mb-4">
            <Card>
              <CardBody style={{ padding: 0, alignItems: 'center', justifyContent: 'center' }}>
              <CardTitle style={{textAlign: 'center', marginTop: 32, marginBottom: 32}}>{'Avg. Response Time (Days)'}</CardTitle>
              <h2 style={{textAlign: 'center'}}>25</h2>
              <AreaChart data={ideasPerUserData} ></AreaChart>
                  {/* <Pie data={{ labels: ideasByType.labels, datasets: ideasByType.datasets }} options={{ maintainAspectRatio: false, responsive: 'true' }} /> */}
                  
                  {/* <PieChart data={ideasByType}></PieChart> */}
                

              </CardBody>
              
            </Card>
            {/* <Chart data={data} axes={axes} /> */}
          </Col>
         

      <Col lg="6" sm="12" className="mb-4">
       <Card>
       <CardBody>
        <CardTitle>{'Evaluation: Approval Rate'}</CardTitle>
        <MultiLineChart type="evaluation" data={approvalRateData}></MultiLineChart>
      
      </CardBody>
      <CardFooter>
       
        </CardFooter>
    </Card>
        
      </Col>
      
      <Col lg="6" sm="12" className="mb-4">
       <Card>
       <CardBody>
        <CardTitle>{'Economic Impact: Approval Rate'}</CardTitle>
        <MultiLineChart data={econImpactApprovalRate}></MultiLineChart>
      
      </CardBody>
      <CardFooter>
       
        </CardFooter>
    </Card>
        
      </Col>
      {/* <Col lg="6" sm="12" className="mb-4">
        <Card>
          <CardBody>
            <CardTitle>Participation per Site</CardTitle>
            <ApexChart></ApexChart>
          </CardBody>
        <CardFooter>
          </CardFooter>
       </Card> 
      </Col> */}

        </Row>

      </Container>
    )
  }
}
// const Analytics = ({ smallStats }) => (
//   <Container fluid className="main-content-container px-4">
//     <Row noGutters className="page-header py-4">
//       {/* Page Header :: Title */}
//       <PageTitle title="Ver Reportes" subtitle="Reportes" className="text-sm-left mb-3" />

//       {/* Page Header :: Actions */}
//       {/* <Col xs="12" sm="4" className="col d-flex align-items-center">
//         <ButtonGroup size="sm" className="d-inline-flex mb-3 mb-sm-0 mx-auto">
//           <Button theme="white" tag={NavLink} to="/analytics">
//             Personal
//           </Button>
//           <Button theme="white" tag={NavLink} to="/ecommerce">
//             Admin
//           </Button>
//         </ButtonGroup>
//       </Col> */}

//       {/* Page Header :: Datepicker */}
//       {/* <Col sm="4" className="d-flex"> */}
//         {/* <RangeDatePicker className="justify-content-end" /> */}
//       {/* </Col> */}
//     </Row>

//     {/* Small Stats Blocks */}
//     <Row>
//       {smallStats.map((stats, idx) => (
//         <Col key={idx} md="6" lg="4" className="mb-4">
//           <SmallStats
//             id={`small-stats-${idx}`}
//             chartData={stats.datasets}
//             chartLabels={stats.chartLabels}
//             label={stats.label}
//             value={stats.value}
//             percentage={stats.percentage}
//             increase={stats.increase}
//             decrease={stats.decrease}
//           />
//         </Col>
//       ))}
//     </Row>

//     <Row>
//       {/* Top Referrals */}
//       <Col lg="6" sm="6" className="mb-4">
//         <IdeasTable/>
//         {/* <UserPerformance/> */}
//       </Col>

//       {/* Goals Overview */}
//       <Col lg="6" className="mb-4">
//         <GoalsOverview title="Idea Progress"/>
//       </Col>
//     </Row>
//   </Container>
// );

EnterpriseR2.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

EnterpriseR2.defaultProps = {
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default withTranslation()(EnterpriseR2);